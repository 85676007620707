/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { ProductCategory } from './product-categories';
import { ProductVariant } from './product-variants';
import { isSubscriber } from './subscriptions';
import { User, UserRole } from './users';

export function getProductPriceBasedOnCurrentUser(
  product: Product,
  currentUser: Partial<User> | null,
): number {
  if (isSubscriber(currentUser as User)) {
    return product.subscriberPrice;
  }

  return product.price;
}

export interface Product {
  id: number
  name: string
  description: string
  price: number
  subscriberPrice: number
  isActive: boolean
  availableTo: UserRole
  productCategory: ProductCategory
  numberOfViews: number
  createdBy: User
  updatedBy: User
  createdAt: string
  updatedAt: string
  productVariants: ProductVariant[]
  content: string
}

export const productFullDetailsFragment = gql`
  fragment productFullDetailsFragment on ProductEntity {
    id
    name
    description
    price
    subscriberPrice
    isActive
    availableTo
    content
    productVariants {
      id
      productImages
      stockQuantity
      content
      productFilters {
        id
        value
        order
        productFilterCategory {
          id
          filterName
        }
      }
    }
    productCategory {
      id
      name
      productFilterCategories {
        id
        filterName
        filterValues {
          id
          value
          order
        }
      }
    }
    numberOfViews
    createdBy {
      id
      username
    }
    updatedBy {
      id
      username
    }
    createdAt
    updatedAt
  }
`;

export const productMinimumDetailsFragment = gql`
  fragment productMinimumDetailsFragment on ProductEntity {
    id
    name
    description
    price
    subscriberPrice
    isActive
    availableTo
    productVariants {
      id
      productImages
      stockQuantity
      content
    }
    productCategory {
      id
      name
    }
    numberOfViews
    createdBy {
      id
      username
    }
    createdAt
  }
`;

// Mutations
export const createProductGql = gql`
  mutation createProduct($input: ICreateProduct!) {
    createProduct(input: $input)
  }
`;

export const updateProductGql = gql`
  mutation updateProduct($input: IUpdateProduct!) {
    updateProduct(input: $input)
  }
`;

export const deleteProductGql = gql`
  mutation deleteProduct($input: IDeleteProduct!) {
    deleteProduct(input: $input)
  }
`;

// Queries
export const getProductByIdGql = gql`
  query getProductById($id: Int!) {
    getProductById(id: $id) {
      ...productFullDetailsFragment
    }
  }
  ${productFullDetailsFragment}
`;

export const listProductsGql = gql`
  query listProducts($filter: IProductFilter!) {
    listProducts(filter: $filter) {
      ...productMinimumDetailsFragment
    }
  }
  ${productMinimumDetailsFragment}
`;

export const listRecommendedProductsGql = gql`
  query listProducts($filter: IProductFilter!) {
    listProducts(filter: $filter) {
      id
      name
      price
      subscriberPrice
      productVariants {
        productImages
      }
    }
  }
`;
