/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import { useLayoutStyles } from 'styles/layout';
import { APP_ROUTES } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useQuery } from '@apollo/client';
import { listHighlightedPublicationsGql, Publication } from 'data/publications';
import HighlightedNewsCarousel from './highlighted-news-carousel';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  layout: {
    ...useLayoutStyles(theme),
  },
  carouselWraper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function HighlightedNews() {
  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(
    listHighlightedPublicationsGql, {
    variables: {
      filter: {
        limit: 999,
        offset: 0,
      },
    },
  },
  );

  const data: Publication[] = rawData?.listHighlightedPublications || [];

  if (!data?.length) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.layout}>
        <div className={classes.wrapper}>
          <SectionTitle>
            Em destaque
          </SectionTitle>

          <DataRenderer error={formatGraphqlErrorMessage(error)} loading={loading}>
            <div className={classes.carouselWraper}>
              <HighlightedNewsCarousel
                steps={data.map((publication, index) => ({
                  index,
                  label: publication.name,
                  image: publication.image,
                  description: publication.description,
                  href: `${APP_ROUTES.publication}/${publication.id}`,
                }))}
              />
            </div>
          </DataRenderer>
        </div>
      </div>
    </div>
  );
}
