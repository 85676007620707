/* eslint linebreak-style: ["error", "unix"] */

import Typography from '@material-ui/core/Typography/Typography';
import { makeStyles } from '@material-ui/core';
import { ANJB_CONSTANTS, APP_ROUTES } from 'data/app';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import Newsletter from 'components/newsletter/newsletter';
import Partners from 'components/partners/partners';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    minHeight: theme.spacing(8),
    background: theme.palette.secondary.main,
  },
  spacer: {
    marginRight: theme.spacing(2),
  },
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.secondary.light,
    margin: theme.spacing(4, 0),
  },
  endingSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      minHeight: theme.spacing(12),
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },
  linkList: {
    display: 'flex',
    flexDirection: 'column',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  linkItem: {
    listStyle: 'none',
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  link: {
    color: theme.palette.common.white,
    fontWeight: 200,

    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  allRightsReserved: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
  },
  socialSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  socialIconButton: {
    border: 'none',
    minWidth: 'unset',
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  font: {
    color: theme.palette.common.white,
  },
}));

export default function Footer() {
  const classes = useStyles();
  const currentYear = moment().get('year');

  return (
    <footer className={classes.footer}>
      <section className={classes.socialSection}>
        <Newsletter />
      </section>

      <div className={classes.divider} />
      <Partners />
      <div className={classes.divider} />

      <section className={classes.endingSection}>
        <ul className={classes.linkList}>
          <li className={classes.linkItem}>
            <a className={classes.link} href={APP_ROUTES.termsAndConditions}>
              <Typography className={classes.font}>
                Termos e Condições
              </Typography>
            </a>
          </li>
          <li className={classes.linkItem}>
            <a className={classes.link} href={APP_ROUTES.privacyPolicy}>
              <Typography className={classes.font}>
                Política de Privacidade
              </Typography>
            </a>
          </li>
        </ul>

        <div className={classes.allRightsReserved}>
          <Typography
            className={classes.font}
            align="center"
          >
            A.N.J.B. ©
            {' '}
            {currentYear}
            {' '}
            <br />
            {' '}
            Todos os direitos reservados
          </Typography>
        </div>

        <div className={classes.socialSection}>
          <a
            className={classes.socialIconButton}
            href={ANJB_CONSTANTS.social.facebook}
          >
            <FacebookIcon />
          </a>

          <a
            className={classes.socialIconButton}
            href={ANJB_CONSTANTS.social.instagram}
          >
            <InstagramIcon />
          </a>
        </div>
      </section>
    </footer>
  );
}
