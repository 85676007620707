/* eslint linebreak-style: ["error", "unix"] */
export const mjmlHeader = (text: string) => `
  <mj-section full-width="full-width">
    <mj-column>
      <mj-text font-size="20px" color="#F45E43">
        ${text}
      </mj-text>
    </mj-column>
  </mj-section>
`;

export const mjmlText = (text: string) => `
  <mj-section full-width="full-width" padding="0px;">
    <mj-column>
      <mj-text font-size="14px">
        ${text}
      </mj-text>
    </mj-column>
  </mj-section>
`;

export const mjmlInlineText = (text: string) => `
  <mj-text font-size="14px">
    ${text}
  </mj-text>
`;

export const mjmlBold = (text: string) => `<span style="font-weight:bold;">${text}</span>`;

export const mjmlItalic = (text: string) => `<span style="font-style:italic;">${text}</span>`;
export const mjmlUnderline = (text: string) => `<span style="text-decoration:underline;">${text}</span>`;

export const mjmlStrike = (text: string) => `<span style="text-decoration:line-through;">${text}</span>`;

export const mjmlInlineLink = (text: string, href: string) => `<span><a href="${href}">${text}</a></span>`;

export const mjmlLink = (text: string, href: string) => `<mj-section full-width="full-width"><mj-column><mj-text font-size="14px"><a href="${href}">${text}</a></mj-text></mj-column></mj-section>`;

export const mjmlImage = (image: string) => `
  <mj-section full-width="full-width" padding="0px;">
    <mj-column>
      <mj-image src="${image}" />
    </mj-column>
  </mj-section>
`;

export const mjmlRoot = (text: string) => `<mjml><mj-body>${text}</mj-body></mjml>`;
