/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
} from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

import Spacer from 'components/spacer/spacer';
import { useQuery } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import { getOwnProductOrderPaymentByReferenceGql, ProductOrderPayment } from 'data/product-order-payments';
import { PaymentMethod, PaymentStatus } from 'data/payments';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  id: number | string;
  productOrderPayment: ProductOrderPayment;
  customerPhone?: number | string;
  onPaymentCompleted: () => void;
}

export default function ProductOrderPaymentPendingDetails({
  id,
  productOrderPayment,
  customerPhone,
  onPaymentCompleted = () => { },
}: Props) {
  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(getOwnProductOrderPaymentByReferenceGql,
    {
      variables: {
        input: {
          productOrderId: id,
          reference: productOrderPayment?.reference,
        },
      },
      pollInterval: 30 * 1000,
      skip: Boolean(id) === false,
      onCompleted: (rawData) => {
        const data: ProductOrderPayment = rawData?.getOwnProductOrderPaymentByReference;

        if (data.paymentStatus === PaymentStatus.PAID) {
          onPaymentCompleted();
        }
      },
    });

  const data: ProductOrderPayment = rawData?.getOwnProductOrderPaymentByReference;

  return (
    <section className={classes.container}>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        <Typography variant="h6" color="primary">
          Estado do Pagamento
        </Typography>
        <Divider />
        <Spacer spacing={4} />

        <Typography variant="subtitle1" color="textSecondary">
          A aguardar pagamento
        </Typography>
        <Typography variant="caption" color="textSecondary">
          (Atualiza automaticamente a cada 30 segundos)
        </Typography>

        <Spacer spacing={4} />

        {data?.paymentMethod === PaymentMethod.MULTIBANCO
          ? renderMultibanco(data?.entity, data.reference, data.amount)
          : renderMbway(customerPhone)}
      </DataRenderer>
    </section>
  );
}

export const renderMultibanco = (entity: string | null, reference: string, amount: number) => (
  <>
    {renderInfo('Método do Pagamento', PaymentMethod.MULTIBANCO)}

    <Typography variant="subtitle2" color="primary">
      Instruções de Pagamento
    </Typography>
    <Spacer spacing={1} />
    <Typography variant="subtitle2">
      Para completar o pagamento, dirige-te a um
      equipamento multibanco e seleciona:
    </Typography>
    <Spacer spacing={1} />
    <Typography variant="subtitle2">
      “Pagamentos e Outros Serviços” &gt; “Pagamento de Serviços” e,
      de seguida, introduz os seguintes valores nos campos Entidade, Referência e Montante:
    </Typography>
    <Spacer spacing={2} />

    {entity && renderInfo('Entidade', entity)}
    {renderInfo('Referência', reference)}
    {renderInfo('Montante a Pagar', `${amount}€`)}
  </>
);

export const renderMbway = (customerPhone: number | string | null | undefined) => (
  <>
    {renderInfo('Método do Pagamento', PaymentMethod.MB_WAY)}

    <Typography variant="subtitle1" color="primary">
      Instruções de Pagamento
    </Typography>
    <Spacer spacing={1} />
    <Typography variant="subtitle2">
      Uma notificação com instruções de pagamento foi enviada para o número de telemóvel:
    </Typography>
    <Spacer spacing={1} />
    <Typography variant="subtitle1">
      {customerPhone}
    </Typography>
    <Spacer spacing={2} />

    <Typography variant="subtitle2">
      Por favor confirma a compra pela tua aplicação MBWay num prazo de 5 minutos.
    </Typography>
  </>
);

const renderInfo = (label: string, value: string) => (
  <>
    <Typography variant="subtitle1" color="primary">
      {label}
    </Typography>
    <Typography variant="subtitle2">
      {value}
    </Typography>
    <Spacer spacing={2} />
  </>
);
