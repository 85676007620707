/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import Search from 'components/search/search';
import MobileNavbarButton from 'components/buttons/button-mobile-navbar-menu/mobile-navbar-menu-button';

const useStyles = makeStyles((theme) => ({
  navbarLinks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  iconWrapper: {
    marginLeft: theme.spacing(4),
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',

    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}));

export default function MobileNavbar() {
  const classes = useStyles();

  return (
    <div className={classes.navbarLinks}>
      <div className={classes.iconWrapper}>
        <Search />
      </div>

      <div className={classes.iconWrapper}>
        <MobileNavbarButton />
      </div>
    </div>
  );
}
