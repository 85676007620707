/* eslint linebreak-style: ["error", "unix"] */
import moment from 'moment';

export const formatBool = (value?: boolean): string => (value ? 'Sim' : 'Não');

/**
 * Ouputs: '14/06/2020, 23:12'
 */
export const formatToDateAndHour = (
  dateString: string | null | undefined,
  separator: string = ',',
): string => {
  if (!dateString || typeof dateString !== 'string') {
    return '';
  }

  return moment(dateString).format(`DD/MM/YY[${separator}] HH:MM`);
};

/**
 * Ouputs: '14/05'
 */
export const formatToDayAndMonth = (
  str: string,
): string => moment(str).format('DD/MM');

/**
 * Ouputs: '14/05/21'
 */
export const formatToDayAndMonthAndYear = (
  str: string,
): string => moment(str).format('DD/MM/YY');

/**
 * Converts a DD/MM/YYYY date to YYYY-MM-DD

 * HTML5 Datepicker requires this format to work
 */
export const formatDateToYYYYMMDD = (
  value: string | undefined,
): string | undefined => {
  const splitDate = value?.split('/');

  if (splitDate?.length === 1) {
    return value;
  }

  return `${splitDate?.[2]}-${splitDate?.[1]}-${splitDate?.[0]}`;
};
