/* eslint linebreak-style: ["error", "unix"] */

import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store, persistor } from 'libs/redux/redux.store';
import { client } from 'libs/graphql/graphql.apollo-client';
import { PersistGate } from 'redux-persist/integration/react';
import moment from 'moment';
import 'moment/locale/pt';
import Firebase, { FirebaseContext } from 'libs/firebase/firebase';
import { ApolloProvider } from '@apollo/client';
import App from './_app/_app';

moment.locale('pt');

function WrappedApp() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <FirebaseContext.Provider value={new Firebase()}>
              <App />
            </FirebaseContext.Provider>
          </Router>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
}

render(WrappedApp(), document.getElementById('root'));
