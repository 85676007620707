/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import Page from 'pages/_layouts/page';
import SectionTitle from 'components/section-title/section-title';
import ShoppingCartBuyerDetailsForm from 'components/forms/form-shopping-cart-buyer-details/shopping-cart-buyer-details-form';
import { useSelector } from 'react-redux';
import { selectShoppingItems } from 'redux/shopping-cart.redux';
import { useQuery } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import { listProductVariantsGql, ProductVariant } from 'data/product-variants';
import OrderedProductsList from 'components/lists/list-ordered-products/ordered-products-list';
import ShoppingCartSummary from 'components/details/details-shopping-cart-summary/shopping-cart-summary';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import { selectCurrentUser } from 'redux/auth.redux';
import { UserRole } from 'data/users';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },
}));

export default function ShoppingCartPreorderPage() {
  const classes = useStyles();

  const shoppingItems = useSelector(selectShoppingItems) || [];
  const shoppingCartIsEmpty = !shoppingItems?.length;

  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.role === UserRole.ADMIN;

  const { data: rawData, loading, error } = useQuery(listProductVariantsGql, {
    variables: {
      filter: {
        productVariantIds: shoppingItems.map((shoppingItem) => shoppingItem.productVariantId) || [],
      },
    },
    skip: shoppingCartIsEmpty, // If we don't skip it, listProductVariants returns all productVariants
    fetchPolicy: 'cache-and-network',
  });

  const data: ProductVariant[] = rawData?.listProductVariants || [];

  return (
    <Page>
      <SectionTitle>
        Carrinho de compras
      </SectionTitle>

      <section className={classes.container}>
        {shoppingCartIsEmpty
          ? (
            <>
              <Typography>
                Carrinho de compras vazio
              </Typography>
              <Spacer spacing={2} />
              <PrimaryLink to={APP_ROUTES.store}>
                Visitar a loja
              </PrimaryLink>
            </>
          )
          : (
            <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>

              <OrderedProductsList productVariants={data} readonly={false} />

              <ShoppingCartSummary productVariants={data} />

              {currentUser ? <ShoppingCartBuyerDetailsForm productVariantsInShoppingCart={data} /> : (
                <>
                  <Typography>
                    É necessária uma conta de utilizador para prosseguir com a compra.
                  </Typography>
                  <Spacer spacing={2} />
                  <PrimaryLink to={`?${ModalQueryParams.SIGN_IN}`}>
                    Iniciar Sessão
                  </PrimaryLink>
                  <Spacer spacing={2} />
                  <PrimaryLink to={`?${ModalQueryParams.SIGN_UP}`}>
                    Torna-te sócio
                  </PrimaryLink>
                </>
              )}
            </DataRenderer>
          )}
      </section>
    </Page>
  );
}
