/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles, Typography } from '@material-ui/core';
import DataRenderer from 'components/data-renderer/data-renderer';
import { useLocation } from 'react-router-dom';
import { extractIdFromLocation } from 'libs/router/router.utils';
import { useQuery } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { APP_ROUTES } from 'data/app';
import Spacer from 'components/spacer/spacer';
import SectionTitle from 'components/section-title/section-title';

import PublicationsList from 'components/lists/list-publications/publications-list';
import Page from 'pages/_layouts/page';
import { getPublicationCategoryByIdGql, PublicationCategory } from 'data/publication-categories';

const useStyles = makeStyles((theme) => ({
  publicationsList: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
}));

const getBreadcrumbs = (publicationCategoryName?: string) => [
  {
    displayName: 'Início',
    to: APP_ROUTES.home,
  },
  {
    displayName: publicationCategoryName,
  },
];

export default function PublicationsByCategoryPage() {
  const classes = useStyles();
  const location = useLocation();
  const publicationCategoryId = extractIdFromLocation(location.pathname);

  const { data: rawData, loading, error } = useQuery(getPublicationCategoryByIdGql, {
    variables: {
      id: publicationCategoryId,
    },
    skip: !publicationCategoryId,
  });

  const data: PublicationCategory = rawData?.getPublicationCategoryById;
  const breadcrumbs = getBreadcrumbs(data?.name);

  return (
    <Page breadcrumbs={breadcrumbs}>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        <Spacer spacing={4} />

        <SectionTitle>
          {data?.name}
        </SectionTitle>

        <Typography variant="subtitle2">
          A mostrar todas as publicações da categoria
          {' '}
          <strong>{data?.name}</strong>
          .
        </Typography>

        <section className={classes.publicationsList}>
          <PublicationsList
            initialOffset={0}
            searchFilters={{ publicationCategories: [publicationCategoryId] }}
          />
        </section>

        <Spacer spacing={8} />
      </DataRenderer>
    </Page>
  );
}
