/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import { useModalStyles } from 'styles/modal.styles';
import FormDocument from 'components/forms/form-document/form-document';

export default function ModalCreateDocument() {
  const classes = useModalStyles();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.CREATE_NEW_DOCUMENT);

  return (
    <Modal className={classes.modal} open={isOpen} onClose={handleClose} closeButtonComponent={CloseButtonWhite} hasCloseButton>
      <SectionTitle>Criar documento</SectionTitle>
      <FormDocument
        onSuccess={handleClose}
      />
    </Modal>
  );
}
