/* eslint linebreak-style: ["error", "unix"] */
import { useMutation, useQuery } from '@apollo/client';
import {
  Button, CircularProgress, makeStyles, Typography,
} from '@material-ui/core';
import DataRenderer from 'components/data-renderer/data-renderer';
import { membershipPerksBulletpoints, Perk } from 'components/membership-perk/membership-perk';
import Spacer from 'components/spacer/spacer';
import {
  getSubscriptionPlanByUserIdGql, SubscriptionPlan,
} from 'data/subscription-plans';
import { createSubscriptionGql } from 'data/subscriptions';
import { User } from 'data/users';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  joinButton: {
    display: 'flex',
    width: '100%',

    '&.MuiButton-contained': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
}));

interface Props {
  user: User
  onCompleted: () => void;
}

export default function SubscribeForm({ user, onCompleted }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, loading, error } = useQuery(getSubscriptionPlanByUserIdGql, {
    variables: {},
  });

  const data: SubscriptionPlan = rawData?.getSubscriptionPlanByUserId;

  const [mutate, { loading: isSubmitting }] = useMutation(createSubscriptionGql, {
    onCompleted: (data) => {
      enqueueSnackbar('Pedido de subscrição criado com sucesso', { variant: 'success' });

      onCompleted();
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  const onSubmit = (event: any) => {
    event?.preventDefault();

    mutate({
      variables: {
        input: {
          subscriptionPlanId: data?.id,
        },
      },
    });
  };

  // Need to return early because we need the subscription plan data from the backend to perform the next calculations
  if (!data || loading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      <form onSubmit={onSubmit}>
        {membershipPerksBulletpoints.map((perk, index) => (
          <div key={index}>
            <Perk perk={perk} />
          </div>
        ))}
        <Spacer spacing={2} />

        <Button type="submit" color="primary" className={classes.joinButton} variant="contained" disabled={isSubmitting}>
          Aderir ao plano
          {' '}
          {data?.name}
        </Button>
      </form>
    </DataRenderer>
  );
}
