/* eslint linebreak-style: ["error", "unix"] */
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { listDocumentsGql, Document } from 'data/documents';
import Table from 'components/table/table';
import useTable from 'hooks/useTable';
import DownloadLink from 'components/links/link-download/download-link';

interface Props {
  documentCategoryId: number
}

export default function DocumentsList({ documentCategoryId }: Props) {
  const {
    limit, setLimit, offset, setOffset, currentPage, setCurrentPage,
  } = useTable();

  const { data: rawData, loading, error } = useQuery(listDocumentsGql, {
    variables: {
      filter: {
        documentCategories: [documentCategoryId],
      },
    },
  });

  const data: Document[] = rawData?.listDocuments || [];

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      <Table
        columns={
          [
            {
              title: 'ID',
              field: 'id',
              defaultSort: 'desc',
              width: 32,
            },
            {
              title: 'Nome',
              field: 'name',
            },
            {
              title: 'Descrição',
              field: 'description',
            },
            {
              title: 'Transferir',
              render: (document: Document) => <DownloadLink bucketName={document.bucketName} bucketFile={document.bucketFile} />,
              width: 32,
            },
          ]
        }
        data={data.map((document) => ({
          id: document?.id,
          name: document?.name,
          description: document?.description,
          bucketName: document?.bucketName,
          bucketFile: document?.bucketFile,
        }))}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isSearching={false}
      />
    </DataRenderer>
  );
}
