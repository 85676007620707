/* eslint linebreak-style: ["error", "unix"] */
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  CircularProgress, FormHelperText, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { listSubscriptionPlansGql, SubscriptionPlan } from 'data/subscription-plans';
import { Control, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(2, 0),

    '& .MuiSelect-select': {
      color: theme.palette.common.white,
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      borderColor: theme.palette.common.white,
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderTop: `1px solid ${theme.palette.common.white}`,
      color: theme.palette.common.white,
    },
    '&.MuiInput-underline:after': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.white,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  name: string
  label: string
  control: Control<any>
  errors: Record<string, any>
}

export default function SubscriptionPlansSelect({
  name,
  label,
  control,
  errors,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, loading } = useQuery(listSubscriptionPlansGql, {
    variables: {
      filter: {
        offset: 0,
        limit: 999,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  const data: SubscriptionPlan[] = rawData?.listSubscriptionPlans || [];
  const options = data.map((subscriptionPlan) => ({
    label: subscriptionPlan.name,
    description: subscriptionPlan.description,
    value: subscriptionPlan.id,
  }));

  return (
    <>
      <Controller
        render={({ onChange, value, name }) => (
          <>
            <Select
              className={classes.select}
              name={name}
              label={label}
              value={options.find((option) => option.value === value)}
              inputProps={{
                name,
              }}
              displayEmpty
              onChange={(evt) => onChange(evt.target.value)}
            >
              <MenuItem disabled>
                Escolher categoria
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value} className={classes.item}>
                  <Typography>
                    {option.label}
                  </Typography>
                  <Typography variant="caption">
                    {option.description}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {errors && <FormHelperText className={classes.helperText}>{errors?.[name]?.message}</FormHelperText>}
          </>
        )}
        name={name}
        control={control}
      />
    </>
  );
}
