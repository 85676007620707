/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import FormProductVariant from 'components/forms/form-product-variant/form-product-variant';
import { useModalStyles } from 'styles/modal.styles';

export default function ModalCreateProductVariant() {
  const classes = useModalStyles();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.CREATE_NEW_PRODUCT_VARIANT);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <SectionTitle>Criar variante de produto</SectionTitle>
      <FormProductVariant
        onSuccess={() => {
          handleClose();
        }}
      />
    </Modal>
  );
}
