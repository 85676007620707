/* eslint linebreak-style: ["error", "unix"] */
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as yup from 'yup';
import { ANJB_CONSTANTS } from 'data/app';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { stringRequired } from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'column',

    '& .MuiButton-contained': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
    },
  },
}));

const validationSchema = yup.object().shape({
  name: stringRequired,
  subject: stringRequired,
  content: stringRequired,
});

export default function ContactUsForm() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const formApi = useForm<Record<string, any>>({
    defaultValues: {
      name: currentUser ? currentUser.username : '',
      subject: '',
      content: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const { errors, register } = formApi;

  const onSubmit = (data: Record<string, any>) => {
    const content = `${data.content}\r\n\r\n - ${data.name}`;
    window.location.href = `mailto:${ANJB_CONSTANTS.contact.email}?subject=${data.subject}&body=${encodeURIComponent(content)}`;
  };

  return (
    <form className={classes.form} onSubmit={formApi.handleSubmit(onSubmit)}>
      <TextField
        name="name"
        label="Nome"
        variant="standard"
        inputProps={{
          ref: register,
        }}
        error={errors?.name}
        helperText={errors?.name?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="subject"
        label="Assunto"
        variant="standard"
        inputProps={{
          ref: register,
        }}
        error={!!errors?.subject?.name}
        helperText={errors?.subject?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="content"
        label="Mensagem"
        variant="standard"
        inputProps={{
          ref: register,
        }}
        error={errors?.content}
        helperText={errors?.content?.message}
        multiline
        rows={8}
      />
      <Spacer spacing={2} />

      <Button variant="contained" type="submit">
        Enviar
      </Button>
    </form>
  );
}
