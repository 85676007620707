/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
  TextField,
  Link as MaterialUILink,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import * as yup from 'yup';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useDispatch } from 'react-redux';
import { authLogin } from 'redux/auth.redux';
import { useMutation } from '@apollo/client';
import { password, stringRequired } from 'utils/validators';
import { loginGql } from 'data/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordField from 'components/inputs/input-password-field/password-field';
import { navbarForm, navbarField } from 'styles/navbar-form-styles';
import SubmitButton from 'components/buttons/button-submit/submit-button';

const useStyles = makeStyles((theme) => ({
  form: navbarForm,
  field: navbarField(theme),
  linksSection: {
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  link: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  email: stringRequired,
  password: password(),
});

export default function LoginForm() {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit, register, errors,
  } = formApi;

  const [mutate, { loading }] = useMutation(loginGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: (rawData) => {
      const data = rawData?.login;

      // Update redux
      dispatch(authLogin({
        currentUser: data?.user,
        accessToken: data?.accessToken,
      }));

      // Notify user
      enqueueSnackbar(`Bem-vindo de volta, ${data.user.username}`, { variant: 'success' });

      // Go to homepage (or close modal)
      history.push(APP_ROUTES.home);
    },
  });

  const onSubmit = (data: Record<string, any>) => {
    closeSnackbar();

    mutate({
      variables: {
        input: {
          email: data.email,
          password: data.password,
        },
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.field}
        name="email"
        color="primary"
        label="E-mail"
        required
        error={errors?.email}
        helperText={errors?.email?.message}
        inputProps={{
          ref: register,
        }}
      />

      <PasswordField register={register} errors={errors} />

      <div className={classes.linksSection}>
        <Link to={`${APP_ROUTES.home}?${ModalQueryParams.SIGN_UP}`}>
          <div className={classes.link}>
            Torna-te sócio
          </div>
        </Link>

        <Link to={`${APP_ROUTES.home}?${ModalQueryParams.RECOVER_PASSWORD}`}>
          <div className={classes.link}>
            Recuperar acesso
          </div>
        </Link>
      </div>

      <Spacer spacing={2} />

      <SubmitButton loading={loading} buttonLabel="Iniciar sessão" />
    </form>
  );
}
