/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';

export interface ProductVariantQuantities {
  productVariantId: number,
  quantity: number,
}

export interface ProductVariantsState {
  productVariantQuantities: ProductVariantQuantities[]
}

// @ Actions

export const SET_PRODUCT_VARIANT_QUANTITY = 'PRODUCT_VARIANTS/SET_PRODUCT_VARIANT_QUANTITY';

interface SetProductVariantQuantityAction {
  type: typeof SET_PRODUCT_VARIANT_QUANTITY,
  payload: ProductVariantQuantities
}
export const setProductVariantQuantity = (payload: ProductVariantQuantities): SetProductVariantQuantityAction => ({
  type: SET_PRODUCT_VARIANT_QUANTITY,
  payload,
});

export type ProductVariantActions = SetProductVariantQuantityAction

// @ Selectors

export const selectProductVariantQuantities = (state: RootState): ProductVariantQuantities[] => state.productVariants.productVariantQuantities;

export const selectProductVariantQuantity = (productVariantId?: number) => (state: RootState) => {
  const { productVariantQuantities = [] } = state.productVariants;

  return productVariantQuantities.find((productVariantQuantity) => productVariantQuantity.productVariantId === productVariantId);
};

const initialState: ProductVariantsState = {
  productVariantQuantities: [],
};

export const productVariantsReducer = (
  state = initialState,
  action: ProductVariantActions,
): ProductVariantsState => {
  switch (action.type) {
    case SET_PRODUCT_VARIANT_QUANTITY: {
      const index = state.productVariantQuantities.findIndex((x) => x.productVariantId === action.payload.productVariantId);

      if (index !== -1) {
        const updatedState = state.productVariantQuantities.slice();
        updatedState[index] = action.payload;
        return {
          ...state,
          productVariantQuantities: updatedState,
        };
      }

      return {
        ...state,
        productVariantQuantities: state.productVariantQuantities.concat(action.payload),
      };
    }
    default:
      return state;
  }
};
