/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import {
  Divider, makeStyles, Typography,
} from '@material-ui/core';
import EmblaCarousel from 'components/carousels/carousel-embla/embla-carousel';
import Spacer from 'components/spacer/spacer';
import ProductFilters from 'components/filters/filter-product-filters/product-filters';
import ProductPrice from 'components/product-price/product-price';
import ProductQuantity from 'components/product-quantity/product-quantity';
import ShoppingCartButtons from 'components/buttons/buttons-shopping-cart/shopping-cart-buttons';
import { Product as IProduct } from 'data/products';
import EditorReadonly from 'components/editor-readonly/editor-readonly';
import { APP_ROUTES } from 'data/app';
import Share from 'components/share/share';
import PrimaryLink from 'components/links/link-primary/primary-link';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: theme.palette.common.white,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  productImages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  productDetails: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  shareSection: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  product: IProduct
}

export default function Product({
  product,
}: Props) {
  const classes = useStyles();
  const [selectedVariantId, setSelectedVariantId] = useState<number | null | undefined>();

  const selectedProductVariant = selectedVariantId
    && product?.productVariants.find((variant) => variant.id === selectedVariantId);

  // @ts-ignore
  const productContent = selectedProductVariant?.content || product?.content;

  let hasContent = false;
  try {
    const parsedContent = JSON.parse(productContent);
    hasContent = parsedContent?.blocks?.some((block: any) => block?.text?.length);
  } catch (e) {
    // @ts-ignore
    console.error(e);
  }

  const images = selectedProductVariant ? selectedProductVariant?.productImages : [product?.productVariants?.[0]?.productImages?.[0]];

  const shareUrl = `https://${window.location.host}${APP_ROUTES.product}/${product?.id}`;

  return (
    <section className={classes.wrapper}>
      <article className={classes.productContainer}>
        <div className={classes.productImages}>
          <EmblaCarousel images={images} />
        </div>

        <div className={classes.productDetails}>
          <Typography variant="h6" color="primary">
            {product?.productCategory?.name}
          </Typography>

          <Typography color="textPrimary" variant="h4">
            {product?.name}
          </Typography>
          <Spacer spacing={1} />
          <Divider />
          <Spacer spacing={1} />

          <ProductPrice product={product} />

          <Spacer spacing={2} />

          <Typography variant="subtitle2" color="textPrimary">
            {product?.description}
          </Typography>

          <Spacer spacing={4} />

          <ProductFilters
            product={product}
            selectedVariantId={selectedVariantId}
            setSelectedVariantId={setSelectedVariantId}
          />
          <Spacer spacing={4} />

          {selectedProductVariant && (
            <ProductQuantity productVariant={selectedProductVariant} />
          )}

          <Spacer spacing={4} />

          {selectedVariantId && (
            <ShoppingCartButtons productVariantId={selectedVariantId} />
          )}
        </div>

        <Spacer spacing={4} />
      </article>

      {hasContent && (
        <article className={classes.contentContainer}>
          <EditorReadonly
            defaultValue={productContent}
            readOnly
            toolbar={false}
          />
        </article>
      )}
      <Spacer spacing={4} />

      <article className={classes.contentContainer}>
        <Typography>
          Precisas de ajuda? Envia um e-mail para
          {' '}
          <a className={classes.link} href="mailto:loja@anjb.pt">
            loja@anjb.pt
          </a>
          {' '}
        </Typography>
      </article>

      <div className={classes.shareSection}>
        <Typography>Partilhar artigo: </Typography>
        <Share small link={shareUrl} />
      </div>

    </section>
  );
}
