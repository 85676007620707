/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import {
  deletePublicationGql, formatPublicationStatus, listBackofficePublicationsGql, PublicationStatus,
} from 'data/publications';
import PublishedIcon from '@material-ui/icons/CheckCircle';
import DraftIcon from '@material-ui/icons/BorderColor';
import VisibleToAllIcon from '@material-ui/icons/Public';
import SubscribersOnlyIcon from '@material-ui/icons/CardMembership';
import { deletePublicationCategoryGql, listPublicationCategoriesGql } from 'data/publication-categories';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import MailIcon from '@material-ui/icons/Mail';
import { setPublicationId } from 'redux/newsletter.redux';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 75,
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ManagePublicationsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const publicationFeature: BackofficeFeature = {
    name: 'Publicações',
    url: APP_ROUTES.backoffice.publications.managePublications,
    listQuery: listBackofficePublicationsGql,
    listQuerySelector: 'listBackofficePublications',
    deleteMutation: deletePublicationGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Título da publicação',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Imagem',
        field: 'image',
        width: 100,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center'
        },
        cellRendererFramework: (params: any) => <img alt={params.data?.name} className={classes.image} src={params.data?.image} />,
      },
      {
        headerName: 'Estado',
        field: 'status',
        flex: 0.5,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
        },
        cellRendererFramework: (params: any) => (
          <ColumnStatusRenderer publicationStatus={params?.data?.status} />
        ),
      },
      {
        headerName: 'Visibilidade',
        field: 'subscribersOnly',
        flex: 0.5,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
        },
        cellRendererFramework: (params: any) => (
          <VisibilityRenderer subscribersOnly={params?.data?.subscribersOnly} />
        ),
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.READ_PUBLICATION,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PUBLICATION,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_PUBLICATION,

    searchTextFilterKey: 'name',
    addLabel: 'Criar nova publicação',

    renderTableCell: (id) => (
      <>
        <Tooltip title="Reencaminhar para newsletter">
          <IconButton
            onClick={() => {
              dispatch(setPublicationId(id));

              history.push(APP_ROUTES.backoffice.newsletter.manageNewsletter);
            }}
            className={classes.icon}
          >
            <MailIcon />
          </IconButton>
        </Tooltip>
      </>
    ),

    gridProps: {
      rowHeight: 75,
    }
  };

  const publicationCategoryFeature: BackofficeFeature = {
    name: 'Categorias',
    url: APP_ROUTES.backoffice.publications.manageCategories,
    listQuery: listPublicationCategoriesGql,
    listQuerySelector: 'listPublicationCategories',
    deleteMutation: deletePublicationCategoryGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome da categoria',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Descrição da categoria',
        field: 'description',
        flex: 2,
      },
    ],
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PUBLICATION_CATEGORY,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_PUBLICATION_CATEGORY,

    searchTextFilterKey: 'name',
    addLabel: 'Criar nova categoria',
  };

  return (
    <BackofficeFactory
      features={[
        publicationFeature,
        publicationCategoryFeature,
      ]}
    />
  );
}

// Helpers

const useColumnStatusStyles = (published: boolean) => makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: published ? theme.palette.success.main : theme.palette.divider,
  },
  icon: {
    width: 15, height: 15, marginRight: 5,
  },
}));
const ColumnStatusRenderer = ({ publicationStatus }: { publicationStatus: PublicationStatus }) => {
  const classes = useColumnStatusStyles(publicationStatus === PublicationStatus.PUBLISHED)();

  return (
    <div className={classes.container}>
      <Typography>
        {publicationStatus === PublicationStatus.PUBLISHED && (
          <PublishedIcon className={classes.icon} />
        )}
        {publicationStatus === PublicationStatus.IN_DRAFT && (
          <DraftIcon className={classes.icon} />
        )}
        {formatPublicationStatus(publicationStatus)}
      </Typography>
    </div>
  );
};

const useVisibilityRenderer = (subscribersOnly: boolean) => makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    color: subscribersOnly ? theme.palette.info.main : theme.palette.secondary.main,
  },
  icon: {
    width: 15, height: 15, marginRight: 5,
  },
}));

const VisibilityRenderer = ({ subscribersOnly }: { subscribersOnly: boolean }) => {
  const classes = useVisibilityRenderer(subscribersOnly)();

  return (
    <div className={classes.container}>
      <Typography>
        {subscribersOnly
          ? (<SubscribersOnlyIcon className={classes.icon} />)
          : (<VisibleToAllIcon className={classes.icon} />)}

        {subscribersOnly ? 'Sócios' : 'Público'}
      </Typography>
    </div>
  );
};
