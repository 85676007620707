/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import { getModalStyle } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { formatToDateAndHour } from 'utils/formatters';
import SectionStepper from 'components/section-stepper/section-stepper';
import { getSubscriptionPaymentByIdGql, SubscriptionPayment } from 'data/subscription-payment';
import { formatSubscriptionPaymentStatus } from 'pages/backoffice-page/manage-subscriptions/manage-subscriptions-page';
import PrimaryLink from 'components/links/link-primary/primary-link';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  productOrderDetailsContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRight: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
  },
}));

export default function ModalViewSubscriptionPayment() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<SubscriptionPayment>(
    ModalQueryParams.VIEW_SUBSCRIPTION_PAYMENT,
    getSubscriptionPaymentByIdGql,
    'getSubscriptionPaymentById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="h6" color="primary">
          Pagamento Quota Nr. #
          {data?.id}
          {' '}
        </Typography>
        <Spacer spacing={2} />
        <Typography variant="body2">
          Pago a
          {' '}
          {formatToDateAndHour(data?.paidAt)}
        </Typography>

        <Spacer spacing={4} />
        <SectionStepper displayText="Dados do Cliente" />

        <Spacer spacing={4} />
        <div className={classes.paragraph}>
          <Typography variant="body1" color="primary">
            Utilizador
          </Typography>

          <PrimaryLink to={`${APP_ROUTES.backoffice.users.manageUsers}?${ModalQueryParams.VIEW_USER(data?.subscription?.user?.id as number)}`}>
            {data?.subscription?.user?.username}
          </PrimaryLink>
          <Spacer spacing={2} />
        </div>

        <Spacer spacing={4} />

        <SectionStepper displayText="Dados da Subscrição" />
        {renderInfo('ID da Subscrição', `${data?.subscription?.id}`)}
        {renderInfo('Modalidade', `${data?.subscription?.subscriptionPlan?.name}`)}
        {renderInfo('Data Início', `${formatToDateAndHour(data?.subscription?.startDate)}`)}
        {renderInfo('Data Fim', `${formatToDateAndHour(data?.subscription?.endDate)}`)}

        <Spacer spacing={4} />

        <SectionStepper displayText="Dados do Pagamento" />
        {renderInfo('Referência', `${data?.reference}€`)}
        {renderInfo('Entidade', `${data?.entity}`)}
        {renderInfo('Identificador', `${data?.identifier}`)}
        {renderInfo('Valor', `${data?.amount}€`)}
        {renderInfo('Método de Pagamento', `${data?.paymentMethod}`)}
        {renderInfo('Contacto', `${data?.phoneNumber}`)}
        {renderInfo('Estado', `${formatSubscriptionPaymentStatus(data)}`)}
        {renderInfo('Pago a', `${formatToDateAndHour(data?.paidAt)}`)}

        <Spacer spacing={4} />

      </DataRenderer>
    </Modal>
  );
}
