/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Box, Table, TableHead, TableRow, TableCell, TableBody, Link, Button,
} from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import { formatToDateAndHour } from 'utils/formatters';

import { APP_ROUTES } from 'data/app';
import {
 cancelProductOrderGql, formatProductOrderPaymentStatus, listOwnProductOrdersGql, ProductOrder,
} from 'data/product-orders';
import Page from 'pages/_layouts/page';
import { useMutation, useQuery } from '@apollo/client';

import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { PaymentStatus } from 'data/payments';
import { useSnackbar } from 'notistack';
import useGetTransportationFee from 'hooks/useGetTransportationFee';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    padding: theme.spacing(2),
    overflow: 'scroll',
  },
}));

export default function ManageOrdersPage() {
  const classes = useStyles();

  const {
 data: rawData, loading, error, refetch,
} = useQuery(listOwnProductOrdersGql, {
    variables: {
      filter: {
        limit: 9999,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  const data: ProductOrder[] = rawData?.listOwnProductOrders || [];

  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(cancelProductOrderGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Encomenda cancelada com sucesso!', { variant: 'success' });
      refetch();
    },
  });

  const { transportationFee, loading: transportationFeeLoading, error: transportationFeeError } = useGetTransportationFee();

  return (
    <Page>
      <div className={classes.wrapper}>
        <SectionTitle>
          Encomendas
        </SectionTitle>

        <Box margin={1}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Preço</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <DataRenderer loading={loading || transportationFeeLoading} error={formatGraphqlErrorMessage(error || transportationFeeError)}>
                {data?.map((productOrder) => (
                  <TableRow key={productOrder?.id}>
                    <TableCell component="th" scope="row">
                      <Link href={`${APP_ROUTES.orderDetails}/${productOrder?.id}`}>
                        {productOrder?.id}
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {formatToDateAndHour(productOrder?.orderedAt)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {Number(productOrder?.orderAmount) + Number(transportationFee)}
                      {' '}
                      €
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {formatProductOrderPaymentStatus(productOrder)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {productOrder?.productOrderPayment?.paymentStatus !== PaymentStatus.PAID && (
                        <Button onClick={() => {
                          mutate({ variables: { id: productOrder?.id } });
                        }}
                        >
                          Cancelar encomenda
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </DataRenderer>
            </TableBody>
          </Table>
        </Box>
      </div>
    </Page>
  );
}
