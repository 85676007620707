/* eslint linebreak-style: ["error", "unix"] */
import firebase from 'firebase';
import { APP_CONFIG } from 'data/app';
import { Environments } from 'utils/common';
import { createContext } from 'react';
import { firebaseDevelopmentConfig, firebaseProductionConfig } from './firebase.config';

export default class Firebase {
  private _app: firebase.app.App

  constructor() {
    this._app = firebase.initializeApp(APP_CONFIG.app.environment === Environments.PRODUCTION
      ? firebaseProductionConfig
      : firebaseDevelopmentConfig);
  }

  get() {
    return this._app;
  }

  getAnalytics() {
    return this._app.analytics();
  }
}

export const FirebaseContext = createContext<Firebase | null>(null);
