/* eslint linebreak-style: ["error", "unix"] */
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { useState } from 'react';

interface Props {
  setCurrentLimit: (v: number) => void,
  setCurrentOffset: (v: number) => void,
  setCurrentPage: (v: number) => void,
  currentLimit: number,
  showAll?: boolean,
}

export const DEFAULT_PAGE_SIZES = [25, 50, 100];

export const DEFAULT_LIMIT = 25;

export default function PageCounter({
  setCurrentLimit,
  currentLimit,
  setCurrentOffset,
  setCurrentPage,
  showAll,
  ...rest
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (v: number) => {
    setCurrentLimit(v);
    setCurrentOffset(0);
    setCurrentPage(0);
    handleClose();
  };

  const pageSizes = DEFAULT_PAGE_SIZES.slice();
  if (showAll) {
    pageSizes.push(9999);
  }

  return (
    <div>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        {currentLimit}
        {' '}
        resultados
      </Button>
      <Menu
        variant="selectedMenu"
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {
          // @ts-ignore
          pageSizes.map((pageSize) => (
            <MenuItem key={pageSize} onClick={() => handleSelection(pageSize)}>
              {pageSize}
              {' '}
              resultados
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
}
