/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import SectionStepper from 'components/section-stepper/section-stepper';
import { formatToDateAndHour } from 'utils/formatters';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getModalStyle } from 'styles/modal.styles';
import { Product } from 'data/products';
import { getProductCategoryByIdGql } from 'data/product-categories';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  images: {
    display: 'flex',
  },
  image: {
    display: 'flex',
    maxWidth: 300,
  },
}));

export default function ModalViewProductCategory() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<Product>(
    ModalQueryParams.VIEW_PRODUCT_CATEGORY,
    getProductCategoryByIdGql,
    'getProductCategoryById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="subtitle1">
          Detalhes da categoria de produto
          {' '}
        </Typography>
        <Spacer spacing={2} />

        <Typography variant="h5" color="primary">
          {data?.name}
          {' '}
        </Typography>
        <Divider />

        <Spacer spacing={4} />

        {renderInfo('ID', data?.id)}
        {renderInfo('Nome da categoria', data?.name)}
        {/* {renderInfo('Categoria', data?.productCategory?.name)}
        {renderInfo('Descrição do Produto', data?.description)}
        {renderInfo('Visível ao público?', data?.isActive ? 'Sim' : 'Não')}

        <SectionStepper displayText="Preços" />
        {renderInfo('Preço', `${data?.price}€`)}
        {renderInfo('Preço de Sócio', `${data?.subscriberPrice}€`)}

        <Typography color="primary">Variantes</Typography>
        <Spacer spacing={1} />
        <TableGrid
          columns={[
            {
              field: 'id',
              headerName: 'ID',
              width: 70,
            },
            {
              field: 'image',
              headerName: 'Imagem',
              flex: 1,
              renderCell: (params) => <img alt={params.row?.productImages?.[0]} style={{ height: '100%' }} src={params.row?.productImages?.[0]} />,
            },
            {
              field: 'name',
              headerName: 'Variante',
              flex: 1,
              renderCell: (param) => {
                const variant = param.row as ProductVariant;
                const name = variant?.productFilters?.map((x) => x?.value).join(', ');
                return (
                  <PrimaryLink to={`${APP_ROUTES.backoffice.store.manageProductVariants}?${ModalQueryParams.VIEW_PRODUCT_VARIANT(variant?.id)}`}>
                    {name}
                  </PrimaryLink>
                );
              },
            },
          ]}
          rows={data?.productVariants || []}
          height={250}
        /> */}

        <SectionStepper displayText="Atividade" />
        {renderInfo('Criado a', formatToDateAndHour(data?.createdAt))}
        {renderInfo('Atualizado a', formatToDateAndHour(data?.updatedAt))}
      </DataRenderer>
    </Modal>
  );
}
