/* eslint linebreak-style: ["error", "unix"] */

import { Typography, makeStyles } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fill: theme.palette.secondary.main,
      marginRight: theme.spacing(0.5),
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    color: theme.palette.secondary.main,
    opacity: 0.5,
  },
}));

interface Props {
  date: string,
  color?: string
  justifyContent?: string
}

export default function Date({
  date = '',
  color = '#FFF',
  justifyContent = 'center',
}: Props) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ justifyContent }}
    >
      <QueryBuilderIcon />
      <Typography style={{ color }} variant="subtitle2">{date}</Typography>
    </div>
  );
}
