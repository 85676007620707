/* eslint linebreak-style: ["error", "unix"] */
import { Theme } from '@material-ui/core';

export const useLayoutStyles = (theme: Theme) => ({
  display: 'flex',
  padding: theme.spacing(2),
  maxWidth: '65rem',
  width: '100%',
  margin: `${theme.spacing(4)}px auto`,
  justifyContent: 'center',
});
