/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { useForm } from 'react-hook-form';
import { stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import EditorInput from 'components/inputs/input-editor-input/editor-input';
import {
  createPageGql, Page, updatePageGql,
} from 'data/pages';
import { useFormStyles } from 'styles/form.styles';
import SwitchInput from 'components/inputs/input-switch/switch-input';

const validationSchema = yup.object().shape({
  name: stringRequired,
  url: stringRequired,
  content: stringRequired,
});

export interface Props {
  data?: Page
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function PageForm({
  data,
  onSuccess,
}: Props) {
  const classes = useFormStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        url: data?.url,
        content: data?.content,
        subscribersOnly: data?.subscribersOnly,
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updatePageGql : createPageGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing ? 'Página atualizada com sucesso' : 'Página criada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Tooltip title="O nome da página">
        <TextField
          name="name"
          label="Título"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <Tooltip title="O url da página. E.g. 'sobre-a-anjb' para o endereço https://anjb.pt/sobre-a-anjb">
        <TextField
          name="url"
          label="url"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.url)}
          helperText={errors?.url?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <EditorInput
        name="content"
        label="Conteúdo"
        defaultValue={data?.content}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <Spacer spacing={4} />

      <SwitchInput
        name="subscribersOnly"
        label="Página exclusiva para a Área de Sócios"
        tooltip="Se este campo estiver marcado, a página aparecerá listada apenas na Área do Sócio e será visível para sócios ativos"
        control={control}
        errors={errors}
        defaultValue={data?.subscribersOnly}
      />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar página'}
      </Button>

    </form>
  );
}
