/* eslint linebreak-style: ["error", "unix"] */
import {
  Button, CircularProgress, IconButton, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PublishIcon from '@material-ui/icons/Publish';
import BackupIcon from '@material-ui/icons/Backup';
import { useMutation } from '@apollo/client';
import { uploadFileGql } from 'data/storage';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  uploadedFileContainer: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),

    '& button': {
      marginLeft: theme.spacing(0.5),
    },

    '& label': {
      width: '100%',
    },
  },
  uploadFileButton: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    width: '100%',
    justifySelf: 'center',
    alignSelf: 'center',
    alignItems: 'center',

    '& svg': {
      marginLeft: theme.spacing(1),
      fill: theme.palette.info.main,
    },
  },
  fileDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  uploadButton: {
    '& svg': {
      color: theme.palette.info.main,
    },
  },
  removeButton: {
    '& svg': {
      color: theme.palette.secondary.light,
    },
  },
}));

interface Props {
  bucketName: string
  refetch: () => void;
}

export default function BucketFileUpload({ bucketName, refetch }: Props) {
  const classes = useStyles();
  const [fileToUpload, setFileToUpload] = useState<File | null>();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate, { loading }] = useMutation(uploadFileGql, {
    onError: (error) => {
      enqueueSnackbar(error, { variant: 'error' });
    },
    onCompleted: (rawData) => {
      const data = rawData?.uploadFile;

      enqueueSnackbar('Upload bem sucedido', { variant: 'success' });

      refetch();

      setFileToUpload(null);
    },
  });

  const onUpload = () => {
    mutate({
      variables: {
        bucketName,
        file: fileToUpload,
      },
    });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className={classes.uploadedFileContainer}>
        {
          fileToUpload
            ? (
              <>
                <div className={classes.fileDetails}>
                  <Typography variant="caption">
                    Ficheiro a enviar:
                  </Typography>
                  <Typography>
                    {fileToUpload.name}
                  </Typography>
                </div>
                <div>
                  <IconButton onClick={onUpload} className={classes.uploadButton}>
                    <PublishIcon />
                  </IconButton>
                  <IconButton onClick={() => setFileToUpload(null)} className={classes.removeButton}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              </>
            )
            : (
              <>
                <Tooltip title={`Fazer upload de um ficheiro para o bucket /${bucketName}`}>
                  <label htmlFor="uploadButton">
                    <input
                      id="uploadButton"
                      name="uploadButton"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files![0];

                        setFileToUpload(file);
                      }}
                    />
                    <Button className={classes.uploadFileButton} component="span" variant="outlined">
                      Upload de ficheiro
                      <BackupIcon />
                    </Button>
                  </label>
                </Tooltip>
              </>
            )
        }
      </div>
    </>
  );
}
