/* eslint linebreak-style: ["error", "unix"] */
import { ApolloError } from '@apollo/client/core';

export const formatGraphqlErrorMessage = (error: ApolloError | undefined): string | undefined => {
  if (error?.message.endsWith('Failed to fetch')) {
    return 'Erro de servidor: Servidor em baixo';
  }

  if (error?.message.endsWith('code 400')) {
    return 'Erro de servidor: Resposta com estado 400';
  }

  return error?.message?.replace('GraphQL error: ', 'Erro de servidor: ');
};
