/* eslint linebreak-style: ["error", "unix"] */
import { APP_CONFIG } from 'data/app';
import * as yup from 'yup';

export const numberRequired = yup.number().typeError('O valor deve ser um número');

export const numberRequiredtaxpayerNumber = yup.number().typeError('O valor deve ser um número com 9 dígitos');

export const numberOptional = numberRequired.nullable();

export const stringOptional = yup.string().nullable();

export const stringRequired = stringOptional.required('Este campo não pode estar vazio');

export const arrayRequired = yup.array().required('Pelo menos uma seleção é necessária.').typeError('');

export const arrayOptional = yup.array().nullable();

export const booleanOptional = yup.boolean().nullable();

export const noWhiteSpace = stringRequired.test(
  'is-not-whitespace',
  'Não pode conter espaços em branco',
  (value) => !/\s/.test(value),
);

export const minimumLengthRequired = (
  length: number,
  fieldName: string = '',
) => yup
  .string()
  .min(length, `O campo '${fieldName}' tem de ter pelo menos ${length} caracteres.`);

export const minimumLengthRequiredNumber = (
  length: number,
  fieldName: string = '',
) => minimumLengthRequired(length, fieldName).typeError('Este campo deve ser preenchido com dígitos');

export const minimumLengthRequired2 = (
  length: number,
  fieldName: string = '',
) => yup
  .number()
  .min(length, `O campo '${fieldName}' tem de ter pelo menos ${length} caracteres.`);

export const minimumLengthRequiredNumber2 = (
  length: number,
  fieldName: string = '',
) => minimumLengthRequired2(length, fieldName).typeError('Este campo deve ser preenchido com dígitos');

export const password = (
  minimumPasswordLength: number = APP_CONFIG.app.password.minimumLength,
) => {
  const validator = minimumLengthRequired(minimumPasswordLength, 'senha')
    .test(
      'is-not-whitespace',
      'O campo \'senha\' não pode conter espaços.',
      (value: string) => !/\s/.test(value),
    )
    .test(
      'has-at-least-1-number',
      'Deve conter pelo menos um número',
      (value) => /\d/.test(value),
    )
    .test(
      'has-at-least-1-letter',
      'Deve conter pelo menos uma letra',
      (value) => /([A-Za-z]+)/.test(value),
    );

  return validator;
};

export const optionalPassword = password().nullable();

export const confirmPassword = yup.string().required('Este campo é obrigatório').test(
  'Validar passwords',
  'Passwords não coincidem',
  function (value) {
    return this.parent.password === value;
  },
);

export const emailRequired = yup.string().required('Campo obrigatório').email('Deve ser um e-mail válido');

export const phoneRequired = yup.number()
  .required('Este campo é obrigatório')
  .test('len', 'Deve conter exatamente 9 dígitos', (val) => val?.toString()?.length === 9)
  .typeError('Este campo deve ser preenchido com dígitos');

export const phoneOptional = yup.number()
  .nullable()
  .test('len', 'Deve conter exatamente 9 dígitos', (val) => val?.toString()?.length === 9).typeError('Este campo deve ser preenchido com dígitos');

// https://gist.github.com/jamesbar2/1c677c22df8f21e869cca7e439fc3f5b
// {
//   "Note": "",
//   "Country": "Portugal",
//   "ISO": "PT",
//   "Format": "NNNN-NNN (NNNN NNN)",
//   "Regex": "^\\d{4}[- ]{0,1}\\d{3}$"
// },
export const postalCodeRequired = yup.string().test(
  'Validação de código postal',
  'Deve ser um formato de código postal válido (XXXX-XXX ou XXXXXXX)',
  (value: string) => new RegExp('^\\d{4}[- ]{0,1}\\d{3}$').test(value),
);
