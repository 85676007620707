/* eslint linebreak-style: ["error", "unix"] */
/**
 * On every pages per row event, this resizes the table to correctly display the new limit of rows per page
 *
 */
export const handlePageResize = (tableRef: any, dataLength: number): void => {
  tableRef?.current?.onChangeRowsPerPage({
    target: {
      value: dataLength,
    },
  });
};
