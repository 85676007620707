/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { Controller, useForm } from 'react-hook-form';
import {
  arrayRequired, stringOptional, stringRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  createProductFilterCategoryGql, ProductFilterCategory, updateProductFilterCategoryGql,
} from 'data/product-filter-categories';
import { ProductFilter } from 'data/product-filter';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  filterName: stringRequired,
  filterValues: arrayRequired,
  description: stringOptional,
});

export interface Props {
  data?: ProductFilterCategory
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function FormProductFilter({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, watch, handleSubmit,
  } = useForm<{
    filterName: string,
    filterValues: ProductFilter[],
    description?: string,
  }>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        filterName: data?.filterName as string,
        filterValues: data?.filterValues?.map((x) => ({
          value: x.value,
          order: x.order,
        })),
        description: data?.description,
      }
      : {
        filterName: '' as string,
        filterValues: [{
          value: '',
          order: 0,
        }] as ProductFilter[],
      },
  });

  const [mutate] = useMutation(isEditing ? updateProductFilterCategoryGql : createProductFilterCategoryGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing
        ? 'Filtro atualizado com sucesso'
        : 'Filtro criado com sucesso',
        { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  const filterValues = watch('filterValues') || [];

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

      <Tooltip title="O nome do filtro (ex. 'Cores', 'Tamanhos', ...)">
        <TextField
          name="filterName"
          label="Nome do filtro"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.filterName)}
          helperText={errors?.filterName?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <TextField
        name="description"
        label="Descrição do filtro"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <Controller
        render={({ value = [], onChange }: { value: ProductFilter[], onChange: any }) => (
          <>
            <>
              <FormLabel>Filtros</FormLabel>
              <Spacer spacing={1} />

              {value.map((filterValue, index) => (
                <Grid container>
                  <TextField
                    style={{ flex: 1 }}
                    placeholder="Valor"
                    value={filterValue?.value}
                    onChange={(evt: any) => {
                      const newValue = evt?.target?.value;

                      const payload = filterValues?.slice();
                      payload[index].value = newValue;

                      onChange(payload);
                    }}
                  />
                  <TextField
                    placeholder="Ordem"
                    type="number"
                    value={filterValue?.order}
                    onChange={(evt: any) => {
                      const newValue = evt?.target?.value;

                      const payload = filterValues?.slice();
                      payload[index].order = Number(newValue);

                      onChange(payload);
                    }}
                  />
                  <Button onClick={() => {
                    const payload = filterValues?.slice();
                    payload.splice(index, 1);

                    onChange(payload);
                  }}
                  >
                    X

                  </Button>
                </Grid>
              ))}
              {/* <ChipInput
                value={value}
                onAdd={(valueToAdd) => {
                  value.push(valueToAdd);
                  onChange(value);
                }}
                onDelete={(valueToDelete) => {
                  const newValue = value.filter((x: string) => x !== valueToDelete);
                  onChange(newValue);
                }}
              /> */}

              <Button
                variant="text"
                onClick={() => {
                  const payload = filterValues?.slice();

                  // @ts-ignore
                  payload.push({ value: '', order: filterValues?.length });

                  setValue('filterValues', payload);
                }}
              >
                Adicionar novo valor
              </Button>
            </>
          </>
        )}
        control={control}
        name="filterValues"
      />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar filtro'}
      </Button>

    </form>
  );
}
