/* eslint linebreak-style: ["error", "unix"] */
import { ModalQueryParams } from 'data/app';
import { Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import RecoverPasswordForm from 'components/forms/form-recover-password/recover-password-form';
import NavbarFormModal from '../modal-navbar-form/navbar-form-modal';

export default function RecoverPasswordModal() {
  return (
    <NavbarFormModal
      modalQueryParam={ModalQueryParams.RECOVER_PASSWORD}
      sectionTitle="Recuperar acesso"
      backgroundImage="/images/anjb_recover_password.jpg"
    >
      <Spacer spacing={1} />

      <Typography>
        Introduz o email associado à tua conta ANJB. Irás receber um email
        com um link onde poderás criar uma nova senha.
      </Typography>

      <RecoverPasswordForm />
    </NavbarFormModal>
  );
}
