/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import {
  makeStyles, withStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';
import { BucketFile } from 'data/storage';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import LinkIcon from '@material-ui/icons/Link';
import VpnLockIcon from '@material-ui/icons/VpnLock';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    display: 'flex',
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: '100%',

    '&.MuiPaper-root ': {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        minWidth: '600px',
        maxWidth: '100%',
        padding: theme.spacing(2),
      },
    },
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

interface Props {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  caret: {
    start: number;
    end: number;
  }
}

export default function EditorBar({
  content, setContent, caret,
}: Props) {
  const classes = useStyles();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [mode, setMode] = useState<'url' | 'private_url' | 'img'>('img');

  // calling to this function you can insert text in the cursor position
  const handleInsertText = (text: string) => {
    setContent(
      `${content.substring(0, caret.start)}${text}${content.substring(caret.end, content.length)}`,
    );
  };

  const handleFileInsert = (selectedFile: BucketFile) => {
    if (mode === 'img') {
      handleInsertText(`<mj-image width="137px" height="185px" padding="0" src=${selectedFile.publicUrl} />`);
    }

    if (mode === 'private_url') {
      handleInsertText(`<mj-text><a href="${selectedFile.signedUrl}" target="_blank">Link</a></mj-text>`);
    }

    if (mode === 'url') {
      handleInsertText(`<mj-text><a href="${selectedFile.publicUrl}" target="_blank">Link</a></mj-text>`);
    }

    setUploadOpen(false);
  };

  return (
    <div>
      <BucketManagerModal
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        onFileSelect={(selectedFile) => {
          handleFileInsert(selectedFile);
        }}
      />
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          size="small"
          value=""
          exclusive
          onChange={() => { }}
          aria-label="text alignment"
        >
          <Tooltip title="Inserir uma imagem">
            <ToggleButton
              value="left"
              aria-label="left aligned"
              onClick={() => {
                setMode('img');
                setUploadOpen(true);
              }}
            >
              <ImageSearchIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Inserir um link">
            <ToggleButton
              value="left"
              aria-label="left aligned"
              onClick={() => {
                setMode('url');
                setUploadOpen(true);
              }}
            >
              <LinkIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Inserir um link privado (apenas quem tiver o link poderá ver. O acesso expira em 2 semanas)">
            <ToggleButton
              value="left"
              aria-label="left aligned"
              onClick={() => {
                setMode('private_url');
                setUploadOpen(true);
              }}
            >
              <VpnLockIcon />
            </ToggleButton>
          </Tooltip>
        </StyledToggleButtonGroup>
        <Divider flexItem orientation="vertical" className={classes.divider} />
      </Paper>
    </div>
  );
}
