/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import SendNewsletterForm from 'components/forms/form-send-newsletter/form-send-newsletter';
import { useDispatch, useSelector } from 'react-redux';
import { selectedPublicationId, selectSelectedMailingList, setPublicationId } from 'redux/newsletter.redux';

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root ': {
      minWidth: 'unset',

      [theme.breakpoints.up('md')]: {
        minWidth: '600px',
        padding: theme.spacing(2),
      },
    },
  },
}));

export default function SendNewsletterModal() {
  const classes = useStyles();
  const mailingList = useSelector(selectSelectedMailingList);
  const publicationId = useSelector(selectedPublicationId);

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.SEND_NEWSLETTER);

  const dispatch = useDispatch();

  if (!mailingList?.length) {
    return null;
  }

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <SectionTitle>Enviar newsletter</SectionTitle>
      <SendNewsletterForm
        onSuccess={() => {
          if (publicationId) {
            dispatch(setPublicationId(null));
          }
        }}
      />
    </Modal>
  );
}
