/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { Fade, makeStyles } from '@material-ui/core';
import Pagination from 'components/table/pagination/pagination';
import { DEFAULT_PAGE_SIZES } from 'components/table/pagination/page-counter/page-counter';
import { APP_ROUTES } from 'data/app';
import { Publication, listPublicationsGql } from 'data/publications';
import { FeedSearchFilters } from 'components/landing-page-publications-feed/landing-page-publications-feed';
import { DocumentNode, useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import PublicationPreviewTile from 'components/tiles/tile-publication-preview/publication-preview-tile';

interface Props {
  searchFilters?: FeedSearchFilters
  initialOffset?: number
  initialLimit?: number
  baseLocation?: string

  showPagination?: boolean

  // Queries
  listQuery?: DocumentNode,
  listQuerySelector?: string,
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'wrap',
  },
}));

export default function PublicationsList({
  searchFilters = {},
  initialLimit = DEFAULT_PAGE_SIZES[0],
  initialOffset = 0,
  baseLocation = APP_ROUTES.publication,
  showPagination = true,
  listQuery = listPublicationsGql,
  listQuerySelector = 'listPublications',
}: Props) {
  const [limit, setLimit] = useState<number>(initialLimit);
  const [offset, setOffset] = useState<number>(initialOffset);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(listQuery, {
    variables: {
      filter: {
        limit,
        offset,
        ...searchFilters,
      },
    },
  });

  const data = rawData?.[listQuerySelector];

  return (
    <Fade in>
      <>
        <div className={classes.root}>
          <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
            {data?.map((publication: Publication, index: number) => (
              <PublicationPreviewTile
                key={index}
                publication={publication}
                baseLocation={baseLocation}
              />
            ))}
          </DataRenderer>
        </div>
        {showPagination && (
          <Pagination
            data={data}
            setCurrentLimit={setLimit}
            setCurrentOffset={setOffset}
            setCurrentPage={setCurrentPage}
            currentLimit={limit}
            currentPage={currentPage}
          />
        )}
      </>
    </Fade>
  );
}
