/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
} from '@material-ui/core/styles';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { PaymentMethod } from 'data/payments';
import { ProductOrder } from 'data/product-orders';
import { requestProductOrderPaymentGql } from 'data/product-order-payments';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardButton: {
    border: 'none',
    background: 'none',
    display: 'flex',
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',

    height: theme.spacing(24),

    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      transform: 'translateY(-0.1rem)',
      boxShadow: '0 1rem 2rem 0 rgba(0, 0, 0, 0.1)',
      position: 'relative',
      zIndex: theme.zIndex.appBar,
      cursor: 'pointer',
    },

    '& img': {
      width: theme.spacing(8),
      marginRight: theme.spacing(1),
    },
    transition: '0.1s ease all',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
}));

interface Props {
  productOrder: ProductOrder;
  onCreatePaymentMethodSuccess: () => void;
}

export default function PaymentMethodSelection({
  productOrder,
  onCreatePaymentMethodSuccess = () => { },
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate, { loading }] = useMutation(requestProductOrderPaymentGql, {
    onCompleted: onCreatePaymentMethodSuccess,
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  return (
    <section className={classes.container}>
      <Typography variant="h6" color="primary">
        Método de pagamento
      </Typography>
      <Divider />
      <Spacer spacing={4} />

      {loading
        ? <CircularProgress color="primary" />
        : (
          <>
            <div className={classes.content}>
              <button
                className={classes.cardButton}
                onClick={
                  () => mutate({
                    variables: {
                      input: {
                        productOrderId: productOrder.id,
                        paymentMethod: PaymentMethod.MB_WAY,
                      },
                    },
                  })
                }
              >

                <img alt="Mbway logo" src="/images/mbway.png" />

                <Typography>
                  Pagar com MB Way
                </Typography>
              </button>
              <button
                className={classes.cardButton}
                onClick={
                  () => mutate({
                    variables: {
                      input: {
                        productOrderId: productOrder.id,
                        paymentMethod: PaymentMethod.MULTIBANCO,
                      },
                    },
                  })
                }
              >
                <img alt="Multibanco logo" src="/images/multibanco.png" />

                <Typography>
                  Pagar com Multibanco
                </Typography>
              </button>
            </div>

            <Spacer spacing={3} />
            <Typography variant="caption">
              (Uma referência será gerada com os detalhes do pagamento após a seleção)
            </Typography>
          </>
        )}
    </section>
  );
}
