/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
  TextField,
  Link as MaterialUILink,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  stringRequired, password, emailRequired, numberRequired, postalCodeRequired, phoneRequired,
} from 'utils/validators';
import { signUpGql } from 'data/auth';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from 'components/buttons/button-submit/submit-button';
import PasswordField from 'components/inputs/input-password-field/password-field';
import SubscriptionPlansSelect from 'components/selects/select-subscription-plans/subscription-plans-select';
import { navbarForm, navbarField } from 'styles/navbar-form-styles';

const useStyles = makeStyles((theme) => ({
  form: navbarForm,
  field: navbarField(theme),
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  fieldSpace: {
    width: theme.spacing(2),
  },
  linksSection: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  username: stringRequired,
  email: emailRequired,
  fullName: stringRequired,
  address: stringRequired,
  postalCode: postalCodeRequired,
  taxpayerNumber: phoneRequired,
  password: password(),
  passwordConfirmation: password(),
  allowedSubscriptionPlanId: numberRequired,
});

export default function SignupForm() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit, register, errors, control,
  } = formApi;

  const [mutate, { loading }] = useMutation(signUpGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      // Notify user
      enqueueSnackbar('Conta criada com sucesso', { variant: 'success' });

      // Go to homepage (or close modal)
      history.push({
        pathname: APP_ROUTES.home,
        search: `?${ModalQueryParams.SIGN_UP_SUCCESS}`,
      });
    },
  });

  const onSubmit = (data: any) => {
    closeSnackbar();

    mutate({
      variables: {
        input: {
          username: data.username,
          email: data.email,
          fullName: data.fullName,
          address: data.address,
          postalCode: data.postalCode,
          taxpayerNumber: data.taxpayerNumber,
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
          allowedSubscriptionPlanId: data.allowedSubscriptionPlanId,
        },
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.field}
        name="email"
        color="primary"
        label="E-mail"
        required
        error={errors?.email}
        helperText={errors?.email?.message}
        inputProps={{
          ref: register,
        }}
      />
      <TextField
        className={classes.field}
        name="fullName"
        color="primary"
        label="Nome Completo"
        required
        error={errors?.name}
        helperText={errors?.name?.message}
        inputProps={{
          ref: register,
        }}
      />
      <div className={classes.fieldContainer}>
        <TextField
          className={classes.field}
          name="username"
          color="primary"
          label="Nome de utilizador"
          required
          error={errors?.username}
          helperText={errors?.username?.message}
          inputProps={{
            ref: register,
          }}
        />
        <div className={classes.fieldSpace} />
        <TextField
          className={classes.field}
          name="taxpayerNumber"
          color="primary"
          label="Número de Contribuinte"
          required
          error={errors?.taxpayerNumber}
          helperText={errors?.taxpayerNumber?.message}
          inputProps={{
            ref: register,
          }}
        />
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          className={classes.field}
          name="address"
          color="primary"
          label="Morada"
          required
          error={errors?.address}
          helperText={errors?.address?.message}
          inputProps={{
            ref: register,
          }}
        />
        <div className={classes.fieldSpace} />
        <TextField
          className={classes.field}
          name="postalCode"
          color="primary"
          label="Código postal"
          required
          error={errors?.postalCode}
          helperText={errors?.postalCode?.message}
          inputProps={{
            ref: register,
          }}
        />
      </div>
      <div className={classes.fieldContainer}>
        <PasswordField register={register} errors={errors} />
        <div className={classes.fieldSpace} />
        <PasswordField name="passwordConfirmation" label="Confirmar password" placeholder="Confirmar password" register={register} errors={errors} />
      </div>

      <SubscriptionPlansSelect control={control} name="allowedSubscriptionPlanId" label="Categoria" errors={errors} />

      <div className={classes.linksSection}>
        <Link to={`${APP_ROUTES.home}?${ModalQueryParams.SIGN_IN}`}>
          <div className={classes.link}>
            Iniciar sessão
          </div>
        </Link>
      </div>

      <Spacer spacing={2} />

      <SubmitButton loading={loading} buttonLabel="Criar conta" />
    </form>
  );
}
