/* eslint linebreak-style: ["error", "unix"] */
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import Modal from 'components/modals/modal/modal';
import DataRenderer from 'components/data-renderer/data-renderer';
import Product from 'components/product/product';
import { ModalQueryParams } from 'data/app';
import { getProductByIdGql, Product as IProduct } from 'data/products';
import useModalQueryWithId from 'hooks/useModalQueryWithId';

export default function ProductDetailsModal() {
  const {
    data, loading, errorMessage, handleClose, isOpen,
  } = useModalQueryWithId<IProduct>(
    ModalQueryParams.VIEW_PRODUCT,
    getProductByIdGql,
    'getProductById',
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Product product={data} />
      </DataRenderer>
    </Modal>
  );
}
