/* eslint linebreak-style: ["error", "unix"] */
import { IconButton } from '@material-ui/core';
import {
  SearchFilter, selectSearchFilters, setProductSearchFilters, setSelectedProductCategory,
} from 'redux/product-search.redux';
import ClearFiltersIcon from '@material-ui/icons/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
  parameterNameToClear: keyof SearchFilter
}

export default function FilterClear({
  parameterNameToClear,
}: Props) {
  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);

  const handleClear = () => {
    dispatch(setProductSearchFilters({
      ...searchFilters,
      [parameterNameToClear]: null,
    }));

    // Judge not
    if (parameterNameToClear === 'productCategories') {
      dispatch(setSelectedProductCategory(null));
    }
  };

  return (
    <IconButton onClick={handleClear}>
      <ClearFiltersIcon color="primary" />
    </IconButton>
  );
}
