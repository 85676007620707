/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { PublicationCategory } from './publication-categories';
import { User, UserRole } from './users';

export enum PublicationStatus {
	PUBLISHED = 'PUBLISHED',
	IN_DRAFT = 'IN_DRAFT',
	SOFT_DELETED = 'SOFT_DELETED',
}

export const formatPublicationStatus = (publicationStatus: PublicationStatus) => {
	switch (publicationStatus) {
		case PublicationStatus.PUBLISHED:
			return 'Publicado';
		case PublicationStatus.IN_DRAFT:
			return 'Rascunho';
		default:
			return '';
	}
};

export interface Publication {
	id: number
	name: string
	description: string
	image: string
	content: string
	publicationCategories: PublicationCategory[]
	status: PublicationStatus
	isHighlighted: boolean
	subscribersOnly: boolean
	createdBy: User
	updatedBy: User
	createdAt: string
	updatedAt: string
}

export const publicationMinimumDetailsFragment = gql`
	fragment publicationMinimumDetailsFragment on PublicationEntity {
		id
		name
		description
		image
		publicationCategories {
			id
			name
		}
		status
		isHighlighted
		subscribersOnly
		createdBy {
			id
			username
		}
		updatedBy {
			id
			username
		}
		createdAt
		updatedAt
	}
`;

export const publicationFullDetailsFragment = gql`
	fragment publicationFullDetailsFragment on PublicationEntity {
		id
		name
		description
		image
		content
		isHighlighted
		subscribersOnly
		publicationCategories {
			id
			name
		}
		status
		createdBy {
			id
			username
		}
		updatedBy {
			id
			username
		}
		createdAt
		updatedAt
	}
`;

// Mutations
export const createPublicationGql = gql`
  mutation createPublication($input: ICreatePublication!) {
    createPublication(input: $input) {
			id
		}
  }
`;

export const updatePublicationGql = gql`
  mutation updatePublication($input: IUpdatePublication!) {
    updatePublication(input: $input)
  }
`;

export const deletePublicationGql = gql`
  mutation deletePublication($input: IDeletePublication!) {
    deletePublication(input: $input)
  }
`;

// Queries
export const getPublicationByIdGql = gql`
	query getPublicationById($id: Int!) {
		getPublicationById(id: $id) {
			...publicationFullDetailsFragment
		}
	}
	${publicationFullDetailsFragment}
`;

export const getPublicationByNameGql = gql`
  query getPublicationByName($name: String!) {
    getPublicationByName(name: $name) {
      ...publicationFullDetailsFragment
    }
  }
  ${publicationFullDetailsFragment}
`;

export const listPublicationsGql = gql`
  query listPublications($filter: IPublicationFilter) {
    listPublications(filter: $filter) {
      ...publicationMinimumDetailsFragment
    }
  }
  ${publicationMinimumDetailsFragment}
`;

export const listBackofficePublicationsGql = gql`
  query listBackofficePublications($filter: IPublicationFilter) {
    listBackofficePublications(filter: $filter) {
      ...publicationMinimumDetailsFragment
    }
  }
  ${publicationMinimumDetailsFragment}
`;

export const listSubscriberPublicationsGql = gql`
  query listSubscriberPublications($filter: IPublicationFilter) {
    listSubscriberPublications(filter: $filter) {
      ...publicationMinimumDetailsFragment
    }
  }
  ${publicationMinimumDetailsFragment}
`;

export const publicationHighlightMinimumDetailsFragment = gql`
	fragment publicationHighlightMinimumDetailsFragment on PublicationEntity {
		id
		name
		description
		image
		createdAt
		updatedAt
	}
`;
export const listHighlightedPublicationsGql = gql`
	query listHighlightedPublications {
		listHighlightedPublications {
			...publicationHighlightMinimumDetailsFragment
		}
	}
	${publicationHighlightMinimumDetailsFragment}
`;
