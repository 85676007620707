/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, updateUser } from 'redux/auth.redux';
import Page from 'pages/_layouts/page';
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { getUserByIdGql, User } from 'data/users';
import { isSubscriber } from 'data/subscriptions';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'data/app';
import SubscriberMenu from 'components/menus/menu-subscriber/sidebar';
import SubscriberRoutes from './subscriber-routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subPage: {
    display: 'flex',
    width: '100%',
  },
}));

export default function SubscriberAreaPage() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser) as User;
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: rawData, loading, error } = useQuery(getUserByIdGql,
    {
      variables: {
        id: currentUser.id,
      },
      onCompleted: (data) => {
        // Always update user in redux with the new details
        dispatch(updateUser(data?.getUserById));
      },
    });

  const data: User = rawData?.getUserById;

  if (data && !isSubscriber(data)) {
    history.push(APP_ROUTES.subscribe);
    return null;
  }

  return (
    <>
      <Page fullWidth white>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <section className={classes.root}>
            <SubscriberMenu user={data} />

            <section className={classes.subPage}>
              <SubscriberRoutes />
            </section>
          </section>
        </DataRenderer>
      </Page>
    </>
  );
}
