/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '@material-ui/core/Button/Button';
import PageCounter from './page-counter/page-counter';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  paginationButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    minWidth: '12rem',
    padding: theme.spacing(1),
  },
  pageIndex: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(2),
  },
}));

interface Props {
  currentPage: number,
  currentLimit: number,
  setCurrentOffset: (v: number) => void,
  setCurrentLimit: (v: number) => void,
  setCurrentPage: (v: number) => void,
  data: any[],
  showAll?: boolean
}

export default function Pagination({
  currentPage,
  currentLimit,
  setCurrentLimit,
  setCurrentOffset,
  setCurrentPage,
  data,
  showAll = false,
  ...rest
}: Props) {
  const classes = useStyles();
  const isFirstPage = currentPage <= 0;
  const hasNoMoreData = data?.length < currentLimit;

  const handleNext = () => {
    if (hasNoMoreData) {
      // No more data left
      return;
    }

    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    setCurrentOffset(currentLimit * nextPage);
  };

  const handlePrevious = () => {
    if (isFirstPage) {
      return;
    }

    const previousPage = currentPage - 1;

    if (previousPage <= 0) {
      setCurrentPage(0);
      setCurrentOffset(0);
      return;
    }

    setCurrentPage(previousPage);
    setCurrentOffset(currentLimit * previousPage);
  };

  return (
    <div className={classes.container}>
      <PageCounter
        setCurrentLimit={setCurrentLimit}
        setCurrentOffset={setCurrentOffset}
        setCurrentPage={setCurrentPage}
        currentLimit={currentLimit}
        showAll={showAll}
        {...rest}
      />

      <div className={classes.paginationButtons}>
        <Button size="small" onClick={handlePrevious} disabled={isFirstPage}>
          Anterior
        </Button>
        <Button size="small" onClick={handleNext} disabled={hasNoMoreData}>
          Próximo
        </Button>
      </div>

      <div className={classes.pageIndex}>
        Página
        {' '}
        {currentPage}
      </div>
    </div>
  );
}
