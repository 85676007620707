/* eslint linebreak-style: ["error", "unix"] */
import {
  IconButton, makeStyles, TextField, Typography,
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Spacer from 'components/spacer/spacer';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { subscribeNewsletterGql } from 'data/newsletter';
import { emailRequired } from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 500,
  },
  formContainer: {
    display: 'flex',
    width: '100%',
  },
  field: {
    display: 'flex',
    width: '100%',
    '& .MuiInputBase-input': {
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
        WebkitTextFillColor: theme.palette.common.white,
      },
    },
    '& .MuiFilledInput-input': {
      paddingTop: 10,
    },
    '& .MuiFilledInput-underline:before': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.common.white,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: 45,
      left: 0,
      margin: 0,
      color: theme.palette.primary.main,
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
  },
  submitIcon: {
    background: theme.palette.primary.main,
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
  },
  font: {
    color: theme.palette.common.white,
  },
}));

const validationSchema = yup.object().shape({
  email: emailRequired,
});

export default function Newsletter() {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit, register, errors,
  } = formApi;

  const [mutate, { loading }] = useMutation(subscribeNewsletterGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Subscrito com sucesso!', { variant: 'success' });
    },
  });

  const onSubmit = (data: any) => {
    closeSnackbar();

    mutate({
      variables: {
        input: {
          email: data.email,
        },
      },
    });
  };

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <Typography variant="h5" color="primary">
          Subscreve a nossa newsletter
        </Typography>

        <Typography variant="subtitle1" className={classes.font}>
          Fica a par das últimas notícias, artigos e atualizações sobre a ANJB
        </Typography>
        <Spacer spacing={2} />

        <form
          className={classes.formContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextField
            className={classes.field}
            name="email"
            variant="filled"
            placeholder="E-mail"
            inputProps={{
              ref: register,
            }}
            error={errors?.email}
            helperText={errors?.email?.message}
          />

          <IconButton
            type="submit"
            className={classes.submitIcon}
            disabled={loading}
          >
            <SendIcon style={{ width: '1rem', height: '1rem' }} />
          </IconButton>
        </form>
      </div>
    </section>
  );
}
