/* eslint linebreak-style: ["error", "unix"] */

import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import DesktopNavbarMenuButton from 'components/buttons/button-navbar-desktop-menu/desktop-navbar-menu-button';

export default function LoginMenu() {
  const {
    isOpen,
    handleOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.SIGN_IN);

  return (
    <DesktopNavbarMenuButton
      buttonLabel="Iniciar sessão"
      isActive={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
    />
  );
}
