/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  makeStyles, MenuItem, Select,
} from '@material-ui/core';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { getProductByIdGql, Product } from 'data/products';
import SectionStepper from 'components/section-stepper/section-stepper';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  select: {
    '& .MuiSelect-select': {
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },

    '&.MuiOutlinedInput-root': {
      borderRadius: 0,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },

    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
}));

interface Props {
  selectedProductId: number | null;
  value: number[],
  onChange: (selection: number[]) => void
}

// TODO: Works for now, but too complex. and material ui select is not rerendering with useEffect
export default function SelectProductFilterValues({
  value = [],
  onChange,
  selectedProductId,
}: Props) {
  const classes = useStyles();

  const [localValue, setLocalValue] = useState<Record<string, any>>({});

  const { data: rawData, loading, error } = useQuery(getProductByIdGql, {
    variables: {
      id: selectedProductId,
    },
    skip: !selectedProductId,
  });
  const product: Product = rawData?.getProductById;

  const productFilterCategories = product?.productCategory?.productFilterCategories || [];

  const updateData = (nextState: Record<string, any>) => {
    onChange(Object.values(nextState));
  };

  useEffect(() => {
    productFilterCategories.forEach((productFilterCategory) => {
      const filterValues = productFilterCategory.filterValues || [];
      const selectedValue = filterValues.find((filterValue) => value.includes(filterValue.id));

      if (selectedValue) {
        setLocalValue((state) => ({
          ...state,
          [productFilterCategory.filterName]: selectedValue.id,
        }));
      }
    });
  }, [productFilterCategories, value]);

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      {productFilterCategories.map((productFilterCategory) => {
        const filterValues = productFilterCategory.filterValues || [];
        const options = filterValues.map((filterValue) => ({
          name: `${filterValue.value}`,
          value: filterValue.id,
        }));

        const handleChange = (filterValueId: number) => {
          const nextState = {
            ...localValue,
            [productFilterCategory?.filterName]: filterValueId,
          };

          setLocalValue(nextState);
          updateData(nextState);
        };

        return (
          <div key={productFilterCategory.filterName} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <SectionStepper displayText={productFilterCategory.filterName} />
            <Select
              key={productFilterCategory.filterName}
              value={localValue?.[productFilterCategory.filterName] || null}
              onChange={(evt) => handleChange(Number(evt.target.value) as number)}
              className={classes.select}
              variant="outlined"
            >
              {options.map(
                (option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.name}
                  </MenuItem>
                ),
              )}
            </Select>
          </div>
        );
      })}
    </DataRenderer>
  );
}
