/* eslint linebreak-style: ["error", "unix"] */
import useTheme from '@material-ui/core/styles/useTheme';
import {
  AppBar, makeStyles, Toolbar, useMediaQuery, Typography,
} from '@material-ui/core';
import Logo from 'components/logo/logo';
import { isStaging } from 'data/app';
import MobileNavbar from '../navbar-mobile/mobile-navbar';
import DesktopNavbar from '../navbar-desktop/desktop-navbar';

export const NAVBAR_HEIGHT = 68;

export const getHeight = () => {
  if (isStaging()) {
    return NAVBAR_HEIGHT + 20;
  }

  return NAVBAR_HEIGHT;
};

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    zIndex: theme.zIndex.appBar,
  },
  relativeContainer: {
    height: `${getHeight()}px`,

    display: 'flex',
  },
  navbar: {
    position: 'fixed',
    width: '100%',

    '& header': {
      background: theme.palette.secondary.main,
    },

    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },

    '& .MuiToolbar-root': {
      padding: 0,
      margin: 0,
      minHeight: 'unset',
    },

    '& .MuiToolbar-regular': {
      padding: 0,
      margin: 0,
      minHeight: 'unset',
    },

    '& .MuiToolbar-gutters': {
      padding: 0,
      margin: 0,
      minHeight: 'unset',
    },
  },
  navbarContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: NAVBAR_HEIGHT,

    background: theme.palette.secondary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,

    '& *': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  stagingBanner: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    background: '#ff9900',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function NavbarMain() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.relativeContainer}>
        <nav className={classes.navbar}>
          <AppBar position="static">
            {(isStaging()) && (
              <div className={classes.stagingBanner}>
                <Typography variant="caption">
                  Ambiente de testes
                </Typography>
              </div>
            )}

            <Toolbar>
              <div className={classes.navbarContent}>
                <div className={classes.logoContainer}>
                  <Logo />
                </div>
                {isMobile
                  ? <MobileNavbar />
                  : <DesktopNavbar />}
              </div>
            </Toolbar>
          </AppBar>
        </nav>
      </div>
    </header>
  );
}
