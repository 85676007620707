/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import {
  FormGroup,
  FormLabel,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { defaultNewsletterTemplate, processEmailGql } from 'data/newsletter';
import { useMutation } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';
import RefreshIcon from '@material-ui/icons/Refresh';
import Spacer from 'components/spacer/spacer';
import EditorBar from './editor-bar';

interface Props {
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& .MuiPaper-root': {
      minWidth: 'unset !important',
    },
  },
  textArea: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.divider,
    },
    '& textarea': {
      fontSize: 12,
      display: 'block',
      fontFamily: 'monospace',
      whiteSpace: 'pre',
      margin: '1em 0',
    },
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
  previewNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    width: '100%',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
}));

export default function MJMLRawEditor({
  content,
  setContent,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [mutate, { data: rawData }] = useMutation(processEmailGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
  });
  const data = rawData?.processEmail;

  const [caret, setCaret] = useState({ start: 0, end: 0 });

  // updates the cursor position onselect
  const handleSelect = (e: any) => {
    setCaret({ start: e.target.selectionStart, end: e.target.selectionEnd });
  };

  return (
    <section>
      <div className={classes.formContainer}>
        <FormGroup>
          <EditorBar content={content} setContent={setContent} caret={caret} />

          <TextField
            className={classes.textArea}
            label=""
            multiline
            rows={20}
            variant="outlined"
            onSelect={(e) => handleSelect(e)}
            value={content}
            onChange={(evt) => {
              const { value } = evt.target;

              setContent(value);
            }}
          />

        </FormGroup>
      </div>
      <Spacer spacing={2} />

      <div className={classes.preview}>
        <nav className={classes.previewNav}>
          <FormLabel>
            Pré-visualizar newsletter
          </FormLabel>
          <IconButton onClick={() => {
            mutate({
              variables: {
                input: {
                  content,
                },
              },
            });
          }}
          >
            <RefreshIcon />
          </IconButton>
        </nav>
        <Spacer spacing={2} />

        <div dangerouslySetInnerHTML={{
          __html: data,
        }}
        />

        <Spacer spacing={2} />
      </div>
    </section>
  );
}
