/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import Page from 'pages/_layouts/page';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getSubscriberPageByUrlGql, Page as IPage } from 'data/pages';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import EditorReadonly from 'components/editor-readonly/editor-readonly';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export default function SubscriberDynamicPageSubpage() {
  const classes = useStyles();

  const location = useLocation();

  const url = location.pathname?.split('/').reverse()?.[0];

  const { data: rawData, loading, error } = useQuery(getSubscriberPageByUrlGql, {
    variables: {
      url,
    },
  });

  const data: IPage = rawData?.getSubscriberPageByUrl;

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <SectionTitle>
            {data?.name}
          </SectionTitle>

          <EditorReadonly
            defaultValue={data?.content}
            readOnly
            toolbar={false}
          />
        </DataRenderer>
      </div>
    </Page>
  );
}
