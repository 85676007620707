/* eslint linebreak-style: ["error", "unix"] */
import { SyntheticEvent } from 'react';

export function preventDefaultAndStopPropagation(event: SyntheticEvent) {
  if (event?.stopPropagation) {
    event.stopPropagation();
  }

  if (event?.preventDefault) {
    event.preventDefault();
  }
}
