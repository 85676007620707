/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import ProductsList from 'components/lists/list-products/products-list';
import { Typography } from '@material-ui/core';
import ProductSearchFilters from 'components/filters/filters-product-search-filters/product-search-filters';
import StoreHighlightsCarousel from 'components/carousels/carousel-store-highlights/store-highlights-carousel';
import StoreSearchbar from 'components/search-bars/search-bar-store/search-bar';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    width: '100%',
  },
  storeHighlights: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  searchBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    bottom: -20,
    position: 'absolute',
  },
  storeContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    padding: theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2),
    },
  },
  searchFiltersContainer: {
    height: '100%',
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    maxWidth: '25%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset'
    },

    marginBottom: theme.spacing(4),
  },
  productListContainer: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default function StorePage() {
  const classes = useStyles();

  return (
    <section className={classes.section}>
      <div className={classes.storeHighlights}>
        <StoreHighlightsCarousel />
        <div className={classes.searchBarContainer}>
          <StoreSearchbar />
        </div>
      </div>

      <section className={classes.storeContent}>
        <div className={classes.searchFiltersContainer}>
          <Typography id="discrete-slider" gutterBottom color="primary">
            Filtros
          </Typography>

          <ProductSearchFilters />
        </div>

        <div className={classes.productListContainer}>
          <ProductsList />
        </div>
      </section>
    </section>
  );
}
