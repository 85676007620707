/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles } from '@material-ui/core';
import { FeedSearchFilters } from 'components/landing-page-publications-feed/landing-page-publications-feed';
import MultiSelectionFilter from 'components/filters/filter-multi-selection/multi-selection-filter';
import { useQuery } from '@apollo/client';
import { listPublicationCategoriesGql, PublicationCategory } from 'data/publication-categories';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';

interface Props {
  searchFilters: FeedSearchFilters
  onApplyFilter: (value: any) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function PublicationCategoriesFilter({
  searchFilters,
  onApplyFilter,
}: Props) {
  const classes = useStyles();
  const { data: rawData, loading, error } = useQuery(listPublicationCategoriesGql, {});

  const data: PublicationCategory[] = rawData?.listPublicationCategories;

  const selectOptions = data?.map((publicationCategory) => ({
    label: publicationCategory.name,
    value: publicationCategory.id,
  }));

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      <div className={classes.root}>
        <MultiSelectionFilter
          name="categorias"
          label="Categorias"
          options={selectOptions}
          onApplyFilter={onApplyFilter}
          searchFilters={searchFilters}
          searchKey="publicationCategories"
        />
      </div>
    </DataRenderer>
  );
}
