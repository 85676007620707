/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectSearchFilters } from 'redux/product-search.redux';
import Pagination from 'components/table/pagination/pagination';
import { DEFAULT_PAGE_SIZES } from 'components/table/pagination/page-counter/page-counter';
import { useQuery } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import { listProductsGql, Product } from 'data/products';
import ProductTile from 'components/tiles/tile-product/product-tile';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
}));

export default function ProductsList() {
  const classes = useStyles();

  const [limit, setLimit] = useState<number>(DEFAULT_PAGE_SIZES[0]);
  const [offset, setOffset] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const searchFilters = useSelector(selectSearchFilters);

  const { data: rawData, loading, error } = useQuery(listProductsGql, {
    variables: {
      filter: {
        limit,
        offset,
        ...searchFilters,
      },
    },
  });

  const data: Product[] = rawData?.listProducts || [];

  return (
    <div className={classes.root}>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        {data?.map((product) => (
          <ProductTile product={product} key={product?.id} />
        ))}

        <Pagination
          data={data}
          setCurrentLimit={setLimit}
          setCurrentOffset={setOffset}
          setCurrentPage={setCurrentPage}
          currentLimit={limit}
          currentPage={currentPage}
        />
      </DataRenderer>
    </div>
  );
}
