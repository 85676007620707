/* eslint linebreak-style: ["error", "unix"] */

import { Button, makeStyles, TextField } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { emailRequired } from 'utils/validators';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { requestNewEmailVerificationGql } from 'data/email-verification';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  button: {
    '&.MuiButton-contained': {
      borderRadius: 0,
      boxShadow: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const validationSchema = yup.object().shape({
  email: emailRequired,
});

export default function RequestEmailVerificationCodeForm() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),

  });

  const { handleSubmit, errors: formErrors, register } = formApi;

  const [mutate, { loading }] = useMutation(requestNewEmailVerificationGql,
    {
      onError: (error) => {
        enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
      },
      onCompleted: () => {
        enqueueSnackbar('Foi enviado um e-mail com instruções', { variant: 'success' });
      },
    });

  const onSubmit = (data: Record<string, any>) => {
    mutate({
      variables: {
        input: {
          email: data?.email,
        },
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.content}>
        <TextField
          name="email"
          placeholder="E-mail"
          type="email"
          variant="standard"
          helperText={formErrors?.email?.message}
          inputProps={{
            ref: register,
          }}
        />

        <Spacer spacing={2} />

        <Button disabled={loading} type="submit" variant="contained" color="primary" className={classes.button}>
          Submeter
        </Button>
      </div>
    </form>
  );
}
