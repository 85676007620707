/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';

export interface Newsletter {
  id: number
  email: string
  subscribedAt: string
  subscribedOnSignup: boolean
}

// Mutations
export const subscribeNewsletterGql = gql`
  mutation subscribeNewsletter($input: ISubscribeNewsletter!) {
    subscribeNewsletter(input: $input)
  }
`;

export const sendNewsletterGql = gql`
  mutation sendNewsletter($input: ISendNewsletter!) {
    sendNewsletter(input: $input)
  }
`;

export const processEmailGql = gql`
  mutation processEmail($input: IProcessEmail!) {
    processEmail(input: $input) 
  }
`;

// Queries
export const listNewslettersGql = gql`
  query listNewsletters($filter: INewsletterFilter) {
    listNewsletters(filter: $filter) {
      id
      email
      subscribedAt
      subscribedOnSignup
    }
  }
`;

// Utils

const PRIMARY_COLOR = '#cc4a33';

export const defaultNewsletterTemplate = `<mjml>
  <mj-body background-color="#f5f6f8">
    <mj-section text-align="center" full-width="full-width">
      <mj-column>
        <mj-text font-size="24px" color="${PRIMARY_COLOR}" font-family="helvetica">
          Olá,
        </mj-text>
      </mj-column>
    </mj-section>

    <mj-section text-align="center" full-width="full-width">
      <mj-column>
        <mj-text>
          Caso necessites de alguma informação adicional,
          não hesites em contactar-nos: <a href="mailto:direccao@anjb.pt">direccao@anjb.pt</a>.
        </mj-text>
      </mj-column>
    </mj-section>

    <mj-section text-align="center" full-width="full-width">
      <mj-column>
        <mj-text>
          🏀 A Equipa ANJB
        </mj-text>
      </mj-column>
    </mj-section>
  </mj-body>
</mjml>`;
