/* eslint linebreak-style: ["error", "unix"] */

import {
  Typography, Button, makeStyles,
} from '@material-ui/core';
import MaterialAvatar from '@material-ui/core/Avatar';
import { Member } from './data';

interface Props {
  member: Member
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(6, 2),
    background: theme.palette.common.white,
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    position: 'relative',
    minHeight: 185,
    minWidth: 150,
    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',

    '&:hover': {
      transform: 'translateY(-.2rem)',
      boxShadow: '0 .1rem .5rem 0 rgba(0, 0, 0, .15)',
      zIndex: theme.zIndex.appBar - 1,
    },

    transition: '.25s ease all',
  },
  avatar: {
    height: theme.spacing(11),
    width: theme.spacing(11),
    position: 'absolute',
    top: '-2.5rem',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  info: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
  socialMediaWrapper: {
    display: 'flex',
    flexDirection: 'row',
    background: theme.palette.primary.main,

    position: 'absolute',
    bottom: 0,
    width: '100%',
    minHeight: theme.spacing(5),
  },
  socialIconContent: {
    display: 'flex',
    position: 'relative',

    '& svg': {
      fill: theme.palette.common.white,
    },
  },
}));

export default function Avatar({ member }: Props) {
  const classes = useStyles();

  const image = member?.image || '/images/anjb-publication-fallback-image.jpeg';
  const { socialLinks = [] } = member;

  return (
    <article className={classes.container}>
      <MaterialAvatar
        src={image}
        className={classes.avatar}
      />

      <div className={classes.info}>
        <Typography color="primary" variant="subtitle1" style={{ fontWeight: 700 }}>
          {member.name}
        </Typography>
        <Typography color="textSecondary" variant="subtitle2" style={{ fontWeight: 300 }}>
          <em>{member.role}</em>
        </Typography>
      </div>

      {
        socialLinks.length > 0 && (
          <div className={classes.socialMediaWrapper}>
            {socialLinks.map((socialLink, index) => {
              const Icon = socialLink.icon;
              return (
                <div className={classes.socialIconContent} key={index}>
                  <a href={socialLink.to} target="__blank">
                    <Button>
                      <Icon />
                    </Button>
                  </a>
                </div>
              );
            })}
          </div>
        )
      }
    </article>
  );
}
