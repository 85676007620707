/* eslint linebreak-style: ["error", "unix"] */
import { useMutation } from '@apollo/client';
import {
  Button,
  TextField,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { formatDateToYYYYMMDD } from 'utils/formatters';
import { updateUserOwnProfileGql, UserProfile } from 'data/user-profiles';
import {
  minimumLengthRequiredNumber, numberOptional, phoneRequired, postalCodeRequired,
} from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import SectionStepper from 'components/section-stepper/section-stepper';
import GenderSelect from 'components/selects/select-gender/gender-select';

const validationSchema = yup.object().shape({
  fullName: yup.string().nullable(),
  gender: yup.string(),
  birthdate: yup.string().nullable(),
  placeOfBirth: yup.string().nullable(),
  address: yup.string().nullable(),
  locality: yup.string().nullable(),
  postalCode: postalCodeRequired.nullable(),
  country: yup.string().nullable(),
  phoneNumber: phoneRequired.nullable(),
  zoneId: numberOptional,
  taxpayerNumber: minimumLengthRequiredNumber(9, 'Número de contribuinte'),
});

interface Props {
  classes: Record<string, any>;
  onSuccess: (data: UserProfile) => void;
  defaultValues?: UserProfile;
}

export default function EditProfileForm({ classes, onSuccess, defaultValues }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit, errors, register, watch, control,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...defaultValues,
      birthdate: formatDateToYYYYMMDD(defaultValues?.birthdate),
    },
  });

  const [mutate, { loading }] = useMutation(
    updateUserOwnProfileGql,
    {
      onError: (error) => {
        enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
      },
      onCompleted: (rawData) => {
        const data: UserProfile = rawData?.updateUserOwnProfile;

        enqueueSnackbar('Perfil atualizado com sucesso', { variant: 'success' });

        onSuccess(data);
      },
    },
  );

  const onSubmit = (data: Record<string, any>) => {
    const input = getValidatedInput(data);

    if (!input || !Object.values(input)?.length) {
      enqueueSnackbar('Perfil atualizado com sucesso', { variant: 'success' });
      return;
    }

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

      <SectionStepper displayText="Informações pessoais" />

      {renderTextInput('Nome próprio', 'fullName', register, errors)}
      <Spacer spacing={2} />

      <TextField
        name="birthdate"
        label="Data de Aniversário"
        type="date"
        inputProps={{
          ref: register,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.birthdate}
        helperText={errors?.birthdate?.message}
      />
      <Spacer spacing={2} />
      <Spacer spacing={2} />

      <GenderSelect name="gender" label="Género" control={control} errors={errors} defaultValue={watch('gender')} />
      <Spacer spacing={2} />

      <TextField
        name="phoneNumber"
        label="Número de Telemóvel"
        type="text"
        inputProps={{
          ref: register,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.phoneNumber}
        helperText={errors?.phoneNumber?.message}
      />
      <Spacer spacing={2} />

      <SectionStepper displayText="Morada" />

      {renderTextInput('Morada', 'address', register, errors)}
      <Spacer spacing={2} />

      {renderTextInput('Localidade', 'locality', register, errors)}
      <Spacer spacing={2} />

      <TextField
        name="postalCode"
        label="Código Postal"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={!!errors?.postalCode}
        helperText={errors?.postalCode?.message}
        InputLabelProps={{
          shrink: true,
        }}

      />
      <Spacer spacing={2} />

      {renderTextInput('País', 'country', register, errors)}
      <Spacer spacing={2} />

      <SectionStepper displayText="Dados Civis" />

      <Spacer spacing={2} />

      <TextField
        name="taxpayerNumber"
        label="Número de Contribuinte"
        type="number"
        inputProps={{
          ref: register,
        }}
        error={!!errors?.taxpayerNumber}
        helperText={errors?.taxpayerNumber?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Spacer spacing={2} />

      <Spacer spacing={4} />
      <Button type="submit" color="primary" variant="contained" disabled={loading}>
        Editar Perfil
      </Button>
    </form>
  );
}

const renderTextInput = (label: string, name: string, register: any, errors: Record<string, any>) => (
  <>
    <TextField
      name={name}
      label={label}
      type="text"
      inputProps={{
        ref: register,
      }}
      error={!!errors?.[name]}
      helperText={errors?.[name]?.message}
    />
  </>
);

const getValidatedInput = (data: Record<string, any>) => {
  // Only send filled values to the backend
  let input = {};

  const stringFields = ['phoneNumber'];
  const numberFields = ['taxpayerNumber'];
  Object.entries(data).forEach((entry) => {
    // No value, return
    if (!entry[1]) {
      return;
    }

    if (stringFields.includes(entry[0])) {
      input = {
        ...input,
        [entry[0]]: entry[1].toString(),
      };

      return;
    }

    if (numberFields.includes(entry[0])) {
      input = {
        ...input,
        [entry[0]]: Number(entry[1]),
      };

      return;
    }

    input = {
      ...input,
      [entry[0]]: entry[1],
    };
  });

  return input;
};
