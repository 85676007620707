/* eslint linebreak-style: ["error", "unix"] */
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import { APP_ROUTES } from 'data/app';

export default function TeamBreadcrumbs() {
  return (
    <Breadcrumbs links={[
      {
        displayName: 'Início',
        to: APP_ROUTES.home,
      },
      {
        displayName: 'Órgãos Sociais',
      },
    ]}
    />
  );
}
