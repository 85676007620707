/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Avatar, makeStyles } from '@material-ui/core';
import { authLogout, selectCurrentUser } from 'redux/auth.redux';
import { useDispatch, useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'data/app';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  icon: {
    display: 'flex',
    '& .MuiAvatar-root': {
      background: theme.palette.primary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  iconActive: {
    fill: theme.palette.primary.main,
  },
}));

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: 0,
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)',

    '& svg': {
      fill: theme.palette.common.white,
    },

    borderLeft: `2px solid ${theme.palette.primary.main}`,

    '& .MuiList-padding': {
      padding: 0,
    },

    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  },
}))((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function UserAvatarMenu() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // On route change, close menu
  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <section className={classes.root}>
      <Button className={classes.icon} onClick={handleClick}>
        <Avatar>
          {currentUser?.username?.[0].toUpperCase()}
        </Avatar>

        {anchorEl
          ? <ArrowDropUpIcon className={classes.iconActive} />
          : <ArrowDropDownIcon />}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={APP_ROUTES.manageAccount}>
          <StyledMenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Gerir conta" />
          </StyledMenuItem>
        </Link>

        <Link to={APP_ROUTES.manageOrders}>
          <StyledMenuItem>
            <ListItemIcon>
              <ReceiptIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Gerir encomendas" />
          </StyledMenuItem>
        </Link>

        <StyledMenuItem onClick={() => dispatch(authLogout())}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </section>
  );
}
