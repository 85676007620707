/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import { useModalStyles } from 'styles/modal.styles';
import SubscriptionPlanForm from 'components/forms/form-subscription-plan/form-subscription-plan';

export default function ModalCreateSubscriptionPlan() {
  const classes = useModalStyles();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.CREATE_NEW_SUBSCRIPTION_PLAN);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <SectionTitle>Criar modalide</SectionTitle>
      <SubscriptionPlanForm onSuccess={handleClose} />
    </Modal>
  );
}
