/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { subscriptionPaymentMinimumDetailsFragment } from './subscription-payment';
import { subscriptionPlansFullDetailsFragment } from './subscription-plans';
import { Subscription } from './subscriptions';
import { UserProfile } from './user-profiles';

export enum UserRole {
	USER = 'USER',
	ADMIN = 'ADMIN',
}

export const formatUserRole = (userRole: UserRole): string => {
	if (userRole === UserRole.ADMIN) {
		return 'Administrador';
	}

	if (userRole === UserRole.USER) {
		return 'Utilizador';
	}

	return 'Role de utilizador não reconhecido';
};

export interface User {
	id: number
	username: string
	email: string
	role: UserRole
	isActive: boolean
	createdAt: string
	lastLoginAt: string
	profile: UserProfile
	subscription: Subscription | null
	allowedSubscriptionPlan: {
		id: number
		name: string
	}
	membershipNumber: number | null
}

export const userMinimumDetailsFragment = gql`
	fragment userMinimumDetailsFragment on UserEntity {
		id
		username
		email
		role
		lastLoginAt
		allowedSubscriptionPlan {
			id
			name
		}
	}
`;

export const userFullDetailsFragment = gql`
	fragment userFullDetailsFragment on UserEntity {
		id
		username
		email
		role
		isActive
		lastLoginAt
		createdAt
		membershipNumber
		allowedSubscriptionPlan {
			id
			name
		}
		profile {
			fullName
			gender
			address
			locality
			country
			postalCode
			taxpayerNumber
			birthdate
			phoneNumber
			updatedAt
		}
		subscription {
			id
			subscriptionPlan {
				...subscriptionPlansFullDetailsFragment
			}
			startDate
			endDate
			subscriptionPayments {
				...subscriptionPaymentMinimumDetailsFragment
			}
		}
	}
	${subscriptionPlansFullDetailsFragment}
	${subscriptionPaymentMinimumDetailsFragment}
`;

export const userPartnerFullDetailsFragment = gql`
	fragment userPartnerFullDetailsFragment on UserEntity {
		id
		username
		email
		role
		isActive
		lastLoginAt
		createdAt
		membershipNumber
		allowedSubscriptionPlan {
			id
			name
		}
		profile {
			fullName
			gender
			address
			locality
			country
			postalCode
			taxpayerNumber
			birthdate
			phoneNumber
			updatedAt
		}
		subscription {
			id
			subscriptionPlan {
				...subscriptionPlansFullDetailsFragment
			}
			startDate
			endDate
			subscriptionPayments {
				...subscriptionPaymentMinimumDetailsFragment
			}
		}
	}
	${subscriptionPlansFullDetailsFragment}
	${subscriptionPaymentMinimumDetailsFragment}
`;

// Mutations
export const createUserGql = gql`
	mutation createUser($input: ICreateUser!) {
		createUser(input: $input) {
			id
			username
			email
			role
			isActive
			allowedSubscriptionPlan {
				id
				name
			}
		}
	}
`;

export const updateUserGql = gql`
	mutation updateUser($input: IUpdateUser!) {
		updateUser(input: $input) {
			id
			username
			email
			role
			isActive
			allowedSubscriptionPlan {
				id
				name
			}
		}
	}
`;

export const updateUserBackofficeGql = gql`
	mutation updateUserBackoffice($input: IUpdateUserBackoffice!) {
		updateUserBackoffice(input: $input) {
			id
			username
			email
			role
			isActive
			membershipNumber
			subscription {
				startDate
				endDate
			}
			allowedSubscriptionPlan {
				id
				name
			}
		}
	}
`;

export const deleteUserGql = gql`
	mutation deleteUser($input: IDeleteUser!) {
		deleteUser(input: $input)
	}
`;

export const getUserByIdGql = gql`
	query getUserById($id: Int!) {
		getUserById(id: $id) {
			...userFullDetailsFragment
		}
	}
	${userFullDetailsFragment}
`;

export const listUsersGql = gql`
	query listUsers($filter: IUserFilter) {
		listUsers(filter: $filter) {
			...userFullDetailsFragment
		}
	}
	${userFullDetailsFragment}
`;

export const listUsersPartnersGql = gql`
	query listUsersPartners($filter: IUserFilter) {
		listUsersPartners(filter: $filter) {
			...userPartnerFullDetailsFragment
		}
	}
	${userPartnerFullDetailsFragment}
`;
