/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: theme.spacing(2, 0),
  },
  connector: {
    backgroundColor: theme.palette.divider,
    width: '100%',
    height: 1,
  },
  typography: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 2),
    textTransform: 'uppercase',
    minWidth: 150,
  },
}));

interface Props {
  displayText: string
}

export default function SectionStepper({ displayText }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.connector} />
      <Typography variant="caption" className={classes.typography}>{displayText}</Typography>
      <div className={classes.connector} />
    </div>
  );
}
