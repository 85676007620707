/* eslint linebreak-style: ["error", "unix"] */
import React, { ReactChild, ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';

interface Props {
  children: ReactChild | ReactNode
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  content: {
    display: 'flex',
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
  },
}));

export default function SectionTitle({
  children,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="h4" color="primary">
          {children}
        </Typography>
      </div>
    </div>
  );
}
