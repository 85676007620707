/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { useForm } from 'react-hook-form';
import {
  booleanOptional, numberRequired, stringRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';

import SwitchInput from 'components/inputs/input-switch/switch-input';
import SelectGeneric from 'components/selects/select-generic/select-generic';
import { useFormStyles } from 'styles/form.styles';
import {
  createProductGql, Product, updateProductGql,
} from 'data/products';
import { listProductCategoriesGql } from 'data/product-categories';
import SectionStepper from 'components/section-stepper/section-stepper';
import React from 'react';
import EditorInput from 'components/inputs/input-editor-input/editor-input';

const validationSchema = yup.object().shape({
  name: stringRequired,
  description: stringRequired,
  price: numberRequired.min(0, 'Deve ser um número positivo'),
  subscriberPrice: numberRequired.min(0, 'Deve ser um número positivo'),
  isActive: booleanOptional,
  productCategoryId: numberRequired,
});

export interface Props {
  data?: Product
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function FormProduct({
  data,
  onSuccess,
}: Props) {
  const classes = useFormStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, watch, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        description: data?.description,
        content: data?.content,
        price: data?.price,
        subscriberPrice: data?.subscriberPrice,
        isActive: data?.isActive,
        productCategoryId: data?.productCategory?.id,
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updateProductGql : createProductGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing
        ? 'Produto atualizado com sucesso'
        : 'Produto criado com sucesso',
        { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(onSubmit)}
    >

      <SelectGeneric
        name="productCategoryId"
        label="Categoria do produto"
        tooltipText=""
        query={listProductCategoriesGql}
        querySelector="listProductCategories"
        multiple={false}
        control={control}
        errors={errors}
      />

      <TextField
        name="name"
        label="Nome do produto"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="description"
        label="Descrição do produto"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <EditorInput
        name="content"
        label="Conteúdo"
        defaultValue={data?.content}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <Spacer spacing={4} />

      <SwitchInput
        name="isActive"
        label="Visível ao público"
        tooltip=""
        control={control}
        errors={errors}
        defaultValue={data?.isActive}
      />
      <Spacer spacing={2} />

      <SectionStepper displayText="Preços" />
      <Tooltip title="Preço aplicado a visitantes e utilizadores que não estão subscritos">
        <TextField
          name="price"
          label="Preço do produto (€)"
          type="number"
          inputProps={{
            ref: register,
            step: '0.01',
          }}
          error={Boolean(errors?.price)}
          helperText={errors?.price?.message}
        />
      </Tooltip>
      <Spacer spacing={2} />

      <Tooltip title="Preço aplicado a sócios">
        <TextField
          name="subscriberPrice"
          label="Preço do produto para sócios (€)"
          type="number"
          inputProps={{
            ref: register,
            step: '0.01',
          }}
          error={Boolean(errors?.subscriberPrice)}
          helperText={errors?.subscriberPrice?.message}
        />
      </Tooltip>
      <Spacer spacing={2} />

      <Spacer spacing={4} />
      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar produto'}
      </Button>

    </form>
  );
}
