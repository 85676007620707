/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useMutation } from '@apollo/client';
import {
  BucketFile, deleteFileGql, getFileThumbnail,
} from 'data/storage';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { Button, Menu, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Spacer from 'components/spacer/spacer';
import ConfirmModal from 'components/modals/modal-confirm/confirm-modal';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface Props {
  bucketName: string
  bucketFile: BucketFile
  refetch: () => void;
  onFileSelect: (selectedFile: BucketFile, bucketName?: string) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    width: 150,
    justifyContent: 'center',
    padding: theme.spacing(2),
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(2),

    '&:hover': {
      boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
    },

    transition: '.1s ease all',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fileLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',

  },
  thumbnail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    height: 50,
    width: '100%',

    '& button': {
      height: 30,
      borderRadius: 0,
      boxShadow: 'none',
      border: 'none',
      color: theme.palette.common.white,
      minWidth: 'unset !important',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonOpen: {
    background: theme.palette.primary.main,
    width: '70%',
  },
  buttonRemove: {
    background: theme.palette.grey[500],
    width: '30%',

    '&:hover': {
      background: theme.palette.grey[400],
    },

    '& svg': {
      width: '.7em',
      height: '.7em',
    },
  },
}));

export default function BucketItem({
  bucketName, bucketFile, refetch, onFileSelect,
}: Props) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  const [contextMenu, setContextMenu] = useState<null | HTMLElement>(null);

  const { enqueueSnackbar } = useSnackbar();

  const thumbnail = getFileThumbnail(bucketFile.publicUrl);

  const [mutate] = useMutation(deleteFileGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Ficheiro removido com sucesso!', { variant: 'success' });
      refetch();
    },
  });

  const handleDelete = () => {
    mutate({
      variables: {
        bucketName,
        fileName: bucketFile.fileName,
      },
    });
    setModalOpen(false);
  };

  const handleOpenContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setContextMenu(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  return (
    <>
      <ConfirmModal
        confirmationTitle={`Remover ficheiro ${bucketFile.fileName}`}
        confirmationText="Está prestes a remover este ficheiro permanentemente. Continuar?"
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        confirm={handleDelete}
      />
      <article className={classes.container}>
        <header className={classes.header}>
          <Tooltip title={bucketFile.fileName}>
            <Typography variant="caption" className={classes.fileLabel}>
              {bucketFile.fileName}
            </Typography>
          </Tooltip>
        </header>
        <Spacer spacing={1} />
        <section className={classes.thumbnail} style={{ backgroundImage: `url(${thumbnail})` }} />
        <Spacer spacing={1} />

        <section className={classes.actions}>
          <button className={classes.buttonOpen} onClick={() => onFileSelect(bucketFile, bucketName)}>
            <Typography variant="caption" style={{ fontSize: 11 }}>
              Selecionar
            </Typography>
          </button>

          <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenContextMenu} className={classes.buttonRemove}>
              <MoreHorizIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={contextMenu}
              keepMounted
              open={Boolean(contextMenu)}
              onClose={handleCloseContextMenu}
            >
              <MenuItem onClick={() => {
                navigator.clipboard.writeText(bucketFile.publicUrl);

                handleCloseContextMenu();
              }}
              >
                Obter link público
              </MenuItem>
              <MenuItem onClick={() => {
                navigator.clipboard.writeText(bucketFile.signedUrl);

                handleCloseContextMenu();
              }}
              >
                Obter link secreto (válido por 2 semanas)
              </MenuItem>
              <MenuItem onClick={() => {
                // Open delete confirmation modal
                setModalOpen(true);

                handleCloseContextMenu();
              }}
              >
                Remover
              </MenuItem>
            </Menu>
          </>

          {/* <Tooltip title="Remover este ficheiro permanentemente">
            <button className={classes.buttonRemove} onClick={() => setModalOpen(true)}>
              <Typography variant="caption" style={{ fontSize: 11 }} color="textSecondary">
                Remover
              </Typography>
            </button>
          </Tooltip> */}
        </section>
      </article>
    </>
  );
}
