/* eslint linebreak-style: ["error", "unix"] */
import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Spacer from 'components/spacer/spacer';
import DownloadLink from 'components/links/link-download/download-link';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: theme.spacing(1),
  },
  uploadedDocument: {
    display: 'flex',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
  },
  image: {
    display: 'flex',
    objectFit: 'contain',
  },
  removeIcon: {
    marginLeft: 5,
  },
}));

interface Props {
  bucketName: string
  bucketFile: string
  setValue: (name: string, value: string | null) => void;
}

export default function InputDocumentSelection({
  bucketName,
  bucketFile,
  setValue,
}: Props) {
  const [bucketManagerOpen, setBucketManagerOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <BucketManagerModal
        open={bucketManagerOpen}
        onClose={() => setBucketManagerOpen(false)}
        onFileSelect={(file, bucketName) => {
          setValue('bucketFile', file.fileName);
          setValue('bucketName', bucketName || '');
          setBucketManagerOpen(false);
        }}
      />
      <div className={classes.container}>
        <ul className={classes.list}>
          {(bucketName && bucketFile) ? (
            <li className={classes.item}>

              <div className={classes.uploadedDocument}>
                <Typography>
                  {bucketFile}
                </Typography>
                <DownloadLink
                  bucketFile={bucketFile}
                  bucketName={bucketName}
                />
              </div>
              <Spacer spacing={2} />

              <Button
                onClick={() => {
                  setValue('bucketName', null);
                  setValue('bucketFile', null);
                }}
                variant="outlined"
                style={{ borderRadius: 0 }}
              >
                Remover documento
                <RemoveIcon className={classes.removeIcon} />
              </Button>
            </li>
          ) : (
            <>
              <Spacer spacing={2} />
              <Button onClick={() => setBucketManagerOpen(true)} variant="text" color="primary">
                Selecionar documento
              </Button>
            </>
          )}
        </ul>
      </div>
    </>
  );
}
