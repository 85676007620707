/* eslint linebreak-style: ["error", "unix"] */
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectCurrentUser } from 'redux/auth.redux';
import { APP_ROUTES } from 'data/app';
import { isSubscriber } from 'data/subscriptions';
import { UserRole } from 'data/users';
import { RouteProps } from './routes.interfaces';

interface Props extends RouteProps { }

/**
 * Routes for users who have no subscription active (E.g. a route to apply for a subscription)
 */
export default function UnsubscriberRoute({ component, ...rest }: Props) {
  const user = useSelector(selectCurrentUser);

  // Allow admins to access unsubscriber routes
  if (user?.role === UserRole.ADMIN) {
    return <Route {...rest} component={component} />;
  }

  // If no user or subscription active, redirect to home
  if (!user || isSubscriber(user)) {
    return <Redirect to={APP_ROUTES.home} />;
  }

  // User is not a subscriber, allow route
  return <Route {...rest} component={component} />;
}
