/* eslint linebreak-style: ["error", "unix"] */
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectCurrentUser } from 'redux/auth.redux';
import { APP_ROUTES } from 'data/app';
import { RouteProps } from './routes.interfaces';

interface Props extends RouteProps { }

export default function AuthenticatedRoute({ component, ...rest }: Props) {
	const loggedIn = useSelector(selectCurrentUser);

	return loggedIn
		? <Route {...rest} component={component} />
		: <Redirect to={APP_ROUTES.home} />;
}
