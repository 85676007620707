/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import FormProductCategory from 'components/forms/form-product-category/form-product-category';
import { getProductCategoryByIdGql, ProductCategory } from 'data/product-categories';

export default function ModalEditProductCategory() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<ProductCategory>(
    ModalQueryParams.EDIT_PRODUCT_CATEGORY,
    getProductCategoryByIdGql,
    'getProductCategoryById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <SectionTitle>Editar categoria de produto</SectionTitle>
        <FormProductCategory
          data={data}
          onSuccess={handleClose}
        />
      </DataRenderer>
    </Modal>
  );
}
