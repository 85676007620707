/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';

export interface Page {
  id: number
  name: string
  url: string
  content: string
  subscribersOnly: boolean
}

export const pageFragment = gql`
	fragment pageFragment on PageEntity {
		id
		name
    url
    content
    subscribersOnly
	}
`;

// Mutations
export const createPageGql = gql`
  mutation createPage($input: ICreatePage!) {
    createPage(input: $input) {
      id
      name
      url
      subscribersOnly
    }
  }
`;

export const updatePageGql = gql`
  mutation updatePage($input: IUpdatePage!) {
    updatePage(input: $input) {
      id
      name
      url
      subscribersOnly
    }
  }
`;

export const deletePageGql = gql`
  mutation deletePage($input: IDeletePage!) {
    deletePage(input: $input) 
  }
`;

// Queries
export const getPageByUrlGql = gql`
	query getPageByUrl($url: String!) {
		getPageByUrl(url: $url) {
			...pageFragment
		}
	}
	${pageFragment}
`;

export const getSubscriberPageByUrlGql = gql`
	query getSubscriberPageByUrl($url: String!) {
		getSubscriberPageByUrl(url: $url) {
			...pageFragment
		}
	}
	${pageFragment}
`;

export const getPageByIdGql = gql`
	query getPageById($id: Int!) {
		getPageById(id: $id) {
			...pageFragment
		}
	}
	${pageFragment}
`;

export const listPagesGql = gql`
  query listPages($filter: IPagesFilter!) {
    listPages(filter: $filter) {
      ...pageFragment
    }
  }
  ${pageFragment}
`;

export const listSubscriberPagesGql = gql`
  query listSubscriberPages($filter: IPagesFilter!) {
    listSubscriberPages(filter: $filter) {
      ...pageFragment
    }
  }
  ${pageFragment}
`;
