/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import SectionStepper from 'components/section-stepper/section-stepper';
import { formatToDateAndHour } from 'utils/formatters';
import { ProductVariant } from 'data/product-variants';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getModalStyle } from 'styles/modal.styles';
import { getProductByIdGql, Product } from 'data/products';
import TableGrid from 'components/table-grid/table-grid';
import PrimaryLink from 'components/links/link-primary/primary-link';
import EditorReadonly from 'components/editor-readonly/editor-readonly';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  images: {
    display: 'flex',
  },
  image: {
    display: 'flex',
    maxWidth: 300,
  },
}));

export default function ModalViewProduct() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<Product>(
    ModalQueryParams.VIEW_PRODUCT_BACKOFFICE,
    getProductByIdGql,
    'getProductById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="subtitle1">
          Detalhes do produto
          {' '}
        </Typography>
        <Spacer spacing={2} />

        <Typography variant="h5" color="primary">
          {data?.name}
          {' '}
        </Typography>
        <Divider />

        <Spacer spacing={4} />

        {renderInfo('ID', data?.id)}
        {renderInfo('Nome do Produto', data?.name)}
        {renderInfo('Categoria', data?.productCategory?.name)}
        {renderInfo('Descrição do Produto', data?.description)}

        <div className={classes.paragraph}>
          <Typography variant="body1" color="primary">
            Conteúdo
          </Typography>
          <EditorReadonly
            defaultValue={data?.content}
            readOnly
            toolbar={false}
          />
          <Spacer spacing={2} />
        </div>

        {renderInfo('Visível ao público?', data?.isActive ? 'Sim' : 'Não')}

        <SectionStepper displayText="Preços" />
        {renderInfo('Preço', `${data?.price}€`)}
        {renderInfo('Preço de Sócio', `${data?.subscriberPrice}€`)}

        <SectionStepper displayText="Atividade" />
        {renderInfo('Criado a', formatToDateAndHour(data?.createdAt))}
        {renderInfo('Atualizado a', formatToDateAndHour(data?.updatedAt))}

        <SectionStepper displayText="Variantes" />
        <Spacer spacing={1} />
        <TableGrid
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          height={500}
          rowHeight={100}
          columnDefs={[
            {
              field: 'id',
              headerName: 'ID',
              width: 70,
            },
            {
              field: 'image',
              headerName: 'Imagem',
              flex: 1,
              cellRendererFramework: (params: any) => <img alt={params.data?.productImages?.[0]} style={{ height: '100%', width: 75 }} src={params.data?.productImages?.[0]} />,
            },
            {
              field: 'name',
              headerName: 'Variante',
              flex: 1,
              cellRendererFramework: (params: any) => {
                const variant = params?.data as ProductVariant;
                const name = variant?.productFilters?.map((x) => x?.value).join(', ');
                return (
                  <PrimaryLink to={`${APP_ROUTES.backoffice.store.manageProductVariants}?${ModalQueryParams.VIEW_PRODUCT_VARIANT(variant?.id)}`}>
                    {name}
                  </PrimaryLink>
                );
              },
            },
          ]}
          rowData={data?.productVariants || []}
        />
      </DataRenderer>
    </Modal>
  );
}
