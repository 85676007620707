/* eslint linebreak-style: ["error", "unix"] */
import { useHistory, useLocation } from 'react-router-dom';

export default function useModalWithQueryParams(modalQueryParameter: string) {
  const { push } = useHistory();
  const location = useLocation();

  const queryParameters = location.search.split('?');
  const isOpen = modalQueryParameter.length > 0 && queryParameters.some((param) => param === modalQueryParameter);

  const handleOpen = () => {
    push(`${location.pathname}?${modalQueryParameter}`);
  };

  const handleClose = () => {
    push(location.pathname);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
  };
}

/**
 * Receives a list of possible modal query parameters
 * and returns if any of those modals is currently opened
 */
export function useIsModalOpen(modalQueryParameters: string[]) {
  const location = useLocation();

  const queryParameter = location.search.substr(1);
  const isOpen = modalQueryParameters.some((modalQueryParameter) => queryParameter === modalQueryParameter);

  return {
    isOpen,
  };
}
