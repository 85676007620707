/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
  TextField,
  Link as MaterialUILink,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { emailRequired } from 'utils/validators';
import { createResetPasswordCodeGql } from 'data/recover-password';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from 'components/buttons/button-submit/submit-button';
import { navbarForm, navbarField } from 'styles/navbar-form-styles';

const useStyles = makeStyles((theme) => ({
  form: navbarForm,
  field: navbarField(theme),
  recoverPasswordSection: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  recoverPasswordLink: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  email: emailRequired,
});

export default function RecoverPasswordForm() {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit, register, errors,
  } = formApi;

  const [mutate, { data, error, loading }] = useMutation(createResetPasswordCodeGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      // Notify user
      enqueueSnackbar('Enviámos um link com a recuperação de acesso', { variant: 'success' });
    },
  });

  const onSubmit = (data: any) => {
    closeSnackbar();

    mutate({
      variables: {
        input: {
          email: data.email,
        },
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.field}
        name="email"
        color="primary"
        label="E-mail"
        required
        error={errors?.email}
        helperText={errors?.email?.message}
        inputProps={{
          ref: register,
        }}
      />

      <div className={classes.recoverPasswordSection}>
        <Link to={`${APP_ROUTES.home}?${ModalQueryParams.SIGN_IN}`}>
          <div className={classes.recoverPasswordLink}>
            Iniciar sessão
          </div>
        </Link>
      </div>

      <Spacer spacing={2} />

      <SubmitButton loading={loading} buttonLabel="Recuperar Acesso" />
    </form>
  );
}
