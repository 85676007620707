/* eslint linebreak-style: ["error", "unix"] */
export const appearEffect = {
  '@keyframes appearEffect': {
    '0%': {
      opacity: 0,
      transform: 'scale(1.1)',
    },
    '100%': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
};

export const slideInEffect = {
  '@keyframes slideInEffect': {
    '0%': {
      opacity: 0,
      transform: 'translateY(3%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
};

export const blurEffect = {
  '@keyframes blurEffect': {
    '0%': {
      backdropFilter: 'blur(10px)',
    },
    '100%': {
      backdropFilter: 'blur(2px)',
    },
  },
};
