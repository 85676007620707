/* eslint linebreak-style: ["error", "unix"] */
import {
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch as MaterialUISwitch,
  Tooltip,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

interface Props {
  control: Control<any>;
  name: string;
  label: string;
  errors: Record<string, any>;
  tooltip: string;
  defaultValue: boolean | undefined
}

export default function SwitchInput({
  control,
  name,
  label,
  errors,
  tooltip,
  defaultValue,
}: Props) {
  return (
    <Controller
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <div>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup>
            <Tooltip title={tooltip}>
              <FormControlLabel
                control={(
                  <MaterialUISwitch
                    defaultChecked={defaultValue}
                    checked={value}
                    onChange={(evt) => onChange(evt.target.checked)}
                    color="primary"
                  />
                )}
                label=""
              />
            </Tooltip>
          </FormGroup>

          <FormHelperText>
            {errors?.[name]?.message}
          </FormHelperText>
        </div>
      )}
      control={control}
      name={name}
    />
  );
}
