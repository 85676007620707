/* eslint linebreak-style: ["error", "unix"] */

import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  CircularProgress, FormHelperText, FormLabel, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import { listPublicationCategoriesGql, PublicationCategory } from 'data/publication-categories';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(2, 0),

    '& .MuiSelect-select': {
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderTop: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&.MuiInput-underline:after': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  name: string
  label: string
  control: Control<any>
  errors: Record<string, any>
}

export default function PublicationCategoriesSelect({
  name,
  label,
  control,
  errors,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, loading } = useQuery(listPublicationCategoriesGql, {
    variables: {
      filter: {
        offset: 0,
        limit: 999,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  const data: PublicationCategory[] = rawData?.listPublicationCategories || [];
  const options = data.map((publicationCategory) => ({
    name: publicationCategory.name,
    value: publicationCategory.id,
  }));

  return (
    <>
      <Controller
        render={({ onChange, value = [], name }) => {
          const formattedValue = options.filter((opt) => value?.includes(opt.value));
          return (
            <>
              <FormLabel>{label}</FormLabel>
              <Select
                className={classes.select}
                name={name}
                label={label}
                value={value}
                inputProps={{
                  name,
                  'aria-label': 'Without label',
                }}
                displayEmpty
                onChange={(evt) => onChange(evt.target.value)}
                multiple
                renderValue={() => (formattedValue?.length ? formattedValue.map((x) => x.name).join(', ') : 'Escolher categoria(s)')}
              >
                {options.map((option, index) => (
                  <MenuItem key={option.value} value={option.value} className={classes.item}>
                    <Typography>
                      {option.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              {errors && <FormHelperText className={classes.helperText}>{errors?.[name]?.message}</FormHelperText>}
            </>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
}
