/* eslint linebreak-style: ["error", "unix"] */

import { IconButton, makeStyles } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    background: theme.palette.secondary.main,
  },
  closeButton: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

interface Props {
  onClick: () => void;
}

export default function CloseButton({ onClick }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <IconButton className={classes.closeButton} onClick={onClick} color="primary">
        <CloseOutlined className={classes.closeButton} />
      </IconButton>
    </div>
  );
}
