/* eslint linebreak-style: ["error", "unix"] */
import { useQuery } from '@apollo/client';
import {
  Divider, makeStyles, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { selectCurrentUser } from 'redux/auth.redux';
import { getUserByIdGql, User } from 'data/users';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export default function ViewProfile() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const { data: rawData, loading, error } = useQuery(getUserByIdGql, {
    variables: {
      id: currentUser?.id,
    },
    skip: !currentUser,
  });

  const data: User = rawData?.getUserById;

  return (
    <div className={classes.container}>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>

        <Typography color="primary" variant="h6">
          Perfil de
          {' '}
          {currentUser?.username}
        </Typography>
        <Divider />
        <Spacer spacing={2} />

        {renderInfo('Nome Completo', data?.profile?.fullName)}
        {renderInfo('Género', data?.profile?.gender)}
        {renderInfo('Categoria', data?.allowedSubscriptionPlan?.name)}
        {renderInfo('Data de nascimento', data?.profile?.birthdate)}
        {renderInfo('Nr. Contribuinte', data?.profile?.taxpayerNumber)}
        {renderInfo('E-Mail', data?.email)}
        {renderInfo('Número de Telemóvel', data?.profile?.phoneNumber)}
        {renderInfo('Morada', data?.profile?.address)}
        {renderInfo('Localidade', data?.profile?.locality)}
        {renderInfo('Código Postal', data?.profile?.postalCode)}
        {renderInfo('País', data?.profile?.country)}

      </DataRenderer>
    </div>
  );
}

const renderInfo = (label: string, value: string | number) => (
  <>
    <Typography variant="subtitle1" color="primary">
      {label}
    </Typography>
    <Typography variant="subtitle2">
      {value || '-'}
    </Typography>
    <Spacer spacing={2} />
  </>
);
