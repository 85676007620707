/* eslint linebreak-style: ["error", "unix"] */
import { createUploadLink } from 'apollo-upload-client';
import { APP_CONFIG } from 'data/app';
import { onError } from 'apollo-link-error';
import {
	ApolloClient, ApolloLink, from, InMemoryCache,
} from '@apollo/client/core';
import { store } from 'libs/redux/redux.store';
import { authForcedLogout } from 'redux/auth.redux';

const httpLink = createUploadLink({
	uri: APP_CONFIG.graphql.uri,
});

const linkErrors = onError(({ graphQLErrors, networkError }) => {
	const hasLogoutError = graphQLErrors?.some((error) => error?.message === 'Não autorizado');
	if (!hasLogoutError) {
		return;
	}

	store.dispatch(authForcedLogout());
});

const authLink = new ApolloLink((operation, forward) => {
	const { accessToken } = store.getState().auth;

	operation.setContext(({ headers }: Record<string, any>) => ({
		headers: {
			Authorization: `Bearer ${accessToken}`,
			...headers,
		},
	}));

	return forward(operation);
});

export const client = new ApolloClient({
	cache: new InMemoryCache({
		addTypename: true,
	}),
	// @ts-expect-error
	link: from([authLink, linkErrors, httpLink]),
});
