/* eslint linebreak-style: ["error", "unix"] */
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export interface Member {
  name: string
  role: string
  description?: string
  image?: string
  socialLinks?: {
    icon: React.FC,
    to: string
  }[]
}

export const mesaAssembleiaGeral = [
  {
    name: 'Rui Valente',
    role: 'Presidente',
    image: '/images/team/ruiValente.jpg',
    /* socialLinks: [
      {
        icon: MailOutlineIcon,
        to: 'mailto:rui.valente@anjb.pt',
      },
    ], */
  },
  {
    name: 'Tiago Mota',
    image: '/images/team/tiagoMota.jpg',
    role: 'Vice-presidente',
  },
  {
    name: 'Pedro Teixeira',
    image: '/images/team/pedroTeixeira.jpg',
    role: 'Secretário',
  },
];

export const conselhoFiscal: Member[] = [
  {
    name: 'Tiago Perdigão',
    role: 'Presidente',
    image: '/images/team/tiagoPerdigao.jpg',
    /* socialLinks: [
      {
        icon: MailOutlineIcon,
        to: 'mailto:hugo.silva@anjb.pt',
      },
    ], */
  },
  {
    name: 'Guilherme Vilhena',
    image: '/images/team/guilhermeVilhena.jpg',
    role: 'Vogal',
  },
  {
    name: 'Bernardo Fernandes',
    image: '/images/team/bernardoFernandes.jpeg',
    role: 'Vogal',
  },
];

export const direcao: Member[] = [
  {
    name: 'Vicente Jardim',
    role: 'Presidente',
    image: '/images/team/vicenteJardim.jpg',
    /* socialLinks: [
      {
        icon: MailOutlineIcon,
        to: 'mailto:rui.vieira@anjb.pt',
      },
    ], */
  },
  {
    name: 'Ana Costa',
    image: '/images/team/anaCosta.jpg',
    role: 'Vice-presidente',
  },
  {
    name: 'Frederico Bento',
    image: '/images/team/fredericoBento.jpg',
    role: 'Tesoureiro',
  },
  {
    name: 'Rodrigo Varandas',
    image: '/images/team/rodrigoVarandas.jpg',
    role: 'Secretário',
  },
  {
    name: 'João Saraiva',
    image: '/images/team/joaoSaraiva.jpg',
    role: 'Vogal',
  },
  {
    name: 'Marta Perdigão',
    image: '/images/team/martaPerdigao.jpg',
    role: 'Vogal',
  },
  {
    name: 'Daniel Oliveira',
    image: '/images/team/luisOliveira.jpg',
    role: 'Vogal',
  },
];

export const suplentes: Member[] = [
  {
    name: 'Diogo Pinheiro',
    role: 'Suplente',
    image: '/images/team/diogoPinheiro.jpg',
    /* socialLinks: [
      {
        icon: MailOutlineIcon,
        to: 'mailto:rui.vieira@anjb.pt',
      },
    ], */
  },
  {
    name: 'Luís Costa',
    image: '/images/team/luisCosta.jpg',
    role: 'Suplente',
  },
  {
    name: 'Joel Aguiar',
    image: '/images/team/joelAguiar.jpg',
    role: 'Suplente',
  },
];
