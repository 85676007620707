/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  children: React.ReactNode | string
  to: string
  underline?: boolean
}

const useStyles = (underline: boolean) => makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecorationColor: theme.palette.primary.main,

    textDecoration: underline ? 'underline' : 'none',
  },
}));

/**
 * Creates a hyperlink using the theme's primary color.
 *
 * Uses react router underneath */
export default function PrimaryLink({ children, to, underline = true }: Props) {
  const classes = useStyles(underline)();

  return (
    <Link className={classes.link} to={to}>
      {children}
    </Link>
  );
}
