/* eslint linebreak-style: ["error", "unix"] */
import { Button, CircularProgress } from '@material-ui/core';


interface Props {
  buttonLabel: string;
  loading: boolean;
}

export default function SubmitButton({ buttonLabel, loading }: Props) {
  return (
    <Button disabled={loading} color="primary" variant="outlined" style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }} size="large" type="submit">
      {loading ? (
        <CircularProgress color="primary" />
      ) : (buttonLabel)}
    </Button>
  );
}
