/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import { ANJB_CONSTANTS } from 'data/app';

const useStyles = makeStyles((theme) => ({
  partnersSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    minHeight: theme.spacing(16),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(2),
    },

    marginBottom: theme.spacing(4),
  },
  partnersList: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingInlineStart: 0,
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  partnersSectionTitle: {
    display: 'flex',
    listStyle: 'none',
    color: theme.palette.common.white,
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  partnerItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(14),
    height: '100%',
    marginRight: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      padding: theme.spacing(2),
      width: theme.spacing(14),
    },
  },
  partnerImage: {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
}));

export default function Partners() {
  const classes = useStyles();

  return (
    <>
      <section className={classes.partnersSection}>
        <div className={classes.partnersSectionTitle}>
          <Typography>Parceiros Institucionais</Typography>
        </div>

        <ul className={classes.partnersList}>
          {ANJB_CONSTANTS.partners.map((partner, key) => (
            <li key={key} className={classes.partnerItem}>
              <a href={partner.link} target="__blank">
                <img className={classes.partnerImage} src={partner.image} alt={partner.alt} />
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section className={classes.partnersSection}>
        <div className={classes.partnersSectionTitle}>
          <Typography>Parcerias</Typography>
        </div>

        <ul className={classes.partnersList}>
          {ANJB_CONSTANTS.partnerships.map((partnership: any, key) => (
            <li key={key} className={classes.partnerItem}>
              <a href={partnership.link} target="__blank">
                <img className={classes.partnerImage} src={partnership.image} alt={partnership.alt} />
              </a>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
}
