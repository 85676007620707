/* eslint linebreak-style: ["error", "unix"] */
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { useDispatch, useSelector } from 'react-redux';
import { selectProductVariantQuantity, setProductVariantQuantity } from 'redux/product-variants.redux';
import { ProductVariant } from 'data/product-variants';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  quantity: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    '& .MuiButton-outlined': {
      borderRadius: 0,
    },
  },
  small: {
    '& .MuiButton-root': {
      minWidth: theme.spacing(4),
    },
  },
}));

interface Props {
  productVariant: ProductVariant
  small?: boolean
  readonly?: boolean
}

export default function ProductQuantity({
  productVariant,
  small,
  readonly,
}: Props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const productVariantQuantity = useSelector(selectProductVariantQuantity(productVariant.id));

  const quantity = Number(productVariantQuantity?.quantity || 1);

  useEffect(() => {
    dispatch(setProductVariantQuantity({
      productVariantId: productVariant.id,
      quantity,
    }));
  }, []);

  const maxQuantityAllowed = productVariant.stockQuantity;

  const handleIncrement = () => {
    if (quantity >= maxQuantityAllowed) {
      return;
    }

    dispatch(setProductVariantQuantity({
      productVariantId: productVariant.id,
      quantity: quantity + 1,
    }));
  };

  const handleDecrement = () => {
    if (quantity <= 1) {
      return;
    }

    dispatch(setProductVariantQuantity({
      productVariantId: productVariant.id,
      quantity: quantity - 1,
    }));
  };

  if (readonly) {
    return (
      <Typography>
        {quantity}
      </Typography>
    );
  }

  return (
    <div className={classes.quantity}>
      <Typography variant="caption">
        {maxQuantityAllowed}
        {' '}
        artigos disponíveis
      </Typography>

      <Spacer spacing={1} />

      <ButtonGroup className={small ? classes.small : ''} size="small" aria-label="small outlined button group">
        <Button onClick={handleDecrement} disabled={quantity <= 1}>-</Button>
        <Button disableRipple disableFocusRipple disableTouchRipple>{quantity}</Button>
        <Button onClick={handleIncrement} disabled={quantity >= maxQuantityAllowed}>+</Button>
      </ButtonGroup>
    </div>
  );
}
