/* eslint linebreak-style: ["error", "unix"] */
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  headline: {
    position: 'absolute',
    background: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50% , -50%)',
    color: '#FFF',
    zIndex: 1,
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
    fontWeight: theme.typography.fontWeightBold as number,
  },
  nextButton: {
    position: 'absolute',
    background: 'linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent)',
    top: '50%',
    transform: 'translate(0% , -50%)',
    right: 0,
    borderRadius: 0,

    width: 100,
    height: '100%',
    minWidth: 'unset',

    '& svg': {
      fill: theme.palette.primary.main,
      height: 50,
      width: 50,

      position: 'absolute',
      right: 0,
    },

    '&:hover': {
      background: 'linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent)',
    },

    '&:disabled': {
      display: 'none',
    },
  },
  previousButton: {
    position: 'absolute',
    background: 'linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent)',
    top: '50%',
    transform: 'translate(0% , -50%)',
    left: 0,
    borderRadius: 0,

    width: 100,
    height: '100%',
    minWidth: 'unset',

    '& svg': {
      fill: theme.palette.primary.main,
      height: 50,
      width: 50,
      position: 'absolute',
      left: 0,
    },

    '&:hover': {
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent)',
    },

    '&:disabled': {
      display: 'none',
    },
  },
}));

export interface CarouselStep {
  label: string
  image: string
  index?: number
  description?: string
  href?: string
  color?: string
}

interface Props {
  steps: CarouselStep[]

  /** e.g. 5000 equals 5 seconds  */
  interval?: number

  carouselHeight?: number

  slideComponent?: React.FC<{ activeStep: CarouselStep }>
}

function DefaultComponent({ activeStep }: { activeStep: CarouselStep }) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.headline}
      variant="h4"
    >
      {activeStep.label}
    </Typography>
  );
}

export default function Carousel({
  steps,
  interval = 5000,
  carouselHeight = 255,
  slideComponent: Component = DefaultComponent,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Component activeStep={steps[activeStep]} />

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={interval}
      >
        {steps.map((step, index) => (
          <div
            key={step.label}
            style={{
              display: 'flex',
              width: '100%',
              height: carouselHeight,
              backgroundImage: `url(${step.image})`,
              backgroundColor: step?.color,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          />
        ))}
      </AutoPlaySwipeableViews>

      <Button
        onClick={handleNext}
        disabled={activeStep === maxSteps - 1}
        className={classes.nextButton}
      >

        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </Button>
      <Button
        onClick={handleBack}
        disabled={activeStep === 0}
        className={classes.previousButton}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}

      </Button>
    </div>
  );
}
