/* eslint linebreak-style: ["error", "unix"] */
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Divider, makeStyles, TextField, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { selectCurrentUser, updateUser } from 'redux/auth.redux';
import { updateUserGql, User } from 'data/users';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { stringRequired } from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: theme.palette.common.white,
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),

    '& .MuiButton-contained': {
      color: theme.palette.common.white,
      boxShadow: 'none',
    },
  },
  info: {
    padding: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  username: stringRequired,
});

export default function ChangeSubscription() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, errors, register } = formApi;

  const [mutate, { loading }] = useMutation(
    updateUserGql,
    {
      onError: (error) => {
        enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
      },
      onCompleted: (data) => {
        dispatch(updateUser(data?.updateUser as User));

        enqueueSnackbar('Nome de utilizador atualizado com sucesso', { variant: 'success' });
      },
    },
  );

  const onSubmit = (data: Record<string, any>) => {
    if (data?.username === currentUser?.username) {
      enqueueSnackbar('Deve ser um novo nome de utilizador', { variant: 'error' });
      return;
    }

    mutate({
      variables: {
        input: {
          id: currentUser?.id,
          username: data?.username,
        },
      },
    });
  };

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Typography color="primary" variant="h6">
          Alterar Categoria
        </Typography>
        <Divider />
        <Spacer spacing={2} />

        <Card square variant="outlined" className={classes.info}>
          <Typography color="primary">
            Categoria atual
          </Typography>
          <Typography>
            {currentUser!.subscription!.subscriptionPlan.name}
            <br />
            <small>{currentUser!.subscription!.subscriptionPlan.description}</small>
          </Typography>
        </Card>

        <Spacer spacing={2} />

        <TextField
          name="username"
          label="Nome de utilizador"
          placeholder="Introduz um novo nome de utilizador"
          type="text"
          inputProps={{
            ref: register,
          }}
          error={!!errors?.username}
          helperText={errors?.username?.message}
        />

        <Spacer spacing={4} />

        <Button type="submit" color="primary" variant="contained" disabled={loading}>
          Alterar Categoria
        </Button>
      </form>
    </div>
  );
}
