/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';

export interface ShoppingItem {
  productVariantId: number,
}

export interface ShoppingCartState {
  shoppingItems: ShoppingItem[]
}

// @ Actions

export const ADD_ITEM = 'SHOPPING_CART/ADD_ITEM';
export const REMOVE_ITEM = 'SHOPPING_CART/REMOVE_ITEM';

export const UPDATE_SHOPPING_CART = 'SHOPPING_CART/UPDATE_SHOPPING_CART';
export const CLEAR_SHOPPING_CART = 'SHOPPING_CART/CLEAR_SHOPPING_CART';

interface AddItemAction {
  type: typeof ADD_ITEM,
  payload: ShoppingItem
}
export const addItemToShoppingCart = (payload: ShoppingItem): AddItemAction => ({
  type: ADD_ITEM,
  payload,
});

interface RemoveItemAction {
  type: typeof REMOVE_ITEM,
  payload: {
    productVariantId: number,
  }
}
export const removeItemFromShoppingCart = (payload: { productVariantId: number }): RemoveItemAction => ({
  type: REMOVE_ITEM,
  payload,
});

interface UpdateShoppingCartAction {
  type: typeof UPDATE_SHOPPING_CART,
  payload: ShoppingItem[]
}
export const updateShoppingCart = (payload: ShoppingItem[]): UpdateShoppingCartAction => ({
  type: UPDATE_SHOPPING_CART,
  payload,
});

interface ClearShoppingCartAction {
  type: typeof CLEAR_SHOPPING_CART,
}
export const clearShoppingCart = (): ClearShoppingCartAction => ({
  type: CLEAR_SHOPPING_CART,
});

export type ShoppingCartActions =
  AddItemAction
  | RemoveItemAction
  | UpdateShoppingCartAction
  | ClearShoppingCartAction

// @ Selectors

export const selectShoppingItems = (state: RootState): ShoppingItem[] => state.shoppingCart.shoppingItems;

const initialState: ShoppingCartState = {
  shoppingItems: [],
};

export const shoppingCartReducer = (
  state = initialState,
  action: ShoppingCartActions,
): ShoppingCartState => {
  switch (action.type) {
    case ADD_ITEM: {
      const index = state.shoppingItems.findIndex((x) => x.productVariantId === action.payload.productVariantId);
      if (index !== -1) {
        return state;
      }

      return {
        ...state,
        shoppingItems: state.shoppingItems.concat(action.payload),
      };
    }
    case REMOVE_ITEM: {
      const index = state.shoppingItems.findIndex((x) => x.productVariantId === action.payload.productVariantId);
      if (index === -1) {
        return state;
      }

      return {
        ...state,
        shoppingItems: state.shoppingItems.filter((x) => x.productVariantId !== action.payload.productVariantId),
      };
    }
    case UPDATE_SHOPPING_CART:
      return {
        ...state,
        shoppingItems: action.payload,
      };
    case CLEAR_SHOPPING_CART:
      return {
        ...state,
        shoppingItems: [],
      };
    default:
      return state;
  }
};
