/* eslint linebreak-style: ["error", "unix"] */
import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const container: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  width: '100%',
  height: '100%',
};

export const section = (theme: Theme): CSSProperties => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flex: 0.5,
  overflowY: 'auto',
  height: '100%',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.9)',

  [theme.breakpoints.down('sm')]: {
    flex: 1,
  },
});

export const emptySection = (theme: Theme): CSSProperties => ({
  ...section,
  flex: 0.5,
  background: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

export const sectionImage = (theme: Theme): CSSProperties => ({
  position: 'absolute',
  left: 0,
  top: 0,
  height: '100%',
  width: '100%',
  backgroundSize: 'cover',
  zIndex: 0,
  transition: '1s ease all',
  animation: '$appearEffect 1s',

  // Dont render images on mobile screens
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

export const sectionTitle = (theme: Theme): CSSProperties => ({
  display: 'flex',
  fontSize: 32,
  marginTop: theme.spacing(8),
  alignItems: 'center',
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
});

export const formContainer: CSSProperties = {
  width: '75%',
};
