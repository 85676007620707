/* eslint linebreak-style: ["error", "unix"] */
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  CircularProgress, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { listSubscriptionPlansGql, SubscriptionPlan } from 'data/subscription-plans';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    '& .MuiSelect-select': {
      color: theme.palette.common.black,
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      border: 'none',
      color: theme.palette.common.black,
    },
    '&.MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.black,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  value?: number;
  onSelect?: (value: number) => void;
}

export default function SelectSubscriptionPlansForCreateUser({
  value,
  onSelect,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, loading } = useQuery(listSubscriptionPlansGql, {
    variables: {
      filter: {
        offset: 0,
        limit: 999,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  const data: SubscriptionPlan[] = rawData?.listSubscriptionPlans || [];
  const options = data.map((subscriptionPlan) => ({
    label: subscriptionPlan.name,
    description: subscriptionPlan.description,
    value: subscriptionPlan.id,
  }));

  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      onChange={(evt) => onSelect && onSelect(Number(evt.target.value))}
    >
      <MenuItem disabled>
        Escolher categoria
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} className={classes.item}>
          <Typography>
            {option.label}
          </Typography>
          <Typography variant="caption">
            {option.description}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
