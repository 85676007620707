/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Typography,
} from '@material-ui/core';
import { BACKGROUND_COLOR, ANJB_CONSTANTS, APP_ROUTES } from 'data/app';
import Page from 'pages/_layouts/page';
import Spacer from 'components/spacer/spacer';
import SectionTitle from 'components/section-title/section-title';

import PrimaryLink from 'components/links/link-primary/primary-link';
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import ContactUsForm from 'components/forms/form-contact-us/contact-us-form';

const breadcrumbs = [
  {
    displayName: 'Início',
    to: APP_ROUTES.home,
  },
  {
    displayName: 'Contactos',
  },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },

    '& .MuiInputBase-root': {
      background: BACKGROUND_COLOR,
    },

    '& .MuiButton-root': {
      background: BACKGROUND_COLOR,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: 'auto',
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default function ContactPage() {
  const classes = useStyles();
  return (
    <>
      <Page>

        <Breadcrumbs links={breadcrumbs} />
        <Spacer spacing={4} />

        <SectionTitle>Contactos</SectionTitle>

        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            {renderSection(
              'E-mail',
              () => (
                <div style={{ display: 'inline' }}>
                  <a className={classes.link} href={`mailto:${ANJB_CONSTANTS.contact.email}`}>
                    {ANJB_CONSTANTS.contact.email}
                  </a>
                </div>
              ),
              classes,
            )}

            {renderSection(
              'Morada',
              () => (
                <Typography variant="subtitle1">
                  {ANJB_CONSTANTS.contact.street}
                  <br />
                  {ANJB_CONSTANTS.contact.postalCode}

                  {' '}
                  {ANJB_CONSTANTS.contact.city}
                </Typography>
              ),
              classes,
            )}
          </div>
          <div className={classes.info}>
            {renderSection(
              'Localização',
              () => (
                <iframe
                  className={classes.iframe}
                  title="Maps"
                  width="300"
                  height="200"
                  src={ANJB_CONSTANTS.contact.googleMapsUrl}
                />
              ),
              classes,
            )}
          </div>
        </div>

        {renderSection(
          'Quotas',
          () => (
            <Typography variant="subtitle1">
              Caso tenhas alguma dúvida no valor ou na regularização de quotas contactar:
              {' '}
              <a className={classes.link} href="mailto:direccao@anjb.pt">
                direccao@anjb.pt
              </a>
            </Typography>
          ),
          classes,
        )}

        {renderSection(
          'Loja Online',
          () => (
            <Typography variant="subtitle1">
              Para questões relacionadas exclusivamente com encomendas e loja online contactar:
              {' '}
              <a className={classes.link} href="mailto:loja@anjb.pt">
                loja@anjb.pt
              </a>
            </Typography>
          ),
          classes,
        )}

        {renderSection(
          'Informações Gerais',
          () => (
            <Typography variant="subtitle1">
              Para informações gerais e outros assuntos contactar:
              {' '}
              <a className={classes.link} href="mailto:direccao@anjb.pt">
                direccao@anjb.pt
              </a>
            </Typography>
          ),
          classes,
        )}

        {renderSection(
          'Fala connosco',
          () => (
            <ContactUsForm />
          ),
          classes,
        )}

        <Spacer spacing={8} />
      </Page>
    </>
  );
}

const renderSection = (
  title: string,
  children: React.FC,
  classes: ReturnType<typeof useStyles>,
) => {
  const C = children;
  return (
    // eslint-disable-next-line react/jsx-indent
    <section className={classes.section}>
      <Typography
        color="primary"
        variant="h6"
      >
        {title}
      </Typography>
      <C />
      <Spacer spacing={4} />
    </section>
  );
};
