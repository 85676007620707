/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';

// Actions
export const ACCEPT_COOKIES = 'COOKIES/ACCEPT_COOKIES';

interface AcceptCookiesAction {
  type: typeof ACCEPT_COOKIES,
}

export const acceptCookies = (): CookiesActions => ({
  type: ACCEPT_COOKIES,
});

export type CookiesActions = AcceptCookiesAction

// Selectors
export const selectCookies = (state: RootState): boolean => state.cookies.accepted;

// Reducers
export interface CookiesState {
  accepted: boolean,
}

const initialState: CookiesState = {
  accepted: false,
};

export const cookiesReducer = (
  state = initialState,
  action: CookiesActions,
): CookiesState => {
  switch (action.type) {
    case ACCEPT_COOKIES:
      return {
        ...state,
        accepted: true,
      };
    default:
      return state;
  }
};
