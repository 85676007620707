/* eslint linebreak-style: ["error", "unix"] */
export enum PaymentMethod {
  MULTIBANCO = 'MULTIBANCO',
  MB_WAY = 'MB_WAY'
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
}

export const formatPaymentStatus = (paymentStatus: PaymentStatus | undefined): string => {
  switch (paymentStatus) {
    case PaymentStatus.PENDING:
      return 'A aguardar pagamento...';
    case PaymentStatus.PAID:
      return 'Pagamento efetuado';
    case PaymentStatus.REJECTED:
      return 'Pagamento rejeitado';
    default:
      return '';
  }
};
