/* eslint linebreak-style: ["error", "unix"] */
import { useRef } from 'react';
import MUIRichTextEditor, { TMUIRichTextEditorProps, TMUIRichTextEditorRef } from 'mui-rte';
import { makeStyles } from '@material-ui/core';
import { EditorState } from 'draft-js';

interface EditorProps extends TMUIRichTextEditorProps { }

const handleChange = (state: EditorState): void => {
};

const handleSave = (data: string) => {
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
}));

export default function EditorReadonly({
  onSave = handleSave,
  // @ts-ignore
  onChange = handleChange,
  defaultValue,
  ...rest
}: EditorProps) {
  const ref = useRef<TMUIRichTextEditorRef>(null);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MUIRichTextEditor
        controls={[]}
        defaultValue={defaultValue}
        onSave={onSave}
        onChange={onChange}
        inlineToolbar={false}
        ref={ref}
        {...rest}
      />
    </div>
  );
}
