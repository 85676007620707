/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import { UserRole } from 'data/users';
import BackofficeCreatePublicationModal from 'components/modals/modal-create-publication/backoffice-create-publication-modal';
import EditUserModal from 'components/modals/modal-edit-user/modal-edit-user';
import ModalViewProductOrder from 'components/modals/modal-view-product-order/modal-view-product-order';
import ModalCreateSubscriptionPlan from 'components/modals/modal-create-subscription-plan/modal-create-subscription-plan';
import EditSubscriptionPlanModal from 'components/modals/modal-edit-subscription-plan/modal-edit-subscription-plan';
import ModalViewSubscriptionPayment from 'components/modals/modal-view-subscription-payment/modal-view-subscription-payment';
import AboutMenuModal from '../modal-about-menu/about-menu-modal';
import BackofficeCreatePublicationCategoryModal from '../modal-create-publication-category/backoffice-create-publication-category-modal';
import BackofficeEditPublicationCategoryModal from '../modal-edit-publication-category/backoffice-edit-publication-category-modal';
import BackofficeEditPublicationModal from '../modal-edit-publication/backoffice-edit-publication-modal';
import LoginModal from '../modal-login/login-modal';
import MobileNavbarModal from '../modal-navbar-mobile/mobile-navbar-modal';
import ProductDetailsModal from '../modal-product-details/product-details-modal';
import PublicationModal from '../modal-publication/publication-modal';
import RecoverPasswordModal from '../modal-recover-password/recover-password-modal';
import ResetPasswordModal from '../modal-reset-password/reset-password-modal';
import SearchModal from '../modal-search/search-modal';
import SignupSuccessModal from '../modal-signup-success/signup-success-modal';
import SignupModal from '../modal-signup/signup-modal';
import SubscriptionPlansListModal from '../modal-subscription-plans-list/subscription-plans-list-modal';
import CreatePageModal from '../modal-create-page/modal-create-page';
import PageModal from '../modal-page/modal-page';
import EditPageModal from '../modal-edit-page/modal-edit-page';
import SendNewsletterModal from '../modal-send-newsletter/modal-send-newsletter';
import ModalViewUser from '../modal-view-user/modal-view-user';
import ModalCreateProductVariant from '../modal-create-product-variant/modal-create-product-variant';
import ModalEditProductVariant from '../modal-edit-product-variant/modal-edit-product-variant';
import ModalViewProductVariant from '../modal-view-product-variant/modal-view-product-variant';
import ModalCreateProduct from '../modal-create-product/modal-create-product';
import ModalEditProduct from '../modal-edit-product/modal-edit-product';
import ModalViewProduct from '../modal-view-product/modal-view-product';
import ModalCreateProductFilterCategory from '../modal-create-product-filter-category/modal-create-product-filter-category';
import ModalEditProductFilterCategory from '../modal-edit-product-filter-category/modal-edit-product-filter-category';
import ModalCreateProductCategory from '../modal-create-product-category/modal-create-product-category';
import ModalEditProductCategory from '../modal-edit-product-category/modal-edit-product-category';
import ModalViewProductCategory from '../modal-view-product-category/modal-view-product-category';
import ModalCreateDocument from '../modal-create-document/modal-create-document';
import ModalEditDocument from '../modal-edit-document/modal-edit-document';
import ModalCreateDocumentCategory from '../modal-create-document-category/modal-create-document-category';
import ModalEditDocumentCategory from '../modal-edit-document-category/modal-edit-document-category';
import ModalCreateUser from '../modal-create-user/modal-create-user';
import ModalDefineAccess from '../modal-define-access/modal-define-access';
import ModalViewSubscriptionPlan from 'components/modals/modal-view-subscription-plan/modal-view-subscription-plan';
import ModalCreateSubscriptionPayment from '../modal-create-subscription-payment/modal-create-subscription-payment';

export default function ModalManager() {
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.role === UserRole.ADMIN;

  return (
    <>
      {/** Navbar */}
      <AboutMenuModal />
      <LoginModal />
      <SignupModal />
      <SignupSuccessModal />
      <RecoverPasswordModal />
      <ResetPasswordModal />
      <ModalDefineAccess />
      <SearchModal />
      <MobileNavbarModal />

      {/** Store */}
      <ProductDetailsModal />

      {/** Subscriptions */}
      <SubscriptionPlansListModal />

      {/** Publications */}
      <PublicationModal />

      {/** Pages */}
      <PageModal />

      {/** Backoffice */}
      {isAdmin && (
        <>
          {/** ------------ Publications & Pages ------------------------*/}
          {/** Publication */}
          <BackofficeCreatePublicationModal />
          <BackofficeEditPublicationModal />
          {/** Publication Categories */}
          <BackofficeCreatePublicationCategoryModal />
          <BackofficeEditPublicationCategoryModal />
          {/** Page */}
          <CreatePageModal />
          <EditPageModal />

          {/** ------------------------ Product Orders ------------------------*/}
          <ModalViewProductOrder />

          {/** ------------------------ Store ------------------------*/}
          {/** Product Variants */}
          <ModalViewProductVariant />
          <ModalCreateProductVariant />
          <ModalEditProductVariant />
          {/** Products */}
          <ModalViewProduct />
          <ModalCreateProduct />
          <ModalEditProduct />
          {/** Product Filter */}
          <ModalCreateProductFilterCategory />
          <ModalEditProductFilterCategory />
          {/** Product Categories */}
          <ModalViewProductCategory />
          <ModalCreateProductCategory />
          <ModalEditProductCategory />

          {/** ------------------------ Subscription Plans ------------------------*/}
          <ModalViewSubscriptionPlan />
          <ModalCreateSubscriptionPlan />
          <EditSubscriptionPlanModal />

          {/** ------------------------ Subscription Payments ------------------------*/}
          <ModalViewSubscriptionPayment />
          <ModalCreateSubscriptionPayment />

          {/** ------------------------ Documents ------------------------*/}
          {/** Document */}
          <ModalCreateDocument />
          <ModalEditDocument />
          {/** Document Category */}
          <ModalCreateDocumentCategory />
          <ModalEditDocumentCategory />

          {/** ------------------------ Users ------------------------*/}
          {/** User */}
          <ModalViewUser />
          <ModalCreateUser />
          <EditUserModal />

          {/** ------------------------ Newsletters ------------------------*/}
          {/** Newsletter */}
          <SendNewsletterModal />
        </>
      )}
    </>
  );
}
