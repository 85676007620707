/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Tab, Tabs,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import Page from 'pages/_layouts/page';
import { useHistory, Route, Switch } from 'react-router-dom';

import ListRenderer, { ListRendererProps } from './list-renderer/list-renderer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
}));

export interface BackofficeFeature extends ListRendererProps {
  buttons?: any[]
  name: string
  url: string
}

interface Props {
  features: BackofficeFeature[];
  children?: React.ReactNode;
}

export default function BackofficeFactory({ features, children }: Props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        {children}
        <Tabs
          value={history.location.pathname}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, value) => {
            history.push(value);
          }}
          aria-label="disabled tabs example"
        >
          {features.map((feature, index) => (
            <Tab
              key={index}
              label={feature.name}
              value={feature.url}
            />
          ))}
        </Tabs>

        <Spacer spacing={2} />

        <Switch>
          {features.map((feature, index) => (
            <Route
              key={index}
              render={() => (
                <ListRenderer {...feature} />
              )}
              path={feature.url}
            />
          ))}
        </Switch>
      </div>
    </Page>
  );
}
