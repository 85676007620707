/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import { extractIdFromLocation } from 'libs/router/router.utils';
import { useLocation, Link } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import { APP_ROUTES } from 'data/app';
import { useDispatch, useSelector } from 'react-redux';
import { SearchFilter, selectSearchFilters, setProductSearchFilters } from 'redux/product-search.redux';
import Spacer from 'components/spacer/spacer';
import Product from 'components/product/product';
import { useQuery } from '@apollo/client';
import { getProductByIdGql, Product as IProduct } from 'data/products';
import Page from 'pages/_layouts/page';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import SimilarProducts from '../../components/similar-products/similar-products';

const useStyles = makeStyles((theme) => ({
  page: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(4),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0, 8),
    maxWidth: '60rem',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  breadcrumb: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  link: {
    color: theme.palette.grey[600],
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function ProductPage() {
  const classes = useStyles();

  const location = useLocation();
  const productId = extractIdFromLocation(location.pathname) as number;

  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);

  const { data: rawData, loading, error } = useQuery(getProductByIdGql, {
    variables: {
      id: productId,
    },
    skip: Boolean(productId) === false,
  });

  const data: IProduct = rawData?.getProductById;

  return (
    <Page>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        <div className={classes.breadcrumb}>
          <Breadcrumbs links={getBreadcrumbLinks(classes, dispatch, searchFilters, data)} />
        </div>

        <Spacer spacing={2} />

        <Product product={data} />

        <Spacer spacing={4} />

        <SimilarProducts product={data} />
      </DataRenderer>
    </Page>
  );
}

const getBreadcrumbLinks = (
  classes: Record<string, any>,
  dispatch: any,
  searchFilters: SearchFilter,
  data: IProduct,
) => [
    {
      displayName: 'Início',
      to: APP_ROUTES.home,
    },
    {
      displayName: 'Loja',
      to: APP_ROUTES.store,
    },
    {
      displayName: data?.productCategory?.name,
      component: () => (
        <Link
          to={APP_ROUTES.store}
          onClick={() => {
            dispatch(setProductSearchFilters({
              ...searchFilters,
              productCategories: [data?.productCategory?.id],
            }));
          }}
          className={classes.link}
        >
          {data?.productCategory?.name}
        </Link>
      ),
    },
    {
      displayName: data?.name,
    },
  ];
