/* eslint linebreak-style: ["error", "unix"] */
import { Typography } from '@material-ui/core';
import { selectCurrentUser } from 'redux/auth.redux';
import { ProductVariant } from 'data/product-variants';
import { selectProductVariantQuantity } from 'redux/product-variants.redux';
import { User, UserRole } from 'data/users';
import { useSelector } from 'react-redux';
import { isSubscriber } from 'data/subscriptions';

interface Props {
  productVariant: ProductVariant

  /** When true, calculates the product variant price with the current quantities of the variant in the shopping cart */
  relativeToQuantity?: boolean
}

export default function ProductVariantPrice({ productVariant, relativeToQuantity = true }: Props) {
  const selector = useSelector;

  const currentQuantity = relativeToQuantity
    ? selector(selectProductVariantQuantity(productVariant.id))?.quantity || 1
    : 1;

  const currentUser = useSelector(selectCurrentUser);

  const price = Number(
    isSubscriber(currentUser as User)
      ? productVariant?.product.subscriberPrice
      : productVariant.product.price,
  ) * currentQuantity;

  return (
    <Typography>
      {price.toFixed(2)}
      {' '}
      €
    </Typography>
  );
}
