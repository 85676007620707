/* eslint linebreak-style: ["error", "unix"] */
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { UserRole } from 'data/users';
import { selectCurrentUser } from 'redux/auth.redux';
import { APP_ROUTES } from 'data/app';
import { RouteProps } from './routes.interfaces';

interface Props extends RouteProps {
}

export default function AdminRoute({ component, ...rest }: Props) {
	const user = useSelector(selectCurrentUser);

	if (!user || user?.role !== UserRole.ADMIN) {
		return <Redirect to={APP_ROUTES.home} />;
	}

	return <Route {...rest} component={component} />;
}
