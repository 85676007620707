/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import * as yup from 'yup';

import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useMutation } from '@apollo/client';
import { booleanOptional, noWhiteSpace } from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { createBucketGql } from 'data/storage';
import SubmitButton from 'components/buttons/button-submit/submit-button';
import SwitchInput from 'components/inputs/input-switch/switch-input';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  linksSection: {
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  link: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  bucketName: noWhiteSpace.lowercase().trim(),
  isPublic: booleanOptional,
});

interface Props {
  onSuccess: () => void;
}

export default function BackofficeCreateBucketForm({ onSuccess }: Props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit, register, errors, control, getValues,
  } = formApi;

  const [mutate, { loading }] = useMutation(createBucketGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: (rawData) => {
      onSuccess();
    },
  });

  const onSubmit = (data: Record<string, any>) => {
    mutate({
      variables: {
        input: {
          bucketName: data?.bucketName,
          isPublic: data?.isPublic || false,
        },
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

      <Tooltip title="O nome da pasta criada na storage do google cloud. Exemplo: anjb-public-uploads/">
        <TextField
          name="bucketName"
          color="primary"
          label="Nome do bucket"
          required
          error={errors?.bucketName}
          helperText={errors?.bucketName?.message}
          inputProps={{
            ref: register,
          }}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <SwitchInput
        name="isPublic"
        label="Tornar público"
        tooltip="Permitir que os conteúdos do bucket sejam acessíveis a visitantes"
        control={control}
        errors={errors}
        defaultValue={false}
      />

      <Spacer spacing={2} />

      <SubmitButton loading={loading} buttonLabel="Criar bucket" />
    </form>
  );
}
