/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { useForm } from 'react-hook-form';
import { numberRequired, stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import EditorInput from 'components/inputs/input-editor-input/editor-input';
import {
  createPageGql, Page, updatePageGql,
} from 'data/pages';
import { useFormStyles } from 'styles/form.styles';
import SwitchInput from 'components/inputs/input-switch/switch-input';
import { createSubscriptionPlanGql, SubscriptionPlan, updateSubscriptionPlanGql } from 'data/subscription-plans';

const validationSchema = yup.object().shape({
  name: stringRequired,
  description: stringRequired,
  admissionFee: numberRequired,
  amount: numberRequired,
  startingDay: numberRequired,
  startingMonth: numberRequired,
  endingDay: numberRequired,
  endingMonth: numberRequired,
});

export interface Props {
  data?: SubscriptionPlan
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function SubscriptionPlanForm({
  data,
  onSuccess,
}: Props) {
  const classes = useFormStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        description: data?.description,
        admissionFee: data?.admissionFee,
        amount: data?.amount,
        startingDay: data?.startingDay,
        startingMonth: data?.startingMonth,
        endingDay: data?.endingDay,
        endingMonth: data?.endingMonth,
      }
      : {
        startingDay: 1,
        startingMonth: 1,
        endingDay: 31,
        endingMonth: 12
      },
  });

  const [mutate] = useMutation(isEditing ? updateSubscriptionPlanGql : createSubscriptionPlanGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing ? 'Modalidade atualizada com sucesso' : 'Modalidade criada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Tooltip title="O nome da modalidade">
        <TextField
          name="name"
          label="Nome"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <Tooltip title="A descrição da modalidade">
        <TextField
          name="description"
          label="Descrição"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.description)}
          helperText={errors?.description?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <TextField
        name="admissionFee"
        label="Jóia"
        inputProps={{
          ref: register,
          step: '0.01',
        }}
        error={Boolean(errors?.admissionFee)}
        helperText={errors?.admissionFee?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <TextField
        name="amount"
        label="Valor Anual"
        inputProps={{
          ref: register,
          step: '0.01',
        }}
        error={Boolean(errors?.amount)}
        helperText={errors?.amount?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <TextField
        name="startingDay"
        label="Dia de início"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.startingDay)}
        helperText={errors?.startingDay?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <TextField
        name="startingMonth"
        label="Mês de início"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.startingMonth)}
        helperText={errors?.startingMonth?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <TextField
        name="endingDay"
        label="Dia de fim"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.endingDay)}
        helperText={errors?.endingDay?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <TextField
        name="endingMonth"
        label="Mês de fim"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.endingMonth)}
        helperText={errors?.endingMonth?.message}
        type="number"
      />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar modalidade'}
      </Button>

    </form>
  );
}
