/* eslint linebreak-style: ["error", "unix"] */
import {
  IconButton, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import { getModalStyle } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import SectionStepper from 'components/section-stepper/section-stepper';
import { getSubscriptionPlanByIdGql, SubscriptionPlan } from 'data/subscription-plans';
import TableGrid from 'components/table-grid/table-grid';
import { useQuery } from '@apollo/client';
import { listUsersGql } from 'data/users';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { isSubscriber } from 'data/subscriptions';
import { formatToDateAndHour } from 'utils/formatters';
import { useRef } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { GridReadyEvent } from 'ag-grid-community';

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root ': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '880px',
        padding: theme.spacing(2),
      },
    },
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  productOrderDetailsContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRight: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
  },
}));

export default function ModalViewSubscriptionPlan() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<SubscriptionPlan>(
    ModalQueryParams.VIEW_SUBSCRIPTION_PLAN,
    getSubscriptionPlanByIdGql,
    'getSubscriptionPlanById',
  );

  const { data: userRawData, loading: userDataLoading, error: userDataError } = useQuery(
    listUsersGql, { variables: { filter: { limit: 9999, offset: 0, allowedSubscriptionPlanId: data?.id } }, skip: data?.id === null || !isOpen },
  );

  const userData = userRawData?.listUsers;

  const gridRef = useRef<GridReadyEvent>();

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="h6" color="primary">
          Modalide
        </Typography>
        <Typography variant="h6" color="textSecondary">
          {data?.name}
        </Typography>
        <Spacer spacing={2} />

        <Spacer spacing={4} />
        <SectionStepper displayText="Sócios" />

        <DataRenderer loading={userDataLoading} error={formatGraphqlErrorMessage(userDataError)}>
          <TableGrid
            defaultColDef={{
              sortable: true,
              resizable: true,
              filter: true,
            }}
            onGridReady={(params) => {
              gridRef.current = params;
            }}
            columnDefs={[
              {
                field: 'id',
                headerName: 'ID',
                width: 70,
              },
              {
                field: 'username',
                headerName: 'Utilizador',
                flex: 1,
              },
              {
                field: '',
                headerName: 'Sócio Ativo?',
                flex: 1,
                valueGetter: (params: any) => {
                  if (isSubscriber(params?.data)) {
                    return 'Sim';
                  }

                  return 'Não';
                },
              },
              {
                field: 'subscription.startDate',
                headerName: 'Data Início da Quota',
                flex: 1,
                valueFormatter: (params: any) => formatToDateAndHour(params?.data?.subscription?.startDate),
              },
              {
                field: 'subscription.endDate',
                headerName: 'Data Fim da Quota',
                flex: 1,
                valueFormatter: (params: any) => formatToDateAndHour(params?.data?.subscription?.endDate),
              },
            ]}
            rowData={userData}
            height={350}
          />
          <Tooltip title="Exportar dados para um .csv">
            <IconButton onClick={() => {
              gridRef?.current?.api?.exportDataAsCsv();
            }}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </DataRenderer>
      </DataRenderer>
    </Modal>
  );
}
