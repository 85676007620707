/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles,
} from '@material-ui/core';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import { deleteProductVariantGql, listProductVariantsGql } from 'data/product-variants';
import { deleteProductGql, listProductsGql } from 'data/products';
import { deleteProductFilterCategoryGql, listProductFilterCategoriesGql } from 'data/product-filter-categories';
import { deleteProductCategoryGql, listProductCategoriesGql } from 'data/product-categories';

const useStyles = makeStyles((theme) => ({
  image: {
    width: 100,
  },
}));

export default function ManageStorePage() {
  const classes = useStyles();

  const productVariantsFeature: BackofficeFeature = {
    name: 'Variantes de produto',
    url: APP_ROUTES.backoffice.store.manageProductVariants,
    listQuery: listProductVariantsGql,
    listQuerySelector: 'listProductVariants',
    deleteMutation: deleteProductVariantGql,
    gridProps: {
      rowHeight: 100,
    },
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Imagem',
        field: 'image',
        width: 120,
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
        },
        cellRendererFramework: (params: any) => <img alt={params.data?.name} className={classes.image} src={params.data?.productImages?.[0]} />,
      },
      {
        headerName: 'Produto',
        field: 'product',
        flex: 1,
        valueGetter: (params) => params.data?.product?.name,
      },
      {
        headerName: 'Variante',
        field: 'productFilters',
        flex: 1,
        valueGetter: (params) => params?.data?.productFilters?.map((filter: any) => filter?.value)?.join(', '),
      },
      {
        headerName: 'Em stock',
        field: 'stockQuantity',
        flex: 1,
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_PRODUCT_VARIANT,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PRODUCT_VARIANT,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_PRODUCT_VARIANT,
    searchTextFilterKey: 'productName',
    searchTextPlaceholder: 'Nome de produto...',
    addLabel: 'Criar nova variante de produto',
  };

  const productFeature: BackofficeFeature = {
    name: 'Produtos',
    url: APP_ROUTES.backoffice.store.manageProducts,
    listQuery: listProductsGql,
    listQuerySelector: 'listProducts',
    deleteMutation: deleteProductGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Categoria',
        field: 'productCategory',
        flex: 1,
        valueFormatter: (v) => `${v.value?.name}`,
      },
      {
        headerName: 'Preço',
        field: 'price',
        flex: 1,
        valueFormatter: (v) => `${v.value}€`,
      },
      {
        headerName: 'Preço (Subscritor)',
        field: 'subscriberPrice',
        flex: 1,
        valueFormatter: (v) => `${v.value}€`,
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_PRODUCT_BACKOFFICE,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PRODUCT,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_PRODUCT,

    searchTextFilterKey: 'name',
    searchTextPlaceholder: 'Nome de produto...',
    addLabel: 'Criar novo produto',
  };

  const productFilterFeature: BackofficeFeature = {
    name: 'Filtros',
    url: APP_ROUTES.backoffice.store.manageProductFilters,
    listQuery: listProductFilterCategoriesGql,
    listQuerySelector: 'listProductFilterCategories',
    deleteMutation: deleteProductFilterCategoryGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'filterName',
        flex: 1,
      },
      {
        headerName: 'Descrição',
        field: 'description',
        flex: 1,
      },
      {
        headerName: 'Valores',
        field: 'filterValues',
        flex: 1,
        valueFormatter: (params) => `${params?.data?.filterValues?.map((x: any) => x.value)?.join(', ')}`,
      },
    ],
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PRODUCT_FILTER_CATEGORY,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_PRODUCT_FILTER_CATEGORY,

    // searchTextFilterKey: 'name',
    addLabel: 'Criar filtro',
  };

  const productCategoryFeature: BackofficeFeature = {
    name: 'Categorias de Produtos',
    url: APP_ROUTES.backoffice.store.manageProductCategories,
    listQuery: listProductCategoriesGql,
    listQuerySelector: 'listProductCategories',
    deleteMutation: deleteProductCategoryGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Filtros',
        field: 'filterValues',
        flex: 1,
        valueFormatter: (params) => `${params?.data?.productFilterCategories?.map((x: any) => x.filterName)?.join(', ')}`,
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_PRODUCT_CATEGORY,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PRODUCT_CATEGORY,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_PRODUCT_CATEGORY,

    // searchTextFilterKey: 'name',
    addLabel: 'Criar categoria de produto',
  };

  return (
    <BackofficeFactory
      features={[
        productVariantsFeature,
        productFeature,
        productFilterFeature,
        productCategoryFeature,
      ]}
    />
  );
}
