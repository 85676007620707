/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, TextField,
} from '@material-ui/core';

import Spacer from 'components/spacer/spacer';
import { useForm, Controller } from 'react-hook-form';
import {
  booleanOptional, emailRequired, numberRequired, stringOptional, stringRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useFormStyles } from 'styles/form.styles';
import SwitchInput from 'components/inputs/input-switch/switch-input';
import {
  createUserGql, updateUserBackofficeGql, User,
} from 'data/users';
import SelectUserRole from 'components/selects/select-user-role/select-user-role';
import SelectSubscriptionPlansForCreateUser from 'components/selects/select-subscription-plans-for-create-user/select-subscription-plans-for-create-user';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const validationSchema = yup.object().shape({
  username: stringRequired,
  email: emailRequired,
  role: stringRequired,
  isActive: booleanOptional,
  membershipNumber: stringOptional,
  subscriptionStartDate: stringOptional,
  subscriptionEndDate: stringOptional,
  allowedSubscriptionPlanId: numberRequired,
});

export interface Props {
  data?: User
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function UserForm({
  data,
  onSuccess,
}: Props) {
  const classes = useFormStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, handleSubmit, watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        username: data?.username,
        email: data?.email,
        role: data?.role,
        isActive: data?.isActive,
        membershipNumber: data?.membershipNumber,
        subscriptionStartDate: data?.subscription?.startDate,
        subscriptionEndDate: data?.subscription?.endDate,
        allowedSubscriptionPlanId: data?.allowedSubscriptionPlan?.id,
      }
      : {},
  });

  const allowedSubscriptionPlanId = watch('allowedSubscriptionPlanId');

  const [mutate] = useMutation(isEditing ? updateUserBackofficeGql : createUserGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing ? 'Utilizador atualizado com sucesso' : 'Utilizador criado com sucesso', { variant: 'success' });
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const payload = {
      ...formData,
      membershipNumber: Number(formData.membershipNumber),
      allowedSubscriptionPlanId: Number(formData.allowedSubscriptionPlanId),
      subscriptionStartDate: formData?.subscriptionStartDate?.length ? moment(formData.subscriptionStartDate).format('YYYY-MM-DD') : null,
      subscriptionEndDate: formData?.subscriptionEndDate?.length ? moment(formData.subscriptionEndDate).format('YYYY-MM-DD') : null,
    }

    const input = isEditing
      ? {
        ...payload,
        id: data?.id,
      } : payload;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="username"
        label="Nome de utilizador"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.username)}
        helperText={errors?.username?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="membershipNumber"
        label="Número de sócio"
        type="number"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.membershipNumber)}
        helperText={errors?.membershipNumber?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="email"
        label="E-Mail"
        type="email"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.email)}
        helperText={errors?.email?.message}
      />
      <Spacer spacing={4} />

      <Controller
        as={
          ({ value, onChange }) => <SelectUserRole value={value} onSelect={(value) => onChange(value)} />
        }
        name="role"
        control={control}
      />
      <Spacer spacing={4} />

      <Controller
        as={({ value, onChange }) => (
          <SelectSubscriptionPlansForCreateUser
            value={Number(value)}
            onSelect={(newValue) => {
              onChange(newValue);
            }}
          />
        )}
        control={control}
        name="allowedSubscriptionPlanId"
      />

      <Spacer spacing={4} />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          name="subscriptionStartDate"
          control={control}
          as={({ value, onChange }) => (
            <DatePicker
              format="dd-MM-yyyy"
              label="Date de início da subscrição"
              value={value}
              clearable
              onChange={(newValue) => {
                onChange(newValue);
              }}
            />
          )}
        />

        <Spacer spacing={4} />

        <Controller
          name="subscriptionEndDate"
          control={control}
          as={({ value, onChange }) => (
            <DatePicker
              format="dd-MM-yyyy"
              label="Date de fim da subscrição"
              value={value}
              clearable
              onChange={(newValue) => {
                onChange(newValue);
              }}
            />
          )}
        />
        <Spacer spacing={4} />

      </MuiPickersUtilsProvider>

      <SwitchInput
        name="isActive"
        label="Utilizador ativo?"
        tooltip=""
        control={control}
        errors={errors}
        defaultValue={data?.isActive}
      />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar utilizador'}
      </Button>

    </form>
  );
}
