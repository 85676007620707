/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles } from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import { useLocation } from 'react-router-dom';
import { extractIdFromLocation } from 'libs/router/router.utils';
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import Spacer from 'components/spacer/spacer';
import { useQuery } from '@apollo/client';
import { DocumentCategory, getDocumentCategoryByIdGql } from 'data/document-categories';
import Page from 'pages/_layouts/page';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DocumentsList from 'components/lists/list-documents/documents-list';
import { APP_ROUTES } from 'data/app';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    padding: theme.spacing(2),
    minHeight: '100vh',
  },
}));

export default function DocumentsDetailsSubpage() {
  const classes = useStyles();

  const location = useLocation();
  const documentCategoryId = extractIdFromLocation(location.pathname) as number;

  const { data: rawData, loading, error } = useQuery(getDocumentCategoryByIdGql, {
    variables: {
      id: documentCategoryId,
    },
    skip: Boolean(documentCategoryId) === false,
  });

  const data: DocumentCategory = rawData?.getDocumentCategoryById;

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <Breadcrumbs links={
            [
              {
                displayName: 'Documentos',
                to: APP_ROUTES.subscriberArea.documents,
              },
              {
                displayName: data?.name,
              },
            ]
          }
          />

          <Spacer spacing={2} />

          <SectionTitle>{data?.name}</SectionTitle>

          <DocumentsList documentCategoryId={data?.id} />
        </DataRenderer>
      </div>
    </Page>
  );
}
