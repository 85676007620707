/* eslint linebreak-style: ["error", "unix"] */
import { ModalQueryParams } from 'data/app';
import Spacer from 'components/spacer/spacer';
import useQueryParam from 'hooks/useQueryParam';
import FormDefinePassword from 'components/forms/form-define-password/form-define-password';
import NavbarFormModal from '../modal-navbar-form/navbar-form-modal';

export default function ModalDefineAccess() {
  const code = useQueryParam().get('code');

  return (
    <NavbarFormModal
      modalQueryParam={ModalQueryParams.DEFINE_ACCESS(code)}
      sectionTitle="Definir senha"
      backgroundImage="/images/anjb_recover_password.jpg"
    >
      <Spacer spacing={2} />

      <FormDefinePassword />
    </NavbarFormModal>
  );
}
