/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles, IconButton,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1.2em',
    height: '1.2em',
  },
  active: {
    fill: theme.palette.primary.main,
  },
}));

export default function Search() {
  const classes = useStyles();

  const { isOpen, handleOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.SEARCH);

  return (
    <>
      <IconButton onClick={isOpen ? handleClose : handleOpen}>
        <SearchIcon className={`${classes.icon} ${isOpen ? classes.active : ''}`} />
      </IconButton>
    </>
  );
}
