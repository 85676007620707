/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { ProductFilter } from 'data/product-filter';
import { ProductVariant } from 'data/product-variants';
import { useQuery } from '@apollo/client';
import { Product, listRecommendedProductsGql } from 'data/products';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import ProductTile from 'components/tiles/tile-product/product-tile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  products: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },

  },
}));

interface Props {
  product: Product
}

export default function SimilarProducts({ product }: Props) {
  const classes = useStyles();

  const allVariantFilters: ProductFilter[] = getAllProductVariantFilters(product.productVariants);

  const similarProductFilters = allVariantFilters.map((x) => x.id)
    .filter((filter, index, self) => self.indexOf(filter) === index); // Filter repeated values

  const { data: rawData, loading, error } = useQuery(listRecommendedProductsGql, {
    variables: {
      filter: {
        limit: 3,
        offset: 0,
        similarProductFilters,
      },
    },
  });

  const filteredData: Product[] = rawData?.listProducts?.filter((item: Product) => item.id !== product.id);

  if (!filteredData?.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        Também poderás gostar de:
      </Typography>
      <Spacer spacing={4} />
      <div className={classes.products}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          {filteredData?.map((product, index) => (
            <ProductTile product={product} key={index} />
          ))}
        </DataRenderer>
      </div>
    </div>
  );
}

export const getAllProductVariantFilters = (productVariants: ProductVariant[]): ProductFilter[] => {
  if (!productVariants || !productVariants.length) {
    return [];
  }

  const allFilters = productVariants.reduce((
    accumulator: ProductFilter[], current: ProductVariant,
  ) => accumulator.concat(current.productFilters), []);

  return allFilters;
};
