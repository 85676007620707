/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { useSelector, useDispatch } from 'react-redux';
import { selectSearchFilters, setProductSearchFilters } from 'redux/product-search.redux';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  sliderWrapper: {
    margin: theme.spacing(2),
  },
  slider: {
    maxWidth: 150,

    '& .MuiSlider-thumb': {
      '& span:first-child': {
        '& span:first-child': {
          '& span:first-child': {
            color: '#FFF',
          },
        },
      },
    },
  },
}));

function valuetext(value: number) {
  return `${value}€`;
}

export default function PriceFilter() {
  const searchFilters = useSelector(selectSearchFilters);
  const dispatch = useDispatch();

  const { price = 0 } = searchFilters;

  const [localValue, setLocalValue] = useState<number>(price);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<Element>, value: number | number[]) => {
    const newPrice = Array.isArray(value) ? value[0] : value;

    setLocalValue(newPrice);
  };

  const commitChange = (event: React.ChangeEvent<Element>, value: number | number[]) => {
    const newPrice = Array.isArray(value) ? value[0] : value;

    dispatch(setProductSearchFilters({
      ...searchFilters,
      price: newPrice,
    }));
  };

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom color="primary">
        Preço (€)
      </Typography>

      <section className={classes.sliderWrapper}>
        {/** @ts-ignore */}
        <Slider
          className={classes.slider}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={(v) => `${v} €`}
          step={1}
          marks
          min={0}
          max={100}
          value={localValue}
          onChange={handleChange}
          onChangeCommitted={commitChange}
        />
      </section>
    </div>
  );
}
