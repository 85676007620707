/* eslint linebreak-style: ["error", "unix"] */
import { User } from 'data/users';

export interface AuthState {
	accessToken: string | null
	currentUser: User | null
}

// Actions
export const AUTH_LOGIN = '@AUTH/LOGIN';
export const AUTH_LOGOUT = '@AUTH/LOGOUT';
export const AUTH_FORCED_LOGOUT = '@AUTH/FORCED_LOGOUT';
export const UPDATE_USER = '@AUTH/UPDATE_USER';

interface AuthLoginAction {
	type: typeof AUTH_LOGIN
	payload: AuthState
}
interface AuthLogoutAction {
	type: typeof AUTH_LOGOUT
}
interface AuthForcedLogoutAction {
	type: typeof AUTH_FORCED_LOGOUT
}
interface UpdateUserAction {
	type: typeof UPDATE_USER
	payload: User
}

export type AuthActionTypes = AuthLoginAction | AuthLogoutAction | AuthForcedLogoutAction | UpdateUserAction

// Dispatchers

export const authLogin = (payload: AuthState): AuthActionTypes => ({ type: AUTH_LOGIN, payload });

export const authLogout = (): AuthActionTypes => ({ type: AUTH_LOGOUT });

export const authForcedLogout = (): AuthActionTypes => ({ type: AUTH_FORCED_LOGOUT });

export const updateUser = (payload: User): AuthActionTypes => ({ type: UPDATE_USER, payload });

// Selectors
export const selectCurrentUser = (state: { auth: AuthState }) => state.auth.currentUser;

export const selectAccessToken = (state: { auth: AuthState }) => state.auth.accessToken;

// Reducers
const initialState: AuthState = {
	accessToken: null,
	currentUser: null,
};

export const authReducer = (
	state = initialState,
	action: AuthActionTypes,
): AuthState => {
	switch (action.type) {
		case AUTH_LOGIN:
			return {
				...state,
				accessToken: action.payload.accessToken,
				currentUser: action.payload.currentUser,
			};
		case AUTH_FORCED_LOGOUT:
		case AUTH_LOGOUT:
			return {
				...state,
				accessToken: null,
				currentUser: null,
			};
		case UPDATE_USER:
			return {
				...state,
				currentUser: action.payload,
			};
		default:
			return state;
	}
};
