/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { SubscriptionPayment } from './subscription-payment';
import { SubscriptionPlan, subscriptionPlansFullDetailsFragment } from './subscription-plans';
import { User } from './users';

export const isSubscriber = (user: User | null) => Boolean(user?.subscription?.startDate) === true && Boolean(user?.subscription?.endDate) === false;
export const isSubscriptionActive = (subscription: Subscription | null) => Boolean(subscription?.startDate) === true && Boolean(subscription?.endDate) === false;

export interface Subscription {
  id: number
  subscriptionPlan: SubscriptionPlan
  startDate: string
  endDate: string
  subscriptionPayments: SubscriptionPayment[]
  user?: User
}

export const subscriptionFullDetailsFragment = gql`
  fragment subscriptionFullDetailsFragment on SubscriptionsEntity {
    id
    subscriptionPlan {
      ...subscriptionPlansFullDetailsFragment
    }
    user {
      id
      username
      membershipNumber
    }
    subscriptionPayments {
      id
      amount
      paymentMethod
      paidAt
    }
    startDate
    endDate
  }
  ${subscriptionPlansFullDetailsFragment}
`;

export const subscriptionMinimumDetailsFragment = gql`
  fragment subscriptionMinimumDetailsFragment on SubscriptionsEntity {
    id
    subscriptionPlan {
      id
      name
    }
    user {
      id
      username
      membershipNumber
    }
    subscriptionPayments {
      id
      paidAt
    }
    startDate
    endDate
  }
`;

export const createSubscriptionGql = gql`
  mutation createSubscription($input: ICreateSubscription!) {
    createSubscription(input: $input) {
      id
    }
  }
`;

export const getSubscriptionByIdGql = gql`
  query getSubscriptionById($id: Int!) {
    getSubscriptionById(id: $id) {
      ...subscriptionFullDetailsFragment
    }
  }
  ${subscriptionFullDetailsFragment}
`;

export const listSubscriptionsGql = gql`
  query listSubscriptions($filter: ISubscriptionsFilter) {
    listSubscriptions(filter: $filter) {
      ...subscriptionMinimumDetailsFragment
    }
  }
  ${subscriptionMinimumDetailsFragment}
`;

export const listUserOwnSubscriptionsGql = gql`
  query listUserOwnSubscriptions($filter: ISubscriptionsFilter) {
    listUserOwnSubscriptions(filter: $filter) {
      ...subscriptionMinimumDetailsFragment
    }
  }
  ${subscriptionMinimumDetailsFragment}
`;
