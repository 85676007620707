/* eslint linebreak-style: ["error", "unix"] */
import Page from 'pages/_layouts/page';
import { useQuery } from '@apollo/client';
import { getPageByUrlGql, Page as IPage } from 'data/pages';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import SectionTitle from 'components/section-title/section-title';
import { useLocation } from 'react-router';
import EditorReadonly from 'components/editor-readonly/editor-readonly';

export default function DynamicPage() {
  const url = useLocation().pathname.slice(1);
  const { data: rawData, loading, error } = useQuery(getPageByUrlGql, {
    variables: {
      url,
    },
  });

  const data: IPage = rawData?.getPageByUrl;

  return (
    <>
      <Page>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <SectionTitle>{data?.name}</SectionTitle>
          <EditorReadonly
            defaultValue={data?.content}
            readOnly
            toolbar={false}
          />
        </DataRenderer>
      </Page>
    </>
  );
}
