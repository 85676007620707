/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles,
} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectProductVariantQuantities } from 'redux/product-variants.redux';
import {
  Divider,
  Typography,
} from '@material-ui/core';
import { selectCurrentUser } from 'redux/auth.redux';
import { ProductVariant } from 'data/product-variants';
import { User } from 'data/users';
import { isSubscriber } from 'data/subscriptions';
import useGetTransportationFee from 'hooks/useGetTransportationFee';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: theme.spacing(2),
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

interface Props {
  productVariants: ProductVariant[]
}

export default function ShoppingCartSummary({ productVariants }: Props) {
  const classes = useStyles();

  const { transportationFee, loading, error } = useGetTransportationFee();

  const currentUser = useSelector(selectCurrentUser);

  const productVariantQuantities = useSelector(selectProductVariantQuantities);

  const totalPrice = productVariants?.reduce((sum, currentProductVariant) => {
    const { id, product: { subscriberPrice, price } } = currentProductVariant;
    const basePrice = isSubscriber(currentUser as User) ? subscriberPrice : price;
    const quantity = productVariantQuantities.find((variantQuantity) => variantQuantity.productVariantId === id)?.quantity || 1;

    sum += Number((basePrice * quantity).toFixed(2));
    return sum;
  }, 0);

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      <section className={classes.container} style={{ flexDirection: 'column' }}>
        <div className={classes.column}>
          <Typography variant="body2" align="right" color="primary">
            Preço dos Artigos
          </Typography>
          <Typography variant="h6" align="right">
            {totalPrice}
            {' '}
            €
          </Typography>
        </div>
        <div style={{ marginBottom: 8 }} />
        <div className={classes.column}>
          <Typography variant="body2" align="right" color="primary">
            Taxa de Envio
          </Typography>
          <Typography variant="h6" align="right">
            {transportationFee}
            {' '}
            €
          </Typography>
        </div>
        <div style={{ marginBottom: 8 }} />
        <Typography variant="body2" align="left" color="primary">
            Caso o destinatário não esteja presente no momento da entrega, será cobrada nova taxa de envio
        </Typography>
        <div style={{ marginBottom: 8 }} />
        <Divider />
        <div style={{ marginBottom: 8 }} />

        <div className={classes.column}>
          <Typography variant="h5" align="right" color="primary">
            Total
          </Typography>
          <Typography variant="h6" align="right">
            {totalPrice + transportationFee}
            {' '}
            €
          </Typography>
        </div>
      </section>
    </DataRenderer>
  );
}
