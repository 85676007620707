/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles, Typography, Link } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { APP_ROUTES } from 'data/app';
import { DocumentCategory } from 'data/document-categories';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(32),
    width: theme.spacing(36),
    background: '#FFF',
    margin: theme.spacing(2),
    transition: '.2s ease all',
    boxShadow: '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05)',

    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        transform: 'translateY(-0.2rem)',
        boxShadow: '0 1rem 2rem 0 rgba(0, 0, 0, 0.15)',
        position: 'relative',
        zIndex: theme.zIndex.appBar,
        cursor: 'pointer',
      },
    },

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  orangeBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: theme.palette.primary.main,
    width: '50px',
    heigth: '100%',
    padding: theme.spacing(2),
    color: '#FFF',
  },
  content: {
    width: '100%',
    height: '100%',
    maxWidth: '15rem',
    padding: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 5rem)',
    },
  },
}));

interface Props {
  documentCategory: DocumentCategory
}

export default function DocumentCategoryTile({
  documentCategory,
}: Props) {
  const classes = useStyles();

  const link = `${APP_ROUTES.subscriberArea.documentsDetail}/${documentCategory.id}`;

  return (
    <PrimaryLink to={link} underline={false}>
      <div className={classes.root}>
        <div className={classes.orangeBar}>
          <GetAppIcon />
        </div>

        <div className={classes.content}>
          <Typography variant="h5" noWrap>
            {documentCategory.name}
          </Typography>

          <Typography variant="body1" noWrap>
            {documentCategory.description}
          </Typography>
        </div>
      </div>
    </PrimaryLink>
  );
}
