/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import ManageAccountMenu from 'components/menus/menu-manage-account/manage-account-menu';
import ChangeEmail from 'components/forms/form-change-email/change-email';
import EditProfile from 'components/edit-profile/edit-profile';
import ViewProfile from 'components/view-profile/view-profile';
import SectionTitle from 'components/section-title/section-title';
import { APP_ROUTES } from 'data/app';
import AuthenticatedRoute from 'components/routes/routes.guard.authenticated';
import Page from 'pages/_layouts/page';
import { Switch } from 'react-router-dom';
import ChangePassword from 'components/forms/form-change-password/change-password';
import ChangeUsername from 'components/forms/form-change-username/change-username';
import ChangeSubscription from 'components/forms/form-change-subscription/change-subscription';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  navbarContainer: {
    display: 'flex',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  contentContainer: {
    display: 'flex',
    width: '70%',
    marginLeft: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
}));

export default function ManageAccountPage() {
  const classes = useStyles();

  return (
    <Page>
      <SectionTitle>
        Gerir conta
      </SectionTitle>
      <section className={classes.wrapper}>

        <aside className={classes.navbarContainer}>
          <ManageAccountMenu />
        </aside>

        <article className={classes.contentContainer}>
          <Switch>
            <AuthenticatedRoute path={APP_ROUTES.manageOrders} component={EditProfile} />
            <AuthenticatedRoute path={APP_ROUTES.editProfile} component={EditProfile} />
            <AuthenticatedRoute path={APP_ROUTES.changeUsername} component={ChangeUsername} />
            <AuthenticatedRoute path={APP_ROUTES.changeSubscriptionType} component={ChangeSubscription} />
            <AuthenticatedRoute path={APP_ROUTES.changeEmail} component={ChangeEmail} />
            <AuthenticatedRoute path={APP_ROUTES.changePassword} component={ChangePassword} />
            <AuthenticatedRoute path={APP_ROUTES.manageAccount} component={ViewProfile} />
          </Switch>
        </article>
      </section>
    </Page>
  );
}
