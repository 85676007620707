/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { Product } from 'data/products';
import { ProductFilterCategory } from 'data/product-filter-categories';
import FilterValueSelect, { Filters } from './filter-value-select/filter-value-select';

interface Props {
  product: Product
  selectedVariantId: number | null | undefined
  setSelectedVariantId: React.Dispatch<React.SetStateAction<number | null | undefined>>
}

export interface SelectedFilterGroup {
  filterCategory: string,
  filterValueId: number,
}

const useStyles = makeStyles((theme) => ({
  productFilters: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    background: 'rgba(0, 0, 0, 0.03)',
  },
}));

export default function ProductFilters({
  product,
  selectedVariantId,
  setSelectedVariantId,
}: Props) {
  const { productVariants = [] } = product;
  const classes = useStyles();

  // The available filters of the product variant
  const filters = getFilters(product?.productCategory?.productFilterCategories);
  const filtersLength = Object.entries(filters)?.length;

  const [selectedFilterValues, setSelectedFilterValues] = useState<{
    [filterCategoryName: string]: { filterValueId: number }
  }>({});

  const handleChange = (filterCategoryName: string, filterValueId: number) => {
    setSelectedFilterValues((state) => {
      // https://blog.logrocket.com/using-react-usestate-object/
      const newState = { ...state };

      // Unselecting a filter
      if (filterValueId === -1) {
        delete newState?.filterCategoryName;
      } else {
        newState[filterCategoryName] = { filterValueId };
      }

      return newState;
    });
  };

  useEffect(() => {
    const filterValues = Object.values(selectedFilterValues).map(
      (selectedFilterValue) => selectedFilterValue.filterValueId,
    );

    const nextSelectedVariant = productVariants.find(
      (productVariant) => productVariant?.productFilters?.every(
        (variantFilterValue) => filterValues.includes(variantFilterValue.id),
      ),
    );

    setSelectedVariantId(nextSelectedVariant?.id);
  }, [selectedFilterValues]);

  const allFiltersSelected = Object.entries(selectedFilterValues).length === filtersLength;

  return (
    <section className={classes.productFilters}>
      {Object.entries(filters).map((filter, index) => (
        <div key={index}>
          <FilterValueSelect
            filterValues={filter[1]}
            filterCategoryName={filter[0]}
            onChange={handleChange}
          />
          <Spacer spacing={2} />
        </div>
      ))}

      {allFiltersSelected && !selectedVariantId && (
        <>
          <Spacer spacing={2} />
          <Typography variant="caption" color="primary">
            O produto não está disponível!
          </Typography>
        </>
      )}

      {allFiltersSelected && product?.productVariants[0]?.stockQuantity == 0 && (
        <>
          <Spacer spacing={2} />
          <Typography variant="caption" color="primary">
            O produto não está disponível2.
          </Typography>
        </>
      )}
    </section>
  );
}

const getFilters = (_productFilterCategories: ProductFilterCategory[] = []): Filters => {
  const filterGroups: Filters = {};

  const productFilterCategories = _productFilterCategories.slice().sort((a, b) => {
    if (a.filterName < b.filterName) { return -1; }
    if (a.filterName > b.filterName) { return 1; }
    return 0;
  });

  productFilterCategories.forEach((filterCategory) => {
    const name = filterCategory?.filterName;

    filterCategory.filterValues.forEach((filterValue) => {
      const payload = {
        valueId: filterValue.id,
        valueName: filterValue.value,
      };

      if (!filterGroups[name]?.length) {
        filterGroups[name] = [payload];
      } else {
        filterGroups[name].push(payload);
      }
    });
  });

  return filterGroups;
};
