/* eslint linebreak-style: ["error", "unix"] */
import { User } from 'data/users';
import {
  Card, Grid, Divider, Typography,
} from '@material-ui/core';
import { selectCurrentUser } from 'redux/auth.redux';
import { useSelector } from 'react-redux';
import { Product } from 'data/products';
import { isSubscriber } from 'data/subscriptions';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import useGetTransportationFee from 'hooks/useGetTransportationFee';

interface Props {
  product: Product
}

export default function ProductPrice({ product }: Props) {
  const currentUser = useSelector(selectCurrentUser);
  const { transportationFee, loading, error } = useGetTransportationFee();

  const renderPrice = () => {
    if (isSubscriber(currentUser as User)) {
      return (
        <>
          <Typography color="textSecondary" variant="h6" style={{ textDecoration: 'line-through' }}>
            {product?.price}
            {' '}
            € - Preço Não Sócio
          </Typography>

          <Typography color="primary" variant="h4">
            {product?.subscriberPrice}
            {' '}
            € - Preço Sócio
          </Typography>
        </>
      );
    }

    return (
      <Typography color="primary" variant="h4">
        {product?.price}
        {' '}
        € - Preço Não Sócio
      </Typography>
    );
  };

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      {renderPrice()}
      <div style={{ marginBottom: 8 }} />

      <Card variant="outlined" style={{ padding: 5 }}>
        <Grid container direction="row">
          <Typography>
            + Taxa de Envio:
          </Typography>
          &nbsp;
          <Typography>
            {' '}
            {transportationFee}
            {' '}
            €
          </Typography>
        </Grid>
      </Card>
    </DataRenderer>
  );
}
