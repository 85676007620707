/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import { deleteDocumentGql, listDocumentsGql } from 'data/documents';
import { deleteDocumentCategoryGql, listDocumentCategoriesGql } from 'data/document-categories';
import DownloadLink from 'components/links/link-download/download-link';
import { useState } from 'react';
import { Button } from '@material-ui/core';

interface DocumentLinkGeneratorProps {
  bucketFile: string,
  bucketName: string
}
function DocumentLinkGenerator(props: DocumentLinkGeneratorProps) {
  const [hasRequested, setHasRequested] = useState(false);

  if (!hasRequested) {
    return (
      <Button onClick={() => setHasRequested(true)}>Gerar link de download</Button>
    );
  }

  return (
    <DownloadLink {...props} />
  );
}

export default function ManageDocumentsPage() {
  const documentsFeature: BackofficeFeature = {
    name: 'Documentos',
    url: APP_ROUTES.backoffice.documents.manageDocuments,
    listQuery: listDocumentsGql,
    listQuerySelector: 'listDocuments',
    deleteMutation: deleteDocumentGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Categorias',
        field: 'documentCategories',
        flex: 1,
        // @ts-ignore
        valueGetter: (v) => v.row?.documentCategories?.map((x) => x.name).join(', '),
      },
      {
        headerName: 'Download',
        field: 'bucketFile',
        flex: 1,
        // @ts-ignore
        // renderCell: (params) => (
        //   <DocumentLinkGenerator bucketFile={params?.row?.bucketFile} bucketName={params?.row?.bucketName} />
        // ),
      },
    ],
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_DOCUMENT,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_DOCUMENT,

    // searchTextFilterKey: 'name',
    addLabel: 'Criar novo documento',
  };

  const documentCategoriesFeature: BackofficeFeature = {
    name: 'Categorias de Documentos',
    url: APP_ROUTES.backoffice.documents.manageDocumentCategories,
    listQuery: listDocumentCategoriesGql,
    listQuerySelector: 'listDocumentCategories',
    deleteMutation: deleteDocumentCategoryGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'name',
        flex: 1,
      },
    ],
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_DOCUMENT_CATEGORY,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_DOCUMENT_CATEGORY,

    // searchTextFilterKey: 'name',
    addLabel: 'Criar nova categoria',
  };

  return (
    <BackofficeFactory
      features={[
        documentsFeature,
        documentCategoriesFeature,
      ]}
    />
  );
}
