/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import BackofficeSubscriptionPaymentForm from 'components/forms/form-subscription-payment/backoffice-subscription-payment-form';
import { useModalStyles } from 'styles/modal.styles';
import { useQuery } from '@apollo/client';
import { listUsersGql } from 'data/users';

export default function BackofficeCreateSubscriptionPaymentModal() {
  const classes = useModalStyles();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.CREATE_SUBSCRIPTION_PAYMENT);

  const { data: userRawData, loading: userDataLoading, error: userDataError } = useQuery(
    listUsersGql, { variables: { filter: { limit: 9999, offset: 0 } }, skip: !isOpen },
  );
  const userData = userRawData?.listUsers;

  return (
    <Modal className={classes.modal} open={isOpen} onClose={handleClose} closeButtonComponent={CloseButtonWhite} hasCloseButton>
      <SectionTitle>Criar Pagamento de Quota</SectionTitle>
      <BackofficeSubscriptionPaymentForm
        users={userData}
        onSuccess={() => {
          handleClose();
        }}
      />
    </Modal>
  );
}
