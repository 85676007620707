/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { useForm } from 'react-hook-form';
import {
  arrayRequired, stringRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import React from 'react';
import {
  createProductCategoryGql, ProductCategory, updateProductCategoryGql,
} from 'data/product-categories';
import { listProductFilterCategoriesGql } from 'data/product-filter-categories';
import SelectGeneric from 'components/selects/select-generic/select-generic';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  name: stringRequired,
  productFilterCategoriesIds: arrayRequired,
});

export interface Props {
  data?: ProductCategory
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function FormProductCategory({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, watch, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        productFilterCategoriesIds: data?.productFilterCategories?.map((x) => x.id),
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updateProductCategoryGql : createProductCategoryGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing
        ? 'Categoria atualizada com sucesso'
        : 'Categoria criada com sucesso',
        { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>

      <Tooltip title="O nome da categoria (ex. 'T-shirts', 'Apitos', etc.)">
        <TextField
          name="name"
          label="Nome da categoria"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.stockQuantity)}
          helperText={errors?.stockQuantity?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <SelectGeneric
        name="productFilterCategoriesIds"
        label="Filtros"
        tooltipText="Os filtros usados pelos produtos desta categoria"
        query={listProductFilterCategoriesGql}
        querySelector="listProductFilterCategories"
        multiple
        control={control}
        errors={errors}
        optionName="filterName"
      />
      <Spacer spacing={2} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar categoria'}
      </Button>

    </form>
  );
}
