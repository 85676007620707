/* eslint linebreak-style: ["error", "unix"] */

import HighlightedNews from 'components/highlighted-news/highlighted-news';
import LandingPageHero from 'components/heroes/hero-landing-page/landing-page-hero';
import LandingPagePublicationsFeed from 'components/landing-page-publications-feed/landing-page-publications-feed';
import BecomeASubscriber from '../../components/become-a-subscriber/become-a-subscriber';

export default function HomePage() {
  return (
    <>
      <LandingPageHero />
      <HighlightedNews />
      <LandingPagePublicationsFeed />
      {/* <BecomeASubscriber /> */}
    </>
  );
}
