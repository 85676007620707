/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getProductFilterCategoryByIdGql, ProductFilterCategory } from 'data/product-filter-categories';
import FormProductFilter from 'components/forms/form-product-filter-category/form-product-filter-category';

export default function ModalEditProductFilterCategory() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<ProductFilterCategory>(
    ModalQueryParams.EDIT_PRODUCT_FILTER_CATEGORY,
    getProductFilterCategoryByIdGql,
    'getProductFilterCategoryById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <SectionTitle>Editar filtro de produto</SectionTitle>
        <FormProductFilter
          data={data}
          onSuccess={handleClose}
        />
      </DataRenderer>
    </Modal>
  );
}
