/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField/TextField';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.0),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.025),
    },
    transition: '.5s ease all',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 0.5),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

interface Props {
  /** The placeholder text that will appear by default in the search bar */
  placeholder?: string,

  onChange?: (v: string) => void,
}

const SearchBar = ({ placeholder = 'Pesquisar...', onChange, ...rest }: Props) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { value } = e.target;

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <TextField
        onChange={handleChange}
        placeholder={placeholder}
        classes={{
          root: classes.inputInput,
        }}
        variant="standard"
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};

export default SearchBar;
