/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { PaymentMethod, PaymentStatus } from './payments';
import { ProductOrder } from './product-orders';

export interface ProductOrderPayment {
  id: number
  productOrder: ProductOrder
  reference: string
  entity: string | null
  identifier: string
  phoneNumber: string | null
  paymentStatus: PaymentStatus
  paymentMethod: PaymentMethod
  amount: number
  paidAt: string
}

export const productOrderPaymentMinimumDetailsFragment = gql`
	fragment productOrderPaymentMinimumDetailsFragment on ProductOrderPaymentsEntity {
    id
    reference
    entity
    identifier
    phoneNumber
    paymentStatus
    paymentMethod
    amount
    paidAt
	}
`;

// Mutations
export const requestProductOrderPaymentGql = gql`
  mutation requestProductOrderPayment($input: IRequestProductOrderPayment!) {
    requestProductOrderPayment(input: $input)
  }
`;

// Queries
export const listProductOrderPaymentsGql = gql`
  query listProductOrderPayments($filter: IPaginationInput) {
    listProductOrderPayments(filter: $filter) {
      ...productOrderPaymentMinimumDetailsFragment
      productOrder {
        id
        user {
          id
          username
        }
      }
    }
  }
  ${productOrderPaymentMinimumDetailsFragment}
`;

export const getProductOrderPaymentByIdGql = gql`
  query getProductOrderPaymentById($id: Int!) {
    getProductOrderPaymentById(id: $id) {
      ...productOrderPaymentMinimumDetailsFragment
    }
  }
  ${productOrderPaymentMinimumDetailsFragment}
`;

export const getOwnProductOrderPaymentByReferenceGql = gql`
  query getOwnProductOrderPaymentByReference($input: IGetOwnProductOrderPaymentByReference!) {
    getOwnProductOrderPaymentByReference(input: $input) {
      ...productOrderPaymentMinimumDetailsFragment
    }
  }
  ${productOrderPaymentMinimumDetailsFragment}
`;
