/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { APP_CONFIG } from 'data/app';
import { CircularProgress, Link, Typography } from '@material-ui/core';
import { selectAccessToken } from 'redux/auth.redux';
import { useSelector } from 'react-redux';

interface Props {
  bucketName?: string
  bucketFile: string
}

/**
 * Allows us to download a protected resource
 * by passing the authorization token in the request
 */
export default function DownloadLink({
  bucketName,
  bucketFile,
}: Props) {
  const [url, setUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const accessToken = useSelector(selectAccessToken);

  useEffect(() => {
    async function fetchDownloadLink() {
      if (!bucketName || !bucketFile) {
        setLoading(false);
        return;
      }

      const { api } = APP_CONFIG.app;
      const { bucketRequestUri } = APP_CONFIG.http;

      await fetch(`${api}/${bucketRequestUri}/${bucketName}/${bucketFile}`, {
        mode: 'cors',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        }),
      }).then((res) => res.json())
        .then(({ link }) => {
          setUrl(link);
          setLoading(false);
        });
    }

    fetchDownloadLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  if (!bucketName || !bucketFile) {
    return (
      <Typography color="secondary" variant="caption">
        (Link indisponível)
      </Typography>
    );
  }

  return (
    <Link style={{ display: 'flex', alignItems: 'center' }} color="primary" href={url} target="__blank" rel="noopener noreferrer">
      <GetAppIcon />
    </Link>
  );
}
