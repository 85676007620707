/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect } from 'react';
import useQueryParam from 'hooks/useQueryParam';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import DataRenderer from 'components/data-renderer/data-renderer';
import { ERROR_COLOR, ModalQueryParams } from 'data/app';
import { Button, makeStyles, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { useMutation } from '@apollo/client';
import { verifyEmailGql } from 'data/email-verification';
import RequestEmailVerificationCodeForm from 'components/forms/form-request-email-verification-code/request-email-verification-code-form';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkIcon: {
    marginBottom: theme.spacing(4),

    '&.MuiSvgIcon-root': {
      width: theme.spacing(6),
      height: theme.spacing(6),

      color: theme.palette.primary.main,
    },
  },
}));

export default function EmailVerificationStatus() {
  const classes = useStyles();
  const code = useQueryParam().get('code');

  const [mutate, { loading, error }] = useMutation(verifyEmailGql, { onError: () => { } });

  // On mount
  useEffect(() => {
    if (!code) {
      return;
    }

    mutate({
      variables: {
        code,
      },
    });
  }, [code, mutate]);

  if (error || !code) {
    return (
      <>
        {error && (
          <Alert
            severity="error"
            style={{ background: ERROR_COLOR }}
            data-testid={TestIds.ErrorMessage}
          >
            {formatGraphqlErrorMessage(error)}

          </Alert>
        )}

        {!code && (
          <Alert
            severity="warning"
            data-testid={TestIds.WarningMessage}
          >
            Código de verificação não encontrado!

          </Alert>
        )}

        <Spacer spacing={4} />

        <Typography variant="subtitle1" paragraph>
          Se não recebeste um e-mail com instruções para ativares a tua conta, podes
          pedir um reenvio no seguinte formulário com o e-mail com que te registaste.
        </Typography>

        <RequestEmailVerificationCodeForm />
      </>
    );
  }

  return (
    <DataRenderer loading={loading} error="">
      <div className={classes.content}>
        <Spacer spacing={4} />

        <CheckCircleIcon className={classes.checkIcon} />

        <Typography variant="h4" color="primary" data-testid={TestIds.SuccessMessage}>
          Conta verificada com sucesso!
        </Typography>

        <Spacer spacing={2} />

        <Typography variant="h6">
          A tua conta foi verificada com sucesso!
          Podes agora iniciar a sessão com os teus dados de utilizador.
        </Typography>

        <PrimaryLink to={`?${ModalQueryParams.SIGN_IN}`} underline={false}>
          <Button variant="text" color="primary">
            Iniciar sessão
          </Button>
        </PrimaryLink>

        <Spacer spacing={8} />
      </div>
    </DataRenderer>
  );
}

export const TestIds = {
  ErrorMessage: 'error-message',
  WarningMessage: 'warning-message',
  SuccessMessage: 'success-message',
};
