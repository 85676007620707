/* eslint linebreak-style: ["error", "unix"] */
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core';
import SearchBar from 'components/search-bars/search-bar/search-bar';
import { FeedSearchFilters } from 'components/landing-page-publications-feed/landing-page-publications-feed';
import PublicationCategoriesFilter from '../filter-publication-categories/publication-categories-filter';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(4),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    minHeight: theme.spacing(16),
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  items: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  item: {
    display: 'flex',
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
    },
  },

}));

interface Props {
  searchFilters: FeedSearchFilters
  setSearchFilters: (v: FeedSearchFilters) => void
  setAppliedSearchFilters: (v: FeedSearchFilters) => void
}

export default function PublicationFeedFilters({
  searchFilters,
  setSearchFilters,
  setAppliedSearchFilters,
}: Props) {
  const classes = useStyles();

  const renderPublicationCategoryFilter = () => {
    const handlePublicationCategoriesSelection = (publicationCategories: any) => {
      const publicationCategoriesIds = publicationCategories
        .map((x: any) => x.value as number);

      const oldFilters = searchFilters;

      setSearchFilters({
        ...oldFilters,
        offset: 0,
        publicationCategories: publicationCategoriesIds,
      });
    };

    return (
      <PublicationCategoriesFilter
        searchFilters={searchFilters}
        onApplyFilter={handlePublicationCategoriesSelection}
      />
    );
  };

  const renderSearchBar = () => {
    const handleSearch = (value: string) => {
      const oldFilters = searchFilters;

      setSearchFilters({
        ...oldFilters,
        name: value,
        offset: 0,
      });
    };

    return (
      <SearchBar
        onChange={handleSearch}
        placeholder="Pesquisar..."
      />
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.items}>

          <div className={classes.item}>{renderSearchBar()}</div>
          <div className={classes.item}>{renderPublicationCategoryFilter()}</div>

        </div>

        <Button style={{ height: 40, minWidth: 200 }} variant="outlined" color="primary" onClick={() => setAppliedSearchFilters(searchFilters)}>
          Aplicar filtros
        </Button>
      </div>
    </div>
  );
}
