/* eslint linebreak-style: ["error", "unix"] */

import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { CustomOrder, selectSearchFilters, setProductSearchFilters } from 'redux/product-search.redux';
import { makeStyles, Typography } from '@material-ui/core';
import FilterClear from '../filter-clear/filter-clear';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    '&.MuiSelect-select': {
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
  },
}));

const options: { name: string, value: CustomOrder & { id: number } }[] = [
  {
    name: 'Preço mais baixo',
    value: {
      id: 1,
      filterProperty: 'price',
      filterDirection: 'ASC',
    },
  },
  {
    name: 'Preço mais alto',
    value: {
      id: 2,
      filterProperty: 'price',
      filterDirection: 'DESC',
    },
  },
  {
    name: 'Mais recente',
    value: {
      id: 3,
      filterProperty: 'createdAt',
      filterDirection: 'DESC',
    },
  },
  {
    name: 'Mais antigos',
    value: {
      id: 4,
      filterProperty: 'createdAt',
      filterDirection: 'ASC',
    },
  },
];

export default function OrderBy() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);

  const handleChange = ({ target: { value } }: any) => {
    const customOrder = options.find((customOrder) => customOrder.value.id === value)?.value;

    // @ts-ignore
    const { id, ...rest } = customOrder;

    dispatch(setProductSearchFilters({
      ...searchFilters,
      customOrder: rest,
    }));
  };

  const { customOrder: value } = searchFilters;

  const match = options.find((x) => (
    x.value.filterDirection === value?.filterDirection
    && x.value.filterProperty === value.filterProperty
  ));

  return (
    <section>
      <header className={classes.header}>
        <Typography id="discrete-slider" gutterBottom color="primary">
          Ordenar por
        </Typography>

        {match?.value?.id && (
          <FilterClear parameterNameToClear="customOrder" />
        )}
      </header>

      <Select
        value={match?.value?.id || ''}
        onChange={handleChange}
        className={classes.select}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        variant="standard"
      >
        <MenuItem value="" disabled>
          Escolher uma ordem...
        </MenuItem>

        {options.map((customOrder, index) => (
          <MenuItem key={index} value={customOrder.value.id}>
            {customOrder.name}
          </MenuItem>
        ))}
      </Select>
    </section>
  );
}
