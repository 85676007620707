/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import Spacer from 'components/spacer/spacer';
import { Button } from '@material-ui/core';
import { APP_ROUTES } from 'data/app';
import PrimaryLink from 'components/links/link-primary/primary-link';
import Page from 'pages/_layouts/page';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: theme.spacing(16, 0),
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <Page>
      <section className={classes.section}>
        <Typography variant="h2" color="primary">
          <strong>4🏀4</strong>
        </Typography>
        <br />
        <Typography variant="h4" color="textPrimary" align="center">
          Ups! Página não encontrada.
        </Typography>
        <Spacer spacing={4} />

        <Typography variant="subtitle1" align="center">
          A página que tentaste aceder parece não estar disponível.
        </Typography>
        <Spacer spacing={4} />

        <PrimaryLink to={APP_ROUTES.home} underline={false}>
          <Button>
            Regressar ao início
          </Button>
        </PrimaryLink>
      </section>
    </Page>
  );
}
