/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles } from '@material-ui/core';
import { selectSearchFilters, setProductSearchFilters, setSelectedProductCategory } from 'redux/product-search.redux';
import { useDispatch, useSelector } from 'react-redux';
import { ProductCategory } from 'data/product-categories';
import ProductCategorySelect from 'components/selects/select-product-category/product-category-select';
import FilterClear from '../filter-clear/filter-clear';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  select: {
    '&.MuiSelect-select': {
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
  },
}));

export default function ProductCategories() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);

  const handleChange = (
    selectedProductCategory: ProductCategory | undefined,
  ) => {
    dispatch(setProductSearchFilters({
      ...searchFilters,
      productCategories: [Number(selectedProductCategory?.id)],
      // Clear product filters on changing product categories
      productFilters: [],
    }));

    // Save the selected productCategory data
    dispatch(setSelectedProductCategory(selectedProductCategory));
  };

  const { productCategories: value = [] } = searchFilters;

  return (
    <div className={classes.container}>
      <ProductCategorySelect value={value?.[0]} onChange={handleChange} />
      {value?.length > 0 && <FilterClear parameterNameToClear="productCategories" />}
    </div>
  );
}
