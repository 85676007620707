/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormLabel,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { Controller, useForm } from 'react-hook-form';
import {
  arrayRequired, booleanOptional, numberRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  createProductVariantGql, ProductVariant, updateProductVariantGql,
} from 'data/product-variants';
import SelectProductFilterValues from 'components/selects/select-product-filter-values/select-product-filter-values';
import SwitchInput from 'components/inputs/input-switch/switch-input';
import InputImageSelections from 'components/inputs/input-image-selections/input-image-selections';
import SelectGeneric from 'components/selects/select-generic/select-generic';
import { getProductByIdGql, listProductsGql, Product } from 'data/products';
import { useEffect, useState } from 'react';
import EditorInput from 'components/inputs/input-editor-input/editor-input';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  productId: numberRequired,
  stockQuantity: numberRequired.min(0, 'Deve ser um número positivo'),
  isActive: booleanOptional,
  productFilterIds: arrayRequired,
  productImages: arrayRequired,
});

export interface Props {
  data?: ProductVariant
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function FormProductVariant({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, watch, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        productId: data?.product?.id,
        stockQuantity: data?.stockQuantity,
        isActive: data?.isActive,
        productFilterIds: data?.productFilters?.map((x) => x.id),
        productImages: data?.productImages,
        content: data?.content || data?.product?.content,
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updateProductVariantGql : createProductVariantGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing
        ? 'Variante de produto atualizada com sucesso'
        : 'Variante de produto criada com sucesso',
        { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  const selectedProductId = watch('productId');

  const [defaultContent, setDefaultContent] = useState<string>('');
  const { refetch } = useQuery(getProductByIdGql, {
    variables: {
      id: selectedProductId,
    },
    skip: !selectedProductId,
    onCompleted: (data) => {
      const selectedProductRawData = data?.getProductById as Product;
      setDefaultContent(selectedProductRawData?.content);
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedProductId]);

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <SelectGeneric
        name="productId"
        label="Produto"
        tooltipText="O produto da qual a variante herda os preços, filtros e categoria"
        query={listProductsGql}
        querySelector="listProducts"
        multiple={false}
        control={control}
        errors={errors}
      />
      <Spacer spacing={2} />

      <Controller
        render={({ value = [], onChange }) => (
          <>
            <>
              <FormLabel>Imagens</FormLabel>
              <Spacer spacing={1} />
              <InputImageSelections
                value={value}
                onChange={onChange}
              />
            </>
          </>
        )}
        control={control}
        name="productImages"
      />
      <Spacer spacing={2} />

      <EditorInput
        name="content"
        label="Conteúdo"
        defaultValue={data?.content || defaultContent}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <Spacer spacing={4} />

      <Controller
        render={({ value = [], onChange }) => (
          <>
            <>
              <FormLabel>Filtros</FormLabel>
              <Spacer spacing={1} />
              <SelectProductFilterValues
                selectedProductId={selectedProductId}
                value={value}
                onChange={onChange}
              />
            </>
          </>
        )}
        control={control}
        name="productFilterIds"
      />

      <Spacer spacing={4} />

      <Tooltip title="A quantidade física disponível do produto">
        <TextField
          name="stockQuantity"
          label="Quantidade em stock"
          type="number"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.stockQuantity)}
          helperText={errors?.stockQuantity?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <SwitchInput
        name="isActive"
        label="Visível ao público"
        tooltip=""
        control={control}
        errors={errors}
        defaultValue={data?.isActive || false}
      />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar variante de produto'}
      </Button>

    </form>
  );
}
