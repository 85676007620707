/* eslint-disable jsx-a11y/alt-text */
/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'components/search-bars/search-bar/search-bar';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface Props {
  // Search text filtering
  setSearchText: React.Dispatch<React.SetStateAction<string>> | null
  // Date filtering
  showFrom: string | null;
  setShowFrom: React.Dispatch<React.SetStateAction<string>> | null
  // Add
  onAdd: (() => void) | null;
  addLabel?: string;
  addDisabled?: boolean;
  searchTextPlaceholder?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  addIcon: {
    borderRadius: 0,
    '& svg': {
      width: 20,
      marginRight: theme.spacing(1),
      fill: theme.palette.primary.main,
    },
  },
  dateFilterContainer: {
    marginRight: theme.spacing(2),
  },
  searchContainer: {
    display: 'flex',
    marginRight: theme.spacing(2),
  },
}));

export default function HeaderActions({
  setSearchText,
  showFrom = '',
  setShowFrom,
  onAdd,
  addLabel = '',
  addDisabled,
  searchTextPlaceholder,
}: Props) {
  const classes = useStyles();
  return (
    <>
      <section className={classes.addButtonContainer}>
        {setShowFrom && (
          <div className={classes.dateFilterContainer}>
            <input
              type="date"
              onChange={(evt) => {
                const date = evt.target.value;
                setShowFrom(date);
              }}
              value={showFrom || ''}
            />
            {showFrom && (
              <IconButton onClick={() => setShowFrom('')}>
                <HighlightOffIcon color="action" />
              </IconButton>
            )}
          </div>
        )}

        {/* Search Bar */}
        {setSearchText
          && (
            <div className={classes.searchContainer}>
              <SearchBar onChange={(evt) => setSearchText(evt)} placeholder={searchTextPlaceholder || 'Pesquisar...'} />
            </div>
          )}

        {onAdd && addLabel?.length > 0 && (
          <Button
            color="primary"
            aria-label="add"
            className={classes.addIcon}
            variant="outlined"
            onClick={onAdd}
            disabled={addDisabled != null ? addDisabled : false}
          >
            <AddIcon />
            {addLabel}
          </Button>
        )}
      </section>
    </>
  );
}
