/* eslint linebreak-style: ["error", "unix"] */
import { useLocation } from 'react-router-dom';

export default function useExtractIdFromLocation() {
  // Get id from url
  const location = useLocation();
  const urlParams = new URLSearchParams(location?.search);
  const id = parseInt(urlParams.get('id') as string, 10);

  return {
    id,
  };
}
