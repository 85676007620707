/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  makeStyles,
  TextField,
  InputAdornment,
  Input,
  InputLabel,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { Controller, useForm } from 'react-hook-form';
import { stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { User } from 'data/users';
import { createSubscriptionPaymentGql, SubscriptionPayment, updateSubscriptionPaymentGql } from 'data/subscription-payment';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SelectPaymentMethod from 'components/selects/select-payment-method/select-payment-method';
import SelectPaymentStatus from 'components/selects/select-payment-status/select-payment-status';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  dateFilterContainer: {
    marginRight: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  amount: stringRequired,
  paidAt: stringRequired,
  paymentMethod: stringRequired,
  paymentStatus: stringRequired,
});

export interface Props {
  data?: SubscriptionPayment;
  users?: User[];
  onSuccess?: () => void;
}

export default function BackofficeSubscriptionPaymentForm({
  data,
  users,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const entities: any[] = [];
  if (users && users.length > 0) {
    users.forEach((user: User) => {
      entities.push({
        label: user.username,
        id: user.id
      });
    });
  }

  const {
    register, errors, control, setValue, handleSubmit, watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        paidAt: data?.paidAt,
        user: data?.subscription.user?.id,
        amount: data?.amount,
        reference: data?.reference,
        entity: data?.entity,
        identifier: data?.identifier,
        paymentMethod: data?.paymentMethod,
        paymentStatus: data?.paymentStatus
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updateSubscriptionPaymentGql : createSubscriptionPaymentGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: (rawData) => {
      console.log("SUBMIT FINISH");
      enqueueSnackbar(isEditing ? 'Publicação atualizada com sucesso' : 'Publicação criada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    console.log("SUBMIT");
    console.log(formData);
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} id="forma" onSubmit={handleSubmit(onSubmit)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          render={({ value, onChange }) => (
            <DatePicker
              format="dd-MM-yyyy"
              label="Pago a"
              value={value}
              clearable
              name="paidAt"
              onChange={(newValue) => {
                onChange(newValue);
              }}
            />
          )}
          name="paidAt"
          control={control}
        />
      </MuiPickersUtilsProvider>
      <Spacer spacing={4} />

      <Autocomplete
        disablePortal
        options={entities}
        getOptionLabel={(entity) => (entity.label)}
        getOptionSelected={(option, value) => option.id === value.id}
        size="small"
        renderInput={
          (params) => <TextField {...params} name="user" label="Utilizador" />
        }
      />
      <Spacer spacing={4} />

      <InputLabel htmlFor="outlined-adornment-amount">Valor</InputLabel>
      <Input
        id="outlined-adornment-amount"
        name="amount"
        inputProps={{
          ref: register,
        }}
        startAdornment={<InputAdornment key="input_adr" position="start">€</InputAdornment>}
        error={Boolean(errors?.description)}
      />
      <Spacer spacing={4} />

      <TextField
        name="reference"
        label="Referencia"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="entity"
        label="Entidate"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="identifier"
        label="Identificador"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <Controller
        render={
          ({ value, onChange }) => <SelectPaymentMethod value={value} onSelect={(value) => onChange(value)} />
        }
        name="paymentMethod"
        control={control}
      />
      <Spacer spacing={4} />

      <Controller
        render={
          ({ value, onChange }) => <SelectPaymentStatus value={value} onSelect={(value) => onChange(value)} />
        }
        name="paymentStatus"
        control={control}
      />
      <Spacer spacing={4} />


      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Adicionar Pagamento'}
      </Button>

    </form>
  );
}
