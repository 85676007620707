/* eslint linebreak-style: ["error", "unix"] */
import {
  Button, makeStyles,
} from '@material-ui/core';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  isActive: boolean;
  buttonLabel: string;
  handleOpen: () => void;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  active: {
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
  iconActive: {
    fill: theme.palette.primary.main,
  },
}));

export default function DesktopNavbarMenuButton({
  isActive,
  handleOpen,
  handleClose,
  buttonLabel,
}: Props) {
  const classes = useStyles();
  return (
    <>
      <Button onClick={() => (!isActive ? handleOpen() : handleClose())} className={isActive ? classes.active : ''}>
        {buttonLabel}
        {' '}
        {isActive
          ? <ArrowDropUpIcon className={classes.iconActive} />
          : <ArrowDropDownIcon />}
      </Button>
    </>
  );
}
