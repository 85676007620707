/* eslint linebreak-style: ["error", "unix"] */

import { Button, makeStyles, Typography } from '@material-ui/core';
import SlideDownModal from 'components/modals/modal-slide-down/slide-down-modal';
import { ModalQueryParams, APP_ROUTES } from 'data/app';

import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { Link } from 'react-router-dom';

import { appearEffect } from 'styles/animations';
import {
  container, emptySection, section, sectionImage,
} from 'styles/navbar.styles';

const useStyles = makeStyles((theme) => ({
  container,
  emptySection: emptySection(theme),
  section: section(theme),
  sectionImage: sectionImage(theme),
  ...appearEffect,
  linkContainer: {
    display: 'flex',
    color: `${theme.palette.common.white} !important`,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    textDecoration: 'none !important',

    '&:hover': {
      transition: '0.25s ease all',
    },
  },
  linkButton: {
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(4),
    color: theme.palette.common.white,

    '&.MuiButton-outlinedPrimary': {
      borderTop: 0,
      borderBottom: 0,
      borderRight: 0,
      borderRadius: 0,
      borderWidth: 2,

      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
  },
}));

// @ts-ignore
function renderLink(classes, url, text) {
  return (
    <Link className={classes.linkContainer} to={url}>
      <Button
        color="primary"
        className={classes.linkButton}
        variant="outlined"
      >
        <Typography variant="h5">
          {text}
        </Typography>
      </Button>
    </Link>
  );
}

export default function AboutMenuModal() {
  const classes = useStyles();
  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.ABOUT);

  if (!isOpen) {
    return null;
  }

  return (
    <SlideDownModal
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.sectionImage} style={{ backgroundImage: 'url(/images/anjb_home.jpg)' }} />

        <section className={classes.emptySection} />
        <section className={classes.section}>
          {renderLink(classes, APP_ROUTES.about, 'Sobre a ANJB')}
          {renderLink(classes, APP_ROUTES.team, 'Órgãos Sociais')}
          {renderLink(classes, APP_ROUTES.partners, 'Parcerias')}
          {renderLink(classes, APP_ROUTES.editorial, 'Editorial')}
          {renderLink(classes, APP_ROUTES.contact, 'Contactos')}
        </section>
      </div>
    </SlideDownModal>
  );
}
