/* eslint linebreak-style: ["error", "unix"] */
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridOptions } from 'ag-grid-community';

interface Props extends GridOptions {
  height?: number
}

export default function TableGrid(props: Props) {
  return (

    <div className="ag-theme-alpine" style={{ height: props?.height || 450 }}>
      <AgGridReact
        rowData={props.rowData}
        {...props}
      >
        {
          props.columnDefs?.map((columnDef, index) => (
            <AgGridColumn
              key={`${index}_${columnDef.headerName}_AgGridColumn`}
              field={columnDef.headerName}
              {...columnDef}
            />
          ))
        }
      </AgGridReact>
    </div>
  );
}
