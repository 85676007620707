/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Theme, useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useState } from 'react';
import { PUBLICATION_IMAGE_SIZES } from 'components/tiles/tile-publication-preview/publication-preview-tile';
import StopIcon from '@material-ui/icons/Stop';
import { Link } from 'react-router-dom';
import Spacer from 'components/spacer/spacer';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const getButton = (theme: Theme, direction: 'right' | 'left'): Record<string, any> => ({
  position: 'absolute',
  top: '50%',
  transform: 'translate(0% , -50%)',
  right: direction === 'right' ? 10 : 'unset',
  left: direction === 'left' ? 10 : 'unset',
  borderRadius: 0,
  zIndex: '100 !important',
  overflow: 'visible',

  height: '100%',
  minWidth: 'unset',

  '&.MuiButton-root': {
    background: theme.palette.common.white,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)',
    height: 35,
    width: 35,
    borderRadius: 35,
  },

  '& svg': {
    fill: theme.palette.primary.main,
    height: 35,
    width: 35,

    position: 'absolute',
    right: 0,
  },

  '&:disabled': {
    display: 'none',
  },

  [theme.breakpoints.down('xs')]: {
    '&.MuiButton-root': {
      height: 20,
      width: 20,
      borderRadius: 20,
    },

    '& svg': {
      height: 20,
      width: 20,
    },

  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',

    width: PUBLICATION_IMAGE_SIZES.WIDTH * 3,

    [theme.breakpoints.down('sm')]: {
      width: PUBLICATION_IMAGE_SIZES.WIDTH * 2,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  nextButton: getButton(theme, 'right'),
  previousButton: getButton(theme, 'left'),
  stepContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  imageContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    display: 'flex',
    flexDirection: 'column',
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    maxHeight: PUBLICATION_IMAGE_SIZES.HEIGHT * 3,
  },
  newsLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.secondary.main,
    padding: theme.spacing(2),
    textDecoration: 'none',
  },
  newsLink: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
}));

export interface CarouselStep {
  label: string
  image: string
  index?: number
  description?: string
  href?: string
}

interface Props {
  steps: CarouselStep[]

  /** e.g. 5000 equals 5 seconds  */
  interval?: number
}

export default function HighlightedNewsCarousel({
  steps,
  interval = 8000,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={interval}
      >
        {steps.map((step, index) => (
          <div className={classes.stepContainer} key={index}>
            <div className={classes.imageContent}>
              <img
                src={step.image}
                alt={step.image}
                className={classes.image}
              />

              <Button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                className={classes.nextButton}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0}
                className={classes.previousButton}
              >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              </Button>
            </div>

            <Link className={classes.newsLinkContainer} to={step.href as string}>
              <Typography variant="h5" color="primary" className={classes.newsLink}>
                <StopIcon color="primary" />
                {' '}
                {step?.label}
              </Typography>
              <Spacer spacing={1} />

              <Typography variant="subtitle2" color="primary">
                {step?.description}
              </Typography>
            </Link>
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </div>
  );
}
