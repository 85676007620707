/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import SectionTitle from 'components/section-title/section-title';

import {
  conselhoFiscal, direcao, mesaAssembleiaGeral, suplentes,
} from './data';
import Team from './team-item';
import TeamBreadcrumbs from './team-page.breadcrumbs';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '50%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
}));

export default function TeamPage() {
  const classes = useStyles();

  return (
    <>
      <section className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.content}>
            <TeamBreadcrumbs />
            <Spacer spacing={2} />

            <SectionTitle>Órgãos Sociais</SectionTitle>
            <Spacer spacing={2} />

            <Team members={mesaAssembleiaGeral} title="Mesa da Assembleia Geral" />
            <Team members={conselhoFiscal} title="Conselho Fiscal" />
            <Team members={direcao} title="Direção" />
            <Team members={suplentes} title="Suplentes" />

            <Spacer spacing={4} />
          </div>
        </div>
      </section>
    </>
  );
}
