/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import { useHistory } from 'react-router';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';

import { formatToDateAndHour } from 'utils/formatters';
import { listSubscriptionPaymentsGql, SubscriptionPayment } from 'data/subscription-payment';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { PaymentStatus } from 'data/payments';
import { isSubscriptionActive, listSubscriptionsGql } from 'data/subscriptions';

export default function ManageSubscriptionsPage() {
  const history = useHistory();
  const subscriptionPaymentsFeature: BackofficeFeature = {
    name: 'Pagamentos de Quotas',
    url: APP_ROUTES.backoffice.subscriptions.manageSubscriptions,
    listQuery: listSubscriptionPaymentsGql,
    listQuerySelector: 'listSubscriptionPayments',
    buttons: [
      {
        label: 'Add',
        trigger: (params: any) => {
          history.push(`${history.location.pathname}?${ModalQueryParams.CREATE_SUBSCRIPTION_PAYMENT}`);
        }
      }
    ],
    gridProps: {
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    },
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 80,
      },
      {
        headerName: 'Sócio',
        field: 'subscription.user.username',
        flex: 1,
        cellRendererFramework: (params: any) => (
          <PrimaryLink to={`${APP_ROUTES.backoffice.users.manageUsers}?${ModalQueryParams.VIEW_USER(params?.data?.subscription?.user?.id)}`}>
            {params?.data?.subscription?.user?.username}
          </PrimaryLink>
        ),
      },
      {
        headerName: 'Nrº Sócio',
        field: 'subscription.user.membershipNumber',
        flex: 1,
      },
      {
        headerName: 'Contribuinte',
        field: 'subscription.user.profile.taxpayerNumber',
        flex: 1,
      },
      {
        headerName: 'Modalidade',
        field: 'subscription.subscriptionPlan.name',
        flex: 1,
      },
      {
        headerName: 'Referência',
        field: 'reference',
        flex: 1,
      },
      {
        headerName: 'Estado',
        field: 'paymentStatus',
        valueGetter: (params) => formatSubscriptionPaymentStatus(params.data as SubscriptionPayment),
        flex: 1,
        cellRendererFramework: (params: any) => (
          <p>{formatSubscriptionPaymentStatus(params.data as SubscriptionPayment)}</p>
        ),
      },
      {
        headerName: 'Valor',
        field: 'amount',
        flex: 1,
        valueFormatter: (params) => `${params.data.amount} €`,
      },
      {
        headerName: 'Pago a',
        field: 'paidAt',
        flex: 1,
        valueFormatter: (params) => formatToDateAndHour(params.data.paidAt),
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_SUBSCRIPTION_PAYMENT,
    searchTextFilterKey: 'username',
    searchTextPlaceholder: 'Pesquisar sócio...',
    dateFilterKey: 'showFrom'
  };

  const subscriptionsFeature: BackofficeFeature = {
    name: 'Sócios',
    url: APP_ROUTES.backoffice.subscriptions.manageSubscribers,
    listQuery: listSubscriptionsGql,
    listQuerySelector: 'listSubscriptions',
    gridProps: {
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    },
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 80,
      },

      {
        headerName: 'Sócio',
        field: 'user.username',
        flex: 1,
        cellRendererFramework: (params: any) => (
          <PrimaryLink to={`${APP_ROUTES.backoffice.users.manageUsers}?${ModalQueryParams.VIEW_USER(params?.data?.user?.id)}`}>
            {params?.data?.user?.username}
          </PrimaryLink>
        ),
      },
      {
        headerName: 'Nrº Sócio',
        field: 'user.membershipNumber',
        flex: 1,
      },
      {
        headerName: 'Quota Ativa?',
        field: '',
        valueGetter: (params: any) => (isSubscriptionActive(params?.data) ? 'Sim' : 'Não'),
        flex: 1,
      },
      {
        headerName: 'Modalidade',
        field: 'subscriptionPlan.name',
        flex: 1,
      },
      {
        field: 'subscription.startDate',
        headerName: 'Data Início da Quota',
        flex: 1,
        valueFormatter: (params: any) => formatToDateAndHour(params?.data?.startDate),
      },
      {
        field: 'subscription.endDate',
        headerName: 'Data Fim da Quota',
        flex: 1,
        valueFormatter: (params: any) => formatToDateAndHour(params?.data?.endDate),
      },
    ],
    searchTextFilterKey: 'subscriptionPlanName',
    searchTextPlaceholder: 'Filtrar modalidade...',
    dateFilterKey: 'showFrom',
  };

  return (
    <BackofficeFactory
      features={[
        subscriptionPaymentsFeature,
        subscriptionsFeature,
      ]}
    />
  );
}

export const formatSubscriptionPaymentStatus = (subscriptionPayment: SubscriptionPayment): string => {
  if (!subscriptionPayment) {
    return '';
  }

  switch (subscriptionPayment?.paymentStatus) {
    case PaymentStatus.PAID:
      return 'Pagamento efetuado';
    case PaymentStatus.REJECTED:
      return 'Pagamento rejeitado';
    case PaymentStatus.PENDING:
    default:
      return 'Pagamento pendente';
  }
};
