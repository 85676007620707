/* eslint linebreak-style: ["error", "unix"] */

import Typography from '@material-ui/core/Typography/Typography';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core';

interface Props {
  errorMessage?: string
}

const useStyles = makeStyles((theme) => ({
  alert: {
    '&.MuiPaper-root': {
      minWidth: '100% !important',
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.white,
    },
  },
  typography: {
    color: theme.palette.common.white,
  },
}));

export default function ErrorMessage({
  errorMessage,
}: Props) {
  const classes = useStyles();

  return (
    <Alert severity="error" color="error" className={classes.alert}>
      <Typography variant="subtitle2" className={classes.typography}>
        {errorMessage}
      </Typography>
      {' '}
    </Alert>
  );
}
