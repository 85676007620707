/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { Fade, makeStyles } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import SectionTitle from 'components/section-title/section-title';
import NewsGridFeed from 'components/lists/list-publications/publications-list';
import { FeedSearchFilters } from 'components/landing-page-publications-feed/landing-page-publications-feed';
import { APP_ROUTES } from 'data/app';
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import PublicationFeedFilters from 'components/filters/filter-publications/publication-filters';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
    },
  },
  skew: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    width: '50%',
    padding: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const breadcrumbs = [
  {
    displayName: 'Início',
    to: APP_ROUTES.home,
  },
  {
    displayName: 'Notícias',
  },
];

export default function PublicationsListPage() {
  const classes = useStyles();

  const [searchFilters, setSearchFilters] = useState<FeedSearchFilters>({
    publicationCategories: [],
  });

  /** The real filters that are sent to the backend once we hit the APPLY FILTERS button */
  const [appliedSearchFilters, setAppliedSearchFilters] = useState<FeedSearchFilters>({
    publicationCategories: [],
  });

  return (
    <>
      <Fade in>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Breadcrumbs links={breadcrumbs} />
            <Spacer spacing={4} />

            <SectionTitle>
              Notícias
            </SectionTitle>

            <PublicationFeedFilters
              searchFilters={searchFilters}
              setSearchFilters={setSearchFilters}
              setAppliedSearchFilters={setAppliedSearchFilters}
            />
            <NewsGridFeed searchFilters={appliedSearchFilters} showPagination />

            <Spacer spacing={8} />
          </div>
        </div>
      </Fade>
    </>
  );
}
