/* eslint linebreak-style: ["error", "unix"] */
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import SectionStepper from 'components/section-stepper/section-stepper';
import { formatToDateAndHour } from 'utils/formatters';
import { getProductVariantByIdGql, ProductVariant } from 'data/product-variants';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getModalStyle } from 'styles/modal.styles';
import EditorReadonly from 'components/editor-readonly/editor-readonly';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  images: {
    display: 'flex',
  },
  image: {
    display: 'flex',
    maxWidth: 300,
  },
}));

export default function ModalViewProductVariant() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<ProductVariant>(
    ModalQueryParams.VIEW_PRODUCT_VARIANT,
    getProductVariantByIdGql,
    'getProductVariantById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  const displayFilterValues = data?.productFilters?.map((x) => x.value).join(', ');

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="subtitle1">
          Detalhes da variante
          {' '}
        </Typography>
        <Spacer spacing={2} />

        <Typography variant="h5" color="primary">
          {data?.product.name}
          {' '}
          -
          {' '}
          {displayFilterValues}
          {' '}
        </Typography>
        <Divider />

        <Spacer spacing={4} />

        {renderInfo('ID', data?.id)}
        {renderInfo('Produto', data?.product.name)}

        <div className={classes.paragraph}>
          <Typography variant="body1" color="primary">
            Imagens
          </Typography>

          <div className={classes.images}>
            {data?.productImages?.map((productImage, index) => (
              <img src={productImage} alt={productImage} key={index} className={classes.image} />
            ))}
          </div>
          <Spacer spacing={2} />
        </div>

        <div className={classes.paragraph}>
          <Typography variant="body1" color="primary">
            Conteúdo
          </Typography>
          <EditorReadonly
            defaultValue={data?.content}
            readOnly
            toolbar={false}
          />
          <Spacer spacing={2} />
        </div>

        {renderInfo('Quantidade em stock', data?.stockQuantity)}

        {renderInfo('Visível ao público?', data?.isActive ? 'Sim' : 'Não')}

        <SectionStepper displayText="Filtros" />
        <div className={classes.paragraph}>
          {data?.productFilters?.map((productFilter, index) => (
            <div key={index}>
              <Typography color="primary">{productFilter.productFilterCategory.filterName}</Typography>
              <Typography>{productFilter.value}</Typography>
              <Spacer spacing={2} />
            </div>
          ))}
          <Spacer spacing={2} />
        </div>

        <SectionStepper displayText="Atividade" />
        {renderInfo('Criado a', formatToDateAndHour(data?.createdAt))}
        {renderInfo('Atualizado a', formatToDateAndHour(data?.updatedAt))}
      </DataRenderer>
    </Modal>
  );
}
