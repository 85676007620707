/* eslint linebreak-style: ["error", "unix"] */

import { useMutation } from '@apollo/client';
import {
  Button, Divider, makeStyles, TextField, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { selectCurrentUser } from 'redux/auth.redux';
import { updateUserGql } from 'data/users';
import { password, confirmPassword } from 'utils/validators';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: theme.palette.common.white,
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(2),

    '& .MuiButton-contained': {
      color: theme.palette.common.white,
      boxShadow: 'none',
    },
  },
  info: {
    padding: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  password: password(),
  confirmPassword,
});

export default function ChangePassword() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, errors, register } = formApi;

  const [mutate, { loading }] = useMutation(
    updateUserGql,
    {
      onError: (error) => {
        enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
      },
      onCompleted: (data) => {
        enqueueSnackbar('Password atualizada com sucesso', { variant: 'success' });
      },
    },
  );

  const onSubmit = (data: Record<string, any>) => {
    mutate({
      variables: {
        input: {
          id: currentUser?.id,
          password: data?.password,
        },
      },
    });
  };

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Typography color="primary" variant="h6">
          Alterar password
        </Typography>
        <Divider />
        <Spacer spacing={2} />

        <TextField
          name="password"
          label="Password"
          placeholder="Introduz uma nova password"
          type="password"
          inputProps={{
            ref: register,
          }}
          error={!!errors?.password}
          helperText={errors?.password?.message}
        />

        <Spacer spacing={2} />

        <TextField
          name="confirmPassword"
          label="Confirmar Password"
          placeholder="Confirmar a password"
          type="password"
          inputProps={{
            ref: register,
          }}
          error={!!errors?.confirmPassword}
          helperText={errors?.confirmPassword?.message}
        />

        <Spacer spacing={4} />

        <Button type="submit" color="primary" variant="contained" disabled={loading}>
          Alterar password
        </Button>
      </form>
    </div>
  );
}
