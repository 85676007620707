/* eslint linebreak-style: ["error", "unix"] */
import { DEFAULT_PAGE_SIZES } from 'components/table/pagination/page-counter/page-counter';
import { useState } from 'react';

interface UseTableOptions {
  defaultLimit?: number
  defaultOffset?: number
  defaultPage?: number
  defaultSearchText?: string
}

export default function useTable({
  defaultLimit = DEFAULT_PAGE_SIZES[0],
  defaultOffset = 0,
  defaultPage = 0,
  defaultSearchText = '',
}: UseTableOptions = {}) {
  const [limit, setLimit] = useState<number>(defaultLimit);
  const [offset, setOffset] = useState<number>(defaultOffset);
  const [currentPage, setCurrentPage] = useState<number>(defaultPage);
  const [searchText, setSearchText] = useState<string>(defaultSearchText);

  return {
    limit,
    setLimit,
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    searchText,
    setSearchText,
  };
}
