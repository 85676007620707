/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Theme, createStyles, useTheme,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from 'components/swipeable-drawer/swipeable-drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { Divider } from '@material-ui/core';
import { APP_ROUTES } from 'data/app';
import { User } from 'data/users';
import SubscriptionStatusIndicator from 'components/subscription-status-indicator/subscription-status-indicator';
import { useQuery } from '@apollo/client';
import { listSubscriberPagesGql, Page } from 'data/pages';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    maxWidth: 340,
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      minHeight: 'unset',
      height: '100%',
    },
  },
}));

interface Props {
  user: User
}

export default function SubscriberMenu({
  user,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: rawData, loading, error } = useQuery(listSubscriberPagesGql, {
    variables: {
      filter: {
        limit: 999,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  const subscriberPages: Page[] = rawData?.listSubscriberPages || [];

  const renderContent = () => (
    <>
      <SubscriptionStatusIndicator user={user} />

      <Divider />

      <PrimaryLink to={APP_ROUTES.subscriberArea.root} underline={false}>
        <ListItem button>
          <ListItemText primary="Comunicados Oficiais" />
        </ListItem>
      </PrimaryLink>
      <PrimaryLink to={APP_ROUTES.subscriberArea.documents} underline={false}>
        <ListItem button>
          <ListItemText primary="Documentos" />
        </ListItem>
      </PrimaryLink>

      <Divider />

      {subscriberPages?.map((subPage) => (
        <PrimaryLink to={`${APP_ROUTES.subscriberArea.dynamicPage}/${subPage.url}`} underline={false} key={subPage.id}>
          <ListItem button>
            <ListItemText primary={subPage.name} />
          </ListItem>
        </PrimaryLink>
      ))}
    </>
  );

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {isMobile
          ? (
            <SwipeableDrawer title="Área do Sócio">
              {renderContent()}
            </SwipeableDrawer>
          )
          : (
            renderContent()
          )}
      </List>
    </>
  );
}
