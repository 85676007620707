/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import { getProductVariantByIdGql, ProductVariant } from 'data/product-variants';
import FormProductVariant from 'components/forms/form-product-variant/form-product-variant';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';

export default function ModalEditProductVariant() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<ProductVariant>(
    ModalQueryParams.EDIT_PRODUCT_VARIANT,
    getProductVariantByIdGql,
    'getProductVariantById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <SectionTitle>Editar variante de produto</SectionTitle>
        <FormProductVariant
          data={data}
          onSuccess={handleClose}
        />
      </DataRenderer>
    </Modal>
  );
}
