/* eslint linebreak-style: ["error", "unix"] */
import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';
import { isImage } from 'data/storage';
import { useSnackbar } from 'notistack';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import Spacer from 'components/spacer/spacer';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    width: 250,
    margin: theme.spacing(1),
  },
  image: {
    display: 'flex',
    objectFit: 'contain',
  },
  removeIcon: {
    marginLeft: 5,
  },
}));

interface Props {
  value: string[]
  onChange: (value: string[]) => void
}

export default function InputImageSelections({
  value,
  onChange,
}: Props) {
  const [bucketManagerOpen, setBucketManagerOpen] = useState(false);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <BucketManagerModal
        open={bucketManagerOpen}
        onClose={() => setBucketManagerOpen(false)}
        onFileSelect={(file) => {
          if (!isImage(file?.publicUrl)) {
            enqueueSnackbar('Deve ser uma imagem', { variant: 'error' });
            return;
          }

          const updatedValue = value.slice() || [];
          updatedValue.push(file.publicUrl);
          onChange(updatedValue);

          setBucketManagerOpen(false);
        }}
      />
      <div className={classes.container}>
        <ul className={classes.list}>
          {value?.map(((imageUrl, index) => (
            <li className={classes.item} key={index}>
              <img src={imageUrl} className={classes.image} alt={imageUrl} />

              <Button
                onClick={() => {
                  const newValue = value.slice().filter((x) => x !== imageUrl);
                  onChange(newValue);
                }}
                variant="outlined"
                style={{ borderRadius: 0 }}
              >
                Remover imagem
                <RemoveIcon className={classes.removeIcon} />
              </Button>
            </li>
          )))}
        </ul>

        <Spacer spacing={2} />
        <Button onClick={() => setBucketManagerOpen(true)} variant="outlined" color="primary">
          Adicionar imagem
        </Button>
      </div>
    </>
  );
}
