/* eslint linebreak-style: ["error", "unix"] */
import { DocumentNode, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  CircularProgress, FormHelperText, FormLabel, makeStyles, MenuItem, Select, Tooltip, Typography,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(2, 0),

    '& .MuiSelect-select': {
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderTop: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&.MuiInput-underline:after': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  name: string
  optionName?: string
  label: string
  tooltipText?: string;
  control: Control<any>
  errors: Record<string, any>
  query: DocumentNode
  querySelector: string
  multiple?: boolean
}

export default function SelectGeneric({
  name,
  optionName = 'name',
  label,
  tooltipText,
  control,
  errors,
  query,
  querySelector,
  multiple = false,
}: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: rawData, loading } = useQuery(query, {
    variables: {
      filter: {
        offset: 0,
        limit: 999,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  if (loading) {
    return (
      <CircularProgress color="primary" />
    );
  }

  const data: Record<string, any>[] = rawData?.[querySelector] || [];
  const options = data.map((item) => ({
    name: item?.[optionName],
    value: item?.id,
  }));

  return (
    <>
      <Controller
        render={({ onChange, value = [], name }) => {
          const formattedValue = options.filter((opt) => (
            Array.isArray(value) ? value?.includes(opt.value) : opt.value === value));

          return (
            <>
              <FormLabel>{label}</FormLabel>
              <Tooltip title={tooltipText || ''}>
                <Select
                  className={classes.select}
                  name={name}
                  label={label}
                  value={value}
                  inputProps={{
                    name,
                    'aria-label': 'Without label',
                  }}
                  displayEmpty
                  onChange={(evt) => onChange(evt.target.value)}
                  multiple={multiple}
                  renderValue={
                    () => (formattedValue?.length
                      ? formattedValue.map((x) => x.name).join(', ')
                      : 'Selecionar opção'
                    )
                  }
                >
                  {options.map((option, index) => (
                    <MenuItem key={option.value} value={option.value} className={classes.item}>
                      <Typography>
                        {option.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
              {errors
                && (
                  <FormHelperText
                    className={classes.helperText}
                  >
                    {errors?.[name]?.message}
                  </FormHelperText>
                )}
            </>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
}
