/* eslint linebreak-style: ["error", "unix"] */
import { useEffect, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import './index.css';

export const imageByIndex = (images: string[], index: number) => images[index % images.length];

interface ThumbProps {
  selected: boolean
  onClick: any
  imgSrc: string
}

export const Thumb = ({ selected, onClick, imgSrc }: ThumbProps) => (
  <div
    className={`embla__slide embla__slide--thumb ${selected ? 'is-selected' : ''
      }`}
  >
    <button
      onClick={onClick}
      className="embla__slide__inner embla__slide__inner--thumb"
      type="button"
    >
      <img className="embla__slide__thumbnail" src={imgSrc} alt={imgSrc} />
    </button>
  </div>
);

interface Props {
  images?: string[]
}

export default function EmblaCarousel({
  images = [],
}: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainViewportRef, embla] = useEmblaCarousel();
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    selectedClass: '',
  });

  const onThumbClick = (index: number) => {
    if (emblaThumbs?.clickAllowed()) {
      embla?.scrollTo(index);
    }
  };

  useEffect(() => {
    embla?.reInit();
  }, [images]);

  useEffect(() => {
    if (!embla || !emblaThumbs) {
      return;
    }

    embla.on('select', () => {
      setCurrentIndex(embla.selectedScrollSnap());
      emblaThumbs.scrollTo(embla.selectedScrollSnap());
    });
  }, [embla, emblaThumbs]);

  useEffect(() => {
    if (!embla || !emblaThumbs) {
      return;
    }

    if (currentIndex === 0) {
      embla.scrollTo(0);
      emblaThumbs.scrollTo(0);
    }
  }, [embla, emblaThumbs, currentIndex]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={mainViewportRef}>
          <div className="embla__container">
            {images.map((image, key) => (
              <div className="embla__slide" key={key}>
                <div className="embla__slide__inner">
                  <img
                    className="embla__slide__img"
                    src={imageByIndex(images, key)}
                    alt={image}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {images?.length > 1 && (
        <div className="embla embla--thumb">
          <div className="embla__viewport" ref={thumbViewportRef}>
            <div className="embla__container embla__container--thumb">
              {images.map((_, imageIndex) => (
                <Thumb
                  onClick={() => onThumbClick(imageIndex)}
                  selected={imageIndex === currentIndex}
                  imgSrc={imageByIndex(images, imageIndex)}
                  key={imageIndex}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
