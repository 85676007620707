/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { Zone } from './zones';

export enum Gender {
  MALE = 'Masculino',
  FEMALE = 'Feminino',
  NOT_SPECIFIED = 'Não especificado'
}

export interface UserProfile {
  id: number
  fullName: string
  gender: Gender
  birthdate: string
  placeOfBirth: string
  address: string
  locality: string
  postalCode: string
  country: string
  taxpayerNumber: number
  phoneNumber: string
  zone: Zone | null
  updatedAt: string
}

export const updateUserOwnProfileGql = gql`
		mutation updateUserOwnProfile($input: IUpdateProfile!) {
			updateUserOwnProfile(input: $input) {
				id
				fullName
        gender
				birthdate
        placeOfBirth
				address
        locality
				postalCode
        country
				taxpayerNumber
				phoneNumber
        zone {
          id
          name
        }
			}
		}
	`;
