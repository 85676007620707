/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Typography, IconButton,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import Spacer from 'components/spacer/spacer';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import InfoIcon from '@material-ui/icons/Info';
import { isSubscriber } from 'data/subscriptions';
import { User } from 'data/users';

const useStyles = makeStyles((theme) => ({
  section: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  },
  lightFont: {
    fontWeight: 200,
    color: theme.palette.text.secondary,
  },
  primaryFont: {
    fontWeight: 200,
    color: theme.palette.primary.main,
  },
  border: {
    height: '1px',
    width: '100%',
    background: theme.palette.grey[100],
    margin: theme.spacing(1, 0),
  },
  perks: {
    padding: theme.spacing(4),
    width: '100%',
    background: theme.palette.common.white,
    boxShadow: '0 1rem 2rem 0 rgba(0, 0, 0, 0.15)',
  },
  illustration: {
    width: '50%',
    height: '100%',
    background: theme.palette.primary.main,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    alignItems: 'flex-start',

    background: 'url(/images/anjb_juizes.jpg)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  callToActionButton: {
    width: '100%',
    borderColor: theme.palette.primary.main,
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(1, 0),

    '& button': {
      padding: 5,
      marginRight: 5,
    },
  },
  white: {
    color: theme.palette.common.white,
  },
  actionButtonFont: {
    fontSize: '16px',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pricesModal: {
    display: 'flex',
    minWidth: '25rem',
  },
}));

export const membershipPerksBulletpoints = [
  'Acesso exclusivo a todas as iniciativas organizadas pela ANJB',
  'Possibilidade de integrar um Núcleo Regional de Arbitragem',
  'Preços reduzidos na loja online',
  'Ferramentas únicas e restritas na área pessoal',
  'Acesso ao Gabinete de Suporte ao Juiz',
  'Ofertas exclusiva',
];

interface Props {
  children: React.ReactNode,
  showInfoIcon?: boolean
  setPriceTableOpen?: (v: boolean) => void
}

export default function MembershipPerk({
  children, showInfoIcon, setPriceTableOpen,
}: Props) {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  if (isSubscriber(currentUser as User)) {
    return null;
  }

  return (
    <>
      <div className={classes.section}>
        <div className={classes.perks}>

          <div className={classes.title}>
            <Typography variant="h5" color="textSecondary" className={classes.lightFont}>
              Escolhe a melhor opção para ti
            </Typography>

            {showInfoIcon && (
              <>
                &nbsp;
                <IconButton onClick={() => (setPriceTableOpen ? setPriceTableOpen(true) : {})}>
                  <InfoIcon />
                </IconButton>
              </>
            )}
          </div>

          <div className={classes.border} />

          <Spacer spacing={2} />

          {membershipPerksBulletpoints.map((perk, index) => (
            <div key={index}>
              <Perk perk={perk} />
            </div>
          ))}

          <Spacer spacing={2} />

          <div className={classes.border} />

          <Spacer spacing={2} />

          {children}
        </div>
        <div className={classes.illustration}>
          <div className={classes.image} />
        </div>
      </div>
    </>
  );
}

export const Perk = ({ perk }: { perk: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.paragraph}>
      <IconButton>
        <CheckIcon color="action" />
      </IconButton>
      <Typography variant="body1" color="textSecondary" className={classes.lightFont}>
        {perk}
      </Typography>
    </div>
  );
};
