/* eslint linebreak-style: ["error", "unix"] */
import { useLocation } from 'react-router-dom';
import { extractIdFromLocation } from 'libs/router/router.utils';
import Page from 'pages/_layouts/page';
import SectionTitle from 'components/section-title/section-title';
import { makeStyles } from '@material-ui/core';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import BuyerDetails from 'components/details/details-buyer/buyer-details';
import Spacer from 'components/spacer/spacer';
import ProductOrderPaymentMethodSelection from 'components/product-order-payment-method-selection/product-order-payment-method-selection';
import ProductOrderPaymentPendingDetails from 'components/details/details-product-order-payment-pending/product-order-payment-pending-details';
import ProductOrderPaymentCompletedDetails from 'components/details/details-product-order-payment-completed/product-order-payment-completed-details';
import { getProductOrderByIdGql, ProductOrder } from 'data/product-orders';
import { PaymentStatus } from 'data/payments';
import ShoppingCartSummary from 'components/details/details-shopping-cart-summary/shopping-cart-summary';
import OrderedProductsList from 'components/lists/list-ordered-products/ordered-products-list';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
  },
}));

export default function OrderDetailsPage() {
  const classes = useStyles();
  const location = useLocation();
  const orderId = extractIdFromLocation(location.pathname) as number;

  const {
    data: rawData, loading, error, refetch,
  } = useQuery(getProductOrderByIdGql, {
    variables: {
      id: orderId,
    },
    skip: !orderId,
  });

  const data: ProductOrder = rawData?.getProductOrderById;

  const orderedProductVariants = data?.productOrderDetails?.map(
    (productOrderDetail) => productOrderDetail.productVariant,
  ) || [];

  const pendingPaymentMethod = !data?.productOrderPayment;

  const waitingPayment = data?.productOrderPayment && data?.productOrderPayment?.paymentStatus !== PaymentStatus.PAID;

  const paymentCompleted = data?.productOrderPayment && data?.productOrderPayment?.paymentStatus === PaymentStatus.PAID;

  return (
    <Page>
      <SectionTitle>
        Encomenda #
        {orderId}
      </SectionTitle>

      <section className={classes.container}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <OrderedProductsList productVariants={orderedProductVariants} readonly />
          <Spacer spacing={2} />
          <ShoppingCartSummary productVariants={orderedProductVariants} />
          <Spacer spacing={2} />

          {paymentCompleted && (
            <ProductOrderPaymentCompletedDetails
              productOrderPayment={data?.productOrderPayment}
            />
          )}

          {waitingPayment && (
            <ProductOrderPaymentPendingDetails
              id={data?.id}
              productOrderPayment={data?.productOrderPayment}
              customerPhone={data?.phone}
              onPaymentCompleted={refetch}
            />
          )}

          {pendingPaymentMethod && (
            <ProductOrderPaymentMethodSelection productOrder={data} onCreatePaymentMethodSuccess={refetch} />
          )}
          <Spacer spacing={2} />
          <BuyerDetails productOrder={data} />
        </DataRenderer>
      </section>

    </Page>
  );
}
