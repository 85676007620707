/* eslint linebreak-style: ["error", "unix"] */
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectSearchFilters, SearchFilter, setProductSearchFilters } from 'redux/product-search.redux';
import { ProductFilter } from 'data/product-filter';

const useStyles = makeStyles((theme) => ({
  button: {
  },
  active: {
    background: theme.palette.grey[300],
  },
  inactive: {},
}));

interface Props {
  productFilterValue: ProductFilter,
}

export default function ProductCategoryFilterValues({ productFilterValue }: Props) {
  const classes = useStyles();

  const searchFilters = useSelector(selectSearchFilters);
  const dispatch = useDispatch();
  const { productFilters = [] } = searchFilters;

  const isActive = searchFilters?.productFilters?.find((x) => x === productFilterValue.id);

  const activateFilter = () => {
    const payload: SearchFilter = {
      ...searchFilters,
      productFilters: productFilters.concat(productFilterValue.id),
    };
    dispatch(setProductSearchFilters(payload));
  };

  const removeFilter = () => {
    const payload: SearchFilter = {
      ...searchFilters,
      productFilters: productFilters.filter((x) => x !== productFilterValue.id),
    };

    dispatch(setProductSearchFilters(payload));
  };

  return (
    <Button
      className={`${classes.button} ${isActive ? classes.active : classes.inactive}`}
      onClick={isActive ? removeFilter : activateFilter}
    >
      {productFilterValue.value}
    </Button>
  );
}
