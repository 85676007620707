/* eslint linebreak-style: ["error", "unix"] */
import {
  Button, makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout, selectCurrentUser } from 'redux/auth.redux';
import SlideDownModal from 'components/modals/modal-slide-down/slide-down-modal';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import {
  ModalQueryParams, APP_ROUTES,
} from 'data/app';
import AboutMenu from 'components/menus/menu-navbar-about-us/about-menu';
import { Link } from 'react-router-dom';

import { selectShoppingItems } from 'redux/shopping-cart.redux';
import LoginMenu from 'components/menus/menu-navbar-login/login-menu';
import SignupMenu from 'components/menus/menu-navbar-signup/signup-menu';
import { UserRole } from 'data/users';
import { isSubscriber } from 'data/subscriptions';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    // Overrides
    '& *': {
      color: 'white', // Color all label buttons white
      fontSize: theme.typography.h5.fontSize,
    },
    '& * > svg': {
      display: 'none', // Don't show up / down caret icons for active menus
    },
  },
  separator: {
    display: 'flex',
    margin: theme.spacing(0, 1),
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
  },
  overrides: {
    '& .MuiTypography-body1': {
      fontSize: '1.1rem',
      margin: theme.spacing(0.5, 0),
    },
    '& p': {
      fontSize: '1.1rem',
      marginBottom: theme.spacing(0.5, 0),
    },
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: theme.spacing(4),
    justifyContent: 'center',
    '& svg': {
      color: 'white',
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

export default function MobileNavbarModal() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const isLoggedIn = Boolean(currentUser);
  const isAdmin = currentUser?.role === UserRole.ADMIN;
  const shoppingItems = useSelector(selectShoppingItems);
  const dispatch = useDispatch();

  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.NAVBAR_MOBILE_MENU);

  if (!isOpen) {
    return null;
  }

  const subscribeLink = isSubscriber(currentUser)
    ? APP_ROUTES.subscriberArea.root
    : APP_ROUTES.subscribe;

  return (
    <SlideDownModal onClose={handleClose}>
      <div className={classes.container}>
        <ul className={classes.list}>
          <li>
            <AboutMenu />
          </li>
          {isAdmin && (
            <li>
              <Link
                to={APP_ROUTES.backoffice.publications.managePublications}
                className={classes.link}
              >
                <Button>
                  Backoffice
                </Button>
              </Link>
            </li>
          )}
          <>
            <li>
              <Link to={APP_ROUTES.store} className={classes.link}>
                <Button>
                  Loja
                </Button>
              </Link>
            </li>
            {shoppingItems?.length > 0 && (
              <li>
                <Link to={APP_ROUTES.shoppingCart} className={classes.link}>
                  <Button>
                    Carrinho de compras
                  </Button>
                </Link>
              </li>
            )}
          </>
          {!isLoggedIn
            ? (
              <>
                <li>
                  <LoginMenu />
                </li>
                <li>
                  <SignupMenu />
                </li>
              </>
            )
            : (
              <>
                <li>
                  <Link to={subscribeLink} className={classes.link}>
                    <Button>
                      Área do Sócio
                    </Button>
                  </Link>
                </li>
                <li>
                  <Link to={APP_ROUTES.manageAccount} className={classes.link}>
                    <Button>
                      Gerir Conta
                    </Button>
                  </Link>
                </li>
                <li>
                  <Link to={APP_ROUTES.manageOrders} className={classes.link}>
                    <Button>
                      Gerir Encomendas
                    </Button>
                  </Link>
                </li>

                <li>
                  <Button onClick={() => dispatch(authLogout())}>
                    Logout
                  </Button>
                </li>
              </>
            )}
        </ul>
      </div>
    </SlideDownModal>
  );
}
