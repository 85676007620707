/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-nested-ternary */

import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button/Button';
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import { preventDefaultAndStopPropagation } from 'libs/synthetic-events/synthetic-events.utils';
import Spacer from 'components/spacer/spacer';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';

import { Link } from 'react-router-dom';
import { Product, getProductPriceBasedOnCurrentUser } from 'data/products';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    margin: theme.spacing(2),
    marginTop: 0,
    transition: '.2s ease all',
    textDecoration: 'none',
    color: theme.palette.primary.main,

    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        boxShadow: '0 1rem 2rem 0 rgba(0, 0, 0, 0.15)',
        position: 'relative',
        zIndex: 1,
        cursor: 'pointer',

        '& > .menu': {
          background: theme.palette.common.black,
        },

        '& > .menu > .dynamicMenu': {
          opacity: 1,

          transition: '.2s ease all',
        },
      },

      [theme.breakpoints.up('md')]: {
        '&:not(:hover)': {
          '& > .menu > .dynamicMenu': {
            opacity: 0,
            transition: '.2s ease all',
          },
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      '& > .menu': {
        background: theme.palette.common.black,

        '& > .dynamicMenu': {
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
        },
      },
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: theme.spacing(2, 0),
      maxWidth: 200,
    },
  },
  content: {
    background: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  media: {
    height: '12rem',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '15rem',
    },
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  productName: {
    color: '#000',
    marginRight: theme.spacing(1),
  },
  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',

    '& svg': {
      color: theme.palette.common.white,
    },
  },
  textContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
}));

interface Props {
  product: Product
}

export default function ProductTile({ product }: Props) {
  const classes = useStyles();

  const currentUser = useSelector(selectCurrentUser);
  const { id, name } = product;

  const { productVariants = [] } = product;

  const image = productVariants.length
    ? productVariants[0].productImages[0]
    : '';

  const { handleOpen } = useModalWithQueryParams(ModalQueryParams.VIEW_PRODUCT(id));

  return (
    <>
      <Link
        className={classes.tile}
        to={`${APP_ROUTES.product}/${id}`}
      >
        <div className={classes.content}>
          <CardMedia
            className={classes.media}
            image={image}
            title={image}
          />
          <Spacer spacing={2} />

          <div className={classes.textContent}>
            <Typography variant="body1" className={classes.productName} noWrap>
              {name}
            </Typography>

            <div className={classes.price}>
              <Typography variant="body1" color="primary">
                {getProductPriceBasedOnCurrentUser(product, currentUser)}
                €
              </Typography>
            </div>
          </div>
        </div>

        <div
          className={`${classes.menu} menu`}
          onMouseEnter={(e) => preventDefaultAndStopPropagation(e)}
        >
          <section className="dynamicMenu">
            <Button onClick={(e) => {
              preventDefaultAndStopPropagation(e);
              handleOpen();
            }}
            >
              <VisibilityIcon />
            </Button>
          </section>
        </div>
      </Link>
    </>
  );
}
