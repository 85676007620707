/* eslint linebreak-style: ["error", "unix"] */
import {
  FormHelperText, FormLabel, makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { Gender } from 'data/user-profiles';
import { Control, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(2, 0),

    '& .MuiSelect-select': {
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      borderTop: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    '&.MuiInput-underline:after': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  name: string
  label: string
  control: Control<any>
  errors: Record<string, any>
  defaultValue: string | undefined
}

export default function GenderSelect({
  name,
  label,
  control,
  errors,
  defaultValue,
}: Props) {
  const classes = useStyles();

  const options = Object.values(Gender).map((genderValue) => ({
    label: genderValue,
    value: genderValue,
  }));

  return (
    <>
      <Controller
        render={({ onChange, value }) => (
          <>
            <FormLabel>
              {label}
            </FormLabel>
            <Select
              className={classes.select}
              name={name}
              label={label}
              placeholder={label}
              displayEmpty
              onChange={(evt) => onChange(evt.target.value)}
              defaultValue={defaultValue}
              inputProps={{
                name,
                'aria-label': 'Without label',
              }}
            >
              <MenuItem disabled>
                Selecionar um género
              </MenuItem>
              {options.map((option, index) => (
                <MenuItem key={index} value={option.value} className={classes.item}>
                  <Typography>
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {errors && <FormHelperText className={classes.helperText}>{errors?.[name]?.message}</FormHelperText>}
          </>
        )}
        name={name}
        control={control}
      />
    </>
  );
}
