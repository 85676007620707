/* eslint linebreak-style: ["error", "unix"] */
import { useEffect, useState, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialUISwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(2),

  },
  drawer: {
    position: 'relative',
  },
  button: {
    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
  },
}));

const PANEL_WIDTH = 280;

interface Props {
  title?: string;
  children: ReactNode;
}

export default function SwipeableDrawer({ children, title }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const location = useLocation();

  // Subscribe to every route change and close the panel
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className={classes.container}>
      {title && (
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      )}
      <Button onClick={() => setIsOpen(!isOpen)} className={classes.button}>
        <MenuIcon color="primary" />
      </Button>
      <MaterialUISwipeableDrawer
        className={classes.drawer}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
      >
        <div style={{ width: PANEL_WIDTH }}>
          <div className={classes.closeIcon}>
            <IconButton onClick={() => setIsOpen(false)}>
              <CancelIcon color="primary" />
            </IconButton>
          </div>

          {children}
        </div>
      </MaterialUISwipeableDrawer>
    </div>
  );
}
