/* eslint linebreak-style: ["error", "unix"] */
const prefix = 'REACT_APP_';

export const extractEnv = (key: string): string | undefined => process.env[`${prefix}${key}`];

export enum Environments {
	LOCAL = 'local',
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export const applyOnEnvironment = (
	local: () => string,
	staging: () => string,
	production: () => string,
): string => {
	switch (extractEnv('NODE_ENV')) {
		case 'local':
			return local();
		case 'staging':
			return staging();
		case 'production':
			return production();
		default:
			return '';
	}
};
