/* eslint linebreak-style: ["error", "unix"] */

import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import { APP_ROUTES } from 'data/app';
import { RouteProps } from './routes.interfaces';

interface Props extends RouteProps { }

const UnauthenticatedRoute = ({ component, ...rest }: Props) => {
	const loggedIn = useSelector(selectCurrentUser);

	return loggedIn
		? <Redirect to={APP_ROUTES.home} />
		: <Route {...rest} component={component} />;
};

export default UnauthenticatedRoute;
