/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CloseButton from 'components/buttons/button-close/close-button';

const useStyles = makeStyles((theme) => ({
  modalOverrides: {
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogContent-root:first-child': {
      paddingTop: 0,
    },
  },
  closeWrapper: {
    position: 'absolute',
    top: '.15rem',
    right: '.15rem',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),

    '& button': {
      zIndex: theme.zIndex.modal,
    },
  },
}));

interface Props {
  open: boolean
  onClose: () => void
  children: React.FC | React.ReactChild | React.ReactNode
  title?: string
  buttons?: React.FC | React.ReactChild | React.ReactNode
  hasCloseButton?: boolean,
  closeButtonComponent?: React.FC<{ onClick: () => void }>
  className?: string
}

export default function Modal({
  open,
  onClose,
  title,
  buttons,
  children,
  closeButtonComponent: CloseButtonComponent,
  className,
  hasCloseButton,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!open) {
    return null;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className={`${classes.modalOverrides} ${className}`}
    >
      {hasCloseButton && (
        <div className={classes.closeWrapper}>
          {CloseButtonComponent
            ? <CloseButtonComponent onClick={onClose} /> : <CloseButton onClick={onClose} />}
        </div>
      )}

      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
}
