/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import SectionTitle from 'components/section-title/section-title';
import { median } from 'ramda';
import PartnersBreadcrumbs from './partners-page.breadcrumbs';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    background: 'none',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '32px',
  },
  content: {
    width: '50%',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  partners: {
    margin: '32px 64px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid #cc4a33',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  image: {
    width: '200px',
  },
  text: {

  },
  description: {
    margin: '15px',
    textAlign: 'justify',

    [theme.breakpoints.down('md')]: {
      margin: '0px',
      textAlign: 'justify',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default function PartnersPage() {
  const classes = useStyles();

  return (
    <>
      <section className={classes.container}>
        <div className={classes.content}>
          <PartnersBreadcrumbs />
          <Spacer spacing={2} />

          <SectionTitle>Parcerias</SectionTitle>
          <Spacer spacing={2} />

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                OSTEOFISIO lab
              </Typography>
              <img className={classes.image} src="/images/partners/logo_osteoFisio.jpg" alt="/images/partners/logo_osteoFisio.jpg" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Desconto de 10% em todos os serviços prestados, extensível a familiares dos sócios.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1vfBCI7uqlTwl3EkaqlJAYpCOp55hJaMK/view?usp=share_link" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                ACP
              </Typography>
              <img className={classes.image} src="/images/partners/logo_acp.jpg" alt="/images/partners/logo_acp.jpg" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Beneficios confidenciais para associados ANJB | Contactar parcerias@anjb.pt para informação de protocolo.
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                A Farmácia Online
              </Typography>
              <img className={classes.image} src="/images/partners/logo_afarmaciav2.png" alt="/images/partners/logo_afarmaciav2.png" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Desconto de 15% em receitas médicas | Desconto de 15% sobre o PVP de medicamentos nao sujeitos a receita médica | Desconto de 15% numa enorme variedade de outros produtos.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1DQzmp3Jk4Key0pSIqNd5mAubej3nDX9_/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                Clinica Dentária da Luz
              </Typography>
              <img className={classes.image} src="/images/partners/logo_clinicaDentariaLuz.png" alt="/images/partners/logo_clinicaDentariaLuz.png" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Oferta da 1ª consulta de Check Up oral completo | Acesso a tabela especial de preços de protocolo.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/16OL-odydAj-8Mp9chVOIz6meZkigcEF7/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                Clinica do Tempo
              </Typography>
              <img className={classes.image} src="/images/partners/logo_clinicaDoTempov2.png" alt="/images/partners/logo_clinicaDoTempov2.png" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Oferta da 1ª consulta de Avaliação | Desconto até 50% em técnicas de lipoaspiração e lifting não invasivo | Desconto de 20% em consultas e exames | Desconto de 20% em todos os serviços de estética.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1qVuJkHKYKveK6ypBdDeKjyluYqLBxAQM/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                Prozis
              </Typography>
              <img className={classes.image} src="/images/partners/prozis.jpg" alt="/images/partners/prozis.jpg" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Desconto de 10% imediato em toda a loja | Acesso a campanhas promocionais periódicas exclusivas.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1SF93GYNANgfo6CwA_wb6m2blXppnQkp3/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                Smile Up
              </Typography>
              <img className={classes.image} src="/images/partners/logo_smileUp.jpg" alt="/images/partners/logo_smileUp.jpg" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Oferta da 1ª consulta de Check Up oral completo | Acesso a tabela especial de preços de protocolo | Desconto até 25% em consultas, técnicas e procedimentos.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1sQ-AK-ZM_XwutTXmy9w_uIq_gv150LmD/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <div className={classes.partners}>
            <div className={classes.text}>
              <Typography
                color="primary"
                variant="h6"
              >
                Skydive Portugal
              </Typography>
              <img className={classes.image} src="/images/partners/logo_skyDive.png" alt="/images/partners/logo_skyDive.png" />
            </div>

            <Spacer spacing={2} />

            <div className={classes.description}>
              <Typography variant="subtitle2" paragraph>
                Desconto de 5% sobre o valor individual do salto tandem.
                {' '}
                <a className={classes.link} href="https://drive.google.com/file/d/1tNXM6FXH3wEzT7BNpirFbyw-Y1iH8yWT/view?usp=sharing" target="_blank" rel="noreferrer">
                  Ver detalhes.
                </a>
              </Typography>
            </div>
          </div>

          <Spacer spacing={4} />
        </div>
      </section>
    </>
  );
}
