/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
} from '@material-ui/core';
import Page from 'pages/_layouts/page';
import { useQuery } from '@apollo/client';
import { DocumentCategory, listDocumentCategoriesGql } from 'data/document-categories';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import SectionTitle from 'components/section-title/section-title';
import DocumentCategoryTile from 'components/tiles/tile-document-category/document-category-tile';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    padding: theme.spacing(2),
    overflow: 'scroll',
    width: '100%',
    minHeight: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default function DocumentsListSubpage() {
  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(listDocumentCategoriesGql);

  const data: DocumentCategory[] = rawData?.listDocumentCategories;

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <>
            <SectionTitle>
              Documentos
            </SectionTitle>
            <section className={classes.container}>
              {
                data?.map((documentCategory, index) => (
                  <article key={index}>
                    <DocumentCategoryTile documentCategory={documentCategory} />
                  </article>
                ))
              }
            </section>
          </>
        </DataRenderer>
      </div>
    </Page>
  );
}
