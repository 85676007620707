/* eslint linebreak-style: ["error", "unix"] */
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from 'libs/router/scroll-to-top';
import NavbarMain from 'components/navbars/navbar/navbar';
import { getPrimaryTheme, snackbarClasses } from 'data/app';
import Routes from 'components/routes/routes';
import Cookies from 'components/cookies/cookies';
import Footer from 'components/footer/footer';
import { useQuery } from '@apollo/client';
import { validateJwtGql } from 'data/auth';
import { selectCurrentUser } from 'redux/auth.redux';
import { useSelector } from 'react-redux';
import Notifications from 'components/notifications/notifications';
import ModalManager from 'components/modals/modal-manager/modal-manager';
import './index.css';

function AppContainer() {
  const currentUser = useSelector(selectCurrentUser);

  // Here we are doing an initial request to the backend to validate if the user has a valid JWT token.
  useQuery(validateJwtGql, { skip: !currentUser });

  return (
    <div data-testid="app">
      <NavbarMain />
      <Routes />
      <Footer />
      <Cookies />
      <ModalManager />
      <Notifications />
    </div>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={getPrimaryTheme()}>
      <SnackbarProvider maxSnack={3} classes={snackbarClasses}>
        <ScrollToTop />
        <AppContainer />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
