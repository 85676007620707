/* eslint linebreak-style: ["error", "unix"] */
import { Button, makeStyles } from '@material-ui/core';
import AboutMenu from 'components/menus/menu-navbar-about-us/about-menu';

import Search from 'components/search/search';
import ShoppingCartBadge from 'components/shopping-cart-badge/shopping-cart-badge';
import { selectCurrentUser } from 'redux/auth.redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoginMenu from 'components/menus/menu-navbar-login/login-menu';
import SignupMenu from 'components/menus/menu-navbar-signup/signup-menu';
import UserAvatarMenu from 'components/menus/menu-user-avatar/user-avatar-menu';
import { APP_ROUTES } from 'data/app';
import { isSubscriber } from 'data/subscriptions';
import { UserRole } from 'data/users';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(0, 1),
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    display: 'flex',
    margin: theme.spacing(0, 1),
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
  },
}));

export default function DesktopNavbar() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.role === UserRole.ADMIN;

  const subscribeLink = isSubscriber(currentUser)
    ? APP_ROUTES.subscriberArea.root
    : APP_ROUTES.subscribe;

  return (
    <section className={classes.root}>
      <ul className={classes.list}>
        <li>
          <AboutMenu />
        </li>
        <li>
          <Link to={APP_ROUTES.store} className={classes.link}>
            <Button>
              Loja
            </Button>
          </Link>
        </li>
        {isAdmin && (
          <li>
            <Link to={APP_ROUTES.backoffice.publications.managePublications} className={classes.link}>
              <Button>
                Backoffice
              </Button>
            </Link>
          </li>
        )}
        {currentUser && (
          <li>
            <Link to={subscribeLink} className={classes.link}>
              <Button>
                Área do Sócio
              </Button>
            </Link>
          </li>
        )}
        <li className={classes.separator}>
          |
        </li>
        {!currentUser
          ? (
            <>
              <li>
                <LoginMenu />
              </li>

              <li>
                <SignupMenu />
              </li>
            </>
          )
          : (
            <>
              <li>
                <UserAvatarMenu />
              </li>
            </>
          )}

        <li>
          <ShoppingCartBadge />
        </li>
        <li>
          <Search />
        </li>
      </ul>
    </section>
  );
}
