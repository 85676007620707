/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
// Icons
import ViewIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

interface Props {
  onView?: () => void | null;
  onEdit?: () => void | null;
  onDelete?: () => void | null;
  renderTableCell?: () => React.ReactNode;
}

export default function TableCellActions({
  onView, onEdit, onDelete, renderTableCell,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {renderTableCell && renderTableCell()}
      {onView && (
        <Tooltip title="Pré-visualizar">
          <IconButton onClick={onView} className={classes.icon}>
            <ViewIcon />
          </IconButton>
        </Tooltip>
      )}
      {onEdit && (
        <Tooltip title="Editar">
          <IconButton onClick={onEdit} className={classes.icon}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Remover">
          <IconButton onClick={onDelete} className={classes.icon}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
