/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';

export interface BucketProjection {

  id: string

  bucketName: string

  bucketFiles: BucketFile[]

}

export interface BucketFile {
  id: string

  fileName: string

  publicUrl: string

  signedUrl: string
}

export const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];

export function getFileThumbnail(publicUrl: string) {
  if (imageExtensions.some((imageExtension) => publicUrl.endsWith(imageExtension))) {
    return publicUrl;
  }

  if (publicUrl.endsWith('pdf')) {
    return '/images/file.png';
  }

  return '/images/file.png';
}

export function isImage(publicUrl: string) {
  return imageExtensions.some((imageExtension) => publicUrl.endsWith(imageExtension));
}

// Mutations
export const createBucketGql = gql`
  mutation createBucket($input: ICreateBucket!) {
    createBucket(input: $input) 
  } 
`;

export const deleteBucketGql = gql`
  mutation deleteBucket($input: IDeleteBucket!) {
    deleteBucket(input: $input) 
  } 
`;

export const uploadFileGql = gql`
  mutation uploadFile($file: Upload!, $bucketName: String!) {
    uploadFile(file: $file, bucketName: $bucketName)
  }
`;
export const deleteFileGql = gql`
  mutation deleteFile($fileName: String!, $bucketName: String!) {
    deleteFile(fileName: $fileName, bucketName: $bucketName)
  }
`;

// Queries
export const getBucketByNameGql = gql`
  query getBucketByName($name: String!) {
    listPublicationImageUploads(name: $name) {
      id
      bucketName
      bucketFiles {
        id
        fileName
        publicUrl
        signedUrl
      }
    }
  }
`;

export const listBucketsGql = gql`
  query listBuckets {
    listBuckets {
      id
      bucketName
      bucketFiles {
        id
        fileName
        publicUrl
        signedUrl
      }
    }
  }
`;
