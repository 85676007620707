/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';

import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import SectionTitle from 'components/section-title/section-title';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BackofficeCreateBucketForm from 'components/forms/form-create-bucket/backoffice-create-bucket-form';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

interface Props {
  refetch: () => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {

  },
  addButton: {
    borderRadius: 0,
    '& svg': {
      width: 20,
      marginRight: theme.spacing(0.5),
    },
  },
}));

export default function AddBucket({ refetch }: Props) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hasCloseButton
        closeButtonComponent={CloseButtonWhite}
      >
        <SectionTitle>
          Criar bucket
        </SectionTitle>

        <BackofficeCreateBucketForm onSuccess={() => {
          refetch();
          setModalOpen(false);
        }}
        />
      </Modal>
      <Tooltip title="Criar um novo bucket na Google Cloud">
        <Button className={classes.addButton} variant="outlined" color="primary" onClick={() => setModalOpen(true)}>
          <AddCircleOutlineIcon />
          Criar bucket
        </Button>
      </Tooltip>
    </>
  );
}
