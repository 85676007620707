/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { InputBase, makeStyles, Typography } from '@material-ui/core';
import SlideDownModal from 'components/modals/modal-slide-down/slide-down-modal';
import { ModalQueryParams, APP_ROUTES } from 'data/app';

import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import SearchIcon from '@material-ui/icons/Search';
import { useQuery } from '@apollo/client';
import { listPublicationsGql, Publication } from 'data/publications';
import { listProductsGql, Product } from 'data/products';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import Spacer from 'components/spacer/spacer';
import StopIcon from '@material-ui/icons/Stop';
import PrimaryLink from 'components/links/link-primary/primary-link';
import SearchResultProductTitle from 'components/search-result-product-tile/search-result-product-tile';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
  },
  icon: {
    width: '1.2em',
    height: '1.2em',
  },
  overrides: {
    '& .MuiTypography-body1': {
      fontSize: '1.1rem',
      margin: theme.spacing(0.5, 0),
    },
    '& p': {
      fontSize: '1.1rem',
      marginBottom: theme.spacing(0.5, 0),
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },

    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  searchIcon: {
    opacity: 0.5,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: theme.palette.common.white,
    width: '100%',
  },
  inputInput: {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  active: {
    fill: theme.palette.primary.main,
  },
  newsLink: {
    display: 'flex',
    '& a': {
      display: 'flex',
      color: theme.palette.common.white,

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& svg': {
      color: theme.palette.primary.main,
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }
}));

export default function SearchModal() {
  const classes = useStyles();

  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.SEARCH);

  const [currentValue, setCurrentValue] = useState('');

  const shouldFetch = currentValue.length > 2 && isOpen;

  const { data: publicationResults, loading: publicationsLoading, error: publicationErrors } = useQuery(
    listPublicationsGql,
    {
      variables: {
        filter: {
          limit: 99999,
          name: currentValue,
        },
      },
      skip: !shouldFetch,
    },
  );
  const publicationsData = publicationResults?.listPublications;

  const { data: productResults, loading: productsLoading, error: productErrors } = useQuery(
    listProductsGql,
    {
      variables: {
        filter: {
          limit: 99999,
          name: currentValue,
        },
      },
      skip: !shouldFetch,
    },
  );
  const productsData = productResults?.listProducts;

  if (!isOpen) {
    return null;
  }

  return (

    <SlideDownModal onClose={handleClose}>
      <div className={classes.container}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Pesquisar..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            value={currentValue}
            onChange={(event: any) => {
              const { value } = event.target;
              setCurrentValue(value);
            }}
          />
        </div>

        <div className={classes.results}>
          <DataRenderer
            loading={publicationsLoading}
            error={formatGraphqlErrorMessage(publicationErrors)}
          >
            {renderPublicationResults(classes, publicationsData)}
          </DataRenderer>
          <DataRenderer
            loading={productsLoading}
            error={formatGraphqlErrorMessage(productErrors)}
          >
            {renderProductResults(classes, productsData)}
          </DataRenderer>
        </div>
      </div>
    </SlideDownModal>
  );
}

function renderPublicationResults(
  classes: Record<string, any>,
  data: Publication[] = [],
) {
  if (!data?.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" color="primary">
        Notícias
      </Typography>

      <Spacer spacing={1} />

      <div>
        {data.map((result, index) => (
          <div className={classes.newsLink}>
            <PrimaryLink key={index} to={`${APP_ROUTES.publication}/${result.id}`} underline={false}>
              <Typography color="inherit">
                <StopIcon />
                {' '}
                {result.name}
              </Typography>
            </PrimaryLink>
          </div>
        ))}
      </div>

      <Spacer spacing={4} />
    </div>
  );
}

function renderProductResults(
  classes: Record<string, any>,
  data: Product[] = [],
) {
  if (!data?.length) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" color="primary">
        Produtos
      </Typography>

      <Spacer spacing={1} />

      <div className={classes.row}>
        {
          data.map((result, index) => (
            <SearchResultProductTitle product={result} />
          ))
        }
      </div>

      <Spacer spacing={4} />
    </div>
  );
}
