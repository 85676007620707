/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Typography,
} from '@material-ui/core';
import { renderMbway, renderMultibanco } from 'components/details/details-product-order-payment-pending/product-order-payment-pending-details';
import PrimaryLink from 'components/links/link-primary/primary-link';
import Spacer from 'components/spacer/spacer';
import { ANJB_CONSTANTS } from 'data/app';
import { formatPaymentStatus, PaymentMethod } from 'data/payments';
import { User } from 'data/users';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 420,
  },
  payButton: {
    display: 'flex',
    width: '100%',

    '&.MuiButton-contained': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
}));

interface Props {
  user: User
}

export default function SubscribeFormPaymentStatus({ user }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const targetSubscription = user?.subscription;

  const subscriptionPayments = targetSubscription?.subscriptionPayments || [];
  const targetPayment = subscriptionPayments?.[subscriptionPayments?.length - 1];

  if (!targetPayment) {
    return (
      <Typography>
        Pagamento não encontrado. Por favor contacta a equipa da ANJB para mais informações:
        {' '}
        <PrimaryLink to={`mailto:${ANJB_CONSTANTS.contact.email}`}>
          {ANJB_CONSTANTS.contact.email}
        </PrimaryLink>
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      <Typography color="primary">
        Plano
      </Typography>
      <Typography>
        {targetSubscription?.subscriptionPlan?.name}
      </Typography>
      <Spacer spacing={1} />

      <Typography color="primary">
        Valor Total da Adesão
      </Typography>

      <Typography>
        {targetPayment?.amount}
        {' '}
        {' '}
        €
      </Typography>
      <Spacer spacing={2} />

      <Typography color="primary">
        Estado do Pagamento
      </Typography>
      <Typography>
        {formatPaymentStatus(targetPayment?.paymentStatus)}
      </Typography>
      <Typography variant="caption">
        (Atualiza a cada 30 segundos)
      </Typography>
      <Spacer spacing={2} />

      {
        targetPayment?.paymentMethod === PaymentMethod.MB_WAY
          ? renderMbway(targetPayment?.phoneNumber)
          : renderMultibanco(targetPayment?.entity, targetPayment?.reference, targetPayment?.amount)
      }

      <Spacer spacing={2} />
    </div>
  );
}
