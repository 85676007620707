/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';
import SectionTitle from 'components/section-title/section-title';
import BackofficePublicationForm from 'components/forms/form-publication/backoffice-publication-form';
import { useModalStyles } from 'styles/modal.styles';

export default function BackofficeCreatePublicationModal() {
  const classes = useModalStyles();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.CREATE_NEW_PUBLICATION);

  return (
    <Modal className={classes.modal} open={isOpen} onClose={handleClose} closeButtonComponent={CloseButtonWhite} hasCloseButton>
      <SectionTitle>Criar publicação</SectionTitle>
      <BackofficePublicationForm
        onSuccess={() => {
          handleClose();
        }}
      />
    </Modal>
  );
}
