/* eslint linebreak-style: ["error", "unix"] */
import { IconButton, makeStyles } from '@material-ui/core';
import PlayCircleOutlineOutlined from '@material-ui/icons/PlayCircleOutlineOutlined';
import { TEST_IDS } from '../landing-page-hero';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    width: '50%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
    },

  },
  playButton: {
    width: theme.spacing(16),
    height: theme.spacing(16),

    '&:hover': {
      width: theme.spacing(18),
      height: theme.spacing(18),
    },

    transition: '.5s ease all',

    [theme.breakpoints.down('sm')]: {

      width: theme.spacing(10),
      height: theme.spacing(10),

      '&:hover': {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
    },
  },
}));

interface Props {
  onClick: () => void;
}

export default function PlayButton({ onClick }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <IconButton className={classes.playButton} onClick={onClick} color="primary" data-testid={TEST_IDS.playButton}>
        <PlayCircleOutlineOutlined className={classes.playButton} />
      </IconButton>
    </div>
  );
}
