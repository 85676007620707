/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-nested-ternary */
import { DocumentNode, useQuery } from '@apollo/client';
import { ModalId } from 'data/app';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import useExtractIdFromLocation from './useExtractIdFromLocation';
import useModalWithQueryParams from './useModalWithQueryParams';

export default function useModalQueryWithId<DataType>(
  modalQueryParam: (id: ModalId) => string,
  query: DocumentNode,
  querySelector: string,
) {
  const { id } = useExtractIdFromLocation();

  // Modal
  const {
    isOpen,
    handleClose,
  } = useModalWithQueryParams(modalQueryParam(id));

  const { data: rawData, loading, error } = useQuery(query, {
    variables: { id },
    skip: !isOpen,
    fetchPolicy: 'network-only',
  });

  const data: DataType = rawData?.[querySelector];
  const errorMessage = error
    ? formatGraphqlErrorMessage(error)
    : '';

  return {
    data,
    loading,
    errorMessage,
    isOpen,
    handleClose,
  };
}
