/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import {
  deleteUserGql, listUsersGql, listUsersPartnersGql, updateUserGql,
} from 'data/users';
import { formatToDateAndHour } from 'utils/formatters';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/client';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';

export default function ManageUsersPartnersPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(updateUserGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Dado atualizado com sucesso', { variant: 'success' });
    },
  });

  const userFeature: BackofficeFeature = {
    name: 'Sócios',
    url: APP_ROUTES.backoffice.users.managePartners,
    listQuery: listUsersPartnersGql,
    listQuerySelector: 'listUsersPartners',
    deleteMutation: deleteUserGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 75,
      },
      {
        headerName: 'Nrº Sócio',
        field: 'membershipNumber',
        width: 110,
        editable: true,
        type: 'number',
      },
      {
        headerName: 'Nome de utilizador',
        field: 'username',
        flex: 1,
      },
      {
        headerName: 'E-mail',
        field: 'email',
        flex: 1,
      },
      {
        headerName: 'Registado em',
        field: 'createdAt',
        flex: 1,
        valueFormatter: (params) => formatToDateAndHour(params.value?.toString()),
      },
      {
        headerName: 'Último início de sessão',
        field: 'lastLoginAt',
        flex: 1,
        valueFormatter: (params) => formatToDateAndHour(params.value?.toString()),
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_USER,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_USER,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_USER,
    addLabel: 'Criar / Importar utilizadores',
    searchTextFilterKey: 'email',
    searchTextPlaceholder: 'Pesquisar email...',
  };

  return (
    <BackofficeFactory
      features={[
        userFeature,
      ]}

    />
  );
}
