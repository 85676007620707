/* eslint linebreak-style: ["error", "unix"] */
import { Button, makeStyles, Typography } from '@material-ui/core';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { APP_ROUTES } from 'data/app';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCurrentUser } from 'redux/auth.redux';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '100%',
    padding: theme.spacing(0, 2),
  },
  item: {
    margin: theme.spacing(2, 0),

    '& button': {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
}));

export default function ManageAccountMenu() {
  const classes = useStyles();
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);

  return (
    <nav className={classes.container}>
      <ul>
        {renderLink(
          classes,
          APP_ROUTES.manageAccount,
          'Ver perfil',
          location.pathname === APP_ROUTES.manageAccount,
        )}
        {renderLink(
          classes,
          APP_ROUTES.editProfile,
          'Editar perfil',
          location.pathname === APP_ROUTES.editProfile,
        )}
        {renderLink(
          classes,
          APP_ROUTES.changeUsername,
          'Alterar nome de utilizador',
          location.pathname === APP_ROUTES.changeUsername,
        )}
        {renderLink(
          classes,
          APP_ROUTES.changeSubscriptionType,
          'Alterar Categoria',
          location.pathname === APP_ROUTES.changeSubscriptionType,
          (currentUser?.subscription && !currentUser?.subscription?.endDate) || new Date(currentUser!.subscription!.endDate) > new Date(Date.now())
        )}
        {renderLink(
          classes,
          APP_ROUTES.changeEmail,
          'Alterar e-mail',
          location.pathname === APP_ROUTES.changeEmail,
        )}
        {renderLink(
          classes,
          APP_ROUTES.changePassword,
          'Alterar senha',
          location.pathname === APP_ROUTES.changePassword,
        )}
      </ul>
    </nav>
  );
}

const renderLink = (classes: Record<string, any>, to: string, label: string, isActive: boolean, show: boolean = true) => {
  if (show) {
    return (
      <li className={classes.item}>
        <PrimaryLink to={to} underline={false}>
          <Button color={isActive ? 'primary' : 'default'}>
            <Typography variant="button" align="left">
              {label}
            </Typography>
          </Button>
        </PrimaryLink>
      </li>
    )
  }
};
