/* eslint linebreak-style: ["error", "unix"] */
import Typography from '@material-ui/core/Typography';
import MaterialUIBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

export interface BreadcrumbLink {
  displayName?: string | undefined
  to?: string
  component?: React.FC
}

interface Props {
  links: BreadcrumbLink[]
}

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

export default function Breadcrumbs({
  links,
}: Props) {
  const classes = useStyles();

  return (
    <MaterialUIBreadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
      {links.map((link, index) => {
        const { to, displayName, component: Component } = link;

        // Is React component?
        if (Component) {
          return <Component key={index} />;
        }

        // Is hyperlink?
        if (to) {
          return (
            <Link key={index} to={to}>
              {displayName}
            </Link>
          );
        }

        // No link, must be the last breadcrumb
        return (
          <Typography key={index} color="textPrimary">{displayName}</Typography>
        );
      })}
    </MaterialUIBreadcrumbs>
  );
}
