/* eslint linebreak-style: ["error", "unix"] */
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { cleanPendingProductOrdersOlderThan15DaysGql, listProductOrdersGql } from 'data/product-orders';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';

export default function RemoveOldProductOrdersButton() {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(cleanPendingProductOrdersOlderThan15DaysGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Encomendas removidas com sucesso', { variant: 'success' });
    },
    refetchQueries: ['listProductOrders'],
  });

  return (
    <Button onClick={() => mutate({})}>
      Remover encomendas pendentes à mais de 15 dias
    </Button>
  );
}
