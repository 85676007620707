/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const HEIGHT = theme.spacing(0.5);

  return {
    container: {
      display: 'inline-block',
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      outline: 'none',
      width: theme.spacing(3.5),
    },
    bar: {
      width: '100%',
      height: HEIGHT,
      backgroundColor: theme.palette.common.white,
      margin: theme.spacing(1, 0),
      transition: '0.4s',
    },
    bar1: {
      webkitTransform: `rotate(-45deg) translate(${HEIGHT * -2}px, ${HEIGHT * 2}px)`,
      transform: `rotate(-45deg) translate(${HEIGHT * -2}px, ${HEIGHT * 2}px)`,
    },
    bar2: {
      opacity: 0,
    },
    bar3: {
      webkitTransform: `rotate(45deg) translate(${HEIGHT * -2}px, ${HEIGHT * -2}px)`,
      transform: `rotate(45deg) translate(${HEIGHT * -2}px, ${HEIGHT * -2}px)`,
    },
  };
});

interface Props {
  isOpen: boolean;
  setOpen: () => void;
  setClose: () => void;
}

export default function Hamburguer({ isOpen, setOpen, setClose }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={() => (isOpen ? setClose : setOpen)}>
      <div className={`${classes.bar} ${isOpen && classes.bar1}`} />
      <div className={`${classes.bar} ${isOpen && classes.bar2} `} />
      <div className={`${classes.bar} ${isOpen && classes.bar3} `} />
    </div>
  );
}
