/* eslint linebreak-style: ["error", "unix"] */
import React from 'react'
import { useTheme } from '@material-ui/core'

export default function Spacer({
  spacing = 2,
}: { spacing?: number }) {
  const theme = useTheme()
  return (
    <div style={{ marginBottom: theme.spacing(spacing) }} />
  )
}
