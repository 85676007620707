/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { PaymentMethod, PaymentStatus } from './payments';
import { Subscription } from './subscriptions';

export interface SubscriptionPayment {
  id: number
  subscription: Subscription
  reference: string
  entity: string | null
  identifier: string
  phoneNumber: string | null
  paymentStatus: PaymentStatus
  paymentMethod: PaymentMethod
  amount: number
  paidAt: string
}

export interface SubscriptionPriceDetails {
  basePrice: number
  relativePrice: number
  admissionFee: number
  dueAmount: number
  dueYears: number
  finalAmount: number
}

export const subscriptionPaymentMinimumDetailsFragment = gql`
	fragment subscriptionPaymentMinimumDetailsFragment on SubscriptionPaymentsEntity {
    id
    reference
    entity
    identifier
    phoneNumber
    paymentStatus
    paymentMethod
    amount
    paidAt
    subscription {
      id
      startDate
      endDate
      subscriptionPlan {
        name
      }
      user {
        id
        username
        email
        membershipNumber
      }
    }
	}
`;

// Mutations
export const createSubscriptionPaymentGql = gql`
  mutation createSubscriptionPayment($input: ICreateSubscriptionPayment!) {
    createSubscriptionPayment(input: $input) {
			id
		}
  }
`;

export const updateSubscriptionPaymentGql = gql`
  mutation updateSubscriptionPayment($input: IUpdateSubscriptionPayment!) {
    updateSubscriptionPayment(input: $input)
  }
`;

export const requestSubscriptionPaymentGql = gql`
  mutation requestSubscriptionPayment($input: IRequestSubscriptionPayment!) {
    requestSubscriptionPayment(input: $input)
  }
`;

// Queries
export const getSubscriptionPriceDetailsGql = gql`
  query getSubscriptionPriceDetails($subscriptionId: Int!) {
    getSubscriptionPriceDetails(subscriptionId: $subscriptionId) {
      basePrice
      relativePrice
      admissionFee
      dueAmount
      dueYears
      finalAmount
    }
  }
`;

export const listSubscriptionPaymentsGql = gql`
  query listSubscriptionPayments($filter: ISubscriptionPaymentFilter) {
    listSubscriptionPayments(filter: $filter) {
      ...subscriptionPaymentMinimumDetailsFragment
      subscription {
        id
        user {
          id
          username
          membershipNumber
          profile {
            taxpayerNumber
          }
        }
        subscriptionPlan {
          id
          name
        }
      }
    }
  }
  ${subscriptionPaymentMinimumDetailsFragment}
`;

export const getSubscriptionPaymentByIdGql = gql`
  query getSubscriptionPaymentById($id: Int!) {
    getSubscriptionPaymentById(id: $id) {
      ...subscriptionPaymentMinimumDetailsFragment
    }
  }
  ${subscriptionPaymentMinimumDetailsFragment}
`;

export const getOwnSubscriptionPaymentByReferenceGql = gql`
  query getOwnSubscriptionPaymentByReference($input: IGetOwnSubscriptionPaymentByReference!) {
    getOwnSubscriptionPaymentByReference(input: $input) {
      ...subscriptionPaymentMinimumDetailsFragment
    }
  }
  ${subscriptionPaymentMinimumDetailsFragment}
`;
