/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { userFullDetailsFragment } from './users';

// Mutations
export const createUsersGql = gql`
	mutation createUsers($input: ICreateUsers!) {
		createUsers(input: $input)
	}
`;

export const activateUserGql = gql`
	mutation activateUser($input: IActivateUser!) {
		activateUser(input: $input) {
      user {
        ...userFullDetailsFragment
      }
      accessToken
    }
	}
  ${userFullDetailsFragment}
`;
