/* eslint linebreak-style: ["error", "unix"] */
import { useSelector, useDispatch } from 'react-redux';
import { selectCookies, acceptCookies } from 'redux/cookies.redux';
import {
  makeStyles, Typography, Button, Link,
} from '@material-ui/core';
import { APP_ROUTES } from 'data/app';

const useStyles = makeStyles(((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
  },
  card: {
    background: theme.palette.secondary.main,
    height: '2.5rem',

    display: 'flex',
    flexDirection: 'row',

    padding: theme.spacing(1),

    justifyContent: 'space-between',
    alignItems: 'center',

    borderRight: `2px solid ${theme.palette.primary.main}`,
  },
})));

export default function Cookies() {
  const classes = useStyles();
  const cookiesAccepted = useSelector(selectCookies);
  const dispatch = useDispatch();

  if (cookiesAccepted) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.card}>
          <Typography style={{ color: 'white' }} variant="caption">
            Ao utilizar este site concorda com a nossa
            {' '}
            <Link href={APP_ROUTES.privacyPolicy} style={{ color: 'white' }}>Política de Privacidade</Link>

          </Typography>

          <Button onClick={() => dispatch(acceptCookies())} color="primary">
            Ok
          </Button>
        </div>
      </div>
    </>
  );
}
