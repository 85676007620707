/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';

import {
  formatProductOrderPaymentStatus, listProductOrdersGql, ProductOrder, ProductOrderDetails,
} from 'data/product-orders';
import { formatToDateAndHour } from 'utils/formatters';
import RemoveOldProductOrdersButton from 'components/buttons/button-remove-old-product-orders/remove-old-product-orders-button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  removeProductOrders: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
export default function ManageProductOrdersPage() {
  const productOrderFeature: BackofficeFeature = {
    name: 'Encomendas',
    url: APP_ROUTES.backoffice.orders.manageProductOrders,
    listQuery: listProductOrdersGql,
    listQuerySelector: 'listProductOrders',
    gridProps: {
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        },
      },
    },
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 80,
      },
      {
        headerName: 'Cliente',
        field: 'fullName',
        flex: 1,
      },

      {
        headerName: 'Nrº. Contribuinte',
        field: 'taxpayerNumber',
        flex: 1,
      },
      {
        headerName: 'Produtos',
        field: 'product',
        sortable: false,
        flex: 1,
        cellRendererFramework: (params: any) => {
          const productOrderDetails: ProductOrderDetails[] = params.data?.productOrderDetails || [];

          return (
            productOrderDetails.map((x) => x.productVariant.product.name).join(', ')
          );
        },
      },
      {
        headerName: 'Estado',
        field: 'productOrderPayment.paymentStatus',
        flex: 1,
        cellRendererFramework: (params: any) => (
          <p>{formatProductOrderPaymentStatus(params.data as ProductOrder)}</p>
        ),
      },
      {
        headerName: 'Referência EuPago',
        field: 'productOrderPayment.reference',
        flex: 1,
        filter: true,
      },
      {
        headerName: 'Total',
        field: 'orderAmount',
        width: 90,
        cellRendererFramework: (params: any) => (
          <p>
            {params.data?.orderAmount}
            {' '}
            €
          </p>
        ),
      },
      {
        headerName: 'Data',
        field: 'orderedAt',
        flex: 1,
        valueFormatter: (params) => formatToDateAndHour(params.data.orderedAt),
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.READ_PRODUCT_ORDER,
    dateFilterKey: 'showFrom',
    searchTextPlaceholder: 'Nrº contribuinte',
    searchTextFilterKey: 'taxpayerNumber',
  };

  const classes = useStyles();

  return (
    <BackofficeFactory
      features={[
        productOrderFeature,
      ]}
    >
      <div className={classes.removeProductOrders}><RemoveOldProductOrdersButton /></div>
    </BackofficeFactory>
  );
}
