/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import { PaymentStatus } from './payments';
import { ProductOrderPayment } from './product-order-payments';
import { ProductVariant } from './product-variants';
import { User } from './users';

export const formatProductOrderPaymentStatus = (productOrder: ProductOrder): string => {
  if (!productOrder) {
    return '';
  }

  switch (productOrder?.productOrderPayment?.paymentStatus) {
    case PaymentStatus.PAID:
      return 'Pagamento efetuado';
    case PaymentStatus.REJECTED:
      return 'Pagamento rejeitado';
    case PaymentStatus.PENDING:
    default:
      return 'Pagamento pendente';
  }
};

export interface ProductOrder {
  id: number
  user: User
  fullName: string
  phone: string
  email: string
  address: string
  postalCode: string
  locality: string
  orderAmount: number
  taxpayerNumber: number
  observations: string
  productOrderDetails: ProductOrderDetails[]
  productOrderPayment: ProductOrderPayment
  orderedAt: string
}

export interface ProductOrderDetails {
  id: number
  productOrder: ProductOrder
  productVariant: ProductVariant
  quantity: number
}

export const productOrderDetailsFullDetailsFragment = gql`
  fragment productOrderDetailsFullDetailsFragment on ProductOrdersEntity {
    id
    user {
      id
      username
    }
    fullName
    phone
    email
    address
    postalCode    
    taxpayerNumber
    observations
    orderAmount
    productOrderDetails {
      id
      productVariant {
        id
        productImages
        productFilters {
          id
          value
          order
          productFilterCategory {
            id
            filterName
          }
        }
        product {
          id
          name
          subscriberPrice
          price
        }
      }
      quantity
    }
    productOrderPayment {
      id
      reference
      paymentStatus
      paidAt
      amount
      paymentMethod
      identifier
      entity
    }
    orderedAt
  }
`;

export const productOrdersMinimumDetailsFragment = gql`
	fragment productOrdersMinimumDetailsFragment on ProductOrdersEntity {
    id
    user {
      id
      username
    }
    fullName
    phone
    email
    address
    postalCode
    locality
    taxpayerNumber
    observations
    productOrderDetails {
      id
    }
    orderAmount
    orderedAt
	}
`;

// Mutations
export const createProductOrderGql = gql`
  mutation createProductOrder($input: ICreateProductOrder!) {
    createProductOrder(input: $input) {
      id
    }
  }
`;

export const cancelProductOrderGql = gql`
  mutation cancelProductOrder($id: Int!) {
    cancelProductOrder(id: $id)
  }
`;

export const cleanPendingProductOrdersOlderThan15DaysGql = gql`
  mutation cleanPendingProductOrdersOlderThan15Days {
    cleanPendingProductOrdersOlderThan15Days
  }
`;

export const sendEmailNotificationForNonActivePartnersGql = gql`
  mutation sendEmailNotificationForNonActivePartners {
    sendEmailNotificationForNonActivePartners
  }
`;

// Queries
export const getProductOrderByIdGql = gql`
  query getProductOrderById($id: Int!) {
    getProductOrderById(id: $id) {
      ...productOrderDetailsFullDetailsFragment
    }
  }
  ${productOrderDetailsFullDetailsFragment}
`;

export const listProductOrdersGql = gql`
  query listProductOrders($filter: IProductOrderFilter!) {
    listProductOrders(filter: $filter) {
      ...productOrderDetailsFullDetailsFragment
    }
  }
  ${productOrderDetailsFullDetailsFragment}
`;

export const listOwnProductOrdersGql = gql`
  query listOwnProductOrders($filter: IProductOrderFilter) {
    listOwnProductOrders(filter: $filter) {
      productOrderPayment {
        id
        reference
        paymentStatus
        paymentMethod
        paidAt
        entity
        identifier
        amount
      }
      ...productOrdersMinimumDetailsFragment
    }
  }
  ${productOrdersMinimumDetailsFragment}
`;

export const getTransportationFeeGql = gql`
  query getTransportationFee {
    getTransportationFee
  }
`;
