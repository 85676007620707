/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import { getPublicationCategoryByIdGql, PublicationCategory } from 'data/publication-categories';
import BackofficePublicationCategoryForm from 'components/forms/form-publication-category/backoffice-publication-category-form';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';

export default function BackofficeEditPublicationCategoryModal() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<PublicationCategory>(
    ModalQueryParams.EDIT_PUBLICATION_CATEGORY,
    getPublicationCategoryByIdGql,
    'getPublicationCategoryById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <SectionTitle>Editar categoria de publicação</SectionTitle>
        <BackofficePublicationCategoryForm
          data={data}
          onSuccess={() => {
            handleClose();
          }}
        />
      </DataRenderer>
    </Modal>
  );
}
