/* eslint linebreak-style: ["error", "unix"] */
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import Spacer from 'components/spacer/spacer';
import { User } from 'data/users';
import { getSubscribedUntilDateFromSubscriptionPlan } from 'data/subscription-plans';
import { isSubscriber } from 'data/subscriptions';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(2),
  },
  subscriptionActive: {
    color: theme.palette.success.main,
  },
  subscriptionInactive: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  user: User
}

export default function SubscriptionStatusIndicator({ user }: Props) {
  const classes = useStyles();

  const activeSubscription = isSubscriber(user) ? user?.subscription : null;

  const subscribedUntil = activeSubscription
    ? getSubscribedUntilDateFromSubscriptionPlan(activeSubscription.subscriptionPlan).format('DD-MM-YYYY')
    : '-';

  const title = user?.profile?.fullName || user?.username;

  return (
    <div className={classes.root}>
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
      <Spacer spacing={2} />

      <Typography variant="subtitle1" color="primary">
        Nr. Sócio
      </Typography>
      <Typography variant="body1">
        {user.membershipNumber || '-'}
      </Typography>

      <Typography variant="subtitle1" color="primary">
        Categoria
      </Typography>
      <Typography variant="body1">
        {user.allowedSubscriptionPlan.name}
      </Typography>

      <Spacer spacing={2} />

      <Typography variant="subtitle1" color="primary">
        Estado da Quota
      </Typography>

      {activeSubscription
        ? <Typography className={classes.subscriptionActive}>Ativa</Typography>
        : <Typography className={classes.subscriptionInactive}>Inativa</Typography>}

      <Spacer spacing={2} />

      <Typography variant="subtitle1" color="primary">
        Quota ativa até
      </Typography>
      <Typography variant="body1">
        {subscribedUntil}
      </Typography>

      <Spacer spacing={2} />
    </div>
  );
}
