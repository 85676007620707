/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-shadow */
import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SlideDownModal from 'components/modals/modal-slide-down/slide-down-modal';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';

import { appearEffect } from 'styles/animations';
import {
  container, formContainer, emptySection, section, sectionImage, sectionTitle,
} from 'styles/navbar.styles';

const useStyles = makeStyles((theme) => ({
  container,
  emptySection: emptySection(theme),
  section: section(theme),
  sectionImage: sectionImage(theme),
  sectionTitle: sectionTitle(theme),
  formContainer,
  ...appearEffect,
  linkContainer: {
    display: 'flex',
    color: `${theme.palette.common.white} !important`,
    background: 'rgba(0, 0, 0, .9)',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 4),
    alignItems: 'center',
    textDecoration: 'none !important',

    '&:hover': {
      background: theme.palette.primary.main,
      transition: '0.25s ease all',
    },
  },
  link: {
    fontWeight: 500,
  },
}));

interface Props {
  modalQueryParam: string;
  sectionTitle: string;
  backgroundImage: string;
  children: React.ReactNode;
}

export default function NavbarFormModal({
  modalQueryParam, sectionTitle, backgroundImage, children,
}: Props) {
  const classes = useStyles();
  const { isOpen, handleClose } = useModalWithQueryParams(modalQueryParam);

  if (!isOpen) {
    return null;
  }

  return (
    <SlideDownModal
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.sectionImage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundPosition: 'center center' }} />

        <section className={classes.emptySection} />
        <section className={classes.section} style={{ alignItems: 'center' }}>
          <Typography variant="h3" className={classes.sectionTitle}>{sectionTitle}</Typography>

          <div className={classes.formContainer}>
            {children}
          </div>
        </section>
      </div>
    </SlideDownModal>
  );
}
