/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import { appearEffect } from 'styles/animations';

const useStyles = makeStyles(() => ({
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    animation: '$appearEffect 2s',
  },
  ...appearEffect,
  backgroundShadowOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))',
  },
}));

export default function HeroBackground() {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.background}
        style={{ backgroundImage: 'url(/images/landing-page-hero-background.jpg)' }}
      />
      <div className={classes.backgroundShadowOverlay} />
    </>
  );
}
