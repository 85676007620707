/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles,
} from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { ProductOrder } from 'data/product-orders';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    fontWeight: theme.typography.fontWeightBold as number,
  },
}));

interface Props {
  productOrder: ProductOrder
}

export default function BuyerDetails({
  productOrder,
}: Props) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <Typography variant="h6" color="primary">
        Dados pessoais
      </Typography>
      <Divider />
      <Spacer spacing={2} />

      {renderInfo('Nome Completo', productOrder.fullName)}
      {renderInfo('E-Mail', productOrder.email)}
      {renderInfo('Número de Telemóvel', productOrder.phone)}
      {renderInfo('Morada', productOrder.address)}
      {renderInfo('Código Postal', productOrder.postalCode)}
      {renderInfo('Número de Contribuinte', productOrder?.taxpayerNumber ? productOrder?.taxpayerNumber?.toString() : '-')}
      {renderInfo('Observações', productOrder?.observations || '-')}
    </section>
  );
}

const renderInfo = (label: string, value: string) => (
  <>
    <Typography variant="subtitle1" color="primary">
      {label}
    </Typography>
    <Typography variant="subtitle2">
      {value}
    </Typography>
    <Spacer spacing={2} />
  </>
);
