/* eslint linebreak-style: ["error", "unix"] */
import Carousel from 'components/carousels/carousel/carousel';

// Should come from backend
const steps = [
  {
    label: '',
    image: '',
    color: '#e0e0e0'
  },
];

export default function StoreHighlightsCarousel() {
  return (
    <Carousel steps={steps} carouselHeight={60}/>
  );
}
