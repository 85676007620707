/* eslint linebreak-style: ["error", "unix"] */
import {
  Typography, useTheme, makeStyles,
} from '@material-ui/core';
import Date from 'components/date/date';
import { formatToDateAndHour } from 'utils/formatters';
import { Publication } from 'data/publications';
import { APP_ROUTES } from 'data/app';
import Spacer from 'components/spacer/spacer';
import { Link } from 'react-router-dom';
import PublicationPreviewButton from './publication-preview-button';

export const PUBLICATION_IMAGE_SIZES = {
  WIDTH: 220,
  HEIGHT: 150,
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    minHeight: '7.5rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: `calc(100% - ${PUBLICATION_IMAGE_SIZES.WIDTH}px)`,
    textAlign: 'left',
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  newsImageContainer: {
    display: 'flex',
    position: 'relative',
    height: PUBLICATION_IMAGE_SIZES.HEIGHT,
    width: PUBLICATION_IMAGE_SIZES.WIDTH,

    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 255,
    },

    '& .previewButton': {
      display: 'none',
    },

    '&:hover': {
      '& .previewButton': {
        display: 'flex',
        width: '100%',
        height: '100%',
      },
    },
  },
  preview: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'transparent',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.9)',
    },

    '& svg': {
      fill: theme.palette.common.white,
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    transition: '0.5s ease all',
  },
  link: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    width: '100%',
    textDecoration: 'none',

  },
  publicationTitle: {
    margin: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  date: {
    display: 'flex',
    color: theme.palette.common.white,
    textDecoration: 'none',
    opacity: 0.5,
    marginLeft: theme.spacing(0.5),
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  publicationImage: {
    position: 'relative',
    height: PUBLICATION_IMAGE_SIZES.HEIGHT,
    width: PUBLICATION_IMAGE_SIZES.WIDTH,

    objectFit: 'contain',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
}));

interface Props {
  publication: Publication
  baseLocation?: string
}

export default function PublicationPreviewTile({
  publication,
  baseLocation = APP_ROUTES.publication,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const image = encodeURI(`${publication?.image || '/images/anjb-publication-fallback-image.jpeg'}`);
  const link = `${baseLocation}/${publication.id}`;

  return (
    <>
      <article className={classes.container}>
        <div className={classes.newsImageContainer}>
          <img src={image} className={classes.publicationImage} alt={image} />
          <PublicationPreviewButton publicationId={publication.id} />
        </div>

        <div className={classes.content}>
          <Link
            className={classes.link}
            to={link}
          >
            <Typography variant="h6" className={classes.publicationTitle}>
              {publication.name}
            </Typography>
          </Link>

          <Date
            date={formatToDateAndHour(publication.createdAt)}
            color={theme.palette.secondary.main}
          />

          <Spacer spacing={1} />

          <Typography variant="subtitle2" color="textPrimary" paragraph>
            {publication.description}
          </Typography>
        </div>
      </article>
    </>
  );
}
