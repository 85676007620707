/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));
