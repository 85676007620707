/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';

import {
  Typography, Select, MenuItem, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(1),
    },
  },
}));

export interface Filters {
  [filterCategoryName: string]: FilterValue[],
}

interface FilterValue {
  valueId: number,
  valueName: string
}

interface Props {
  filterCategoryName: string
  filterValues: FilterValue[]
  onChange: (filterCategoryName: string, filterValueId: number) => unknown,
}

export default function FilterValueSelect({
  filterCategoryName,
  filterValues,
  onChange,
}: Props) {
  const classes = useStyles();

  const handleChange = (filterValueId: number | unknown) => {
    onChange(filterCategoryName, Number(filterValueId));
  };

  const options = filterValues.map((filterValue) => ({
    name: filterValue.valueName,
    value: filterValue.valueId,
  }));

  return (
    <div className={classes.container}>
      <Typography variant="caption">
        {filterCategoryName}
      </Typography>

      <Select
        onChange={(evt) => handleChange(evt.target.value)}
        className={classes.selectContent}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        variant="standard"
      >
        <MenuItem value="" disabled>
          Escolher
          {' '}
          {filterCategoryName}
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
