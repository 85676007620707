/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormLabel,
  makeStyles, TextField,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { Controller, useForm } from 'react-hook-form';
import {
  arrayRequired, stringRequired,
} from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import SelectGeneric from 'components/selects/select-generic/select-generic';
import {
  createDocumentGql, Document, updateDocumentGql,
} from 'data/documents';
import React from 'react';
import InputDocumentSelection from 'components/inputs/input-document-selection/input-document-selection';
import { listDocumentCategoriesGql } from 'data/document-categories';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  name: stringRequired,
  description: stringRequired,
  bucketName: stringRequired,
  bucketFile: stringRequired,
  documentCategories: arrayRequired,
});

export interface Props {
  data?: Document
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function FormDocument({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, getValues, handleSubmit, watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        description: data?.description,
        bucketName: data?.bucketName,
        bucketFile: data?.bucketFile,
        documentCategories: data?.documentCategories?.map((category) => category.id) || [],
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updateDocumentGql : createDocumentGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing
        ? 'Documento atualizado com sucesso'
        : 'Documento criado com sucesso',
        { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const bucketName = watch('bucketName');

  const bucketFile = watch('bucketFile');

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <SelectGeneric
        name="documentCategories"
        label="Categorias"
        tooltipText=""
        query={listDocumentCategoriesGql}
        querySelector="listDocumentCategories"
        multiple
        control={control}
        errors={errors}
      />
      <Spacer spacing={2} />

      <TextField
        name="name"
        label="Nome do documento"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
      />
      <Spacer spacing={4} />

      <TextField
        name="description"
        label="Descrição"
        type="text"
        inputProps={{
          ref: register,
        }}
        error={Boolean(errors?.description)}
        helperText={errors?.description?.message}
      />
      <Spacer spacing={4} />

      <Controller
        render={() => (
          <>
            <>
              <FormLabel>Documento</FormLabel>
              <Spacer spacing={1} />
              <InputDocumentSelection
                setValue={setValue}
                bucketName={bucketName}
                bucketFile={bucketFile}
              />
            </>
          </>
        )}
        control={control}
        name=""
      />
      <input type="hidden" name="bucketName" ref={register} />
      <input type="hidden" name="bucketFile" ref={register} />
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar documento'}
      </Button>

    </form>
  );
}
