/* eslint linebreak-style: ["error", "unix"] */
import { ModalQueryParams } from 'data/app';
import LoginForm from 'components/forms/form-login/login-form';
import NavbarFormModal from '../modal-navbar-form/navbar-form-modal';

export default function LoginModal() {
  return (
    <NavbarFormModal
      modalQueryParam={ModalQueryParams.SIGN_IN}
      sectionTitle="Iniciar sessão"
      backgroundImage="/images/anjb_login.jpg"
    >
      <LoginForm />
    </NavbarFormModal>
  );
}
