/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useMutation } from '@apollo/client';
import { deleteBucketGql } from 'data/storage';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import ConfirmModal from 'components/modals/modal-confirm/confirm-modal';

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type BucketHeaderProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  refetch: () => void;
};

const useTreeItemStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    // @ts-ignore
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

export default function BucketManagerHeader(props: BucketHeaderProps) {
  const classes = useTreeItemStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    labelText: bucketName, refetch, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other
  } = props;

  const [mutate] = useMutation(deleteBucketGql, {
    variables: {
      input: {
        bucketName,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Bucket removido com sucesso!', { variant: 'success' });
      refetch();
    },
  });

  const handleDelete = () => {
    mutate();
    setModalOpen(false);
  };

  return (
    <>
      <ConfirmModal
        confirmationTitle="Apagar bucket?"
        confirmationText="Esta ação irá remover permanentemente este bucket do Google Cloud, junto com todos os ficheiros associados."
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
        confirm={handleDelete}
      />
      <TreeItem
        label={(
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {bucketName}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>

            <Tooltip title="Remover este bucket e todos os ficheiros associados permanentemente">
              <IconButton onClick={() => setModalOpen(true)}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
        style={{
          // @ts-ignore
          '--tree-view-color': color,
          '--tree-view-bg-color': bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
    </>
  );
}
