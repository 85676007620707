/* eslint linebreak-style: ["error", "unix"] */
import { Divider, makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import SectionStepper from 'components/section-stepper/section-stepper';
import { formatToDateAndHour } from 'utils/formatters';
import { isSubscriber } from 'data/subscriptions';
import TableGrid from 'components/table-grid/table-grid';
import { getModalStyle } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getUserByIdGql, User } from 'data/users';
import { formatPaymentStatus, PaymentStatus } from 'data/payments';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function ModalViewUser() {
  const classes = useStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<User>(
    ModalQueryParams.VIEW_USER,
    getUserByIdGql,
    'getUserById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Typography variant="subtitle1">
          Detalhes do utilizador
          {' '}
        </Typography>
        <Spacer spacing={2} />

        <Typography variant="h4" color="primary">
          {data?.username}
          {' '}
        </Typography>
        <Divider />

        <Spacer spacing={4} />

        {renderInfo('ID', data?.id)}
        {renderInfo('Nome de utilizador', data?.username)}
        {renderInfo('Número de sócio', data?.membershipNumber)}
        {renderInfo('E-Mail', data?.email)}
        {renderInfo('Categoria', data?.allowedSubscriptionPlan?.name)}
        {renderInfo('Último início de sessão a', formatToDateAndHour(data?.lastLoginAt))}
        {renderInfo('Registado a', formatToDateAndHour(data?.createdAt))}
        {renderInfo('Estado da conta', data?.isActive ? 'Ativada' : 'Por ativar')}

        <SectionStepper displayText="Perfil" />
        {renderInfo('Nome completo', data?.profile?.fullName)}
        {renderInfo('Aniversário', data?.profile?.birthdate)}
        {renderInfo('Género', data?.profile?.gender)}
        {renderInfo('Morada', data?.profile?.address)}
        {renderInfo('Localidade', data?.profile?.locality)}
        {renderInfo('Código postal', data?.profile?.postalCode)}
        {renderInfo('País', data?.profile?.country)}
        {renderInfo('Local de nascimento', data?.profile?.placeOfBirth)}
        {renderInfo('Zona', data?.profile?.zone?.name || '')}

        {renderInfo('Nr. Contribuinte', data?.profile?.taxpayerNumber)}

        <SectionStepper displayText="Subscrições" />
        {renderInfo('Sócio ativo?', isSubscriber(data) ? 'Sim' : 'Não')}

        {renderInfo('Data de início da subscrição', formatToDateAndHour(data?.subscription?.startDate))}
        {renderInfo('Data de fim da subscrição', formatToDateAndHour(data?.subscription?.endDate))}

        <Typography color="primary">Pagamentos relativos a subscrições</Typography>
        <Spacer spacing={1} />
        <TableGrid
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          columnDefs={[
            {
              field: 'id',
              headerName: 'ID',
              width: 70,
            },
            {
              field: 'reference',
              headerName: 'Referência',
              flex: 1,
            },
            {
              field: 'paymentMethod',
              headerName: 'Método de pagamento',
              flex: 1,
            },
            {
              field: 'amount',
              headerName: 'Quantia',
              flex: 1,
              valueFormatter: (p) => `${p.value}€`,
            },
            {
              field: 'paymentStatus',
              headerName: 'Estado do Pagamento',
              flex: 1,
              valueFormatter: (p) => formatPaymentStatus(p?.value as PaymentStatus),
            },
            {
              field: 'paidAt',
              headerName: 'Pago a',
              flex: 1,
              valueFormatter: (p) => formatToDateAndHour(p?.value as string),
            },
          ]}
          rowData={data?.subscription?.subscriptionPayments || []}
          height={250}
        />
      </DataRenderer>
    </Modal>
  );
}
