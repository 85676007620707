/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import Spacer from 'components/spacer/spacer';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectSelectedProductCategory } from 'redux/product-search.redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ProductFilterCategory } from 'data/product-filter-categories';
import ProductCategoryFilterValues from './product-category-filter-values';

export default function ProductCategoryFilterCategories() {
  const selectedProductCategory = useSelector(selectSelectedProductCategory);
  const filterCategories = selectedProductCategory?.productFilterCategories || [];

  if (!filterCategories?.length) {
    return null;
  }

  return (
    <>
      {filterCategories?.map((filterCategory, index) => (
        <FilterCategory filterCategory={filterCategory} index={index} />
      ))}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterCategory: {
    display: 'flex',
    flexDirection: 'column',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

interface Props {
  filterCategory: ProductFilterCategory
  index: number
}

const FilterCategory = ({ filterCategory, index }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={classes.filterCategory} key={index}>
      <header className={classes.header}>
        <Typography color="primary">
          {filterCategory.filterName}
        </Typography>

        <IconButton onClick={() => setExpanded((stateExpanded) => !stateExpanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </header>

      {expanded && (
        <>
          <Spacer spacing={2} />

          <div className={classes.filters}>
            {
              filterCategory.filterValues.map((filterValue, index2) => (
                <ProductCategoryFilterValues key={index2} productFilterValue={filterValue} />
              ))
            }
          </div>
        </>
      )}

      <Spacer spacing={2} />
    </div>
  );
};
