/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, updateUser } from 'redux/auth.redux';
import Page from 'pages/_layouts/page';
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { getUserByIdGql, User, UserRole } from 'data/users';
import { Route, Switch, useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'data/app';
import BackofficeMenu from 'components/menus/menu-backoffice/backoffice-menu';

// Icons
import PublicationIcon from '@material-ui/icons/PostAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StorefrontIcon from '@material-ui/icons/Storefront';
import GroupIcon from '@material-ui/icons/Group';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import LocalBarIcon from '@material-ui/icons/LocalActivitySharp';
// Pages
import ManageProductOrdersPage from 'pages/backoffice-page/manage-product-orders-page/manage-product-orders-page';
import ManageUsersPage from './manage-users-page/manage-users-page';
import ManagePublicationsPage from './manage-publications-page/manage-publications-page';
import ManagePagesPage from './manage-pages-page/manage-pages-page';
import ManageNewsletterPage from './manage-newsletter-page/manage-newsletter-page';
import ManageStorePage from './manage-store-page/manage-store-page';
import ManageDocumentsPage from './manage-documents-page/manage-documents-page';
import ManageSubscriptionPlansPage from 'pages/backoffice-page/manage-subscription-plans/manage-subscription-plans';
import { ImportContacts } from '@material-ui/icons';
import ManageSubscriptionsPage from 'pages/backoffice-page/manage-subscriptions/manage-subscriptions-page';
import ManageUsersPartnersPage from './manage-users-partners-page/manage-users-partners-page';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subPage: {
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
    height: '100%',
  },
}));

export default function BackofficePage() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser) as User;
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: rawData, loading, error } = useQuery(getUserByIdGql,
    {
      variables: {
        id: currentUser.id,
      },
      onCompleted: (data) => {
        // Always update user in redux with the new details
        dispatch(updateUser(data?.getUserById));
      },
    });

  const data: User = rawData?.getUserById;

  if (data && data.role !== UserRole.ADMIN) {
    history.push(APP_ROUTES.home);
    return null;
  }

  const routes = APP_ROUTES.backoffice;

  return (
    <>
      <Page fullWidth white>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <section className={classes.root}>
            <BackofficeMenu
              routes={[
                {
                  name: 'Publicações',
                  icon: PublicationIcon,
                  path: routes.publications.managePublications,
                },
                {
                  name: 'Páginas',
                  icon: FileCopyIcon,
                  path: routes.pages.managePages,
                },
                {
                  name: 'Loja',
                  icon: StorefrontIcon,
                  path: routes.store.manageProductVariants,
                },
                {
                  name: 'Encomendas',
                  icon: ShoppingBasketIcon,
                  path: routes.orders.manageProductOrders,
                },
                {
                  name: 'Documentos',
                  icon: AllInboxIcon,
                  path: routes.documents.manageDocuments,
                },
                {
                  name: 'Modalidades',
                  icon: LocalBarIcon,
                  path: routes.subscriptionPlans.manageSubscriptionPlans,
                },
                {
                  name: 'Quotas',
                  icon: ImportContacts,
                  path: routes.subscriptions.manageSubscriptions,
                },
                {
                  name: 'Utilizadores',
                  icon: GroupIcon,
                  path: routes.users.manageUsers,
                },
                {
                  name: 'Sócios',
                  icon: GroupIcon,
                  path: routes.users.managePartners,
                },
                {
                  name: 'Newsletter',
                  icon: MailOutlineIcon,
                  path: routes.newsletter.manageNewsletter,
                },
              ]}
            />

            <section className={classes.subPage}>
              <Switch>
                <Route component={ManageSubscriptionsPage} path={routes.subscriptions.manageSubscribers} />
                <Route component={ManageSubscriptionsPage} path={routes.subscriptions.manageSubscriptions} />
                <Route component={ManageSubscriptionPlansPage} path={routes.subscriptionPlans.manageSubscriptionPlans} />
                <Route component={ManageProductOrdersPage} path={routes.orders.manageProductOrders} />
                <Route component={ManageUsersPage} path={routes.users.manageUsers} />
                <Route component={ManageUsersPartnersPage} path={routes.users.managePartners} />
                <Route component={ManageNewsletterPage} path={routes.newsletter.manageNewsletter} />
                <Route component={ManageDocumentsPage} path={routes.documents.manageDocumentCategories} />
                <Route component={ManageDocumentsPage} path={routes.documents.manageDocuments} />
                <Route component={ManageStorePage} path={routes.store.manageProductCategories} />
                <Route component={ManageStorePage} path={routes.store.manageProductFilters} />
                <Route component={ManageStorePage} path={routes.store.manageProducts} />
                <Route component={ManageStorePage} path={routes.store.manageProductVariants} />
                <Route component={ManagePagesPage} path={routes.pages.managePages} />
                <Route
                  component={ManagePublicationsPage}
                  path={routes.publications.managePublications}
                />
                <Route component={ManagePublicationsPage} path={routes.root} />
              </Switch>
            </section>
          </section>
        </DataRenderer>
      </Page>
    </>
  );
}
