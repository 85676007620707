/* eslint linebreak-style: ["error", "unix"] */

import Hamburguer from 'components/hamburguer/hamburguer';
import { IconButton, makeStyles } from '@material-ui/core';
import useModalWithQueryParams, { useIsModalOpen } from 'hooks/useModalWithQueryParams';
import { ModalQueryParams } from 'data/app';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',

    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
}));

export default function MobileNavbarButton() {
  const classes = useStyles();

  const {
    handleOpen,
    handleClose,
  } = useModalWithQueryParams(ModalQueryParams.NAVBAR_MOBILE_MENU);

  // If any of these modals are active, enable hamburguer
  const { isOpen } = useIsModalOpen([
    ModalQueryParams.NAVBAR_MOBILE_MENU,
    ModalQueryParams.ABOUT,
    ModalQueryParams.SIGN_IN,
    ModalQueryParams.SIGN_UP,
    ModalQueryParams.SIGN_UP_SUCCESS,
    ModalQueryParams.RECOVER_PASSWORD,
    ModalQueryParams.SEARCH,
  ]);

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={() => (isOpen ? handleClose() : handleOpen())}
        edge="start"
      >
        <div className={classes.wrapper}>
          <Hamburguer isOpen={isOpen} setOpen={handleOpen} setClose={handleClose} />
        </div>
      </IconButton>
    </>
  );
}
