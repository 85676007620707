/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable, { Column } from 'material-table';
import { tableIcons } from './icons/icons';
import localization from './data/localization';
import Pagination from './pagination/pagination';
import { DEFAULT_PAGE_SIZES } from './pagination/page-counter/page-counter';
import { handlePageResize } from './pagination/table.helpers';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    '& tbody': {
      outlineOffset: '-1px',

    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
      borderRadius: 0,
      border: 'none',
    },
    '& .MuiTableCell-root': {
      border: 'none',
    },
    '& .MuiToolbar-root': {
      background: '#f2f2f2',

      '& *': {
        color: theme.palette.text.secondary,
      },

      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        opacity: 0.5,
      },
      '& .MuiInput-underline:after': {
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        opacity: 1,
      },
    },

    '& .MuiTableCell-head': {
      background: '#f2f2f2',
      color: theme.palette.text.secondary,
      '& div': {
        color: theme.palette.text.secondary,
      },
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

interface Props {
  columns: Array<Column<any>>
  data: Record<string, any>[]
  limit?: number,
  setLimit?: React.Dispatch<React.SetStateAction<number>>
  offset?: number,
  setOffset?: React.Dispatch<React.SetStateAction<number>>
  currentPage?: number,
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
  isSearching: boolean
  onSelection?: (data: any[], rawData: Record<string, any>) => void;
}

// TODO: PaginatedData should be an array
export default function Table({
  data = [],
  columns,
  currentPage = 0,
  setCurrentPage,
  limit = DEFAULT_PAGE_SIZES[0],
  setLimit,
  offset = 0,
  setOffset,
  isSearching,
  onSelection,
}: Props) {
  const classes = useStyles();
  const tableRef = useRef();

  useEffect(() => {
    handlePageResize(tableRef, data?.length);
  }, [tableRef, data]);

  return (
    <div className={classes.table}>
      <MaterialTable
        columns={columns}
        data={data}
        icons={tableIcons}
        localization={localization}
        tableRef={tableRef}
        options={{
          selection: Boolean(onSelection),

        }}
        onSelectionChange={(data, rowData) => {
          if (!onSelection) return;

          onSelection(data, rowData);
        }}
        components={{
          Toolbar: () => null,
          Pagination: (props) => (isSearching ? null : (
            <Pagination
              data={data}
              setCurrentLimit={setLimit}
              setCurrentOffset={setOffset}
              setCurrentPage={setCurrentPage}
              currentLimit={limit}
              currentPage={currentPage}
              {...props}
            />
          )),
        }}
      />
    </div>
  );
}
