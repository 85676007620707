/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Theme } from '@material-ui/core';

export const getModalStyle = (theme: Theme) => ({
  '& .MuiPaper-root ': {
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
      maxWidth: '50%',
      padding: theme.spacing(2),
    },
  },
});

export const useModalStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
}));
