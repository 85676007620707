/* eslint linebreak-style: ["error", "unix"] */
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { sendEmailNotificationForNonActivePartnersGql } from 'data/product-orders';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';

export default function SendEmailNotificationForNonActivePartners() {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(sendEmailNotificationForNonActivePartnersGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Emails enviados para sócios com quotas em atraso com sucesso', { variant: 'success' });
    },
  });

  return (
    <Button onClick={() => mutate({})}>
      Envio de emails para sócios com quotas em atraso
    </Button>
  );
}
