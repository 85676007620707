/* eslint linebreak-style: ["error", "unix"] */

import {
  Divider, makeStyles, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { selectCurrentUser, updateUser } from 'redux/auth.redux';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'data/users';
import EditProfileForm from 'components/forms/form-edit-profile/edit-profile-form';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '100%',
    padding: theme.spacing(2),

  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& .MuiButton-contained': {
      color: theme.palette.common.white,
      boxShadow: 'none',
    },
  },
  info: {
    padding: theme.spacing(2),
  },
}));

export default function EditProfile() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  return (
    <div className={classes.container}>
      <Typography color="primary" variant="h6">
        Editar Perfil
      </Typography>
      <Divider />
      <Spacer spacing={2} />

      <EditProfileForm
        classes={classes}
        defaultValues={currentUser?.profile}
        onSuccess={(data) => {
          // Update redux
          dispatch(updateUser({
            ...currentUser,
            profile: data,
          } as User));
        }}
      />
    </div>
  );
}
