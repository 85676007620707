/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import SubscriptionPlanForm from 'components/forms/form-subscription-plan/form-subscription-plan';
import { getSubscriptionPlanByIdGql, SubscriptionPlan } from 'data/subscription-plans';

export default function EditSubscriptionPlanModal() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<SubscriptionPlan>(
    ModalQueryParams.EDIT_SUBSCRIPTION_PLAN,
    getSubscriptionPlanByIdGql,
    'getSubscriptionPlanById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <SectionTitle>Editar modalidade</SectionTitle>
        <SubscriptionPlanForm
          data={data}
          onSuccess={handleClose}
        />
      </DataRenderer>
    </Modal>
  );
}
