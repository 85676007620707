/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import BucketManager from 'components/bucket-manager/bucket-manager';
import { BucketFile } from 'data/storage';
import { useModalStyles } from 'styles/modal.styles';

interface Props {
  open: boolean
  onClose: () => void
  onFileSelect: (selectedFile: BucketFile, bucketName?: string) => void;
}

export default function BucketManagerModal({ open, onClose, onFileSelect }: Props) {
  const classes = useModalStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose} closeButtonComponent={CloseButtonWhite} hasCloseButton>
      <BucketManager onFileSelect={onFileSelect} />
    </Modal>
  );
}
