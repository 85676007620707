/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles,
  Link as MaterialUILink,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import { useMutation } from '@apollo/client';
import * as yup from 'yup';
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { confirmPassword, password } from 'utils/validators';
import { resetPasswordGql } from 'data/recover-password';
import { yupResolver } from '@hookform/resolvers/yup';
import useQueryParam from 'hooks/useQueryParam';
import SubmitButton from 'components/buttons/button-submit/submit-button';
import ErrorMessage from 'components/error-message/error-message';
import PasswordField from 'components/inputs/input-password-field/password-field';
import { navbarForm, navbarField } from 'styles/navbar-form-styles';
import { activateUserGql } from 'data/user-bulk-import';
import { useDispatch } from 'react-redux';
import { authLogin } from 'redux/auth.redux';

const useStyles = makeStyles((theme) => ({
  form: navbarForm,
  field: navbarField(theme),
  recoverPasswordSection: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  recoverPasswordLink: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  password: password(),
  passwordConfirmation: confirmPassword,
});

export default function FormDefinePassword() {
  const classes = useStyles();
  const code = useQueryParam().get('code');

  const dispatch = useDispatch();
  const history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit, register, errors,
  } = formApi;

  const [mutate, { data, error, loading }] = useMutation(activateUserGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: (rawData) => {
      // Login user
      const data = rawData?.activateUser;

      // Update redux
      dispatch(authLogin({
        currentUser: data?.user,
        accessToken: data?.accessToken,
      }));

      // Notify user
      enqueueSnackbar(`Bem-vindo, ${data.user.username}`, { variant: 'success' });

      // Go to homepage (or close modal)
      history.push(APP_ROUTES.home);
    },
  });

  const onSubmit = (data: any) => {
    closeSnackbar();

    mutate({
      variables: {
        input: {
          password: data?.password,
          passwordConfirmation: data?.passwordConfirmation,
          code,
        },
      },
    });
  };

  if (!code) {
    return (
      <ErrorMessage errorMessage="Código de definição de acesso não encontrado" />
    );
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <PasswordField name="password" label="Password" register={register} errors={errors} />
      <PasswordField name="passwordConfirmation" label="Confirmar password" register={register} errors={errors} />

      <div className={classes.recoverPasswordSection}>
        <Link to={`${APP_ROUTES.home}?${ModalQueryParams.SIGN_IN}`}>
          <MaterialUILink className={classes.recoverPasswordLink}>
            Iniciar sessão
          </MaterialUILink>
        </Link>
      </div>

      <Spacer spacing={2} />
      <SubmitButton loading={loading} buttonLabel="Continuar" />
    </form>
  );
}
