/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { useForm } from 'react-hook-form';
import { stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import {
  createPublicationCategoryGql, PublicationCategory, updatePublicationCategoryGql,
} from 'data/publication-categories';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  name: stringRequired,
  description: stringRequired,
});

export interface Props {
  data?: PublicationCategory
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function BackofficePublicationCategoryForm({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register, errors, control, setValue, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        description:
          data?.description,

      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updatePublicationCategoryGql : createPublicationCategoryGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar(isEditing ? 'Categoria atualizada com sucesso' : 'Categoria criada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Tooltip title="O nome da categoria">
        <TextField
          name="name"
          label="Nome da categoria"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <Tooltip title="A descrição da categoria.">
        <TextField
          name="description"
          label="Descrição"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.description)}
          helperText={errors?.description?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar categoria'}
      </Button>

    </form>
  );
}
