/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES } from 'data/app';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { Publication } from 'data/publications';
import Breadcrumbs from '../breadcrumb/breadcrumb';

interface Props {
  publication: Publication
}

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.grey[600],
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default function PublicationBreadcrumbs({
  publication,
}: Props) {
  const classes = useStyles();

  if (!publication?.publicationCategories?.length) {
    return null;
  }

  const Categories = () => publication.publicationCategories.map((publicationCategory, index) => (
    <Link key={index} to={`${APP_ROUTES.publicationByCategoryId}/${publicationCategory.id}`} className={classes.link}>
      {publicationCategory.name}
      {' '}
      {index < publication.publicationCategories.length - 1 ? ', ' : ''}
    </Link>
  ));

  const links = [
    {
      displayName: 'Início',
      to: APP_ROUTES.home,
    },
  ]
    .concat([{ component: Categories }] as any)
    .concat([{ displayName: publication?.name }] as any);

  return (
    <Breadcrumbs links={(links)} />
  );
}
