/* eslint linebreak-style: ["error", "unix"] */
export const firebaseDevelopmentConfig = {
  apiKey: 'AIzaSyDuQ6oMmTVsIQ6NqdhzC-YkcmDCVPFH0gU',
  authDomain: 'anjb-client.firebaseapp.com',
  databaseURL: 'https://anjb-client.firebaseio.com',
  projectId: 'anjb-client',
  storageBucket: 'anjb-client.appspot.com',
  messagingSenderId: '218394647373',
  appId: '1:218394647373:web:bf549263f6ba2a136cb504',
  measurementId: 'G-VTG7E3ZWLC',
};

export const firebaseProductionConfig = {
  apiKey: 'AIzaSyBmPiRJeWiCbBkaXlFTQ1WW016lSFFb1k8',
  authDomain: 'anjb-client-b64c2.firebaseapp.com',
  databaseURL: 'https://anjb-client-b64c2.firebaseio.com',
  projectId: 'anjb-client-b64c2',
  storageBucket: 'anjb-client-b64c2.appspot.com',
  messagingSenderId: '344385671817',
  appId: '1:344385671817:web:8e2b413bca5603fbd36c58',
};
