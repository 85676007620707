/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-nested-ternary */

import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Product, getProductPriceBasedOnCurrentUser } from 'data/products';
import { APP_ROUTES } from 'data/app';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import Spacer from 'components/spacer/spacer';
import PrimaryLink from 'components/links/link-primary/primary-link';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    width: 150,
    marginTop: 0,
    transition: '.2s ease all',
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  content: {
    padding: theme.spacing(2),
  },
  media: {
    height: '8rem',
    width: '100%',
  },
  price: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  productName: {
    color: theme.palette.common.white,

    marginRight: theme.spacing(1),
  },
  textContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
}));

interface Props {
  product: Product
}

export default function SearchResultProductTitle({
  product,
}: Props) {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const { id, name } = product;
  const { productVariants = [] } = product;

  const image = productVariants.length
    ? productVariants?.[0]?.productImages?.[0]
    : '';

  return (
    <PrimaryLink to={`${APP_ROUTES.product}/${id}`} underline={false}>
      <article className={classes.tile}>
        <div className={classes.content}>
          <CardMedia
            className={classes.media}
            image={image}
            title={image}
          />
          <Spacer spacing={2} />

          <div className={classes.textContent}>
            <Typography variant="body1" className={classes.productName} noWrap>
              {name}
            </Typography>

            <div className={classes.price}>
              <Typography variant="body1">
                {getProductPriceBasedOnCurrentUser(product, currentUser)}
                €
              </Typography>
            </div>
          </div>
        </div>
      </article>
    </PrimaryLink>
  );
}
