/* eslint linebreak-style: ["error", "unix"] */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * On every route change, forces the page to scroll to the top
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
