/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';
import { ProductCategory } from 'data/product-categories';

export interface SearchFilter {
  name?: string
  productCategories?: number[]
  productFilters?: number[]
  price?: number
  customOrder?: CustomOrder
}

export interface CustomOrder {
  filterProperty: string // 'price', 'created_at'

  filterDirection: 'ASC' | 'DESC'
}

export interface ProductSearchState {
  searchFilters: SearchFilter
  selectedProductCategory: ProductCategory | null | undefined
}

export const SET_FILTERS = 'PRODUCT_SEARCH/SET_FILTERS';

interface SetFiltersAction {
  type: typeof SET_FILTERS,
  payload: SearchFilter
}

export const setProductSearchFilters = (payload: SearchFilter): SetFiltersAction => ({
  type: SET_FILTERS,
  payload,
});

export const CLEAR_FILTERS = 'PRODUCT_SEARCH/CLEAR_FILTERS';

interface ClearFiltersAction {
  type: typeof CLEAR_FILTERS,
}

export const clearProductSearchFilters = (): ClearFiltersAction => ({
  type: CLEAR_FILTERS,
});

export const SET_SELECTED_PRODUCT_CATEGORY = 'PRODUCT_SEARCH/SET_SELECTED_PRODUCT_CATEGORY';

interface SetSelectedProductCategoryAction {
  type: typeof SET_SELECTED_PRODUCT_CATEGORY,
  payload: ProductCategory | null | undefined
}

export const setSelectedProductCategory = (payload: ProductCategory | null | undefined): SetSelectedProductCategoryAction => ({
  type: SET_SELECTED_PRODUCT_CATEGORY,
  payload,
});

export type ProductSearchActions = SetFiltersAction | ClearFiltersAction | SetSelectedProductCategoryAction

export const selectSearchFilters = (
  state: RootState,
): SearchFilter => state.productSearch.searchFilters;

export const selectSelectedProductCategory = (
  state: RootState,
): ProductCategory | null | undefined => state.productSearch.selectedProductCategory;

const initialState: ProductSearchState = {
  searchFilters: {},
  selectedProductCategory: null,
};

export const productSearchReducer = (
  state = initialState,
  action: ProductSearchActions,
): ProductSearchState => {
  switch (action.type) {
    case SET_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        searchFilters: {},
      };
    case SET_SELECTED_PRODUCT_CATEGORY:
      return {
        ...state,
        selectedProductCategory: action.payload,
      };
    default:
      return state;
  }
};
