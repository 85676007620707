/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import PublicationsList from 'components/lists/list-publications/publications-list';
import SectionTitle from 'components/section-title/section-title';
import Page from 'pages/_layouts/page';
import { APP_ROUTES } from 'data/app';
import { listSubscriberPublicationsGql } from 'data/publications';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export default function SubscriberNewsSubpage() {
  const classes = useStyles();

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <SectionTitle>
          Comunicados Oficiais
        </SectionTitle>

        <PublicationsList
          initialOffset={0}
          baseLocation={APP_ROUTES.subscriberArea.newsDetail}
          listQuery={listSubscriberPublicationsGql}
          listQuerySelector="listSubscriberPublications"
        />
      </div>
    </Page>
  );
}
