/* eslint linebreak-style: ["error", "unix"] */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch as MaterialUISwitch,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import {
  createPublicationGql, Publication, updatePublicationGql,
} from 'data/publications';

import { useForm } from 'react-hook-form';
import { arrayRequired, stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import EditorInput from 'components/inputs/input-editor-input/editor-input';
import PublicationImageUpload from 'components/inputs/input-publication-image-upload/publication-image-upload';
import SwitchInput from 'components/inputs/input-switch/switch-input';
import PublicationCategoriesSelect from 'components/selects/select-publication-categories/publication-categories.select';
import PublicationStatusSelect from 'components/selects/select-publication-status/publication-status-select';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from 'data/app';
import { useDispatch } from 'react-redux';
import { setPublicationId } from 'redux/newsletter.redux';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));

const validationSchema = yup.object().shape({
  name: stringRequired,
  description: stringRequired,
  image: stringRequired,
  publicationCategories: arrayRequired,
  content: stringRequired,
  status: stringRequired,
});

export interface Props {
  data?: Publication
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function BackofficePublicationForm({
  data,
  onSuccess,
}: Props) {
  const classes = useStyles();
  const isEditing = Boolean(data);
  const { enqueueSnackbar } = useSnackbar();

  const [sendNewsletter, setSendToNewsletter] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register, errors, control, setValue, handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: isEditing
      ? {
        name: data?.name,
        description: data?.description,
        content: data?.content,
        image: data?.image,
        isHighlighted: data?.isHighlighted,
        subscribersOnly: data?.subscribersOnly,
        status: data?.status,
        publicationCategories: data?.publicationCategories?.map((x) => x.id),
      }
      : {},
  });

  const [mutate] = useMutation(isEditing ? updatePublicationGql : createPublicationGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: (rawData) => {
      enqueueSnackbar(isEditing ? 'Publicação atualizada com sucesso' : 'Publicação criada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }

      if (sendNewsletter) {
        const id = isEditing ? data?.id : rawData?.createPublication?.id;
        dispatch(setPublicationId(id));

        history.push(APP_ROUTES.backoffice.newsletter.manageNewsletter);
      }
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = isEditing
      ? {
        ...formData,
        id: data?.id,
      } : formData;

    mutate({
      variables: {
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Tooltip title="O nome da publicação">
        <TextField
          name="name"
          label="Título"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.name)}
          helperText={errors?.name?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <Tooltip title="A descrição da publicação. Deve conter apenas um parágrafo.">
        <TextField
          name="description"
          label="Descrição"
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.description)}
          helperText={errors?.description?.message}
        />
      </Tooltip>
      <Spacer spacing={4} />

      <PublicationCategoriesSelect
        name="publicationCategories"
        label="Categorias"
        control={control}
        errors={errors}
      />
      <Spacer spacing={4} />

      <PublicationImageUpload name="image" label="Imagem de Destaque" control={control} errors={errors} setValue={setValue} />
      <Spacer spacing={4} />

      <EditorInput
        name="content"
        label="Conteúdo"
        defaultValue={data?.content}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <Spacer spacing={4} />

      <SwitchInput
        name="isHighlighted"
        label="Destacar publicação"
        tooltip="Se este campo estiver marcado, a publicação aparecerá no slideshow de notícias Em Destaque"
        control={control}
        errors={errors}
        defaultValue={data?.isHighlighted}
      />
      <Spacer spacing={4} />

      <SwitchInput
        name="subscribersOnly"
        label="Notícia exclusiva para sócios"
        tooltip="Se este campo estiver marcado, a publicação aparecerá apenas na Área do Sócio e será visível apenas por sócios ativos"
        control={control}
        errors={errors}
        defaultValue={data?.subscribersOnly}
      />
      <Spacer spacing={4} />

      <PublicationStatusSelect
        name="status"
        label="Estado da publicação"
        control={control}
        errors={errors}
        defaultValue={data?.status}
      />
      <Spacer spacing={4} />

      <div>
        <FormLabel component="legend">Enviar notícia como newsletter</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={(
              <MaterialUISwitch
                defaultChecked={sendNewsletter}
                checked={sendNewsletter}
                onChange={(evt) => setSendToNewsletter(evt.target.checked)}
                color="primary"
              />
            )}
            label=""
          />
        </FormGroup>
      </div>
      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary">
        {isEditing ? 'Guardar Alterações' : 'Criar publicação'}
      </Button>

    </form>
  );
}
