/* eslint linebreak-style: ["error", "unix"] */
import React, { useRef, useState } from 'react';
import MUIRichTextEditor, { TMUIRichTextEditorProps, TMUIRichTextEditorRef } from 'mui-rte';
import { convertToRaw } from 'draft-js';
import { FormHelperText, InputLabel, makeStyles } from '@material-ui/core';
import { isImage } from 'data/storage';
import { useSnackbar } from 'notistack';
import ImageIcon from '@material-ui/icons/Image';
import { Control, Controller, SetValueConfig } from 'react-hook-form';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';

interface EditorProps extends TMUIRichTextEditorProps {
  label: string
  name: string
  errors: Record<string, any>
  control: Control<any>
  setValue: (name: string, value: any, options?: SetValueConfig) => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },

    '& #mui-rte-container': {
      border: `1px solid ${theme.palette.divider}`,
    },

    '& #mui-rte-editor': {
      padding: theme.spacing(2),
    },

    '& #mui-rte-toolbar': {
      borderBottom: `1px dotted ${theme.palette.divider}`,
    },
  },
}));

const handleImageSelection = async (ref: any, imagePublicUri: string) => {
  ref.current?.insertAtomicBlockAsync(
    'IMAGE',
    new Promise<any>((resolve, reject) => {
      resolve({
        data: {
          url: imagePublicUri,
          alignment: 'center', // or "center", "right"
          type: 'image', // or "video"
        },
      });
    }),
    '',
  );
};

export default function EditorInput({
  name,
  control,
  errors,
  label,
  defaultValue,
  setValue,
  ...rest
}: EditorProps) {
  const [bucketManagerOpen, setBucketManagerOpen] = useState(false);
  const ref = useRef<TMUIRichTextEditorRef>(null);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <BucketManagerModal
        open={bucketManagerOpen}
        onClose={() => setBucketManagerOpen(false)}
        onFileSelect={(selectedBucketFile) => {
          if (!isImage(selectedBucketFile.publicUrl)) {
            enqueueSnackbar('O ficheiro selecionado dever ser uma imagem', { variant: 'error' });
            return;
          }

          handleImageSelection(ref, selectedBucketFile.publicUrl);
          setBucketManagerOpen(false);
        }}
      />
      <div className={classes.wrapper}>
        <InputLabel>
          {label}
        </InputLabel>

        <Controller
          render={({ onChange, value }) => (
            <>
              <MUIRichTextEditor
                controls={[
                  'title',
                  'bold',
                  'italic',
                  'underline',
                  'link',
                  'numberList',
                  'bulletList',
                  'quote',
                  'code',
                  'strikethrough',
                  'undo',
                  'show-image-uploads',
                ]}
                defaultValue={defaultValue}
                onSave={() => { }}
                onChange={(state) => {
                  const currentContent = state.getCurrentContent();
                  const rawData = convertToRaw(currentContent);

                  const content = JSON.stringify(rawData);
                  setValue(name, content);
                }}
                inlineToolbar={false}
                ref={ref}
                customControls={[
                  {
                    name: 'show-image-uploads',
                    icon: <ImageIcon />,
                    type: 'callback',
                    onClick: (_editorState: any, _name: any, anchor: any) => {
                      setBucketManagerOpen(true);
                    },
                  },
                ]}
                {...rest}
              />

              <FormHelperText>
                {errors?.[name]?.message}
              </FormHelperText>
            </>
          )}
          name={name}
          control={control}
        />
        {' '}
      </div>
    </>
  );
}
