/* eslint linebreak-style: ["error", "unix"] */
import { useQuery } from '@apollo/client';
import { getTransportationFeeGql } from 'data/product-orders';

export default function useGetTransportationFee() {
  const { data: rawData, loading, error } = useQuery(getTransportationFeeGql);
  const transportationFee = rawData?.getTransportationFee;

  return {
      transportationFee,
    loading,
    error,
  };
}
