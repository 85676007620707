/* eslint linebreak-style: ["error", "unix"] */

import { Typography, makeStyles } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import Avatar from './avatar';
import { Member } from './data';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },
}));

interface Props {
  title: string
  members: Member[]
}

export default function Team({
  title, members,
}: Props) {
  const classes = useStyles();

  return (
    <section>
      <Typography variant="h6">
        {title}
      </Typography>

      <Divider />

      <div className={classes.cardWrapper}>
        {members.map((member, index) => <Avatar member={member} key={index} />)}
      </div>

      <Spacer spacing={2} />
    </section>
  );
}

const Divider = () => (
  <div
    style={{
      height: '1px', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)', margin: '.5rem 0',
    }}
  />
);
