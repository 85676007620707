/* eslint linebreak-style: ["error", "unix"] */

import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { extractIdFromLocation } from 'libs/router/router.utils';
import Page from 'pages/_layouts/page';
import Publication from 'components/publication/publication';
import { useQuery } from '@apollo/client';
import { getPublicationByIdGql, Publication as IPublication } from 'data/publications';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import Breadcrumbs from 'components/breadcrumbs/breadcrumb/breadcrumb';
import { APP_ROUTES } from 'data/app';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    padding: theme.spacing(2),
  },
  publicationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function SubscriberNewsDetailsSubpage() {
  const classes = useStyles();

  const location = useLocation();
  const publicationId = extractIdFromLocation(location.pathname) as number;

  const { data: rawData, loading, error } = useQuery(getPublicationByIdGql,
    {
      variables: {
        id: publicationId,
      },
    });

  const data: IPublication = rawData?.getPublicationById;

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <Breadcrumbs
            links={
              [
                {
                  displayName: 'Comunicados Oficiais',
                  to: APP_ROUTES.subscriberArea.root,
                },
                {
                  displayName: data?.name,
                },
              ]
            }
          />

          <div className={classes.publicationContainer}>
            <Publication publication={data} />
          </div>
        </DataRenderer>
      </div>
    </Page>
  );
}
