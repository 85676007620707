/* eslint linebreak-style: ["error", "unix"] */
import { ModalQueryParams } from 'data/app';
import Spacer from 'components/spacer/spacer';
import useQueryParam from 'hooks/useQueryParam';
import ResetPasswordForm from 'components/forms/form-reset-password/reset-password-form';
import NavbarFormModal from '../modal-navbar-form/navbar-form-modal';

export default function ResetPasswordModal() {
  const code = useQueryParam().get('code');
  return (
    <NavbarFormModal
      modalQueryParam={ModalQueryParams.RESET_PASSWORD(code)}
      sectionTitle="Redifinir senha"
      backgroundImage="/images/anjb_recover_password.jpg"
    >
      <Spacer spacing={2} />

      <ResetPasswordForm />
    </NavbarFormModal>
  );
}
