/* eslint linebreak-style: ["error", "unix"] */
import React from 'react';
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import { listNewslettersGql } from 'data/newsletter';
import { formatToDateAndHour } from 'utils/formatters';
import {
  selectSelectedMailingList, setSelectedMailingList, selectedPublicationId, setPublicationId,
} from 'redux/newsletter.redux';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';
import { getPublicationByIdGql, Publication } from 'data/publications';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { Alert } from '@material-ui/lab';
import {
  Button, makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiAlert-message': {
      width: '100%',
    },

    '& svg': {
      alignSelf: 'center',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function ManageNewsletterPage() {
  const mailingList = useSelector(selectSelectedMailingList);
  const dispatch = useDispatch();
  const classes = useStyles();

  const newsletterFeature: BackofficeFeature = {
    name: 'Newsletter',
    url: APP_ROUTES.backoffice.newsletter.manageNewsletter,
    listQuery: listNewslettersGql,
    listQuerySelector: 'listNewsletters',
    columns: [
      {
        field: 'checkbox',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        flex: 0.5,
      },
      {
        field: 'email',
        headerName: 'E-mail',
        flex: 2,
      },
      {
        field: 'subscribedAt',
        headerName: 'Subscrito a',
        valueFormatter: (v) => v?.value && typeof v?.value === 'string' && formatToDateAndHour(v.value),
        flex: 1,
      },
      {
        field: 'subscribedOnSignup',
        headerName: 'Categoria',
        valueFormatter: (v) => (v.value ? 'Sócio' : 'Assinante'),
        flex: 1,
      },
    ],
    ADD_QUERY_PARAM: ModalQueryParams.SEND_NEWSLETTER,

    searchTextFilterKey: 'email',
    dateFilterKey: 'showFrom',

    addLabel: 'Enviar newsletter',
    addDisabled: mailingList?.length <= 0,

    gridProps: {
      rowSelection: 'multiple',
      onSelectionChanged: (event) => {
        const selectedRows = event?.api?.getSelectedRows();

        const selectedEmails = selectedRows?.map((row) => row.email);

        dispatch(setSelectedMailingList(selectedEmails));
      },
    },
  };

  const publicationId = useSelector(selectedPublicationId);
  const { data: rawData, loading, error } = useQuery(getPublicationByIdGql, {
    variables: {
      id: publicationId,
    },
    skip: !publicationId,
  });

  const data: Publication = rawData?.getPublicationById;

  return (
    <BackofficeFactory
      features={[
        newsletterFeature,
      ]}
    >
      {publicationId && (
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <Alert severity="info" className={classes.root}>
            <div className={classes.container}>
              <div>
                A reencaminhar a publicação
                {' '}
                <strong>{data?.name}</strong>
                .
              </div>

              <Button onClick={() => {
                dispatch(setPublicationId(null));
              }}
              >
                Cancelar
              </Button>
            </div>
          </Alert>
        </DataRenderer>
      )}
    </BackofficeFactory>
  );
}
