/* eslint linebreak-style: ["error", "unix"] */
import { gql } from "@apollo/client";

export const verifyEmailGql = gql`
  mutation verifyEmail($code: String!) {
    verifyEmail(code: $code)
  }
`

export const requestNewEmailVerificationGql = gql`
  mutation requestNewEmailVerification($input: IRequestNewEmailVerification!) {
    requestNewEmailVerification(input: $input)
  }
`
