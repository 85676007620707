/* eslint linebreak-style: ["error", "unix"] */

import { useQuery } from '@apollo/client';
import {
  makeStyles, MenuItem, Select,
} from '@material-ui/core';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { listProductCategoriesGql, ProductCategory } from 'data/product-categories';

const useStyles = makeStyles((theme) => ({
  container: {
  },
  select: {
    '&.MuiSelect-select': {
      padding: theme.spacing(2),
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
  },
}));

interface Props {
  /** Product category id */
  value: string | number

  onChange: (selection: ProductCategory | undefined) => void
}

export default function ProductCategorySelect({
  value = '',
  onChange,
}: Props) {
  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(listProductCategoriesGql, {
    variables: {},
  });

  const data: ProductCategory[] = rawData?.listProductCategories || [];
  const options = data?.map((productCategory) => ({
    name: productCategory.name,
    value: productCategory.id,
  }));

  const handleChange = ({ target: { value } }: any) => {
    const payload = data?.find((productCat) => productCat.id === value);

    onChange(payload);
  };

  return (
    <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
      <Select
        value={value}
        onChange={handleChange}
        className={classes.select}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        variant="standard"
      >
        <MenuItem value="" disabled>
          Selecionar tipo de produto
        </MenuItem>
        {options.map(
          (option, index) => (
            <MenuItem
              key={index}
              value={option.value}
            >
              {option.name}
            </MenuItem>
          ),
        )}
      </Select>
    </DataRenderer>
  );
}
