/* eslint linebreak-style: ["error", "unix"] */
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authReducer } from 'redux/auth.redux';
import { cookiesReducer } from 'redux/cookies.redux';
import { productSearchReducer } from 'redux/product-search.redux';
import { shoppingCartReducer } from 'redux/shopping-cart.redux';
import { productVariantsReducer } from 'redux/product-variants.redux';
import { newsletterReducer } from 'redux/newsletter.redux';

export const rootReducer = combineReducers({
	auth: persistReducer(
		{
			key: 'auth',
			whitelist: ['accessToken', 'currentUser'],
			storage,
		},
		authReducer,
	),
	cookies: persistReducer(
		{
			key: 'cookies',
			whitelist: ['accepted'],
			storage,
		},
		cookiesReducer,
	),
	productSearch: persistReducer(
		{
			key: 'productSearch',
			whitelist: [''],
			storage,
		},
		productSearchReducer,
	),
	productVariants: persistReducer(
		{
			key: 'productVariants',
			whitelist: ['productVariantQuantities'],
			storage,
		},
		productVariantsReducer,
	),
	shoppingCart: persistReducer(
		{
			key: 'shoppingCart',
			whitelist: ['shoppingItems'],
			storage,
		},
		shoppingCartReducer,
	),
	newsletter: newsletterReducer,
	// https://github.com/reduxjs/redux/issues/580#issuecomment-133188511
	lastAction: function lastAction(state = null, action) {
		return action;
	},
});

export type RootState = ReturnType<typeof rootReducer>
export const store = createStore(rootReducer, undefined, composeWithDevTools());
export const persistor = persistStore(store);
