/* eslint linebreak-style: ["error", "unix"] */

import ErrorMessage from 'components/error-message/error-message';
import { CircularProgress, makeStyles } from '@material-ui/core';

interface Props {
  error?: string
  loading: boolean
  children: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 10,
  }
}))

/**
 * Handles loading and error cases, if any, otherwise loads children
 */
export default function DataRenderer({ error, loading, children }: Props) {
  const classes = useStyles()

  if (loading) {
    return (
      <div className={classes.spinnerContainer}>
        <CircularProgress color="primary" />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage errorMessage={error} />;
  }

  return (
    <>
      {children}
    </>
  );
}
