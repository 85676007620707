/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles } from '@material-ui/core';
import { APP_ROUTES } from 'data/app';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    maxWidth: '5.5rem',
    height: '100%',
  },
  image: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}));

export default function Logo() {
  const classes = useStyles();

  return (
    <Link to={APP_ROUTES.home}>
      <div className={classes.wrapper}>
        <img className={classes.image} src="/images/anjb-navbar-logo.png" alt="A.N.J.B. Logo" draggable={false} />
      </div>
    </Link>
  );
}
