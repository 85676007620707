/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { slideInEffect } from 'styles/animations';
import { getHeight } from 'components/navbars/navbar/navbar';
import HeroBackground from './hero-background/hero-background';
import HeroContent from './hero-content/hero-content';
import PlayButton from './play-button/play-button';
import HeroVideo from './hero-video/hero-video';

export const TEST_IDS = { heroVideo: 'heroVideo', playButton: 'playButton' };

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    height: `calc(100vh - ${getHeight()}px)`,
    width: '100%',
    background: theme.palette.secondary.main,
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: -theme.spacing(8),

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
      flexDirection: 'column',
    },

    animation: '$slideInEffect 2s',
  },
  ...slideInEffect,
}));

export default function LandingPageHero() {
  const classes = useStyles();
  const [playing, setPlaying] = useState(false);

  return (
    <section className={classes.container}>
      {
        playing
          ? (
            <HeroVideo onClose={() => setPlaying(false)} />
          )
          : (
            <>
              <HeroBackground />
              <div className={classes.content}>
                <HeroContent />
                <PlayButton onClick={() => setPlaying(true)} />
              </div>
            </>
          )
      }
    </section>
  );
}
