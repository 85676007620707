/* eslint linebreak-style: ["error", "unix"] */
import {
  Button, makeStyles, Typography,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline';
import { addItemToShoppingCart, removeItemFromShoppingCart, selectShoppingItems } from 'redux/shopping-cart.redux';
import CheckoutIcon from '@material-ui/icons/ShoppingCart';
import { APP_ROUTES } from 'data/app';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { useSnackbar } from 'notistack';
import Spacer from 'components/spacer/spacer';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'column',
  },
  addToCartButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '&.MuiButton-root': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: 0,
    },

    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
  removeFromCartButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '&.MuiButton-root': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      borderRadius: 0,
    },

    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
  checkoutButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',

    '&.MuiButton-root': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      borderRadius: 0,
    },

    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  productVariantId: number
}

export default function ShoppingCartButtons({ productVariantId }: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const currentShoppingItems = useSelector(selectShoppingItems);
  const hasProductVariantInShoppingCart = Boolean(
    currentShoppingItems.find((shoppingItem) => shoppingItem.productVariantId === productVariantId),
  );

  const cartIsEmpty = !currentShoppingItems?.length;

  const handleAddProductToCart = () => {
    dispatch(addItemToShoppingCart({ productVariantId }));

    enqueueSnackbar('Produto adicionado ao carrinho', {
      variant: 'success',
    });
  };

  const handleRemoveProductFromCart = () => {
    dispatch(removeItemFromShoppingCart({
      productVariantId,
    }));

    enqueueSnackbar('Produto removido do carrinho', {
      variant: 'info',
    });
  };

  return (
    <div className={classes.buttons}>
      {!hasProductVariantInShoppingCart
        ? (
          <Button className={classes.addToCartButton} onClick={handleAddProductToCart}>
            <Typography variant="button">
              Adicionar ao carrinho
            </Typography>
            {' '}
            <AddIcon />
          </Button>
        ) : (
          <Button className={classes.removeFromCartButton} onClick={handleRemoveProductFromCart}>
            <Typography variant="button">Remover do carrinho</Typography>
            {' '}
            <RemoveIcon />
          </Button>
        )}
      <Spacer spacing={1} />
      {
        cartIsEmpty
          ? (
            <Button className={classes.checkoutButton} color="primary" variant="outlined" disabled>
              Fazer checkout
              {' '}
              <CheckoutIcon />
            </Button>
          )
          : (
            <PrimaryLink to={APP_ROUTES.shoppingCart} underline={false}>
              <Button className={classes.checkoutButton} color="primary" variant="outlined">
                Fazer checkout
                {' '}
                <CheckoutIcon />
              </Button>
            </PrimaryLink>
          )
      }

    </div>
  );
}
