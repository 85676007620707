/* eslint linebreak-style: ["error", "unix"] */

import Page from 'pages/_layouts/page';
import PublicationBreadcrumbs from 'components/breadcrumbs/breadcrumbs-publications/publication-breadcrumbs';
import Publication from 'components/publication/publication';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useQuery } from '@apollo/client';
import { getPublicationByIdGql, Publication as IPublication } from 'data/publications';
import { extractIdFromLocation } from 'libs/router/router.utils';
import Spacer from 'components/spacer/spacer';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import { UserRole } from 'data/users';
import { Fab, makeStyles } from '@material-ui/core';
import { ModalQueryParams } from 'data/app';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  editButton: {
    display: 'flex',
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),

    '& svg': {
      fill: theme.palette.common.white,
    },
  },
}));

export default function PublicationPage() {
  const classes = useStyles();
  const publicationId = extractIdFromLocation(location.pathname) as number;
  const isAdmin = useSelector(selectCurrentUser)?.role === UserRole.ADMIN;
  const history = useHistory();

  const handleEdit = () => {
    history.push(`${history.location.pathname}?${ModalQueryParams.EDIT_PUBLICATION(publicationId)}`);
  };

  const { data: rawData, loading, error } = useQuery(getPublicationByIdGql,
    {
      variables: {
        id: publicationId,
      },
    });

  const data: IPublication = rawData?.getPublicationById;

  return (
    <Page>
      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        {isAdmin && (
          <Fab onClick={handleEdit} color="primary" className={classes.editButton}>
            <EditIcon />
          </Fab>
        )}
        <PublicationBreadcrumbs publication={data as any} />
        <Publication publication={data} />
        <Spacer spacing={8} />
      </DataRenderer>
    </Page>
  );
}
