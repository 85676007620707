/* eslint linebreak-style: ["error", "unix"] */
import {
  Fade, makeStyles,
} from '@material-ui/core';
import Breadcrumbs, { BreadcrumbLink } from 'components/breadcrumbs/breadcrumb/breadcrumb';

const useStyles = (fullWidth: boolean, white: boolean) => makeStyles((theme) => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
    background: white ? theme.palette.common.white : 'none',
  },
  container: {
    margin: fullWidth ? theme.spacing(0) : theme.spacing(4, 8),
    display: 'flex',
    flexDirection: 'column',
    width: fullWidth ? '100%' : '50%',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      width: '100%',
      padding: fullWidth ? theme.spacing(0) : theme.spacing(2),
    },
  },
}));

interface Props {
  children: React.ReactNode,
  breadcrumbs?: BreadcrumbLink[]
  fullWidth?: boolean
  white?: boolean
}

export default function Page({
  children, breadcrumbs = [], fullWidth = false, white = false,
}: Props) {
  const classes = useStyles(fullWidth, white)();

  return (
    <Fade in>
      <section className={classes.page}>
        <div className={classes.container}>
          {breadcrumbs?.length > 0 && <Breadcrumbs links={breadcrumbs} />}
          {children}
        </div>
      </section>
    </Fade>
  );
}
