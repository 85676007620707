/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { FeedSearchFilters } from 'components/landing-page-publications-feed/landing-page-publications-feed';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    '& .MuiFormControl-root': {
      width: '100%',
    },

    '& .MuiInput-formControl': {
      minWidth: 150,
    },

    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
}));

export interface MultiSelectFilterOption {
  label: string
  value: string | number
}

interface Props {
  label: string
  searchKey: string
  searchFilters: FeedSearchFilters
  options?: Array<MultiSelectFilterOption>
  onSelectionChanged?: (selection: MultiSelectFilterOption[]) => void
  onApplyFilter?: (selection: MultiSelectFilterOption[]) => void
  name: string
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelectionFilter({
  options = [],
  onSelectionChanged,
  onApplyFilter,
  searchFilters = {},
  searchKey,
  label,
  name,
}: Props) {
  const classes = useStyles();

  const [selected, setSelected] = useState<MultiSelectFilterOption[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: MultiSelectFilterOption[] }>) => {
    const { value } = event.target;
    setSelected(value);

    if (onSelectionChanged) {
      onSelectionChanged(event.target.value);
    }
  };

  const handleClose = () => {
    if (onApplyFilter) {
      onApplyFilter(selected);
    }
  };

  // TODO: Improve
  useEffect(() => {
    // @ts-ignore
    const filter = searchFilters[searchKey] || [];
    const values = options?.filter((x) => filter.includes(x.value)) || [];

    setSelected(values);
  }, [searchFilters, options]);

  return (
    <div className={classes.root}>
      <FormControl>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          id={label}
          name={name}
          value={selected}
          // @ts-ignore
          onChange={handleChange}
          multiple
          renderValue={() => {
            const labels = selected.map((x: MultiSelectFilterOption) => x.label);

            return (labels as string[]).join(', ');
          }}
          MenuProps={MenuProps}
          onClose={handleClose}
        >
          {options.map((option, index) => {
            const isSelected = selected.find((x) => x.value === option.value);
            const { label: optionLabel, value } = option;

            return (
              <MenuItem key={optionLabel} value={option as any}>
                <Checkbox checked={!!isSelected} />
                <ListItemText primary={optionLabel} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
