/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';

export interface NewsletterState {
  selectedMailingList: string[] | null

  publicationId: number | null
}

export const SET_SELECTED_MAILING_LIST = '@NEWSLETTER/SET_SELECTED_MAILING_LIST';

interface SetSelectedMailingListAction {
  type: typeof SET_SELECTED_MAILING_LIST,
  payload: string[] | null
}

export const setSelectedMailingList = (payload: string[] | null): SetSelectedMailingListAction => ({
  type: SET_SELECTED_MAILING_LIST,
  payload,
});

export const SET_PUBLICATION_ID = '@NEWSLETTER/SET_PUBLICATION_ID';

interface SetSelectedPublicationIdAction {
  type: typeof SET_PUBLICATION_ID,
  payload: number | null
}

export const setPublicationId = (payload: number | null): SetSelectedPublicationIdAction => ({
  type: SET_PUBLICATION_ID,
  payload,
});

export type NewsletterActions = SetSelectedMailingListAction | SetSelectedPublicationIdAction

export const selectSelectedMailingList = (
  state: RootState,
) => state.newsletter.selectedMailingList || [];

export const selectedPublicationId = (
  state: RootState,
) => state.newsletter.publicationId;

const initialState: NewsletterState = {
  selectedMailingList: [],

  publicationId: null,
};

export const newsletterReducer = (
  state = initialState,
  action: NewsletterActions,
): NewsletterState => {
  switch (action.type) {
    case SET_SELECTED_MAILING_LIST:
      return {
        ...state,
        selectedMailingList: action.payload,
      };
    case SET_PUBLICATION_ID:
      return {
        ...state,
        publicationId: action.payload,
      };
    default:
      return state;
  }
};
