/* eslint linebreak-style: ["error", "unix"] */
import React, { useRef, useState } from 'react';
import MUIRichTextEditor, { TMUIRichTextEditorProps, TMUIRichTextEditorRef } from 'mui-rte';
import {
  convertToRaw, RawDraftContentState,
} from 'draft-js';
import {
  FormLabel,
  IconButton, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import { isImage } from 'data/storage';
import { useSnackbar } from 'notistack';
import ImageIcon from '@material-ui/icons/Image';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';
import {
  mjmlBold,
  mjmlHeader, mjmlImage, mjmlRoot, mjmlText,
} from 'utils/mjml';
import { useMutation } from '@apollo/client';
import { processEmailGql } from 'data/newsletter';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import Spacer from 'components/spacer/spacer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface EditorProps extends TMUIRichTextEditorProps {
  content: string;
  attachments: {
    bucketName: string,
    fileName: string,
  }[];
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setAttachments: React.Dispatch<React.SetStateAction<{
    bucketName: string,
    fileName: string,
  }[]>>;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },

    '& #mui-rte-container': {
      margin: 0,
      border: `1px solid ${theme.palette.divider}`,
    },

    '& #mui-rte-editor': {
      padding: theme.spacing(2),
    },

    '& #mui-rte-toolbar': {
      borderBottom: `1px dotted ${theme.palette.divider}`,
    },
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.divider}`,
  },
  previewContent: {
    width: '100%',
  },
  filesAttachedSection: {
    marginTop: theme.spacing(2),
  },
  filesList: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
  },
  file: {

  },
}));

export default function MJMLEditorInput({
  content,
  setContent,
  attachments,
  setAttachments,
  defaultValue,
  ...rest
}: EditorProps) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const ref = useRef<TMUIRichTextEditorRef>(null);

  const [fileMode, setFileMode] = useState<'img' | 'link' | 'private_link'>('img');
  const [bucketManagerOpen, setBucketManagerOpen] = useState(false);

  const [mutate, { data: rawData }] = useMutation(processEmailGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
  });
  const data = rawData?.processEmail;

  return (
    <>
      <BucketManagerModal
        open={bucketManagerOpen}
        onClose={() => setBucketManagerOpen(false)}
        onFileSelect={async (selectedBucketFile, bucketName = '') => {
          if (fileMode === 'img') {
            if (!isImage(selectedBucketFile.publicUrl)) {
              enqueueSnackbar('O ficheiro selecionado dever ser uma imagem', { variant: 'error' });
              return;
            }

            // Upload image
            ref.current?.insertAtomicBlockAsync(
              'IMAGE',
              new Promise((resolve, reject) => {
                resolve({
                  data: {
                    url: selectedBucketFile.publicUrl,
                    alignment: 'center', // or "center", "right"
                    type: 'image', // or "video"
                  },
                });
              }),
              '',
            );

            setBucketManagerOpen(false);
            return;
          }

          const payload = attachments?.slice() || [];
          const indexOf = payload.findIndex((entry) => entry.fileName === selectedBucketFile.fileName);
          if (indexOf !== -1) {
            return;
          }

          payload.push({
            bucketName,
            fileName: selectedBucketFile.fileName,
          });

          setAttachments(payload);
        }}
      />
      <div className={classes.wrapper}>
        <MUIRichTextEditor
          defaultValue={defaultValue}
          controls={[
            'title',
            'show-image-uploads',
            'custom-link',
            'private-link',
            'link',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'undo',
            'preview',
          ]}
          onSave={() => { }}
          onChange={(state) => {
            const currentContent = state.getCurrentContent();
            const rawData = convertToRaw(currentContent);

            const content = convertToMjml(rawData);
            setContent(content);
          }}
          inlineToolbar={false}
          ref={ref}
          customControls={[
            {
              name: 'show-image-uploads',
              icon: <Tooltip title="Inserir imagem"><ImageIcon /></Tooltip>,
              type: 'callback',
              onClick: () => {
                setFileMode('img');
                setBucketManagerOpen(true);
              },
            },
            {
              name: 'custom-link',
              icon: <Tooltip title="Inserir anexo"><AttachFileIcon /></Tooltip>,
              type: 'callback',
              onClick: () => {
                setFileMode('link');
                setBucketManagerOpen(true);
              },
            },
            {
              name: 'preview',
              icon: <Tooltip title="Pré-visualizar newsletter"><VisibilityIcon /></Tooltip>,
              type: 'callback',
              onClick: () => {
                mutate({
                  variables: {
                    input: {
                      content: content.trim(),
                    },
                  },
                });
              },
            },
          ]}
          {...rest}
        />

        {attachments?.length > 0 && (
          <section className={classes.filesAttachedSection}>
            <ul className={classes.filesList}>
              <>
                <Typography color="primary" variant="subtitle1">Anexos</Typography>
                {attachments.map((attachment, index) => (
                  <>
                    <li key={index} className={classes.file}>
                      <Typography variant="caption">{attachment.fileName}</Typography>

                      <IconButton onClick={() => {
                        const newPayload = attachments.filter((att) => att !== attachment);
                        setAttachments(newPayload);
                      }}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </li>
                  </>
                ))}
              </>
            </ul>
          </section>
        )}

        <Spacer spacing={4} />

        <FormLabel>Pré-visualização</FormLabel>
        <Spacer spacing={1} />

        {data && (
          <div className={classes.preview}>
            <div
              className={classes.previewContent}
              dangerouslySetInnerHTML={{
                __html: data,
              }}
            />

            <Spacer spacing={2} />
          </div>
        )}
      </div>
    </>
  );
}

export const convertToMjml = (content: RawDraftContentState): string => {
  const mjmlPayload: string[] = [];

  const contentEntitiesData = content.entityMap || [];

  // Format headers
  content.blocks?.forEach((block, j) => {
    const mjmlParagraph: string[] = [];

    let textContent = block.text;
    if (block.type === 'header-two') {
      mjmlParagraph.push(
        mjmlHeader(textContent),
      );
    }

    const entities = block.entityRanges || [];

    entities.forEach((entity) => {
      const entityData = contentEntitiesData[entity.key];

      if (entityData.type === 'IMAGE') {
        const imageUrl = entityData?.data?.url;

        mjmlParagraph.push(mjmlImage(imageUrl));
      }
    });

    // Just Text
    if (block.type === 'unstyled' && entities?.length <= 0) {
      mjmlParagraph.push(mjmlText(textContent));
    }

    mjmlPayload.push(mjmlParagraph.join());
  });

  return mjmlRoot(mjmlPayload.join());
};
