/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import SlideDownModal from 'components/modals/modal-slide-down/slide-down-modal';
import { ModalQueryParams } from 'data/app';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { appearEffect } from 'styles/animations';
import Spacer from 'components/spacer/spacer';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/auth.redux';
import {
  container, section, sectionImage, sectionTitle, formContainer, emptySection,
} from '../../../styles/navbar.styles';

const useStyles = makeStyles((theme) => ({
  container,
  emptySection: emptySection(theme),
  section: section(theme),
  sectionImage: sectionImage(theme),
  sectionTitle: sectionTitle(theme),
  formContainer,
  ...appearEffect,
  linkContainer: {
    display: 'flex',
    color: `${theme.palette.common.white} !important`,
    background: 'rgba(0, 0, 0, .9)',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 4),
    alignItems: 'center',
    textDecoration: 'none !important',

    '&:hover': {
      background: theme.palette.primary.main,

      transition: '0.25s ease all',
    },
  },
  link: {
    fontWeight: 500,
  },
  registrationCompleteContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  checkIcon: {
    marginBottom: theme.spacing(4),

    '&.MuiSvgIcon-root': {
      width: theme.spacing(6),
      height: theme.spacing(6),

      color: theme.palette.primary.main,
    },
  },
}));

export default function SignupSuccessModal() {
  const currentUser = useSelector(selectCurrentUser);
  const classes = useStyles();

  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.SIGN_UP_SUCCESS);

  if (!isOpen || currentUser) {
    return null;
  }

  return (
    <SlideDownModal
      onClose={handleClose}
    >
      <div className={classes.container}>
        <div className={classes.sectionImage} style={{ backgroundImage: 'url(/images/anjb_signup.jpg)', backgroundPosition: 'center center' }} />

        <section className={classes.emptySection} />
        <section className={classes.section} style={{ alignItems: 'center' }}>

          <div className={classes.formContainer}>
            <div className={classes.registrationCompleteContainer}>
              <CheckCircleIcon className={classes.checkIcon} />

              <Typography variant="h4" color="primary">
                Conta criada com sucesso!
              </Typography>

              <Spacer spacing={2} />

              <Typography variant="h6">
                A tua conta foi criada com sucesso!
                Para a ativar, segue as instruções que te foram enviadas para o e-mail.
              </Typography>
            </div>
          </div>
        </section>
      </div>
    </SlideDownModal>
  );
}
