/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import Label from '@material-ui/icons/Label';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SectionStepper from 'components/section-stepper/section-stepper';
import { useQuery } from '@apollo/client';
import { BucketFile, BucketProjection, listBucketsGql } from 'data/storage';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import Spacer from 'components/spacer/spacer';
import BucketFileUpload from 'components/inputs/input-bucket-file-upload/bucket-file-upload';
import SearchBar from 'components/search-bars/search-bar/search-bar';
import BucketManagerHeader from './bucket-manager-header';
import AddBucket from './bucket-manager-add-bucket';
import BucketItem from './bucket-item';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    height: 264,
    flexGrow: 1,
  },
  folderContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(1),
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  filesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  searchBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  searchBar: {
    display: 'flex',
    maxWidth: 300,
  },
}));

interface Props {
  onFileSelect: (selectedFile: BucketFile, bucketName?: string) => void;
}
export default function BucketManager({ onFileSelect }: Props) {
  const classes = useStyles();

  const {
    data: rawData, loading, error, refetch,
  } = useQuery(listBucketsGql, {
    fetchPolicy: 'network-only',
  });

  const data: BucketProjection[] = rawData?.listBuckets || [];

  return (
    <>
      <SectionStepper displayText="Google Cloud - Buckets" />

      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        <div className={classes.addButtonContainer}>
          <AddBucket refetch={refetch} />
        </div>
        <Spacer spacing={1} />

        <TreeView
          className={classes.root}
          defaultExpanded={['3']}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}
        >

          {data.map((bucket, index) => (
            <div key={index}>
              <BucketManagerHeader nodeId={bucket.id} labelText={bucket.bucketName} labelIcon={Label} refetch={refetch}>
                <div className={classes.folderContainer}>
                  <Spacer spacing={1} />

                  <BucketFileUpload bucketName={bucket.bucketName} refetch={refetch} />

                  <BucketFilesList classes={classes} refetch={refetch} bucket={bucket} onFileSelect={onFileSelect} />

                </div>
              </BucketManagerHeader>
            </div>
          ))}
        </TreeView>
      </DataRenderer>
    </>
  );
}

interface BucketFilesListProps {
  classes: Record<string, any>
  bucket: BucketProjection
  refetch: () => void;
  onFileSelect: (selectedFile: BucketFile, bucketName?: string) => void;
}
function BucketFilesList({
  classes, bucket, refetch, onFileSelect,
}: BucketFilesListProps) {
  const [search, setSearch] = useState('');

  const filteredResult = search?.length > 0
    ? bucket.bucketFiles.filter((bucket) => bucket.fileName.toLowerCase().includes(search.toLowerCase()))
    : bucket.bucketFiles;

  return (
    <>
      <Spacer spacing={2} />
      <div className={classes.searchBarContainer}>
        <div className={classes.searchBar}>
          <SearchBar onChange={(value) => setSearch(value)} />
        </div>
      </div>
      <Spacer spacing={2} />
      <div className={classes.filesGrid}>
        {filteredResult.map((bucketFile, index) => (
          <div key={index}>
            <BucketItem bucketName={bucket.bucketName} bucketFile={bucketFile} refetch={refetch} onFileSelect={onFileSelect} />
          </div>
        ))}
      </div>
    </>
  );
}
