/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';

import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';

import { deleteSubscriptionPlanGql, listSubscriptionPlansGql } from 'data/subscription-plans';

export default function ManageSubscriptionPlansPage() {
  const subscriptionPlansFeature: BackofficeFeature = {
    name: 'Modalidades',
    url: APP_ROUTES.backoffice.subscriptionPlans.manageSubscriptionPlans,
    listQuery: listSubscriptionPlansGql,
    listQuerySelector: 'listSubscriptionPlans',
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Nome',
        field: 'name',
        flex: 0.5,
      },
      {
        headerName: 'Descrição',
        field: 'description',
        flex: 2,
      },
      {
        headerName: 'Preço da Jóia',
        field: 'admissionFee',
        flex: 0.5,
      },
      {
        headerName: 'Preço Anual',
        field: 'amount',
        flex: 0.5,
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.VIEW_SUBSCRIPTION_PLAN,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_SUBSCRIPTION_PLAN,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_SUBSCRIPTION_PLAN,
    deleteMutation: deleteSubscriptionPlanGql,
    addLabel: 'Adicionar modalidade'
  };

  return (
    <BackofficeFactory
      features={[
        subscriptionPlansFeature,
      ]}
    />
  );
}
