/* eslint linebreak-style: ["error", "unix"] */
import { ModalQueryParams } from 'data/app';
import SignupForm from 'components/forms/form-signup/signup-form';
import NavbarFormModal from '../modal-navbar-form/navbar-form-modal';

export default function SignupModal() {
  return (
    <NavbarFormModal
      sectionTitle="Torna-te sócio"
      backgroundImage="/images/anjb_signup.jpg"
      modalQueryParam={ModalQueryParams.SIGN_UP}
    >
      <SignupForm />
    </NavbarFormModal>
  );
}
