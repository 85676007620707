/* eslint linebreak-style: ["error", "unix"] */
import {
  IconButton, InputAdornment, makeStyles, TextField,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useState } from 'react';
import { navbarField } from 'styles/navbar-form-styles';

const useStyles = makeStyles((theme) => ({
  field: navbarField(theme),
}));

interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
  register: any;
  errors: Record<string, any>;
}

export default function PasswordField({
  name = 'password',
  label = 'Senha',
  placeholder = 'Senha',
  register,
  errors,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  return (
    <TextField
      className={classes.field}
      name={name}
      color="primary"
      label={label}
      placeholder={placeholder}
      type={showPassword ? 'text' : 'password'}
      required
      error={errors?.[name]}
      helperText={errors?.[name]?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((state) => !state)}
              onMouseDown={(e: any) => e.preventDefault()}
              edge="end"
              style={{ color: '#FFF' }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        ref: register,
      }}
    />
  );
}
