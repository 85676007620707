/* eslint linebreak-style: ["error", "unix"] */
import { useMutation, useQuery } from '@apollo/client';
import {
  Button, Divider, FormControlLabel, FormHelperText, makeStyles, Radio, RadioGroup, TextField, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { PaymentMethod } from 'data/payments';
import { User } from 'data/users';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { getSubscriptionPriceDetailsGql, requestSubscriptionPaymentGql, SubscriptionPriceDetails } from 'data/subscription-payment';
import DataRenderer from 'components/data-renderer/data-renderer';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
  },
  payButton: {
    display: 'flex',
    width: '100%',

    '&.MuiButton-contained': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: 0,
    },
  },
}));

interface Props {
  user: User
  onCompleted: () => void;
}

const validationSchema = yup.object().shape({
  paymentMethod: stringRequired,
  phoneNumber: yup.number().test('test', 'Deve conter exatamente 9 dígitos', function test(value) {
    // @ts-ignore
    if (this?.parent?.paymentMethod !== PaymentMethod.MB_WAY) {
      return true;
    }

    return value?.toString()?.length === 9;
  }).typeError('Deve conter números apenas'),
});

export default function SubscribeFormPaymentConfirmation({ user, onCompleted }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    errors: formErrors, register, watch, control, handleSubmit,
  } = formApi;

  const paymentMethodValue = watch('paymentMethod');

  const subscription = user?.subscription;

  const {
    data: rawData,
    loading: subscriptionPriceDetailsLoading,
    error: subscriptionPriceDetailsError,
  } = useQuery(
    getSubscriptionPriceDetailsGql,
    {
      variables: {
        subscriptionId: subscription?.id,
      },
      skip: !subscription,
    },
  );
  const subscriptionPriceDetails: SubscriptionPriceDetails = rawData?.getSubscriptionPriceDetails;

  const [mutate, { loading: isSubmitting }] = useMutation(requestSubscriptionPaymentGql, {
    onCompleted: () => {
      enqueueSnackbar('Pedido de pagamento criado com sucesso', { variant: 'success' });

      onCompleted();
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  const onSubmit = (formData: Record<string, any>) => {
    const input = formData?.paymentMethod === PaymentMethod.MB_WAY
      ? {
        paymentMethod: formData?.paymentMethod,
        phoneNumber: formData?.phoneNumber.toString(),
      }
      : {
        paymentMethod: formData?.paymentMethod,
      };

    mutate({
      variables: {
        input: {
          ...input,
          subscriptionId: subscription?.id,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <DataRenderer
        loading={subscriptionPriceDetailsLoading}
        error={formatGraphqlErrorMessage(subscriptionPriceDetailsError)}
      >
        <Typography color="primary">
          Plano
        </Typography>
        <Typography>
          {subscription?.subscriptionPlan?.name}
        </Typography>
        <Spacer spacing={1} />
        <Divider />
        <Spacer spacing={2} />

        {subscriptionPriceDetails?.admissionFee > 0 && (
          <>
            <Typography color="primary">
              Valor da Jóia
            </Typography>

            <Typography>
              {subscriptionPriceDetails?.admissionFee}
              {' '}
              €
            </Typography>
            <Typography variant="caption">
              (Aplicado a novos sócios)
            </Typography>
            <Spacer spacing={2} />
          </>
        )}

        {subscriptionPriceDetails?.dueAmount > 0 && (
          <>
            <Typography color="primary">
              Valor de Quotas em Atraso
            </Typography>

            <Typography>
              {subscriptionPriceDetails?.dueAmount}
              {' '}
              €
            </Typography>
            <Typography variant="caption">
              (Relativo a
              {' '}
              {subscriptionPriceDetails?.dueYears}
              {' '}
              {subscriptionPriceDetails?.dueYears > 1 ? 'anuidades' : 'anuidade'}
              {' '}
              em atraso)
            </Typography>
            <Spacer spacing={2} />
          </>
        )}

        {subscriptionPriceDetails?.basePrice > 0 && (
          <>
            <Typography color="primary">
              Valor da Quota
            </Typography>

            <Typography>
              {subscriptionPriceDetails?.basePrice}
              {' '}
              €
            </Typography>
            <Spacer spacing={2} />
          </>
        )}

        <Divider />
        <Spacer spacing={2} />

        <Typography color="primary">
          Valor Total
        </Typography>

        {subscriptionPriceDetails?.admissionFee > 0
          ? (
            <div>
              <Typography>
                <s>
                  {subscriptionPriceDetails?.basePrice + subscriptionPriceDetails?.admissionFee}
                  {' '}
                  €
                </s>
              </Typography>
              <Typography>
                {subscriptionPriceDetails?.finalAmount}
                {' '}
                €
              </Typography>
              <Typography variant="caption">
                (Calculada relativa à data atual. A quota é válida até ao final do ano corrente)
              </Typography>
            </div>
          )
          : (
            <Typography>
              {subscriptionPriceDetails?.finalAmount}
              {' '}
              €
            </Typography>
          )}
        <Spacer spacing={2} />

        <Divider />
        <Spacer spacing={2} />
        <Typography color="primary">
          Método de Pagamento
        </Typography>

        <Controller
          name="paymentMethod"
          as={(
            <RadioGroup aria-label="quiz" name="paymentMethod">
              <FormControlLabel value={PaymentMethod.MB_WAY} control={<Radio />} label="MB Way" />
              <FormControlLabel value={PaymentMethod.MULTIBANCO} control={<Radio />} label="Multibanco" />

              <FormHelperText>
                {formErrors?.paymentMethod?.message}
              </FormHelperText>
            </RadioGroup>
          )}
          control={control}
        />
        <Spacer spacing={2} />

        {paymentMethodValue === PaymentMethod.MB_WAY && (
          <>
            <TextField
              name="phoneNumber"
              label="Número de Telemóvel"
              type="text"
              inputProps={{
                ref: register,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!formErrors?.phoneNumber}
              helperText={formErrors?.phoneNumber?.message}
              style={{
                width: '100%',
              }}
            />
            <Spacer spacing={2} />
          </>
        )}

        <Spacer spacing={2} />

        <Button type="submit" color="primary" className={classes.payButton} variant="contained" disabled={isSubmitting}>
          Avançar para o pagamento
        </Button>
      </DataRenderer>
    </form>
  );
}
