/* eslint linebreak-style: ["error", "unix"] */

import useTheme from '@material-ui/core/styles/useTheme';
import { Typography, makeStyles } from '@material-ui/core';
import EditorReadonly from 'components/editor-readonly/editor-readonly';
import Date from 'components/date/date';
import Share from 'components/share/share';
import { APP_ROUTES } from 'data/app';
import Spacer from 'components/spacer/spacer';
import { Link } from 'react-router-dom';
import { Publication as IPublication } from 'data/publications';
import { formatToDateAndHour } from 'utils/formatters';

const useStyles = makeStyles((theme) => ({
  publicationTitle: {
    display: 'flex',
    textDecoration: 'none',
    margin: theme.spacing(2, 0),

    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  categoriesContainer: {
    display: 'flex',

    '& a': {
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.main,
      },
    },
  },
  categoryCard: {
    display: 'flex',
    background: theme.palette.common.white,
    outline: '1px solid rgba(0, 0, 0, 0.1)',
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    display: 'flex',
    width: '100%',
    flex: '0 0 100%',
  },
  shareSection: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.primary.main}`,
  },
}));

interface Props {
  publication: IPublication
}

/**
 * Renders a publication article
 */
export default function Publication({ publication }: Props) {
  const classes = useStyles();
  const theme = useTheme();

  const shareUrl = `https://${window.location.host}${APP_ROUTES.publication}/${publication?.id}`;

  if (!publication) {
    return (
      <Typography>
        Publicação não encontrada
      </Typography>
    );
  }

  return (
    <>
      <Link to={`${APP_ROUTES.publication}/${publication?.id}`} className={classes.publicationTitle}>
        <Typography variant="h4" color="primary">
          {publication?.name}
        </Typography>
      </Link>

      <section className={classes.header}>
        <Date date={formatToDateAndHour(publication?.createdAt)} color={theme.palette.secondary.main} justifyContent="flex-start" />

        <Spacer spacing={2} />

        <div className={classes.categoriesContainer}>
          {publication?.publicationCategories?.map((publicationCategory, index) => (
            <Link to={`${APP_ROUTES.publicationByCategoryId}/${publicationCategory?.id}`} key={index}>
              <article className={classes.categoryCard}>
                <Typography variant="subtitle2">
                  {publicationCategory?.name}
                </Typography>
              </article>
            </Link>
          ))}
        </div>
      </section>

      {publication?.image
        && (
          <section className={classes.imageContainer}>
            <img
              className={classes.image}
              alt={`imagem da publicação ${publication?.name} - ${publication?.image}`}
              src={publication?.image}
            />
          </section>
        )}

      <Spacer spacing={4} />

      <EditorReadonly
        defaultValue={publication?.content}
        readOnly
        toolbar={false}
      />

      <div className={classes.shareSection}>
        <Typography>Partilhar publicação: </Typography>
        <Share small link={shareUrl} />
      </div>
    </>
  );
}
