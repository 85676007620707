/* eslint linebreak-style: ["error", "unix"] */

import {
  withStyles, Theme, createStyles, makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { removeItemFromShoppingCart } from 'redux/shopping-cart.redux';
import { useDispatch } from 'react-redux';
import ProductQuantity from 'components/product-quantity/product-quantity';
import ProductVariantPrice from 'components/product-variant-price/product-variant-price';
import { Button, Typography } from '@material-ui/core';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { ModalQueryParams } from 'data/app';
import { ProductVariant } from 'data/product-variants';

const StyledTableCell = withStyles((theme: Theme) => createStyles({
  head: {
    fontSize: theme.typography.subtitle1.fontSize,
    textTransform: 'uppercase',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme: Theme) => createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 'unset',
    '& .MuiTableCell-root': {
      border: 'none',
      borderRight: `1px solid ${theme.palette.common.white}`,
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiTableCell-root': {
        padding: theme.spacing(0.5),
        fontSize: theme.typography.caption.fontSize,
      },
    },
  },
  tableHead: {
    '&.MuiTableRow-root': {
      background: theme.palette.primary.main,
      '& th': {
        color: theme.palette.common.white,
        textTransform: 'capitalize',
      },
    },
  },
  productCell: {
    textAlign: 'center',
  },
  viewProductLink: {
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  productImageContainer: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(2),
    maxWidth: theme.spacing(10),
    margin: 'auto',
  },
  productImage: {
    width: '100%',
    height: '100%',
  },

}));

interface Props {
  productVariants: ProductVariant[]
  readonly?: boolean
}

export default function OrderedProductsList({
  productVariants = [],
  readonly = false,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRemoveArticle = (productVariantId: number) => {
    dispatch(removeItemFromShoppingCart({
      productVariantId,
    }));
  };

  if (!productVariants.length) {
    return (
      <TableContainer>
        <Typography>
          O carrinho de compras está vazio.
        </Typography>
      </TableContainer>
    );
  }

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.tableHead}>
            <StyledTableCell align="center">Produto</StyledTableCell>
            <StyledTableCell align="center">Quantidade</StyledTableCell>
            <StyledTableCell align="center">Preço (unidade)</StyledTableCell>
            <StyledTableCell align="center">Preço (total)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productVariants.map((productVariant) => (
            <StyledTableRow key={productVariant.id}>
              <StyledTableCell component="th" scope="row" className={classes.productCell}>
                <PrimaryLink to={`?${ModalQueryParams.VIEW_PRODUCT(productVariant?.product?.id)}`} underline={false}>
                  <Typography variant="subtitle1" color="primary" className={classes.viewProductLink}>
                    {productVariant.product.name}
                  </Typography>
                </PrimaryLink>

                <Typography variant="subtitle2">
                  (
                  {productVariant.productFilters.map((filter) => filter.value).join(', ')}
                  )
                </Typography>
                <div className={classes.productImageContainer}>
                  <img
                    className={classes.productImage}
                    alt=""
                    src={productVariant?.productImages?.[0]}
                  />
                </div>
                {
                  !readonly && (
                    <Button color="primary" onClick={() => handleRemoveArticle(productVariant?.id)}>
                      <Typography variant="caption">Remover artigo</Typography>
                    </Button>
                  )
                }
              </StyledTableCell>
              <StyledTableCell align="center">
                <ProductQuantity productVariant={productVariant} readonly={readonly} />
              </StyledTableCell>
              <StyledTableCell align="center">
                <ProductVariantPrice productVariant={productVariant} relativeToQuantity={false} />
              </StyledTableCell>
              <StyledTableCell align="center">
                <ProductVariantPrice productVariant={productVariant} relativeToQuantity />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
