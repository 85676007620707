/* eslint linebreak-style: ["error", "unix"] */

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Theme, createStyles, fade, InputBase,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { selectSearchFilters, setProductSearchFilters } from 'redux/product-search.redux';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => createStyles({
  search: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: fade(theme.palette.common.white, 0.98),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 1),
    },
    marginLeft: 0,
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    opacity: 0.5,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function StoreSearchbar() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const searchFilters = useSelector(selectSearchFilters);
  const currentValue = searchFilters?.name;

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Pesquisar..."
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={currentValue}
        onChange={(event) => {
          const name = event.target.value;
          dispatch(setProductSearchFilters({
            ...searchFilters,
            name,
          }));
        }}
      />
    </div>
  );
}
