/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: theme.spacing(8),
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingTop: theme.spacing(2),
      alignItems: 'center',
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  title: {
    color: theme.palette.primary.main,
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
    marginBottom: theme.spacing(0),
    fontWeight: theme.typography.fontWeightBold as number,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h4.fontSize,
      textShadow: '2px 2px 10px rgba(0, 0, 0, .75)',
    },

    textTransform: 'uppercase',
  },
  subtitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
    fontWeight: theme.typography.fontWeightLight as number,

    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
      textShadow: '2px 2px 10px rgba(0, 0, 0, .75)',
    },
  },
}));

export default function HeroContent() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h3" className={classes.title}>
        <strong>
          Associação Nacional de
          {' '}
          <br />
          Juízes de Basquetebol
        </strong>
      </Typography>

      <Typography variant="h4" className={classes.subtitle}>
        Renascer para todos e com todos
      </Typography>
    </div>
  );
}
