/* eslint linebreak-style: ["error", "unix"] */
import { Switch, Route } from 'react-router-dom';

import HomePage from 'pages/home-page/home-page';
import AboutPage from 'pages/about-page/about-page';
import TeamPage from 'pages/team-page/team-page';
import PartnersPage from 'pages/partners-page/partners-page';
import ContactPage from 'pages/contact-page/contact-page';

// Publications
import PublicationPage from 'pages/publication-page/publication-page';
import PublicationsListPage from 'pages/publications-list-page/publications-list-page';
import PublicationsByCategoryPage from 'pages/publications-by-category-page/publications-by-category-page';

import VerifyEmailPage from 'pages/verify-email-page/verify-email-page';

// Subscriber
import SubscriberAreaPage from 'pages/subscriber-area-page/subscriber-area-page';

import StorePage from 'pages/store-page/store-page';
import ProductPage from 'pages/product-page/product-page';
import ShoppingCartCheckoutPage from 'pages/shopping-cart-checkout-page/shopping-cart-checkout-page';

// Account
import ManageAccountPage from 'pages/manage-account-page/manage-account-page';

// Orders
import ManageOrdersPage from 'pages/manage-orders-page/manage-orders-page';
import OrderDetailsPage from 'pages/order-details-page/order-details-page';

import PrivacyPolicyPage from 'pages/privacy-policy-page/privacy-policy-page';
import TermsAndConditionsPage from 'pages/terms-and-conditions-page/terms-and-conditions-page';
import NotFoundPage from 'pages/not-found-page/not-found-page';

import { APP_ROUTES } from 'data/app';
import SubscribePage from 'pages/subscribe-page/subscribe-page';
import EditorialPage from 'pages/editorial-page/editorial-page';
import BackofficePage from 'pages/backoffice-page/backoffice-page';
import DynamicPage from 'pages/_dynamic-page/dynamic-page';
import AuthenticatedRoute from './routes.guard.authenticated';
import UnauthenticatedRoute from './routes.guard.unauthenticated';
import UnsubscriberRoute from './routes.guard.unsubscriber';
import SubscriberRoute from './routes.guard.subscriber';
import AdminRoute from './routes.guard.admin';

export default function Routes() {
  return (
    <Switch>

      <Route exact component={HomePage} path={APP_ROUTES.home} />
      <Route component={DynamicPage} path={APP_ROUTES.about} />
      <Route component={DynamicPage} path={APP_ROUTES.editorial} />
      <Route component={TeamPage} path={APP_ROUTES.team} />
      <Route component={PartnersPage} path={APP_ROUTES.partners} />
      <Route component={ContactPage} path={APP_ROUTES.contact} />

      {/** Terms and conditions */}
      <Route exact component={DynamicPage} path={APP_ROUTES.termsAndConditions} />
      <Route exact component={DynamicPage} path={APP_ROUTES.privacyPolicy} />

      {/** Publications */}
      <Route component={PublicationsByCategoryPage} path={APP_ROUTES.publicationByCategoryId} />
      <Route component={PublicationPage} path={APP_ROUTES.publication} />
      <Route component={PublicationsListPage} path={APP_ROUTES.publications} />

      {/** Store */}
      <Route path={APP_ROUTES.store} component={StorePage} />
      <Route path={APP_ROUTES.product} component={ProductPage} />
      <Route path={APP_ROUTES.shoppingCart} component={ShoppingCartCheckoutPage} />

      {/** Account */}
      <AuthenticatedRoute path={APP_ROUTES.orderDetails} component={OrderDetailsPage} />
      <AuthenticatedRoute path={APP_ROUTES.manageAccount} component={ManageAccountPage} />
      <AuthenticatedRoute path={APP_ROUTES.manageOrders} component={ManageOrdersPage} />

      {/** Subscribe */}
      <UnsubscriberRoute path={APP_ROUTES.subscribe} component={SubscribePage} />
      <SubscriberRoute path={APP_ROUTES.subscriberArea.root} component={SubscriberAreaPage} />

      {/** Backoffice */}
      <AdminRoute path={APP_ROUTES.backoffice.root} component={BackofficePage} />

      {/** E-mail verifications */}
      <UnauthenticatedRoute path={APP_ROUTES.verifyEmail} component={VerifyEmailPage} />

      <Route component={NotFoundPage} />
    </Switch>
  );
}
