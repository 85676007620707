/* eslint linebreak-style: ["error", "unix"] */
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  makeStyles, TextField, Tooltip,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';

import { useForm } from 'react-hook-form';
import { stringRequired } from 'utils/validators';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { defaultNewsletterTemplate, sendNewsletterGql } from 'data/newsletter';
import MJMLEditorInput from 'components/inputs/input-mjml-editor/input-mjml-editor';
import { useSelector } from 'react-redux';
import { selectedPublicationId, selectSelectedMailingList } from 'redux/newsletter.redux';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import MJMLRawEditor from 'components/inputs/input-mjml-editor-old/input-mjml-editor-old';
import { getPublicationByIdGql, Publication } from 'data/publications';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  subject: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: theme.palette.divider,
    },
  },
}));

const validationSchema = yup.object().shape({
  subject: stringRequired,
});

export interface Props {
  submitButtonLabel?: string;
  onSuccess?: () => void;
}

export default function SendNewsletterForm({ onSuccess }: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const mailingList = useSelector(selectSelectedMailingList);

  const {
    register, errors, handleSubmit, setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const [currentEditor, setCurrentEditor] = useState<'rich' | 'raw'>('rich');

  const [content, setContent] = useState('');
  const [attachments, setAttachments] = useState<{
    bucketName: string,
    fileName: string
  }[]>([]);

  const [mutate, { loading }] = useMutation(sendNewsletterGql, {
    onError: (error) => enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' }),
    onCompleted: () => {
      enqueueSnackbar('Newsletter foi enviada com sucesso', { variant: 'success' });

      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const publicationId = useSelector(selectedPublicationId);

  const { data: rawData } = useQuery(getPublicationByIdGql, {
    variables: {
      id: publicationId,
    },
    skip: !publicationId,
  });

  const data: Publication = rawData?.getPublicationById;
  const mjmlEditorDefaultContent = data?.content || '';

  const onSubmit = (formData: Record<string, any>) => {
    const input = {
      mailingList,
      subject: formData?.subject,
      content,
      attachments,
    };

    mutate({
      variables: {
        input,
      },
    });
  };

  const handleCurrentEditor = (event: React.MouseEvent<HTMLElement>, editor: 'rich' | 'raw') => {
    setCurrentEditor(editor);

    if (editor === 'rich') {
      setContent('');
    } else {
      setContent(defaultNewsletterTemplate);
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name=""
        label="A enviar para"
        defaultValue={mailingList.join(', ')}
        disabled
        multiline
        style={{
          overflow: 'scroll',
          maxHeight: 200,
        }}
      />
      <Spacer spacing={4} />

      <Tooltip title="Assunto da newsletter">
        <TextField
          name="subject"
          label="Assunto"
          className={classes.subject}
          inputProps={{
            ref: register,
          }}
          error={Boolean(errors?.subject)}
          helperText={errors?.subject?.message}
          variant="outlined"
        />
      </Tooltip>
      <Spacer spacing={2} />

      <ToggleButtonGroup
        value={currentEditor}
        onChange={handleCurrentEditor}
        exclusive
        aria-label="text alignment"
      >
        <ToggleButton value="rich" aria-label="rich">
          Editor Visual
        </ToggleButton>
        <ToggleButton value="raw" aria-label="raw">
          Editor Código MJML
        </ToggleButton>
      </ToggleButtonGroup>
      <Spacer spacing={2} />
      {
        currentEditor === 'rich'
          ? (
            <MJMLEditorInput
              defaultValue={mjmlEditorDefaultContent}
              content={content}
              setContent={setContent}
              attachments={attachments}
              setAttachments={setAttachments}
            />
          )
          : (
            <MJMLRawEditor content={content} setContent={setContent} />
          )
      }

      <Spacer spacing={4} />

      <Button type="submit" variant="outlined" color="primary" disabled={loading || mailingList?.length <= 0}>
        {!loading ? 'Enviar newsletter' : <CircularProgress />}
      </Button>

    </form>
  );
}
