/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, MenuItem, Select, Typography,
} from '@material-ui/core';
import { UserRole } from 'data/users';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    '& .MuiSelect-select': {
      color: theme.palette.common.black,
    },
    '&.MuiInput-underline:before': {
      border: 'none',
    },
    '&.MuiInput-underline': {
      background: 'rgba(255, 255, 255, 0.1)',
      border: 'none',
      color: theme.palette.common.black,
    },
    '&.MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.common.black,
    },
  },
  helperText: {
    color: theme.palette.primary.main,
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

interface Props {
  value?: string;
  onSelect?: (value: string) => void;
}

export default function SelectUserRole({
  value,
  onSelect,
}: Props) {
  const classes = useStyles();

  const options = [
    {
      label: 'Utilizador',
      value: UserRole.USER,
    },
    {
      label: 'Administrador',
      value: UserRole.ADMIN,
    },
  ]

  return (
    <Select
      className={classes.select}
      value={value}
      displayEmpty
      onChange={(evt: any) => onSelect && onSelect(evt.target.value)}
    >
      <MenuItem disabled>
        Escolher categoria
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} className={classes.item}>
          <Typography>
            {option.label}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
