/* eslint linebreak-style: ["error", "unix"] */
import { Switch, Route } from 'react-router-dom';
import { APP_ROUTES } from 'data/app';
import SubscriptionsHistory from './subscriptions-history-subpage/subscriptions-history-subpage';
import SubscriberNews from './subscriber-news-subpage/subscriber-news-subpage';
import SubscriberNewsDetail from './subscriber-news-details-subpage/subscriber-news-details-subpage';
import DocumentsListSubpage from './documents-list-subpage/documents-list-subpage';
import DocumentsDetail from './documents-details-subpage/documents-details-subpage';
import SubscriberDynamicPageSubpage from './subscriber-dynamic-page-subpage/subscriber-dynamic-page-subpage';

export default function SubscriberRoutes() {
  return (
    <Switch>
      <Route component={SubscriberDynamicPageSubpage} path={APP_ROUTES.subscriberArea.dynamicPage} />
      <Route component={DocumentsDetail} path={APP_ROUTES.subscriberArea.documentsDetail} />
      <Route component={DocumentsListSubpage} path={APP_ROUTES.subscriberArea.documents} />
      <Route component={SubscriptionsHistory} path={APP_ROUTES.subscriberArea.subscriptionsHistory} />
      <Route component={SubscriberNewsDetail} path={APP_ROUTES.subscriberArea.newsDetail} />
      <Route component={SubscriberNews} path={APP_ROUTES.subscriberArea.root} />
    </Switch>
  );
}
