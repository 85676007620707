/* eslint linebreak-style: ["error", "unix"] */
import { gql } from '@apollo/client';
import moment from 'moment';

export interface SubscriptionPlan {
  id: number
  name: string
  description: string
  admissionFee: number
  amount: number
  startingDay: number
  startingMonth: number
  endingDay: number
  endingMonth: number
}

export const getSubscribedUntilDateFromSubscriptionPlan = (
  subscriptionPlan: SubscriptionPlan,
) => {
  const { endingDay, endingMonth } = subscriptionPlan;

  const endingSubscriptionDay = moment().set({
    date: endingDay,
    month: endingMonth - 1,
    year: moment().year(),
  });

  return endingSubscriptionDay;
};

export const subscriptionPlansMinimumDetailsFragment = gql`
  fragment subscriptionPlansMinimumDetailsFragment on SubscriptionPlansEntity {
    id
    name
    admissionFee
    amount
    description
  }
`;

export const subscriptionPlansFullDetailsFragment = gql`
  fragment subscriptionPlansFullDetailsFragment on SubscriptionPlansEntity {
    id
    name
    description
    admissionFee
    amount
    startingDay
    startingMonth
    endingDay
    endingMonth
  }
`;

// Mutations
export const createSubscriptionPlanGql = gql`
  mutation createSubscriptionPlan($input: ICreateSubscriptionPlan!) {
    createSubscriptionPlan(input: $input)
  }
`;

export const updateSubscriptionPlanGql = gql`
  mutation updateSubscriptionPlan($input: IUpdateSubscriptionPlan!) {
    updateSubscriptionPlan(input: $input)
  }
`;

export const deleteSubscriptionPlanGql = gql`
  mutation deleteSubscriptionPlan($input: IDeleteSubscriptionPlan!) {
    deleteSubscriptionPlan(input: $input)
  }
`;

// Queries
export const getSubscriptionPlanByIdGql = gql`
  query getSubscriptionPlanById($id: Int!) {
    getSubscriptionPlanById(id: $id) {
      ...subscriptionPlansFullDetailsFragment
    }
  }
  ${subscriptionPlansFullDetailsFragment}
`;

export const getSubscriptionPlanByUserIdGql = gql`
  query getSubscriptionPlanByUserId {
    getSubscriptionPlanByUserId {
      ...subscriptionPlansFullDetailsFragment
    }
  }
  ${subscriptionPlansFullDetailsFragment}
`;

export const listSubscriptionPlansGql = gql`
  query listSubscriptionPlans($filter: ISubscriptionPlansFilter) {
    listSubscriptionPlans(filter: $filter) {
      ...subscriptionPlansMinimumDetailsFragment
    }
  }
  ${subscriptionPlansMinimumDetailsFragment}
`;
