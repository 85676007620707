/* eslint linebreak-style: ["error", "unix"] */
import { Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const navbarField = (theme: Theme): CSSProperties => ({
  display: 'flex',
  margin: theme.spacing(2, 0),
  width: '100%',
  '& .MuiInputBase-input': {
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
      WebkitTextFillColor: theme.palette.common.white,
    },
  },
  '& .MuiInput-underline:before': {
    borderBottom: `2px solid ${theme.palette.common.white}`,
  },
  '& .MuiFormLabel-root': {
    color: theme.palette.common.white,
  },
  '& .MuiInputBase-root': {
    color: theme.palette.common.white,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.primary.main,
  },
  '& .MuiInput-underline.Mui-error:after': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '& .MuiInputAdornment-positionEnd': {
    position: 'absolute',
    right: 0,
  },
});

export const navbarForm: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
};
