/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Link } from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import { useLayoutStyles } from 'styles/layout';
import { APP_ROUTES } from 'data/app';
import PublicationsList from 'components/lists/list-publications/publications-list';

export interface FeedSearchFilters {
  name?: string
  publicationCategories?: number[]
  limit?: number
  offset?: number
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  layout: {
    ...useLayoutStyles(theme),
  },
  seeMoreLink: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
}));

export default function LandingPagePublicationsFeed() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.layout}>
        <div className={classes.wrapper}>
          <SectionTitle>
            Notícias
          </SectionTitle>

          <PublicationsList showPagination={false} initialLimit={8} />

          <Link className={classes.seeMoreLink} variant="body1" href={APP_ROUTES.publications}>
            Ver mais notícias...
          </Link>
        </div>
      </div>
    </div>
  );
}
