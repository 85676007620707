/* eslint linebreak-style: ["error", "unix"] */
import {
  Facebook,
  Whatsapp,
  Mail, // @ts-ignore
} from 'react-social-sharing';

interface Props {
  link: string
  small?: boolean
}

export default function Share({
  link,
  small = false,
}: Props) {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    }}
    >
      <Facebook small link={link} />
      {/* <Twitter small link={link} /> */}
      <Whatsapp small link={link} />
      <Mail small solidcircle link={link} />
    </div>
  );
}
