/* eslint linebreak-style: ["error", "unix"] */
import {
  makeStyles, Box, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core';
import SectionTitle from 'components/section-title/section-title';
import Page from 'pages/_layouts/page';
import { useQuery } from '@apollo/client';
import {
  listUserOwnSubscriptionsGql, Subscription,
} from 'data/subscriptions';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#FFF',
    padding: theme.spacing(2),
    overflow: 'scroll',
    width: '100%',
    minHeight: '100vh',
  },
}));

export default function SubscriptionsHistorySubpage() {
  const classes = useStyles();

  const { data: rawData, loading, error } = useQuery(listUserOwnSubscriptionsGql, {
    variables: {},
  });

  const data: Subscription[] = rawData?.listUserOwnSubscriptions;

  return (
    <Page fullWidth>
      <div className={classes.wrapper}>
        <SectionTitle>
          Histórico de quotas
        </SectionTitle>

        <Box margin={1}>
          <Table size="small" aria-label="purchases">
            <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Modalidade</TableCell>
                  <TableCell>Estado da subscrição</TableCell>
                  <TableCell>Data de Início</TableCell>
                  <TableCell>Data de Fim</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((subscription) => (
                  <TableRow key={subscription?.id}>
                    <TableCell component="th" scope="row">
                      {subscription?.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {subscription?.subscriptionPlan?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {subscription?.endDate
                        ? 'Expirada'
                        : 'Ativa'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {subscription?.startDate ? moment(subscription?.startDate).format('DD/MM/YYYY') : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {subscription?.endDate ? moment(subscription?.endDate).format('DD/MM/YYYY') : '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </DataRenderer>
          </Table>
        </Box>
      </div>
    </Page>
  );
}
