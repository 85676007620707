/* eslint linebreak-style: ["error", "unix"] */
import React, { useEffect, useState, useRef } from 'react';
import {
  DocumentNode, gql, useMutation, useQuery,
} from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import TableGrid from 'components/table-grid/table-grid';
import { DEFAULT_LIMIT } from 'components/table/pagination/page-counter/page-counter';
import Pagination from 'components/table/pagination/pagination';
import useTable from 'hooks/useTable';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import ConfirmModal from 'components/modals/modal-confirm/confirm-modal';
import TableCellActions from 'components/table-cell-actions/table-cell-actions';
import {
  Button,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import HeaderActions from './header-actions/header-actions';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  bottomAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn_add: {
    width: '10%',
    marginBottom: '10px',
  },
}));

export interface ListRendererProps {
  // Graphql
  listQuery: DocumentNode;
  listQuerySelector: string;

  deleteMutation?: DocumentNode;

  // Table
  columns: ColDef[]
  buttons?: any[]

  VIEW_QUERY_PARAM?: (itemId: string) => string;
  UPDATE_QUERY_PARAM?: (itemId: string) => string;

  // Header Actions
  ADD_QUERY_PARAM?: string;

  /** If set, a search bar will be rendered */
  searchTextFilterKey?: string
  /** If set, a date picker will be rendered */
  dateFilterKey?: string
  addLabel?: string;
  addDisabled?: boolean;

  renderTableCell?: (id: number) => React.ReactNode;

  searchTextPlaceholder?: string;

  gridProps?: GridOptions;
}

function generateUUID() {
  let d = new Date().getTime();
  let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 || 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 || 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : ((r && 0x3) || 0x8)).toString(16);
  });
}

export default function ListRenderer({
  listQuery,
  listQuerySelector,
  columns,
  buttons,

  VIEW_QUERY_PARAM,
  UPDATE_QUERY_PARAM,

  deleteMutation,

  ADD_QUERY_PARAM,
  searchTextFilterKey,
  dateFilterKey,
  addLabel,
  addDisabled,

  renderTableCell,
  searchTextPlaceholder,

  gridProps,
}: ListRendererProps) {
  const history = useHistory();
  const classes = useStyles();

  const gridRef = useRef<GridReadyEvent>();
  const guid = generateUUID();

  const {
    limit,
    setLimit,
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    searchText,
    setSearchText,
  } = useTable({ defaultLimit: DEFAULT_LIMIT });

  // ColDef
  const [colDef, _] = useState(
    (VIEW_QUERY_PARAM || UPDATE_QUERY_PARAM || deleteMutation)
      ? columns.concat({
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        cellStyle: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        cellRendererFramework: (params: any) => {
          const id = Number(params?.data?.id);
          return (
            <TableCellActions
              onView={VIEW_QUERY_PARAM ? () => handleView(id) : undefined}
              onEdit={UPDATE_QUERY_PARAM ? () => handleEdit(id) : undefined}
              onDelete={deleteMutation ? () => setItemIdToRemove(id) : undefined}
              renderTableCell={() => renderTableCell && renderTableCell(id)}
            />
          );
        },
      })
      : columns,
  );

  const getButtonsUsingMap = () => {
    if (buttons) {
      return buttons?.map((button, index) => (
        <Button
          className={classes.btn_add}
          key={`btn_${index}_${button.label.toLowerCase().replace(/ /g, '')}`}
          aria-label={button.label}
          onClick={() => (
            button.trigger()
          )}
        >
          {button.label}
          <Add key={`AddIcon_add_${guid}`} />
        </Button>
      ))
    }
    return null;
  }

  // Date Filtering
  const [showFrom, setShowFrom] = useState('');

  let filterParams = {};
  if (dateFilterKey) {
    filterParams = { ...filterParams, [dateFilterKey]: showFrom };
  }
  if (searchTextFilterKey) {
    filterParams = { ...filterParams, [searchTextFilterKey]: searchText };
  }

  // Force the list query to run every time we change the url search params (e.g. after creating something or updating, it forces the changes to reflect on the table)
  useEffect(() => {
    if (!history.location.search) {
      refetch();
    }
  }, [history.location]);

  // Query
  const {
    data: rawData, loading, error, refetch,
  } = useQuery(listQuery, {
    variables: {
      filter: {
        offset,
        limit,
        ...filterParams,
      },
    },
  });

  const data = rawData?.[listQuerySelector] || [];

  const handleAdd = ADD_QUERY_PARAM
    ? () => {
      history.push(`${history.location.pathname}?${ADD_QUERY_PARAM}`);
    }
    : null;

  // Table Actions
  const handleView = (itemId: number) => {
    if (!VIEW_QUERY_PARAM) return;
    history.push(`${history.location.pathname}?${VIEW_QUERY_PARAM(itemId.toString())}`);
  };

  const handleEdit = (itemId: number) => {
    if (!UPDATE_QUERY_PARAM) return;

    history.push(`${history.location.pathname}?${UPDATE_QUERY_PARAM(itemId.toString())}`);
  };

  // Delete
  const [itemIdToRemove, setItemIdToRemove] = useState(-1);

  const { enqueueSnackbar } = useSnackbar();

  // Dummy graphql mutation because we can't conditionally call useMutation
  const fallbackMutation = gql`mutation { null }` as DocumentNode;

  const [mutate] = useMutation(deleteMutation || fallbackMutation, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Item removido com sucesso', { variant: 'success' });

      refetch();
      setItemIdToRemove(-1);
    },
  });

  const handleDelete = () => {
    mutate({
      variables: {
        input: {
          id: itemIdToRemove,
        },
      },
    });
  };

  return (
    <>
      <ConfirmModal
        isOpen={itemIdToRemove !== -1}
        close={() => setItemIdToRemove(-1)}
        confirm={handleDelete}
        confirmationTitle={`Remover item de id #${itemIdToRemove}`}
        confirmationText="Estás prestes a remover este item permanentemente. Desejas continuar?"
      />

      <HeaderActions
        setSearchText={searchTextFilterKey ? setSearchText : null}
        showFrom={dateFilterKey ? showFrom : ''}
        setShowFrom={dateFilterKey ? setShowFrom : null}
        onAdd={handleAdd}
        addLabel={addLabel}
        addDisabled={addDisabled}
        searchTextPlaceholder={searchTextPlaceholder}
      />

      {getButtonsUsingMap()}

      <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
        <TableGrid
          key={`TableGrid_${guid}_${data.id}`}
          rowHeight={55}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
          }}
          rowData={data}
          columnDefs={colDef}
          onGridReady={(event) => {
            gridRef.current = event;
          }}
          height={400}
          {...gridProps}
        />
        <section className={classes.bottomAction}>
          <Pagination
            key={`pagination_${guid}`}
            data={data}
            setCurrentLimit={setLimit}
            setCurrentOffset={setOffset}
            setCurrentPage={setCurrentPage}
            currentLimit={limit}
            currentPage={currentPage}
            showAll
          />

          <Tooltip
            key={`Tooltip_${guid}`}
            title="Exportar dados para um .csv"
          >
            <IconButton
              key={`IconButton_${guid}`}
              onClick={() => {
                gridRef?.current?.api?.exportDataAsCsv({ columnKeys: colDef.filter((x) => x.field !== 'actions' && x.field !== 'checkbox').map((x) => x.field) as string[] });
              }}
            >
              <GetAppIcon key={`GetAppIcon_${guid}`} />
            </IconButton>
          </Tooltip>
        </section>
      </DataRenderer>
    </>
  );
}
