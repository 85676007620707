/* eslint linebreak-style: ["error", "unix"] */

import {
  Button, Divider, makeStyles, TextField, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { selectCurrentUser } from 'redux/auth.redux';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { clearShoppingCart } from 'redux/shopping-cart.redux';
import { APP_ROUTES } from 'data/app';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { selectProductVariantQuantities } from 'redux/product-variants.redux';
import { ProductVariant } from 'data/product-variants';
import { createProductOrderGql } from 'data/product-orders';
import {
  emailRequired, numberOptional, phoneRequired, postalCodeRequired, stringOptional, stringRequired,
  numberRequiredtaxpayerNumber,
} from 'utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.primary.main,
    },
  },
  button: {
    '&.MuiButton-contained': {
      borderRadius: 0,
      boxShadow: 'none',
      color: theme.palette.common.white,
    },
  },
  errorMessage: {
    color: theme.palette.primary.main,
  },
}));

const validationSchema = yup.object().shape({
  fullName: stringRequired,
  email: emailRequired,
  address: stringRequired,
  postalCode: postalCodeRequired,
  phone: phoneRequired,
  taxpayerNumber: numberRequiredtaxpayerNumber,
  observations: stringOptional,
});

interface Props {
  productVariantsInShoppingCart: ProductVariant[]
}

export default function ShoppingCartBuyerDetailsForm({ productVariantsInShoppingCart }: Props) {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const selectedProductVariantQuantities = useSelector(selectProductVariantQuantities);

  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const formApi = useForm<Record<string, any>>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: currentUser?.profile?.fullName,
      email: currentUser?.email,
      address: currentUser?.profile?.address,
      postalCode: currentUser?.profile?.postalCode,
      phone: currentUser?.profile?.phoneNumber,
      taxpayerNumber: currentUser?.profile?.taxpayerNumber,
    },
  });

  const { register, errors, handleSubmit } = formApi;

  const [mutate, { loading }] = useMutation(createProductOrderGql, {
    onCompleted: (data) => {
      history.push(`${APP_ROUTES.orderDetails}/${data?.createProductOrder?.id}`);

      enqueueSnackbar('Encomenda criada com sucesso', { variant: 'success' });

      dispatch(clearShoppingCart());
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
  });

  const onSubmit = (data: Record<string, any>) => {
    const input = {
      ...data,
      phone: data?.phone?.toString(),
      shoppingItems: productVariantsInShoppingCart.map((productVariantInShoppingCart) => ({
        productVariantId: productVariantInShoppingCart.id,
        quantity: selectedProductVariantQuantities.find(
          (selectedProductVariantQuantity) => selectedProductVariantQuantity.productVariantId === productVariantInShoppingCart.id,
        )?.quantity,
      })),
      taxpayerNumber: Number(data?.taxpayerNumber),
    };

    mutate({
      variables: {
        // @ts-ignore
        input,
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="primary">
        Dados pessoais
      </Typography>
      <Divider />
      <Spacer spacing={4} />

      <TextField
        name="fullName"
        type="text"
        label="Nome completo"
        inputProps={{
          ref: register,
        }}
        error={errors?.fullName}
        helperText={errors?.fullName?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="email"
        type="email"
        label="E-mail"
        inputProps={{
          ref: register,
        }}
        error={errors?.email}
        helperText={errors?.email?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="address"
        type="text"
        label="Endereço, Nº Porta, Andar"
        inputProps={{
          ref: register,
        }}
        error={errors?.address}
        helperText={errors?.address?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="postalCode"
        type="text"
        label="Código postal"
        inputProps={{
          ref: register,
        }}
        error={errors?.postalCode}
        helperText={errors?.postalCode?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="phone"
        label="Número de Telemóvel"
        type="text"
        inputProps={{
          ref: register,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors?.phone}
        helperText={errors?.phone?.message}
      />
      <Spacer spacing={2} />

      <TextField
        name="taxpayerNumber"
        label="Número de Contribuinte"
        type="number"
        inputProps={{
          ref: register,
        }}
        error={!!errors?.taxpayerNumber}
        helperText={errors?.taxpayerNumber?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Spacer spacing={2} />

      <TextField
        name="observations"
        type="text"
        label="Observações"
        inputProps={{
          ref: register,
        }}
        multiline
        rows={2}
        error={errors?.observations}
        helperText={errors?.observations?.message}
      />
      <Spacer spacing={2} />

      <Spacer spacing={4} />
      <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={loading}>
        Avançar para a compra
      </Button>
    </form>
  );
}
