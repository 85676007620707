/* eslint linebreak-style: ["error", "unix"] */
import useSubscribeReduxAction from 'hooks/useSubscribeReduxAction';
import { useSnackbar } from 'notistack';
import { AUTH_FORCED_LOGOUT } from 'redux/auth.redux';

export default function Notifications() {
  const { enqueueSnackbar } = useSnackbar();

  useSubscribeReduxAction(AUTH_FORCED_LOGOUT, () => {
    enqueueSnackbar('Sessão expirada', { variant: 'error' });
  });

  return null;
}
