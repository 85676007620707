/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import DataRenderer from 'components/data-renderer/data-renderer';
import SectionTitle from 'components/section-title/section-title';
import Spacer from 'components/spacer/spacer';
import { APP_ROUTES } from 'data/app';
import { getUserByIdGql, User } from 'data/users';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import Page from 'pages/_layouts/page';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser, updateUser } from 'redux/auth.redux';
import SubscribeFormPaymentConfirmation from 'components/forms/form-subscribe-payment-confirmation/subscribe-form-payment-confirmation';
import SubscribeFormPaymentStatus from 'components/forms/form-subscribe-payment-status/subscribe-form-payment-status';
import SubscribeForm from 'components/forms/form-subscribe-request/subscribe-form';
import { isSubscriber } from 'data/subscriptions';
import { PaymentStatus } from 'data/payments';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',

    boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageBannerContainer: {
    display: 'flex',
    width: '100%',
    height: 250,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 200,
    },
  },
  imageBackground: {
    display: 'flex',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme.palette.common.white,
    padding: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function SubscribePage() {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const {
    data: rawData, loading, error, refetch,
  } = useQuery(getUserByIdGql, {
    variables: {
      id: currentUser?.id,
    },
    fetchPolicy: 'network-only',
    skip: !currentUser,
    pollInterval: 30 * 1000,
    onCompleted: (data) => {
      // Always update user in redux with the new details
      dispatch(updateUser(data?.getUserById));
    },
  });

  if (!currentUser) {
    history.push(APP_ROUTES.home);
    return null;
  }

  const data: User = rawData?.getUserById;

  const neverSubscribed = data?.subscription == null;
  const subscriptionExpired = Boolean(data?.subscription?.endDate);

  const awaitingPaymentRequest = !data?.subscription?.subscriptionPayments?.some((subscriptionPayment) => subscriptionPayment.paymentStatus === PaymentStatus.PENDING);

  const paymentCompleted = isSubscriber(data);

  const awaitingPayment = !awaitingPaymentRequest;

  const renderContent = () => {
    if (neverSubscribed) {
      return <SubscribeForm user={data} onCompleted={refetch} />;
    }

    if (paymentCompleted) {
      // Update user on redux
      dispatch(updateUser(data));

      // TODO: Rendering snackbar twice
      // enqueueSnackbar('Subscrito com sucesso!', { variant: 'success' });

      history.push(APP_ROUTES.subscriberArea.root);

      return null;
    }
    if (awaitingPaymentRequest) {
      return <SubscribeFormPaymentConfirmation user={data} onCompleted={refetch} />;
    }

    if (awaitingPayment) {
      return <SubscribeFormPaymentStatus user={data} />;
    }
  };

  return (
    <Page>
      <SectionTitle>
        Aderir à ANJB
      </SectionTitle>
      <div className={classes.imageBannerContainer}>
        <div className={classes.imageBackground} style={{ backgroundImage: 'url(/images/anjb_juizes.jpg)' }} />
      </div>
      <section className={classes.root}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>

          <div className={classes.container}>
            <Spacer spacing={2} />

            {renderContent()}
          </div>
        </DataRenderer>
      </section>
    </Page>
  );
}
