/* eslint linebreak-style: ["error", "unix"] */
import { withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import EmptyIcon from '@material-ui/icons/ShoppingCartOutlined';
import FullIcon from '@material-ui/icons/ShoppingCart';
import { useSelector } from 'react-redux';
import { selectShoppingItems } from 'redux/shopping-cart.redux';
import { APP_ROUTES } from 'data/app';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import PrimaryLink from 'components/links/link-primary/primary-link';
import Spacer from 'components/spacer/spacer';
import { listProductVariantsGql } from 'data/product-variants';
import OrderedProductsList from 'components/lists/list-ordered-products/ordered-products-list';
import { useState, MouseEvent } from 'react';
import { selectCurrentUser } from 'redux/auth.redux';
import { UserRole } from 'data/users';

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    '& a': {
      textDecoration: 'none',
    },
  },
  checkoutButtonContainer: {
    display: 'flex',
    width: '100%',

    '& a': {
      width: '100%',
    },
    '& button': {
      width: '100%',
    },
  },
  counterContainer: {
    display: 'flex',
    position: 'relative',
  },
  counter: {
    position: 'absolute',
    borderRadius: '50%',
    background: theme.palette.common.white,
    top: -23,
    right: -7.5,
    width: 18,
    height: 18,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export default function ShoppingCartBadge() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const currentShoppingItems = useSelector(selectShoppingItems);

  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.role === UserRole.ADMIN;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cartIsEmpty = !currentShoppingItems || !currentShoppingItems.length;

  const isOpen = Boolean(anchorEl);

  const { data: rawData, loading, error } = useQuery(listProductVariantsGql, {
    variables: {
      filter: {
        productVariantIds: currentShoppingItems.map((shoppingItem) => shoppingItem.productVariantId),
      },
    },
    skip: !isOpen || cartIsEmpty,
  });

  const data = rawData?.listProductVariants;

  const renderCounter = () => {
    if (cartIsEmpty) {
      return null;
    }

    return (
      <div className={classes.counterContainer}>
        <div className={classes.counter}>
          <Typography style={{
            color: theme.palette.primary.main,
            fontSize: 14,
            fontWeight: 900,
          }}
          >
            {currentShoppingItems?.length}

          </Typography>
        </div>
      </div>
    );
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        {
          cartIsEmpty
            ? <EmptyIcon />
            : <FullIcon style={{ fill: theme.palette.primary.main }} />
        }
        {renderCounter()}
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleClose}
      >
        <div className={classes.menuContainer}>
          <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
            <OrderedProductsList productVariants={data} />

            <Spacer spacing={2} />
            <div className={classes.checkoutButtonContainer}>
              {cartIsEmpty
                ? (
                  <>
                    <PrimaryLink to={APP_ROUTES.store}>
                      <Button color="primary" onClick={handleClose}>
                        Visitar a loja
                      </Button>
                    </PrimaryLink>
                  </>
                )
                : (
                  <PrimaryLink to={APP_ROUTES.shoppingCart}>
                    <Button color="primary" onClick={handleClose}>
                      Avançar para o checkout
                    </Button>
                  </PrimaryLink>
                )}
            </div>
          </DataRenderer>
        </div>
      </StyledMenu>
    </div>
  );
}
