/* eslint linebreak-style: ["error", "unix"] */
/* eslint-disable no-use-before-define */
import {
  makeStyles, Theme, createStyles, useTheme,
} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from 'components/swipeable-drawer/swipeable-drawer';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import PrimaryLink from 'components/links/link-primary/primary-link';
import { Divider, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    maxWidth: 340,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.appBar - 1,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      minHeight: 'unset',
      height: '100%',
    },
  },
  menu: {
    padding: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: `${theme.palette.secondary.main}`,

  },
  item: {
    marginLeft: theme.spacing(0.5),
  },
  active: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  routes: {
    name: string,
    icon: React.FC,
    path: string,
  }[]
}

export default function BackofficeMenu({
  routes,
}: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderContent = () => (
    <div className={classes.menu}>
      <Typography variant="h5" color="primary">
        Backoffice
      </Typography>
      <Spacer spacing={1} />
      <Divider />

      <Spacer spacing={2} />

      {routes.map(
        (route, index) => renderItem(
          index, classes, route.name, route.icon, route.path, location.pathname,
        ),
      )}
    </div>
  );

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        {isMobile
          ? (
            <SwipeableDrawer title="Backoffice">
              {renderContent()}
            </SwipeableDrawer>
          )
          : (
            renderContent()
          )}
      </List>
    </>
  );
}

const renderItem = (
  index: number,
  classes: Record<string, any>,
  title: string,
  Icon: React.FC,
  to: string,
  currentLocation: string,
) => (
  <PrimaryLink to={to} underline={false} key={index}>
    <ListItem button className={`${classes.itemContainer} ${to === currentLocation ? classes.active : ''}`}>
      <Icon />
      <ListItemText className={classes.item} primary={title} primaryTypographyProps={{ variant: 'button' }} />
    </ListItem>
  </PrimaryLink>
);
