/* eslint linebreak-style: ["error", "unix"] */

import { makeStyles } from '@material-ui/core';
import YouTube from 'react-youtube';
import CloseButton from '../close-button/close-button';
import { TEST_IDS } from '../landing-page-hero';

const ANJB_YOUTUBE_VIDEO_ID = 'Uu8Y0-aiqZ4';

interface Props {
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  youtubeVideo: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',

    '& iframe': {
      position: 'relative',
      display: 'flex',
      height: '100%',
      width: '100%',
    },
  },
}));

export default function HeroVideo({ onClose }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid={TEST_IDS.heroVideo}>
      <YouTube
        opts={
          {
            playerVars: {
              autoplay: 1,
              controls: 1,
            },
          }
        }
        videoId={ANJB_YOUTUBE_VIDEO_ID}
        containerClassName={classes.youtubeVideo}
      />

      <CloseButton onClick={onClose} />
    </div>
  );
}
