/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles, Typography } from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Spacer from 'components/spacer/spacer';
import { getModalStyle } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';
import { getProductOrderByIdGql, ProductOrder } from 'data/product-orders';
import { formatToDateAndHour } from 'utils/formatters';
import SectionStepper from 'components/section-stepper/section-stepper';
import useGetTransportationFee from 'hooks/useGetTransportationFee';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';

const useStyles = makeStyles((theme) => ({
  modal: getModalStyle(theme),
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  productOrderDetailsContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRight: `2px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
  },
}));

export default function ModalViewProductOrder() {
  const classes = useStyles();
  const { transportationFee, loading: transportationFeeLoading, error: transportationFeeError } = useGetTransportationFee();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<ProductOrder>(
    ModalQueryParams.VIEW_PRODUCT_ORDER,
    getProductOrderByIdGql,
    'getProductOrderById',
  );

  const renderInfo = (label: string, value: string | number | null) => (
    <div className={classes.paragraph}>
      <Typography variant="body1" color="primary">
        {label}
      </Typography>
      <Typography>
        {value || '-'}
      </Typography>
      <Spacer spacing={2} />
    </div>
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading || transportationFeeLoading} error={errorMessage || formatGraphqlErrorMessage(transportationFeeError)}>
        <Typography variant="h6" color="primary">
          Encomenda Nr. #
          {data?.id}
          {' '}
        </Typography>
        <Spacer spacing={2} />
        <Typography variant="body2">
          Encomendado a
          {' '}
          {formatToDateAndHour(data?.orderedAt)}
        </Typography>

        <Spacer spacing={4} />

        <SectionStepper displayText="Produtos Encomendados" />

        {
          data?.productOrderDetails?.map((productOrderDetail, index) => (
            <div key={index} className={classes.productOrderDetailsContainer}>
              <div>
                {renderInfo('Nome do Produto', productOrderDetail.productVariant.product.name)}
                {renderInfo('Especificações',
                  productOrderDetail.productVariant.productFilters?.map(
                    (filter) => `${filter.productFilterCategory.filterName}: ${filter.value}`,
                  )?.join(', '))}
                {renderInfo('Quantidade encomendada: ', productOrderDetail?.quantity)}
                {renderInfo('Preço Normal: ', `${productOrderDetail?.productVariant?.product?.price} €`)}
                {renderInfo('Preço Sócio: ', `${productOrderDetail?.productVariant?.product?.subscriberPrice} €`)}
              </div>
              <img src={productOrderDetail.productVariant.productImages?.[0]} alt={productOrderDetail.productVariant.product.name} height={100} />
            </div>
          ))
        }

        <Spacer spacing={4} />

        {renderInfo('Total dos produtos', `${data?.orderAmount}€`)}
        {renderInfo('Taxa de envio', `${transportationFee}€`)}
        {renderInfo('Total', `${data?.orderAmount + transportationFee}€`)}

        <SectionStepper displayText="Detalhes do Cliente" />
        {renderInfo('Morada', data?.address)}
        {renderInfo('Código Postal', data?.postalCode)}
        {renderInfo('Nome Completo', data?.fullName)}
        {renderInfo('E-Mail', data?.email)}
        {renderInfo('Contacto telefónico', data?.phone)}
        {renderInfo('Nr° Contribuinte', data?.taxpayerNumber)}
        {renderInfo('Observações', data?.observations)}

        <SectionStepper displayText="Dados do Pagamento" />
        {renderInfo('Referência', data?.productOrderPayment?.reference)}
        {renderInfo('Entidade', data?.productOrderPayment?.entity)}
        {renderInfo('Identificador', data?.productOrderPayment?.identifier)}
        {renderInfo('Método de Pagamento', data?.productOrderPayment?.paymentMethod)}
        {renderInfo('Valor', data?.productOrderPayment?.amount)}
        {renderInfo('Pago a', data?.productOrderPayment?.paidAt)}

      </DataRenderer>
    </Modal>
  );
}
