/* eslint linebreak-style: ["error", "unix"] */
import { RootState } from 'libs/redux/redux.store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useSubscribeReduxAction(actionToSubscribe: string, callback: () => void) {
  const lastAction = useSelector((state: RootState) => state.lastAction);

  useEffect(() => {
    if (lastAction?.type === actionToSubscribe) {
      callback();
    }
  }, [lastAction]);
}
