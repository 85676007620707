/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import OrderBy from './filters/order-by';
import PriceFilter from './filters/price-filter';
import ProductCategories from './filters/product-categories';
import ProductCategoryFilterCategories from './filters/product-category-filter-categories';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

export default function ProductSearchFilters() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ProductCategories />
      <ProductCategoryFilterCategories />

      <Spacer spacing={2} />
      <OrderBy />

      <Spacer spacing={2} />
      <PriceFilter />
    </div>
  );
}
