/* eslint linebreak-style: ["error", "unix"] */

import {
  makeStyles,
} from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { formatToDateAndHour } from 'utils/formatters';
import { ProductOrderPayment } from 'data/product-order-payments';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  productOrderPayment: ProductOrderPayment;
}

export default function ProductOrderPaymentCompletedDetails({
  productOrderPayment,
}: Props) {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <Typography variant="h6" color="primary">
        Estado do Pagamento
      </Typography>
      <Divider />
      <Spacer spacing={4} />

      <Typography variant="subtitle1" color="textSecondary">
        Pagamento efetuado com sucesso a
        {' '}
        {formatToDateAndHour(productOrderPayment.paidAt, ' à')}
      </Typography>

      <Spacer spacing={4} />

    </section>
  );
}
