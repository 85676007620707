/* eslint linebreak-style: ["error", "unix"] */
import { makeStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ModalQueryParams } from 'data/app';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';

const useStyles = makeStyles((theme) => ({
  preview: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'transparent',
    cursor: 'pointer',
    border: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.9)',
    },

    '& svg': {
      fill: theme.palette.common.white,
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    transition: '0.5s ease all',
  },
}));

interface Props {
  publicationId: number
}

export default function PublicationPreviewButton({
  publicationId,
}: Props) {
  const classes = useStyles();

  const { handleOpen } = useModalWithQueryParams(ModalQueryParams.READ_PUBLICATION(publicationId));

  return (
    <button className={`${classes.preview} previewButton`} onClick={handleOpen}>
      <VisibilityIcon color="primary" />
    </button>
  );
}
