/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import {
  makeStyles, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography,
} from '@material-ui/core';
import Spacer from 'components/spacer/spacer';
import { ModalQueryParams } from 'data/app';
import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import { useQuery } from '@apollo/client';
import DataRenderer from 'components/data-renderer/data-renderer';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import { listSubscriptionPlansGql, SubscriptionPlan } from 'data/subscription-plans';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    minWidth: 650,
  },
}));

export default function SubscriptionPlansListModal() {
  const classes = useStyles();

  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.SUBSCRIBER_PERKS);

  const { data: rawData, loading, error } = useQuery(listSubscriptionPlansGql, {
    variables: {},
    skip: !isOpen,
  });

  const data: SubscriptionPlan[] = rawData?.listSubscriptionPlans;

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      hasCloseButton
      closeButtonComponent={CloseButtonWhite}
    >
      <div className={classes.container}>
        <DataRenderer loading={loading} error={formatGraphqlErrorMessage(error)}>
          <Spacer spacing={2} />

          <Typography variant="h5" color="textPrimary">
            Opções disponíveis
          </Typography>

          <Spacer spacing={2} />

          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Modalidade</strong></TableCell>
                  <TableCell align="left" size="medium" variant="head">Descrição</TableCell>
                  <TableCell align="left" size="small" variant="head">Valor de admissão</TableCell>
                  <TableCell align="left" size="small" variant="head">Valor anual</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <strong>{row.name}</strong>
                    </TableCell>
                    <TableCell align="left" size="medium">{row.description}</TableCell>
                    <TableCell align="left" size="small">
                      {row.admissionFee}
                      {' '}
                      €
                    </TableCell>
                    <TableCell align="left" size="small">
                      {row.amount}
                      {' '}
                      €
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DataRenderer>
      </div>
    </Modal>
  );
}
