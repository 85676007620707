/* eslint linebreak-style: ["error", "unix"] */
import Page from 'pages/_layouts/page';
import SectionTitle from 'components/section-title/section-title';
import EmailVerificationStatus from 'components/email-verification-status/email-verification-status';

export default function VerifyEmailPage() {
  return (
    <Page>
      <SectionTitle>
        Verificar e-mail
      </SectionTitle>

      <EmailVerificationStatus />
    </Page>
  );
}
