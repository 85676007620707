/* eslint linebreak-style: ["error", "unix"] */
import React, { useRef, useState } from 'react';
import {
  Button,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { ModalQueryParams } from 'data/app';
import Spacer from 'components/spacer/spacer';
import SectionStepper from 'components/section-stepper/section-stepper';

import TableGrid from 'components/table-grid/table-grid';

import useModalWithQueryParams from 'hooks/useModalWithQueryParams';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectSubscriptionPlansForCreateUser from 'components/selects/select-subscription-plans-for-create-user/select-subscription-plans-for-create-user';
import { GridRowId } from '@material-ui/data-grid';
import BackupIcon from '@material-ui/icons/Backup';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useMutation, useQuery } from '@apollo/client';
import { createUsersGql } from 'data/user-bulk-import';
import { useSnackbar } from 'notistack';
import { formatGraphqlErrorMessage } from 'libs/graphql/graphql-error-formatters';
import moment from 'moment';
import { listSubscriptionPlansGql, SubscriptionPlan } from 'data/subscription-plans';
import deburr from 'lodash.deburr';
import camelCase from 'lodash.camelcase';
import { isEmail } from 'class-validator';
import shortid from 'shortid';
import { GridReadyEvent } from 'ag-grid-community';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  modal: {
    '& .MuiPaper-root ': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '100%',
        padding: theme.spacing(2),
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
  },
}));

interface CreatedUser {
  id: GridRowId
  membershipNumber: number
  username: string
  email: string
  allowedSubscriptionPlanId: number | null
  taxpayerNumber: number | null
  phoneNumber: number | null
  subscriptionStartDate: string | null
  subscriptionEndDate: string | null
}

export default function ModalCreateUser() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<any>();
  const { isOpen, handleClose } = useModalWithQueryParams(ModalQueryParams.CREATE_USER);

  const gridRef = useRef<GridReadyEvent>();

  const { data: subscriptionPlansRawData } = useQuery(listSubscriptionPlansGql, {
    variables: {
      filter: {
        offset: 0,
        limit: 999,
      },
    },
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    skip: !isOpen,
  });
  const subscriptionPlansData: SubscriptionPlan[] = subscriptionPlansRawData?.listSubscriptionPlans || [];

  const [mutate, { loading }] = useMutation(createUsersGql, {
    onError: (error) => {
      enqueueSnackbar(formatGraphqlErrorMessage(error), { variant: 'error' });
    },
    onCompleted: () => {
      enqueueSnackbar('Utilizadores criados com sucesso', { variant: 'success' });
    },
  });

  const parseFile = (textContent: string) => {
    const rows = textContent.slice(textContent.indexOf('\n') + 1).split('\n');

    const rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      rowData.push(node.data);
    });

    const startingIndex = rowData?.length;
    const payload = rows.map((row, index) => {
      const entries = row.split(',').map((x) => x.trim());

      let username = entries[1];
      username = deburr(username);
      username = camelCase(username);

      let subscriptionStartDate = entries[6];
      let subscriptionEndDate = entries[7];

      if (subscriptionStartDate?.length <= 0 && subscriptionEndDate?.length > 0) {
        subscriptionStartDate = moment()
          .date(1)
          .month(0)
          .year(Number(subscriptionEndDate))
          .format('YYYY-MM-DD');
      }

      if (subscriptionEndDate?.length === 4) {
        subscriptionEndDate = moment()
          .date(31)
          .month(11)
          .year(Number(subscriptionEndDate))
          .format('YYYY-MM-DD');
      }

      const email = entries[2];
      if (isEmail(email) === false) {
        window.alert(`Email inválido para o utilizador ${username}: Email - ${email}`);
      }

      const allowedSubscriptionPlanId = subscriptionPlansData?.find((x) => x.name === entries[3])?.id;

      return {
        id: startingIndex + index,
        membershipNumber: entries[0],
        username,
        email: entries[2],
        allowedSubscriptionPlanId,
        taxpayerNumber: entries[4],
        phoneNumber: entries[5],
        subscriptionStartDate,
        subscriptionEndDate,
      };
    });


    gridRef?.current?.api?.setRowData(payload);
  };

  const handleDelete = (id: GridRowId) => {
    const rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      rowData.push(node.data);
    });

    gridRef?.current?.api?.setRowData(rowData.filter((x: any) => x.id !== id));
  };

  const handleSubmit = () => {
    // @ts-ignore
    const rowData: any[] = [];
    gridRef?.current?.api?.forEachNode((node) => {
      rowData.push(node.data);
    });

    let hasError = false;
    rowData.forEach((userData) => {
      if (userData.allowedSubscriptionPlanId == null) {
        enqueueSnackbar(`Categoria de sócio inválida para ${userData.username}`, { variant: 'error' });
        hasError = true;
      }
    });

    if (hasError) {
      return;
    }

    mutate({
      variables: {
        input: {
          users: rowData.map((userData) => {
            const { id, ...rest } = userData;

            const allowedSubscriptionPlanId = typeof rest.allowedSubscriptionPlanId === 'number'
              ? rest.allowedSubscriptionPlanId
              : subscriptionPlansData.find((x: any) => x.name === rest.allowedSubscriptionPlanId)?.id;

            return {
              ...rest,
              membershipNumber: Number(rest.membershipNumber),
              taxpayerNumber: Number(rest.taxpayerNumber),
              allowedSubscriptionPlanId,
            };
          }),
        },
      },
    });
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <div className={classes.container}>
        <SectionStepper displayText="Criar utilizadores" />

        <i>(Um e-mail será enviado para os utilizadores criados completarem o processo de registo.)</i>
        <Spacer spacing={2} />

        <Divider />

        <Spacer spacing={2} />
        <ul>
          <li>- Uma subscrição com data de início e sem data de fim significa que está ativa;</li>
          <li>- Uma subscrição com data de início e data de fim significa que a subscrição expirou e será cobrado o valor das anuidades em atraso;</li>
          <li>- Uma subscrição sem data de início e sem data de fim signficia que o utilizador nunca foi sócio e será cobrado o valor da jóia;</li>
        </ul>
        <Spacer spacing={4} />

        <div className={classes.toolbar}>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => {
              const rowData: any[] = [];
              gridRef?.current?.api?.forEachNode((node) => {
                rowData.push(node.data);
              });

              const payload = rowData.concat([{ id: shortid.generate() }]);

              gridRef?.current?.api?.setRowData(payload);
            }}
          >
            <span style={{ marginRight: 5, height: 30 }}><PersonAddIcon /></span>

            Adicionar utilizador
          </Button>

          <Button
            className={classes.button}
            variant="outlined"
            component="label"
            onClick={() => setFile(null)}
            onChange={(evt: any) => {
              const reader = new FileReader();

              reader.addEventListener('load', (event) => {
                parseFile(event?.target?.result as string);
              });

              reader.readAsText(evt.target.files[0]);

              setFile('');
            }}
          >
            <span style={{ marginRight: 5, height: 30 }}><BackupIcon /></span>

            Importar utilizadores

            <input
              type="file"
              hidden
              value={file}
            />
          </Button>

          <Button
            className={classes.button}
            variant="outlined"
            onClick={() => gridRef?.current?.api.setRowData([])}
          >
            <span style={{ marginRight: 5, height: 30 }}><DeleteIcon /></span>

            Limpar utilizadores
          </Button>
        </div>

        <TableGrid
          onGridReady={(ref) => {
            gridRef.current = ref;
          }}
          rowData={[]}
          rowHeight={75}
          defaultColDef={{
            resizable: true,
            flex: 1,
            cellStyle: {
              padding: 2,
            },
          }}
          columnDefs={[
            {
              field: 'membershipNumber',
              headerName: 'Nrº Sócio',
              editable: true,
              type: 'number',
              flex: 0.5,
            },
            {
              field: 'username',
              headerName: 'Nome de utilizador',
              editable: true,
            },
            {
              field: 'email',
              headerName: 'E-mail',
              editable: true,
            },
            {
              field: 'allowedSubscriptionPlanId',
              headerName: 'Categoria',
              editable: true,
              flex: 1.5,
              cellRendererFramework: (params: any) => (
                <SelectSubscriptionPlansForCreateUser
                  value={params.value}
                  onSelect={(value) => {
                    params.setValue(value);
                  }}
                />
              ),
            },
            // Profile
            {
              field: 'taxpayerNumber',
              headerName: 'Nrº Contribuinte',
              type: 'number',
              editable: true,
            },
            {
              field: 'phoneNumber',
              headerName: 'Telemóvel',
              editable: true,
            },
            // Subscriptions
            {
              field: 'subscriptionStartDate',
              headerName: 'Início da última quota',
              type: 'date',
              flex: 1,
              cellStyle: {
                padding: 0,
              },
              filter: 'agDateColumnFilter',
              cellRendererFramework: (params: any) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd-MM-yyyy"
                    value={params.value}
                    onChange={(newValue) => {
                      params.setValue(newValue);
                    }}
                  />
                </MuiPickersUtilsProvider>
              ),
            },
            {
              field: 'subscriptionEndDate',
              headerName: 'Fim da última quota',
              type: 'date',
              flex: 1,
              cellStyle: {
                padding: 0,
              },
              filter: 'agDateColumnFilter',
              cellRendererFramework: (params: any) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd-MM-yyyy"
                    value={params.value}
                    onChange={(newValue) => {
                      params.setValue(newValue);
                    }}
                  />
                </MuiPickersUtilsProvider>
              ),
            },
            {
              field: 'remove',
              headerName: '',
              flex: 0.5,
              cellRendererFramework: (params: any) => (
                <IconButton onClick={() => handleDelete(params.data.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              ),
            },
          ]}
        />

        <Spacer spacing={2} />

        <Button onClick={handleSubmit} variant="outlined" color="primary" disabled={loading}>
          Criar utilizadores
        </Button>
      </div>
    </Modal>
  );
}
