/* eslint linebreak-style: ["error", "unix"] */
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface Props {
  onClick?: () => void,
  className?: string,
}

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.secondary.main,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 0,
    maxWidth: '2rem',
    maxHeight: '2rem',
    minWidth: 'unset',
    border: 'none',

    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      border: 'none',
    },
  },
}));

const CloseButtonWhite = ({ onClick, className }: Props) => {
  const classes = useStyles();

  return (
    <Button className={`${classes.button} ${className} modal-close-button`} variant="text" onClick={onClick}>
      <CloseIcon />
    </Button>
  );
};

export default CloseButtonWhite;
