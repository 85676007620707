/* eslint linebreak-style: ["error", "unix"] */
const localization = {
  body: {
    emptyDataSourceMessage: 'Sem resultados...',
  },
  pagination: {
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',

    nextAriaLabel: 'Página seguinte',
    nextTooltip: 'Página seguinte',

    lastTooltip: 'Última página',
    lastAriaLabel: 'Última página',

    labelRowsSelect: 'resultados',

    labelDisplayedRows: '{from}',
  },
  toolbar: {
    searchPlaceholder: 'Pesquisar...',
    searchTooltip: 'Pesquisar',
    exportTitle: 'Exportar dados',
    exportAriaLabel: 'Exportar dados para .csv',
    exportName: 'Exportar dados (.csv)',
  },
};

export default localization;
