/* eslint linebreak-style: ["error", "unix"] */
import Modal from 'components/modals/modal/modal';
import CloseButtonWhite from 'components/buttons/button-close/close-button.white';
import { getPublicationByIdGql, Publication as IPublication } from 'data/publications';
import { ModalQueryParams } from 'data/app';
import DataRenderer from 'components/data-renderer/data-renderer';
import Publication from 'components/publication/publication';
import { useModalStyles } from 'styles/modal.styles';
import useModalQueryWithId from 'hooks/useModalQueryWithId';

export default function PublicationModal() {
  const classes = useModalStyles();

  const {
    data, loading, errorMessage, isOpen, handleClose,
  } = useModalQueryWithId<IPublication>(
    ModalQueryParams.READ_PUBLICATION,
    getPublicationByIdGql,
    'getPublicationById',
  );

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeButtonComponent={CloseButtonWhite}
      hasCloseButton
    >
      <DataRenderer loading={loading} error={errorMessage}>
        <Publication publication={data} />
      </DataRenderer>
    </Modal>
  );
}
