/* eslint linebreak-style: ["error", "unix"] */
import React, { ReactChild, ReactNode } from 'react';
import {
  createStyles, makeStyles, Theme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseButton from 'components/buttons/button-close/close-button';
import { getHeight } from 'components/navbars/navbar/navbar';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    width: '100%',
    zIndex: `${theme.zIndex.appBar - 1} !important` as any,

    '& .MuiDialog-container': {
      width: '100%',
    },

    '& .MuiDialog-paperFullScreen': {
      display: 'flex',

      position: 'absolute',
      bottom: 0,
      height: `calc(100% - ${getHeight()}px)`,
      background: 'rgba(0, 0, 0, 1)',
      color: theme.palette.common.white,
      overflow: 'hidden',
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paperFullScreen': {
        overflow: 'scroll',
      },
    },
  },
  closeButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),

    '& svg': {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
}));

const Transition = React.forwardRef((
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props} />);

interface Props {
  children: ReactNode | ReactChild
  onClose: () => void
  renderCloseButton?: boolean
}

export default function SlideDownModal({ onClose, renderCloseButton = false, children }: Props) {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.container}
      fullScreen
      open
      onClose={onClose}
      TransitionComponent={Transition}
    >
      {renderCloseButton && (
        <div className={classes.closeButton}>
          <CloseButton onClick={onClose} />
        </div>
      )}

      <div className={classes.content}>
        {children}
      </div>
    </Dialog>
  );
}
