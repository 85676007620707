/* eslint linebreak-style: ["error", "unix"] */
import React, { useState } from 'react';
import {
  Button,
  FormHelperText, FormLabel, makeStyles, Typography,
} from '@material-ui/core';
import {
  Control, Controller, SetValueConfig,
} from 'react-hook-form';
import Spacer from 'components/spacer/spacer';
import ImageIcon from '@material-ui/icons/Image';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { BucketFile, isImage } from 'data/storage';
import { useSnackbar } from 'notistack';
import BucketManagerModal from 'components/modals/modal-bucket-manager/bucket-manager-modal';
import { PUBLICATION_IMAGE_SIZES } from 'components/tiles/tile-publication-preview/publication-preview-tile';

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: theme.palette.primary.main,
  },
  uploadedImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  uploadedImageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedImageTile: {
    display: 'flex',
    height: PUBLICATION_IMAGE_SIZES.HEIGHT,
    width: PUBLICATION_IMAGE_SIZES.WIDTH,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 255,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  selectImageButton: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  removeImageButton: {
    borderRadius: 0,
    color: theme.palette.secondary.light,
    '& svg': {
      marginLeft: theme.spacing(1),
      width: 20,
      fill: theme.palette.secondary.light,
    },
  },
}));

interface Props {
  name: string
  label: string
  control: Control<any>
  errors: Record<string, any>
  setValue: (name: string, value: string | null, options?: SetValueConfig) => void;
}

export default function PublicationImageUpload({
  name,
  label,
  control,
  errors,
  setValue,
}: Props) {
  const classes = useStyles();
  const [fileBrowserOpened, setFileBrowserOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onFileSelect = (bucketFile: BucketFile) => {
    if (!isImage(bucketFile.publicUrl)) {
      enqueueSnackbar('Ficheiro selecionado deve ser uma imagem', { variant: 'error' });
      return;
    }

    if (bucketFile.fileName) { setValue(name, bucketFile.publicUrl); }

    setFileBrowserOpened(false);
  };

  const clear = () => {
    setValue(name, null);
  };

  return (
    <>
      {fileBrowserOpened && (
        <BucketManagerModal open={fileBrowserOpened} onClose={() => setFileBrowserOpened(false)} onFileSelect={onFileSelect} />
      )}

      <Controller
        render={({ value }) => {
          const escapedValue = encodeURI(value);

          if (value) {
            return (
              <div className={classes.uploadedImageContainer}>
                <FormLabel>
                  {label}
                </FormLabel>
                <Spacer spacing={1} />

                <div className={classes.uploadedImageContent}>

                  <div className={classes.selectedImageTile} style={{ backgroundImage: `url(${escapedValue})` }} />
                  <Spacer spacing={1} />
                  <Button className={classes.removeImageButton} variant="outlined" onClick={clear}>
                    Remover imagem
                    {' '}
                    <HighlightOffIcon />
                  </Button>
                </div>
              </div>
            );
          }

          return (
            <>
              <FormLabel>
                {label}
              </FormLabel>
              <Spacer spacing={1} />

              <Button
                className={classes.selectImageButton}
                color="primary"
                variant="outlined"
                onClick={() => {
                  setFileBrowserOpened((state) => !state);
                }}
              >
                <ImageIcon />
                Escolher imagem de destaque
              </Button>

              <Spacer spacing={1} />
              <Typography variant="caption" align="center">
                (Tamanho recomendado
                {' '}
                {PUBLICATION_IMAGE_SIZES.WIDTH * 4}
                x
                {PUBLICATION_IMAGE_SIZES.HEIGHT * 4}
                {' '}
                e ficheiros com menos de 200kbs)
              </Typography>
              {errors && <FormHelperText className={classes.helperText}>{errors?.[name]?.message}</FormHelperText>}
            </>
          );
        }}
        name={name}
        control={control}
      />
    </>
  );
}
