/* eslint linebreak-style: ["error", "unix"] */
import { APP_ROUTES, ModalQueryParams } from 'data/app';
import BackofficeFactory, { BackofficeFeature } from 'components/backoffice-factory/backoffice-factory';
import { deletePageGql, listPagesGql } from 'data/pages';

export default function ManagePagesPage() {
  const pageFeature: BackofficeFeature = {
    name: 'Páginas',
    url: APP_ROUTES.backoffice.pages.managePages,
    listQuery: listPagesGql,
    listQuerySelector: 'listPages',
    deleteMutation: deletePageGql,
    columns: [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
      },
      {
        headerName: 'Título',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Url',
        field: 'url',
        flex: 2,
      },
    ],
    VIEW_QUERY_PARAM: ModalQueryParams.READ_PAGE,
    UPDATE_QUERY_PARAM: ModalQueryParams.EDIT_PAGE,
    ADD_QUERY_PARAM: ModalQueryParams.CREATE_NEW_PAGE,

    searchTextFilterKey: 'name',
    addLabel: 'Criar nova página',
  };

  return (
    <BackofficeFactory
      features={[
        pageFeature,
      ]}
    />
  );
}
