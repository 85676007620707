/* eslint linebreak-style: ["error", "unix"] */

import { Typography } from '@material-ui/core';
import EditorReadonly from 'components/editor-readonly/editor-readonly';
import Spacer from 'components/spacer/spacer';
import { Page as IPage } from 'data/pages';

interface Props {
  page: IPage
}

/**
 * Renders a publication article
 */
export default function Page({ page }: Props) {
  if (!page) {
    return (
      <Typography>
        Página não encontrada
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {page?.name}
      </Typography>

      <Spacer spacing={4} />

      <EditorReadonly
        defaultValue={page?.content}
        readOnly
        toolbar={false}
      />
    </>
  );
}
